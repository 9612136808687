module.exports = {
  //Queensland
  "UQ" : null,
  "QUT" : null,
  "USQ" : null,
  "JCU": null,
  "Southern Cross Uni" : null,
  "Griffith" : null,
  "ACU" : null,
  "CQU" : null,
  "Bond" : null,
  "USC" : null,

  //NSW
  "USyd" : null,
  "UoN" : null,
  "UNE" : null,
  "Notre Dame" : null,
  "UTS" : null,
  "UNSW" : null,
  "Macquarie" : null,
  "UWS" : null,
  "CSU" : null,

  //South Australia
  "Adelaide Uni" : null,
  "UniSA" : null,
  "Flinders Uni" : null,

  //WA
  "Curtin" : null,
  "UWA" : null,
  "ECU" : null,

  //VIC
  "UniMelb" : null,
  "Swinburne" : null,
  "RMIT" : null,
  "Federation Uni" : null,
  "Monash" : null,
  "Victoria Uni" : null,

  //NT
  "CDU" : null,

  //TAS
  "UTAS" : null,

  //ACT
  "ANU" : null,
  "UC" : null
};