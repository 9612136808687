import React, { Component } from 'react';

class specificOrder extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order : "",
      targetObject: []
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.renderOrder = this.renderOrder.bind(this);

  }

  handleChange(event) {
    this.setState({order : event.target.value});
  }

  handleClick() {
    var allOrders = this.props.orders;

    var that = this;

    var specificOrder = allOrders.filter(function(ele) {
        return ele.ordernum.toString() === that.state.order;
      });

    this.setState({targetObject : specificOrder})

  }

  renderOrder(targetObject) {

    return Object.keys(targetObject[0]).map(function(key){
      if(key === 'items') {
        return JSON.parse(targetObject[0][key]).map(function(item){
            return (
              <div key={Math.random()}>
                <li key={Math.random()}>
                  Gown: {item.gown || "n/a"}, Cap: {item.cap || "n/a"}, Hood: {item.hood || "n/a"}, Gift: {item.gift || "n/a"}, Qty: {item.qty}, Price: {item.price}
                </li>
              </div>
            )
          })
      } else

      return (
        <div key={Math.random()}>
          <p key={key}>{key}:   {targetObject[0][key]}</p>
        </div>
      )
    })
    for(prop in targetObject) {
      <p>prop: targetObject[]</p>
    }

  }

  render() {

    return (
      <div className="inventory">
        <h1>specificOrder</h1>
        <input
          value={this.state.order}
          onChange={this.handleChange}
        />
        <button onClick={this.handleClick}>Search</button>
          {
            this.state.targetObject.length === 1 &&
            <div>
              { this.renderOrder(this.state.targetObject) }
            </div>
          }
      </div>
    )
  }
};

export default specificOrder;