module.exports = {
  "/uni/qld/uq": {
    "name": "University of Queensland | UQ Gowns",
    "searchName": "UQ | University of Queensland",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008706/Uni%20Campus%20Photos/UQ/UQ_Main_LS.jpg",
    "altTag": "University of Queensland Entrance Photo",
    "abbreviation": "UQ",
    "titleTag": "University Of Queensland Graduation Gowns | UQ Gowns",
    "metaTag": "Gowning Street -  for university of Queensland graduation gowns. We offer a range UQ graduation gowns in different colors & sizes. Get UQ  gowns and academic dress at the very best prices.",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "All Bachelor Degrees",
        "path": "/uni/qld/uq/bachelor-degrees",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "All Master Degrees",
        "path": "/uni/qld/uq/master-degrees",
        "colors": ["#267CD9"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/qld/uq/phd",
        "colors": ["red"]
      }
    ],
    "graduations-ref": "http://www.uq.edu.au/graduations/july-2017-ceremony-dates-times",
    "graduations": [
      {
        "Date": "18 July",
        "Subjects": [
          "Faculty of Health & Behavioural Sciences",
          "School of Economics (Postgraduate)",
          "School of Business (Business Administration and Commerce)",
        ]
      },
      {
        "Date": "19 July",
        "Subjects": [
          "School of Business",
          "School of Law",
          "School of Economics (Undergrad)"
        ]
      },
      {
        "Date": "20 July",
        "Subjects": [
          "School of Communication & Arts",
          "School of Education",
          "School of Historical & Philosophical Inquiry",
          "School of Political Science & International Studies",
          "School of Languages & Cultures",
          "School of Mechanical & Mining Engineering",
          "School of Music",
          "School of Social Science",
          "SMI",
          "School of Architecture",
          "School of Chemical Engineering",
          "School of Civil Engineering",
          "School of Information Technology & Electrical Engineering",
          "AIBN"
        ]
      },
      {
        "Date": "21 July",
        "Subjects": [
          "Faculty of Science",
          "School of Vetinary Science",
          "School of Agriculture & Food Sciences",
          "Faculty of Medicine"
        ]
      },
    ]
  },

  "/uni/qld/qut": {
    "name": "QUT | Queensland University of Technology",
    "searchName": "QUT | Queensland University of Technology",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008735/Uni%20Campus%20Photos/QUT/QUT_Main_LS.jpg",
    "altTag": "QUT main courtyard photo",
    "abbreviation": "QUT",
    "titleTag": "QUT Graduation Gowns | Queensland University of Technology Gowns",
    "metaTag": "Why rent when you can buy your QUT graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at the best prices.",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level" : "Bachelor",
        "name" : "All Bachelor Degrees",
        "path" : "/uni/qld/qut/bachelor-degrees",
        "colors" : ["#0157D5"]
      },
      {
        "level" : "Master",
        "name" : "All Master Degrees",
        "path" : "/uni/qld/qut/master-degrees",
        "colors" : ["#0043A9"]
      },
      {
        "level" : "PhD",
        "name" : "PhD",
        "path" : "/uni/qld/qut/phd",
        "colors" : ["red"]
      }
      // {
      //   "level" : "Bachelor",
      //   "name" : "Business",
      //   "path" : "/uni/qld/qut/bachelor-of-business",
      //   "colors" : ["#0157D5"]
      // },
      // {
      //   "level" : "Bachelor",
      //   "name" : "Creative Industries",
      //   "path" : "/uni/qld/qut/bachelor-of-creative-industries",
      //   "colors" : ["#A73692"]
      // },
      // {
      //   "level" : "Bachelor",
      //   "name" : "Education",
      //   "path" : "/uni/qld/qut/bachelor-of-education",
      //   "colors" : ["#00ADA0"]
      // },
      // {
      //   "level" : "Bachelor",
      //   "name" : "Health",
      //   "path" : "/uni/qld/qut/bachelor-of-health",
      //   "colors" : ["#FF8000"]
      // },
      // {
      //   "level" : "Bachelor",
      //   "name" : "Science & Engineering",
      //   "path" : "/uni/qld/qut/bachelor-of-science-and-engineering",
      //   "colors" : ["#0088D9"]
      // },
      // {
      //   "level" : "Bachelor",
      //   "name" : "Law",
      //   "path" : "/uni/qld/qut/bachelor-of-law",
      //   "colors" : ["#DFDEDC"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Business",
      //   "path" : "/uni/qld/qut/master-of-business",
      //   "colors" : ["#0043A9"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Creative Industries",
      //   "path" : "/uni/qld/qut/master-of-creative-industries",
      //   "colors" : ["#A73692"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Education",
      //   "path" : "/uni/qld/qut/master-of-education",
      //   "colors" : ["#00ADA0"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Health",
      //   "path" : "/uni/qld/qut/master-of-health",
      //   "colors" : ["#FF8000"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Science & Engineering",
      //   "path" : "/uni/qld/qut/master-of-science-and-engineering",
      //   "colors" : ["#0088D9"]
      // },
      // {
      //   "level" : "Master",
      //   "name" : "Law",
      //   "path" : "/uni/qld/qut/master-of-law",
      //   "colors" : ["#DFDEDC"]
      // },
    ],
    "graduations-ref": "https://www.student.qut.edu.au/enrolment/graduating/graduation-ceremony-schedule#h2-1",
    "graduations": [
      {
        "Date": "18 July",
        "Subjects": [
          "Law",
          "Education",
          "Health"
        ]
      },
      {
        "Date": "19 July",
        "Subjects": [
          "Business (Economics, Finance, HR, Mgmt)",
          "Science & Engineering"
        ]
      },
      {
        "Date": "20 July",
        "Subjects": [
          "Business (Accountancy, Philanthropy & Nonprofit Studies)",
          "Advertising, Marketing & Public Relations",
          "International Business",
          "Creative Industries"
        ]
      },
      {
        "Date": "10 - 15 Dec",
        "Subjects": [
          "December graduation ceremonies - exact dates and subjects TBC"
        ]
      },
    ]
  },

  //NSW
  "/uni/nsw/usyd": {
    "name": "USyd | University of Sydney Academic Gowns",
    "searchName": "USyd | University of Sydney",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008747/Uni%20Campus%20Photos/USyd/USyd_Main_LS.jpg",
    "altTag": "USyd main building",
    "titleTag": "USYD Graduation Gowns | Buy University of Sydney Academic Gowns",
    "metaTag": "Find your USYD faculty’s graduation gown here at Gowning Street, where buying is cheaper than renting! Browse the ranges of Usyd graduation gowns. Call 1300 253 618.",
    "abbreviation": "USyd",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce",
        "path": "/uni/nsw/usyd/bachelor-of-commerce",
        "colors": ["#E05834", "white"]
      },
      {
        "level": "Bachelor",
        "name": "Design in Architecture",
        "path": "/uni/nsw/usyd/bachelor-of-architecture",
        "colors": ["#E05834", "white"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/nsw/usyd/bachelor-of-education",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/nsw/usyd/bachelor-of-engineering",
        "colors": ["hsl(0, 100%, 25%)"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/nsw/usyd/bachelor-of-science",
        "colors": ["#EBDB39"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/nsw/usyd/bachelor-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/nsw/usyd/bachelor-of-law",
        "colors": ["lightblue"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine",
        "path": "/uni/nsw/usyd/bachelor-of-medicine",
        "colors": ["#FD4E4D", "#8535A3"]
      },
      {
        "level": "Bachelor",
        "name": "Economics",
        "path": "/uni/nsw/usyd/bachelor-of-economics",
        "colors": ["#BC422C"]
      },
      {
        "level": "Bachelor",
        "name": "Health Science",
        "path": "/uni/nsw/usyd/bachelor-of-health-science",
        "colors": ["#EBDB39", "hsl(200,100%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Nursing",
        "path": "/uni/nsw/usyd/graduation-gown/bachelor-of-nursing",
        "colors": ["#D9DCE5", "#0A9ACE"]
      },
      {
        "level": "Bachelor",
        "name": "Applied Science",
        "path": "/uni/nsw/usyd/graduation-gown/bachelor-of-applied-sci",
        "colors": ["#D9DCE5", "#0A9ACE"]
      },
      {
        "level": "Master",
        "name": "Commerce",
        "path": "/uni/nsw/usyd/master-of-commerce",
        "colors": ["#E05834", "white"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/nsw/usyd/master-of-law",
        "colors": ["#2460CA"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/nsw/usyd/master-of-education",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "Nursing",
        "path": "/uni/nsw/usyd/master-of-nursing",
        "colors": ["#D9DCE5", "#0A9ACE"]
      },
      {
        "level": "Master",
        "name": "Professional Accounting",
        "path": "/uni/nsw/usyd/graduation-gown/master-of-prof-accounting",
        "colors": ["#E05834", "green"]
      },
      {
        "level": "Master",
        "name": "Teaching",
        "path": "/uni/nsw/usyd/graduation-gown/master-of-teaching",
        "colors": ["#FF9999"]
      },
      {
        "level": "Master",
        "name": "Doctor of Medicine",
        "path": "/uni/nsw/usyd/graduation-gown/doctor-of-medicine",
        "colors": ["#FD4E4D", "#8535A3"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/nsw/usyd/phd",
        "colors": ["black", "red"]
      }
    ],
    "graduations-ref": "https://sydney.edu.au/students/graduation/graduation-ceremony-schedule-and-live-streams.html",
    "graduations": [
      {
        "Date": "9 March",
        "Subjects": [
          "Education & Social Work",
          "Pharmacy"
        ]
      },
      {
        "Date": "10 March",
        "Subjects": [
          "Dentistry",
          "Health Sciences"
        ]
      },
      {
        "Date": "31 March",
        "Subjects": [
          "Health Sciences",
          "Music"
        ]
      },
      {
        "Date": "6 April",
        "Subjects": [
          "Architecture, Design & Planning",
          "Nursing"
        ]
      },
      {
        "Date": "7 April",
        "Subjects": [
          "Science",
          "Visual Arts"
        ]
      },
      {
        "Date": "13 April",
        "Subjects": [
          "Education & Social Work",
          "Science"
        ]
      },
      {
        "Date": "21 April",
        "Subjects": [
          "Medicine",
          "Science"
        ]
      },
      {
        "Date": "3 - 5 May",
        "Subjects": [
          "Arts & Social Sciences",
          "Engineering & IT"
        ]
      },
      {
        "Date": "11 May",
        "Subjects": [
          "Business"
        ]
      },
      {
        "Date": "12 May",
        "Subjects": [
          "Agriculture",
          "Business",
          "Economics",
          "Arts & Social Sciences",
          "Vetinary Science"
        ]
      },
      {
        "Date": "25 May",
        "Subjects": [
          "Business"
        ]
      },
      {
        "Date": "26 May",
        "Subjects": [
          "Health Sciences"
        ]
      },
      {
        "Date": "22 Sept",
        "Subjects": [
          "Arts & Social Sciences",
          "Science"
        ]
      },
      {
        "Date": "28 Sept",
        "Subjects": [
          "Arts & Social Sciences",
          "Health Sciences"
        ]
      },
      {
        "Date": "29 Sept",
        "Subjects": [
          "Architecture, Design & Planning",
          "Education & Social Work",
          "Engineering & IT"
        ]
      },
      {
        "Date": "12 - 13 Oct",
        "Subjects": [
          "Business"
        ]
      },
      {
        "Date": "7 Dec",
        "Subjects": [
          "Business",
          "Medicine",
          "Music",
          "Nursing",
          "Visual Arts"
        ]
      },
      {
        "Date": "8 Dec",
        "Subjects": [
          "Health Sciences"
        ]
      },
      {
        "Date": "13 Dec",
        "Subjects": [
          "Agriculture",
          "Vetinary Science"
        ]
      },
    ]
  },

  "/uni/sa/unisa": {
    "name": "University of South Australia | UniSA Graduation Gown",
    "searchName": "UniSA | University of South Australia",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008668/Uni%20Campus%20Photos/UniSA/UniSA_Main.jpg",
    "altTag": "UniSA main building",
    "titleTag": "UniSA Graduation Gowns | University of South Australia Graduation Gowns",
    "metaTag": "Buy UniSA graduation gown from Gowning Street. The graduation gowns available in black, white and purple colors. Buy your favourite one in your size. Order now!",
    "abbreviation": "UniSA",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Architecture and Building",
        "path": "/uni/sa/unisa/bachelor-of-architecture-and-building",
        "colors": ["#DCE0E3"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/sa/unisa/bachelor-of-education",
        "colors": ["#7B32B7"]
      },
      {
        "level": "Bachelor",
        "name": "Society and Culture",
        "path": "/uni/sa/unisa/bachelor-of-society-and-culture",
        "colors": ["#F9E241"]
      },
      {
        "level": "Bachelor",
        "name": "Natural & Physical Sciences",
        "path": "/uni/sa/unisa/bachelor-of-natural-and-physical-sciences",
        "colors": ["pink"]
      },
      {
        "level": "Bachelor",
        "name": "Health Sciences",
        "path": "/uni/sa/unisa/bachelor-of-health-sciences",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/sa/unisa/bachelor-of-engineering",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/sa/unisa/bachelor-of-information-technology",
        "colors": ["beige"]
      },
      {
        "level": "Bachelor",
        "name": "Business & Management",
        "path": "/uni/sa/unisa/bachelor-of-business-and-mgmt",
        "colors": ["hsl(359,94%,32%)"]
      },
      {
        "level": "Bachelor",
        "name": "Environmental Science",
        "path": "/uni/sa/unisa/bachelor-of-environmental-science",
        "colors": ["orange"]
      },
      {
        "level": "Bachelor",
        "name": "Creative Arts",
        "path": "/uni/sa/unisa/bachelor-of-creative-arts",
        "colors": ["lightblue"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Standard Masters",
        "path": "/uni/sa/unisa/masters-standard",
        "colors": ["blue", "gold"]
      },
    ],
    "graduations-ref": "http://www.unisa.edu.au/Student-Life/Support-services/Student-administration/Graduations/Ceremonies/Ceremony-dates-and-times/",
    "graduations": [
      {
        "Date": "15 Aug",
        "Subjects": [
          "ITEE Division Office",
          "School of IT & Maths Sciences",
          "School of Engineering",
          "School of Natural & Built Environments",
          "UniSA College",
          "School of Comms, Intl Studies & Languages",
          "School of Education",
          "EAS Division Office",
          "School of Psychology, Social Work & Social Policy",
          "School of Art, Architecture & Design"
        ]
      },
      {
        "Date": "16 Aug",
        "Subjects": [
          "School of Management",
          "Business Services",
          "School of Nursing & Midwifery",
          "School of Pharmacy & Medical Sciences",
          "Health Sciences Division Office",
          "School of Health Sciences",
          "School of Commerce",
          "School of Marketing",
          "School of Law"
        ]
      },
      {
        "Date": "20 Dec",
        "Subjects": [
          "School of Commerce",
          "School of Law",
          "School of Marketing",
          "School of Management",
          "Business Services",
          "School of Comms, Intl Studies & Languages",
          "School of Education",
          "School of Psychology, Social Work & Social Policy",
          "School of Art, Architecture & Design",
          "School of Health Sciences",
          "School of Nursing & Midwifery",
          "School of Pharmacy & Medical Services",
          "School of Engineering",
          "School of IT & Maths Sciences",
          "School of Natural & Built Environments"
        ]
      },
    ]
  },

  "/uni/sa/adelaide-uni": {
    "name": "University of Adelaide Graduation Gowns",
    "searchName": "AU | Adelaide University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008718/Uni%20Campus%20Photos/Adelaide/University_of_Adelaide_Emblem.jpg",
    "altTag": "Adelaide university main building",
    "titleTag": "Adelaide Uni Graduation Gown Sets | University Of Adelaide Graduation Gown",
    "metaTag": "Buy full University of Adelaide graduation gown sets including cap and gown. Buy all for $79 all across Australia",
    "abbreviation": "Adelaide Uni",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "All Master Degrees",
        "path": "/uni/sa/adelaide-uni/all-masters",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Natural & Physical Sciences",
        "path": "/uni/sa/adelaide-uni/bachelor-of-science",
        "colors": ["#FADD31"]
      },
      {
        "level": "Bachelor",
        "name": "Business (including Commerce)",
        "path": "/uni/sa/adelaide-uni/bachelor-of-business-commerce",
        "colors": ["#2460CA"]
      },
      {
        "level": "Bachelor",
        "name": "Health & Medical Sciences",
        "path": "/uni/sa/adelaide-uni/bachelor-of-health-medical-sciences",
        "colors": ["#FF69B4"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering & Related Technologies",
        "path": "/uni/sa/adelaide-uni/bachelor-of-engineering-related-technologies",
        "colors": ["#993881"]
      },
      {
        "level": "Bachelor",
        "name": "Society, Culture, Education & Law",
        "path": "/uni/sa/adelaide-uni/bachelor-of-law-soc-educ",
        "colors": ["grey"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/sa/adelaide-uni/phd",
        "colors": ["red"]
      }
    ],
    "graduations-ref": "https://www.adelaide.edu.au/student/graduations/ceremonydates/",
    "graduations": [
      {
        "Date": "12-14 September Inclusive",
        "Subjects": [
          "Final ceremony schedules will be available approximately 4-6 weeks before the ceremonies commence"
        ]
      }
    ]
  },

  //Tasmania

  "/uni/tas/utas": {
    "name": "UTAS Graduation Gown | University of Tasmania Graduation Gown Sets",
    "searchName": "UTAS | University of Tasmania",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008715/Uni%20Campus%20Photos/UTas/UTAS_Main_LS.jpg",
    "altTag": "UTAS main building",
    "titleTag": "UTAS Graduation Gown | University of Tasmania Graduation Gown Sets",
    "metaTag": "Purchase a UTAS academic dress from Gowning Street and pay less than a rental! Shipped right to your door throughout most of Australia. Graduation gowns available in all sizes",
    "abbreviation": "UTAS",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Arts, Humanities & Social Sciences",
        "path": "/uni/tas/utas/bachelor-of-arts-humanities",
        "colors": ["darkblue"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/tas/utas/bachelor-of-law",
        "colors": ["#7a0019"]
      },
      {
        "level": "Bachelor",
        "name": "Biomedicine",
        "path": "/uni/tas/utas/bachelor-of-biomedicine",
        "colors": ["#E3DEFA"]
      },
      {
        "level": "Bachelor",
        "name": "Fine Arts",
        "path": "/uni/tas/utas/bachelor-of-fine-arts",
        "colors": ["#b08d57"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce",
        "path": "/uni/tas/utas/bachelor-of-commerce",
        "colors": ["#FE5001"]
      },
      {
        "level": "Bachelor",
        "name": "Architecture",
        "path": "/uni/tas/utas/bachelor-of-architecture",
        "colors": ["#e75480"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/tas/utas/bachelor-of-engineering",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Psychology",
        "path": "/uni/tas/utas/bachelor-of-psychology",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/tas/utas/bachelor-of-education",
        "colors": ["#9BCAD8"]
      },
      {
        "level": "Bachelor",
        "name": "Health Science",
        "path": "/uni/tas/utas/bachelor-of-health-science",
        "colors": ["#CEC8E4"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/tas/utas/bachelor-of-science",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/tas/utas/master-of-education",
        "colors": ["lightblue"]
      },
      {
        "level": "Master",
        "name": "Commerce",
        "path": "/uni/tas/utas/master-of-commerce",
        "colors": ["#FE5001"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/tas/utas/master-of-law",
        "colors": ["#7a0019"]
      },
      {
        "level": "Master",
        "name": "Nursing",
        "path": "/uni/tas/utas/master-of-nursing",
        "colors": ["#00868B"]
      },
      {
        "level": "Master",
        "name": "Fine Arts",
        "path": "/uni/tas/utas/master-of-fine-arts",
        "colors": ["#b08d57"]
      },
      {
        "level": "Master",
        "name": "Architecture",
        "path": "/uni/tas/utas/master-of-architecture",
        "colors": ["#e75480"]
      },
      {
        "level": "Master",
        "name": "Arts, Humanities & Social Science",
        "path": "/uni/tas/utas/master-of-arts-humanities-social-science",
        "colors": ["darkblue"]
      },
      {
        "level": "Master",
        "name": "Science, Computing & Psychology",
        "path": "/uni/tas/utas/master-of-science-comp-psych",
        "colors": ["white"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/tas/utas/phd",
        "colors": ["red"]
      }
    ],
    "graduations-ref": "http://www.utas.edu.au/graduation/dates-times-and-locations/graduation-dates-2017/winter-graduation-round-2017-schedule-of-ceremonies",
    "graduations": [
      {
        "Date": "18 August",
        "Subjects": [
          "College of Arts and Law",
          "Tasmanian School of Business and Economics",
          "Graduate Research",
        ]
      },
      {
        "Date": "19 August",
        "Subjects": [
          "School of Medicine, Faculty of Health",
          "Menzies Institute for Medical Research",
          "Wicking Dementia Research & Education Centre",
          "Faculty of Education",
          "School of Heath Sciences, Faculty of Health",
          "Faculty of Science, Engineering and Technology",
          "Institute for Marine and Antarctic Studies",
          "Graduate Research"
        ]
      },
      {
        "Date": "26 August",
        "Subjects": [
          "Australian Maritime College",
          "College of Arts and Law",
          "Faculty of Education",
          "Faculty of Science, Engineering and Technology",
          "Institute for Marine and Antarctic Studies",
          "Tasmanian School of Business and Economics",
          "Faculty of Health",
          "Graduate Research"
        ]
      }
    ]
  },


  "/uni/scu": {
    "name": "Southern Cross University | SCU Graduation Gown",
    "searchName": "SCU | Southern Cross University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "SCU graduate wearing graduation gown set",
    "titleTag": "Southern Cross University Graduation Gowns | SCU Gowns",
    "metaTag": "Looking for SCU Graduation Gowns? Contact Gowning Street. We provide graduation gown & Cap set (Hood not incl.)s in different colours and sizes. We directly deliver to your door.",
    "abbreviation": "Southern Cross Uni",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/scu/bachelor-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/scu/bachelor-of-business",
        "colors": ["#FBFD85"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/scu/bachelor-of-education",
        "colors": ["#385DBD"]
      },
      {
        "level": "Bachelor",
        "name": "Health-Sciences",
        "path": "/uni/scu/bachelor-of-health-sciences",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/scu/bachelor-of-science",
        "colors": ["#3DC2E1"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/scu/bachelor-of-law",
        "colors": ["#8B0000"]
      }
    ],
    "graduations-ref": "http://scu.edu.au/graduation/index.php/events/all",
    "graduations": [
      {
        "Date": "23 September",
        "Subjects": [
          "Graduation Ceremony times and allocations have not yet been determined, but will be released around the registration deadline"
        ]
      },
      {
        "Date": "8 December",
        "Subjects": [
          "Graduation Ceremony times and allocations have not yet been determined, but will be released around the registration deadline"
        ]
      },
      {
        "Date": "15 December",
        "Subjects": [
          "Graduation Ceremony times and allocations have not yet been determined, but will be released around the registration deadline"
        ]
      }
    ]
  },

  "/uni/nsw/uon": {
    "name": "UoN | University of Newcastle",
    "searchName": "UoN | University of Newcastle",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "UoN graduate wearing graduation gown set",
    "titleTag": "University Of Newcastle Graduation Gowns | UoN Gowns",
    "metaTag": "Why rent when you can buy your UoN graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at UoN.",
    "abbreviation": "UoN",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Architecture & Building",
        "path": "/uni/nsw/uon/bachelor-of-architecture",
        "colors": ["#800000"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/nsw/uon/bachelor-of-arts",
        "colors": ["#F9F9F9"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce",
        "path": "/uni/nsw/uon/bachelor-of-commerce",
        "colors": ["turquoise"]
      },
      {
        "level": "Bachelor",
        "name": "Economics",
        "path": "/uni/nsw/uon/bachelor-of-economics",
        "colors": ["turquoise"]
      },
      {
        "level": "Bachelor",
        "name": "Computer Science & IT",
        "path": "/uni/nsw/uon/bachelor-of-computer-science",
        "colors": ["#7450BD"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/nsw/uon/bachelor-of-education",
        "colors": ["#009D7B"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/nsw/uon/bachelor-of-engineering",
        "colors": ["#4144A6"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/nsw/uon/bachelor-of-law",
        "colors": ["#DC143C"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine & Health Sciences",
        "path": "/uni/nsw/uon/bachelor-of-med-healthsci",
        "colors": ["#FFC0CB"]
      },
      {
        "level": "Bachelor",
        "name": "Music",
        "path": "/uni/nsw/uon/bachelor-of-music",
        "colors": ["#C8A2C8"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/nsw/uon/bachelor-of-science",
        "colors": ["#FFF47C"]
      },
      {
        "level": "Bachelor",
        "name": "Nursing",
        "path": "/uni/nsw/uon/bachelor-of-nursing",
        "colors": ["#FF4500"]
      },
      {
        "level": "Bachelor",
        "name": "Visual Arts, Design & Comms",
        "path": "/uni/nsw/uon/bachelor-of-visual-arts",
        "colors": ["yellow"]
      },
      {
        "level": "Master",
        "name": "Architecture & Building",
        "path": "/uni/nsw/uon/graduation-gown/master-of-architecture",
        "colors": ["#800000"]
      },
      {
        "level": "Master",
        "name": "Arts & Social Sciences",
        "path": "/uni/nsw/uon/graduation-gown/master-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "Commerce & Economics",
        "path": "/uni/nsw/uon/graduation-gown/master-of-commerce-economics",
        "colors": ["#40e0d0"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/nsw/uon/graduation-gown/master-of-education",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/nsw/uon/graduation-gown/master-of-engineering",
        "colors": ["#4144A6"]
      },
      {
        "level": "Master",
        "name": "Information Technology",
        "path": "/uni/nsw/uon/graduation-gown/master-of-it",
        "colors": ["#4B0082"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/nsw/uon/graduation-gown/master-of-law",
        "colors": ["#DC143C"]
      },
      {
        "level": "Master",
        "name": "Medicine & Health Sciences",
        "path": "/uni/nsw/uon/graduation-gown/master-of-med-healthsci",
        "colors": ["#FFC0CB"]
      },
      {
        "level": "Master",
        "name": "Music",
        "path": "/uni/nsw/uon/graduation-gown/master-of-music",
        "colors": ["#c8a2c8"]
      },
      {
        "level": "Master",
        "name": "Natural & Physical Sciences",
        "path": "/uni/nsw/uon/graduation-gown/master-of-science",
        "colors": ["#ffc87c"]
      },
      {
        "level": "Master",
        "name": "Nursing",
        "path": "/uni/nsw/uon/graduation-gown/master-of-nursing",
        "colors": ["orange"]
      },
      {
        "level": "Master",
        "name": "Visual Arts, Designs & Comms",
        "path": "/uni/nsw/uon/graduation-gown/master-of-visual-arts",
        "colors": ["yellow"]
      }
    ],
    "graduations-ref": "https://www.newcastle.edu.au/current-students/learning/graduation/graduation-dates",
    "graduations": [
      {
        "Date": "28 & 29 September",
        "Subjects": [
          "Details will be released closer to registration"
        ]
      },
      {
        "Date": "18 November",
        "Subjects": [
          "Details will be released closer to registration"
        ]
      }
    ]
  },

  "/uni/qld/usq": {
    "name": "University of Southern Queensland | USQ Graduation Gown",
    "searchName": "USQ | University of Southern Queensland",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008740/Uni%20Campus%20Photos/USQ/USQ_-_Gowning_Street.jpg",
    "altTag": "USQ graduate wearing graduation gown set",
    "titleTag": "University of Southern Queensland Gowns | USQ Graduation Gown Australia",
    "metaTag": "Looking for USQ graduation gowns for your bachelor or master degree? Visit Gowning Street online store and select your favourite USQ graduation gown and hood color. Order Now!",
    "abbreviation": "USQ",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/qld/usq/bachelor-of-business",
        "colors": ["#A9B3BD"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce",
        "path": "/uni/qld/usq/bachelor-of-commerce",
        "colors": ["#DFE454"]
      },
      {
        "level": "Bachelor",
        "name": "Creative Arts and Media",
        "path": "/uni/qld/usq/bachelor-of-creative-arts-and-media",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Health and Community",
        "path": "/uni/qld/usq/bachelor-of-health-and-community",
        "colors": ["turquoise"]
      },
      {
        "level": "Bachelor",
        "name": "Humanities and Communication",
        "path": "/uni/qld/usq/bachelor-of-humanities-and-communication",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/qld/usq/bachelor-of-information-technology",
        "colors": ["#CF3540"]
      },
      {
        "level": "Bachelor",
        "name": "Law and Justice",
        "path": "/uni/qld/usq/bachelor-of-law-and-justice",
        "colors": ["#C159B8"]
      },
      {
        "level": "Bachelor",
        "name": "Sciences",
        "path": "/uni/qld/usq/bachelor-of-sciences",
        "colors": ["#49B7E4"]
      }
    ],
    "graduations-ref": "https://www.usq.edu.au/current-students/graduation-beyond/graduations/graduation-ceremony/ceremonies",
    "graduations": [
      {
        "Date": "8 September",
        "Subjects": [
          "Education",
          "Engineering & Built Environment",
          "Health & Community",
          "Sciences",
          "Business & Commerce",
          "Creative Arts & Media",
          "Humanities & Communication",
          "Information Technology",
          "Law & Justice"
        ]
      }
    ]
  },


  "/uni/nsw/une": {
    "name": "University of New England | UNE Graduation Gowns",
    "searchName": "UNE | University of New England",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "UNE graduate wearing graduation gown set",
    "titleTag": "University of New England Graduation Gowns | UNE Graduation Gowns",
    "metaTag": "Completing your bachelor or master degree this year? Looking for UNE graduation gown? Check out graduation gowns here and compare the price with others. Order Now!",
    "abbreviation": "UNE",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/nsw/une/bachelor-of-business",
        "colors": ["#56D8FE"]
      },
      {
        "level": "Bachelor",
        "name": "Agriculture",
        "path": "/uni/nsw/une/bachelor-of-agriculture",
        "colors": ["darkgreen"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/nsw/une/bachelor-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/nsw/une/bachelor-of-education",
        "colors": ["violet"]
      },
      {
        "level": "Bachelor",
        "name": "Health",
        "path": "/uni/nsw/une/bachelor-of-health",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/nsw/une/bachelor-of-law",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Music",
        "path": "/uni/nsw/une/bachelor-of-music",
        "colors": ["darkgreen"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/nsw/une/bachelor-of-science",
        "colors": ["hsl(42,98%,84%)"]
      },
      {
        "level": "Bachelor",
        "name": "Social Science",
        "path": "/uni/nsw/une/bachelor-of-social-science",
        "colors": ["hsl(0,50%,50%)"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/nsw/une/master-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "Business, Commerce & Economics",
        "path": "/uni/nsw/une/master-of-business-economics",
        "colors": ["#56D8FE"]
      },
      {
        "level": "Master",
        "name": "Education & Teaching",
        "path": "/uni/nsw/une/master-of-education-teaching",
        "colors": ["#A619A6"]
      },
      {
        "level": "Master",
        "name": "Health & Pharmacy",
        "path": "/uni/nsw/une/master-of-health-pharmacy",
        "colors": ["red"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/nsw/une/master-of-law",
        "colors": ["#1E90FF"]
      },

      {
        "level": "Master",
        "name": "Music & Theatre",
        "path": "/uni/nsw/une/master-of-music-theatre",
        "colors": ["#006906"]
      },
      {
        "level": "Master",
        "name": "Science, Biomedicine, Exercise & Sport",
        "path": "/uni/nsw/une/master-of-biomedicine-exercise",
        "colors": ["#E4D96F"]
      },
      {
        "level": "Master",
        "name": "Social Science, Psychology & Criminology",
        "path": "/uni/nsw/une/master-of-social-sciences",
        "colors": ["#D96FE4"]
      }


    ],
    "graduations-ref": "https://www.une.edu.au/current-students/graduation/your-graduation",
    "graduations": [
      {
        "Date": "27 October 2017",
        "Subjects": [
          "Arts and Sciences Awards"
        ]
      },
      {
        "Date": "28 October 2017",
        "Subjects": [
          "The Professions Awards"
        ]
      },
      {
        "Date": "6 April 2018",
        "Subjects": [
          "Arts; Humanities; Behavioural, Cognitive and Social Science Awards"
        ]
      },
      {
        "Date": "7 April 2018",
        "Subjects": [
          "Law; UNE Business Awards"
        ]
      },
      {
        "Date": "13 April 2018",
        "Subjects": [
          "Education Awards"
        ]
      },
      {
        "Date": "14 April 2018",
        "Subjects": [
          "Sciences; Health; Medicine Awards"
        ]
      },
      {
        "Date": "26 October 2018",
        "Subjects": [
          "The Professions Awards"
        ]
      },
      {
        "Date": "27 October 2018",
        "Subjects": [
          "Arts and Science Awards"
        ]
      }
    ]
  },

  "/uni/notre-dame": {
    "name": "University of Notre Dame Graduation Gown",
    "searchName": "University of Notre Dame",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "Notre Dame graduate wearing graduation gown set",
    "titleTag": "University Of Notre Dame Graduation Gowns | Notre Dame Gowns",
    "metaTag": "Buy gown & Cap set (Hood not incl.) at Gowning Street. Browse our high quality Notre Dame graduation gowns for your upcoming bachelor or master degree convocation celebration.",
    "abbreviation": "Notre Dame",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ]
  },


  "/uni/acu": {
    "name": "ACU | Australian Catholic University Graduation Gowns",
    "searchName": "ACU | Australian Catholic University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008583/Uni%20Campus%20Photos/ACU/ACU-Day.jpg",
    "altTag": "ACU Building during the day",
    "titleTag": "Australian Catholic University Graduation Gowns | ACU Academic Dress",
    "metaTag": "Studying at the Catholic University? Looking to buy Graduation Gowns for your graduation? Buy ACU academic dress at Gowning Street. Our gowns are cheaper than renting!",
    "abbreviation": "ACU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/acu/bachelor-education",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Health Sciences",
        "path": "/uni/acu/bachelor-healthsci",
        "colors": ["orange"]
      },
      {
        "level": "Bachelor",
        "name": "Law & Business",
        "path": "/uni/acu/bachelor-law-business",
        "colors": ["#800020"]
      },
      {
        "level": "Bachelor",
        "name": "Theology",
        "path": "/uni/acu/bachelor-theology",
        "colors": ["#7F00FF"]
      },
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/acu/graduation-gown/master-of-education",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Health Sciences",
        "path": "/uni/acu/graduation-gown/master-of-health",
        "colors": ["orange"]
      },
      {
        "level": "Master",
        "name": "Law & Business",
        "path": "/uni/acu/graduation-gown/master-of-law-business",
        "colors": ["#800020"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "http://www.acu.edu.au/study_at_acu/graduation/attending-your-ceremony",
    "graduations": [
      {
        "Date": "5 October",
        "Subjects": [
          "Faculty of Health Sciences",
          "Faculty of Law and Business",
          "Faculty of Education and Arts",
          "Faculty of Theology and Philosophy"
        ]
      },
      {
        "Date": "10 October",
        "Subjects": [
          "Faculty of Health Sciences",
          "Faculty of Law and Business",
          "Faculty of Education and Arts",
          "Faculty of Theology and Philosophy"
        ]
      }
    ]
  },

  "/uni/csu": {
    "name": "Charles Sturt University | CSU Graduation Gown",
    "searchName": "CSU | Charles Sturt University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "CSU graduate wearing graduation gown set",
    "titleTag": "Charles Sturt University Graduation Gowns | CSU Graduation Gowns",
    "metaTag": "Gowning Street provide CSU graduation gowns at guaranteed best prices. You just need to select the size of the gown according to your body fit.",
    "abbreviation": "CSU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/csu/bachelor-of-business",
        "colors": ["#375CD4"]
      },
      {
        "level": "Bachelor",
        "name": "Education & Arts",
        "path": "/uni/csu/bachelor-of-education-arts",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/csu/bachelor-of-science",
        "colors": ["#D49537"]
      },
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Business",
        "path": "/uni/csu/graduation-gown/master-of-business",
        "colors": ["blue"]
      },
      {
        "level": "Master",
        "name": "Science",
        "path": "/uni/csu/graduation-gown/master-of-science",
        "colors": ["#D49537"]
      },
      {
        "level": "Master",
        "name": "Education & Arts",
        "path": "/uni/csu/graduation-gown/master-of-education-arts",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "PhD",
        "name": "PhD Gown Set",
        "path": "/uni/csu/phd",
        "colors": ["blue"]
      },
    ],
    "graduations-ref": "https://graduation.csu.edu.au/options-to-graduate/august",
    "graduations": [
      {
        "Date": "22 August",
        "Subjects": [
          "School of Humanities & Social Sciences",
          "School of Theology",
          "School of Communications & Creative Industries",
          "School of Education",
          "School of Teacher Education",
          "School of Indigenous Australian Studies",
          "School of Information Studies",
          "Division of Student Learning",
          "Academic Success Unit"
        ]
      },
      {
        "Date": "23 August",
        "Subjects": [
          "School of Psychology",
          "School of Computing & Mathematics",
          "School of Accounting & Finance",
          "School of Management & Marketing",
          "Australian Graduate",
          "School of Policing & Security",
          "Centre for Customs & Excise Studies",
          "Centre for Law & Justice",
          "School of Community Health",
          "School of Biomedical Science",
          "School of Nursing, Midwifery & Indigenous Health",
          "School of Environmental Science",
          "School of Dentistry & Health Science",
          "School of Agriculture & Wine Science",
          "School of Animal & Veterinary Science",
          "School of Exercise Science, Sport & Health"
        ]
      }
    ]
  },

  "/uni/nsw/mq" : {
    "name" : "MQ Graduation Gown | Macquarie University Graduation Gown",
    "searchName" : "MQ | Macquarie University",
    "photo" : "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag" : "MQ graduate wearing graduation gown set",
    "titleTag" : "Macquarie University Graduation Gowns | MQ Graduation Gowns Australia",
    "metaTag" : "Completing a degree from Macquarie University? Looking for MQ graduation gowns for graduation day? Buy your professional gown at Gowning Street. Contact to order today!",
    "abbreviation" : "Macquarie",
    "degrees" : [
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/nsw/mq/bachelor-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Science and Engineering",
        "path": "/uni/nsw/mq/bachelor-of-science-and-engineering",
        "colors": ["#64C850"]
      },
      {
        "level": "Bachelor",
        "name": "Business & Economics",
        "path": "/uni/nsw/mq/graduation-gown/bachelor-of-business-and-economics",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/nsw/mq/graduation-gown/bachelor-of-law",
        "colors": ["#CC99FF"]
      },
      {
        "level": "Bachelor",
        "name": "Human Sciences",
        "path": "/uni/nsw/mq/graduation-gown/bachelor-of-human-sciences",
        "colors": ["#00ced1"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine & Health Sciences",
        "path": "/uni/nsw/mq/graduation-gown/bachelor-of-medhealthsci",
        "colors": ["#C0C0C0"]
      },
      {
        "level": "Master",
        "name": "Business & Economics",
        "path": "/uni/nsw/mq/graduation-gown/master-of-business-and-economics",
        "colors": ["blue"]
      },
      {
        "level": "Master",
        "name": "Human Sciences",
        "path": "/uni/nsw/mq/graduation-gown/master-of-human-sciences",
        "colors": ["#00ced1"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/nsw/mq/graduation-gown/master-of-arts",
        "colors": ["white"]
      },
      {
        "level": "Master",
        "name": "Science & Engineering",
        "path": "/uni/nsw/mq/graduation-gown/master-of-science-and-engineering",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Management",
        "path": "/uni/nsw/mq/graduation-gown/master-of-management",
        "colors": ["#1e90ff"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/nsw/mq/graduation-gown/master-of-law",
        "colors": ["#CC99FF"]
      },
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ]
  },

  "/uni/nsw/unsw": {
    "name": "University of New South Wales | UNSW Graduation Gown",
    "searchName": "UNSW | University of New South Wales",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg",
    "altTag": "UNSW graduate wearing graduation gown set",
    "titleTag": "University of New South Wales Graduation Gowns | UNSW Graduation Gowns",
    "metaTag": "Buy graduation gowns at Gowning Street. We are offering gown & Cap set (Hood not incl.) for graduation & master degrees. Order UNSW graduation gown online at very attractive prices.",
    "abbreviation": "UNSW",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "https://student.unsw.edu.au/graduation-ceremonies",
    "graduations": [
      {
        "Date": "6 November",
        "Subjects": [
          "School of Aviation; Biological, Earth & Environmental Sciences",
          "School of Biotechnology & Biomolecular Sciences",
          "School of Chemistry & Nanoscience",
          "School of Materials Science & Engineering",
          "School of Mathematics & Statistics; Physics; and Psychology",
          "Australian Graduat School of Management (AGSM)",
          "Bachelor of Commerce",
          "School of Arts & Media",
          "School of Biotechnology & Biomolecular Sciences",
          "School of Humanities & Languages",
          "Institute of Environmental Studies",
          "Bachelor of Arts",
          "Bachelor of Arts & Business"
        ]
      },
      {
        "Date": "7 November",
        "Subjects": [
          "School of Accounting",
          "Bachelor of Commerce",
          "School of Civil & Environmental Engineering",
          "School of Risk & Actuarial Studies",
          "School of Economics",
          "Master of Commerce in Finance and/or Banking"
        ]
      },
      {
        "Date": "8 November",
        "Subjects": [
          "School of Accounting",
          "Faculty of Law",
          "Faculty of Art and Design",
          "Schools of Banking & Finance",
          "Taxation & Business Law",
          "Bachelor of Commerce"
        ]
      },
      {
        "Date": "9 November",
        "Subjects": [
          "School of Mining Engineering",
          "School of Mechanical & Manufacturing Engineering",
          "Chemical Sciences & Engineering",
          "Petroleum Engineering",
          "Biomedical Engineering",
          "Schools of Medical Science; and Optometry & Vision Science",
          "Faculty of Medicine",
          "Faculty of Built Environment",
          "All other Faculties"
        ]
      },
      {
        "Date": "10 November",
        "Subjects": [
          "Schools of Information Systems, Technology & Management",
          "Schools of Management",
          "Schools of Marketing",
          "Bachelor of Commerce, (Co-op), (Liberal Studies), (International Studies)",
          "Dual awards"
        ]
      }
    ]
  },

  "/uni/nsw/uts": {
    "name": "University of Technology Sydney | UTS Graduation Gown",
    "searchName": "UTS | University of Technology Sydney",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008724/Uni%20Campus%20Photos/UTS/UTS2.jpg",
    "altTag": "UTS building in Sydney",
    "titleTag": "UTS Graduation Gown Sydney | Graduation Gowns for UTS Graduates",
    "metaTag": "Purchase UTS graduation gown in Sydney for your  bachelor/master graduation ceremony. We offer quality graduation gown and cap with the best quality at guaranteed best prices.",
    "abbreviation": "UTS",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/nsw/uts/bachelor-of-business",
        "colors": ["#B0D088"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/nsw/uts/bachelor-of-engineering",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Health",
        "path": "/uni/nsw/uts/bachelor-of-health",
        "colors": ["#FF0080"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/nsw/uts/bachelor-of-it",
        "colors": ["#0892d0"]
      },
      {
        "level": "Bachelor",
        "name": "Design",
        "path": "/uni/nsw/uts/bachelor-of-design",
        "colors": ["#FFFFCC"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/nsw/uts/bachelor-of-law",
        "colors": ["#330066"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "https://www.uts.edu.au/current-students/managing-your-course/graduation/graduation",
    "graduations": [
      {
        "Date": "25 August - 1 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },


  "/uni/nsw/uws": {
    "name": "UWS Graduation Gown | University of Western Sydney",
    "searchName": "UWS | University of Western Sydney",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "UWS graduate wearing graduation gown set",
    "titleTag": "UWS Graduation Gown | University of Western Sydney",
    "metaTag": "Buy graduation cap and gown for your upcoming bachelor or master degree day. We offer a high quality UWS graduation gowns at the very best prices. Select yours today.",
    "abbreviation": "UWS",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Humanities, Communications & Arts",
        "path": "/uni/nsw/uws/bachelor-of-arts",
        "colors": ["#00BFFF", "#FF00FF"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce, Business, Economics & Administration",
        "path": "/uni/nsw/uws/bachelor-of-commerce",
        "colors": ["#00BFFF", "#0000CD"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/nsw/uws/bachelor-of-education",
        "colors": ["#00BFFF", "orange"]
      },
      {
        "level": "Bachelor",
        "name": "Health, Nursing",
        "path": "/uni/nsw/uws/bachelor-of-health-nursing",
        "colors": ["#00BFFF", "#20B2AA"]
      },
      {
        "level": "Bachelor",
        "name": "Law & Legal Studies",
        "path": "/uni/nsw/uws/bachelor-of-law",
        "colors": ["#00BFFF", "purple"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/nsw/uws/bachelor-of-science",
        "colors": ["#00BFFF", "#8B0000"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/nsw/uws/master-of-education",
        "colors": ["#00BFFF", "orange"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "https://www.westernsydney.edu.au/graduation_ceremonies/graduating/graduation_ceremonies",
    "graduations": [
      {
        "Date": "11-14 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },

  "/uni/qld/bond": {
    "name": "Bond University",
    "searchName": "Bond University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "Bond University graduate wearing graduation gown set",
    "titleTag": "Bond University Graduation Gowns | Bond University Gowns",
    "metaTag": "Why rent when you can buy your Bond University graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at Bond University.",
    "abbreviation": "Bond",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-business",
        "colors": ["gold"]
      },
      {
        "level": "Bachelor",
        "name": "Health Sciences",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-health",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Humanities & Social Sciences",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-humanities-and-scoial-sciences",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-information-technology",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-law",
        "colors": ["#c8a2c8"]
      },
      {
        "level": "Bachelor",
        "name": "Sustainable Development & Architecture",
        "path": "/uni/qld/bond/graduation-gown/bachelor-of-architecture",
        "colors": ["#00D4FF"]
      },
    ],
    "graduations-ref": "https://bond.edu.au/current-students/study-information/graduation/ceremony-information",
    "graduations": [
      {
        "Date": "7 October",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },

  "/uni/qld/cqu": {
    "name": "Central Queensland University | CQU Graduation Gowns",
    "searchName": "CQU | Central Queensland University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "CQU graduate wearing graduation gown set",
    "titleTag": "Central Queensland University Gowns | CQU Graduation Gowns Australia",
    "metaTag": "Buy CQU graduation gowns for cheaper than renting. Gowning Street offers a fine quality graduation dress and caps. Available in all sizes. To order, call 1300 253 620",
    "abbreviation": "CQU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "https://www.cqu.edu.au/student-life/new-students/graduations",
    "graduations": [
      {
        "Date": "9 August",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "10 August",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "24 August",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "12 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "26 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "28 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "5 December",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "11 December",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "13 December",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },

  "/uni/qld/jcu": {
    "name": "JCU | James Cook University Graduation Gown",
    "searchName": "JCU | James Cook University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008589/Uni%20Campus%20Photos/JCU/JCU_Main.jpg",
    "altTag": "JCU main entrance",
    "titleTag": "James Cook University Graduation Gowns | James Cook Gowns",
    "metaTag": "Save money, graduate in style and take home a piece of memorabilia from your time at JCU with help from Gowning Steet. Order your gown today.",
    "abbreviation": "JCU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "All Masters Degrees",
        "path": "/uni/qld/jcu/masters-all",
        "colors": ["gold"]
      },
      {
        "level": "Bachelor",
        "name": "Standard",
        "path": "/uni/qld/jcu/bachelor-standard",
        "colors": ["#005FCA"]
      },
      {
        "level": "Bachelor",
        "name": "Honours",
        "path": "/uni/qld/jcu/bachelor-honours",
        "colors": ["#FAC748", "#005FCA"]
      }
    ],
    "graduations-ref": "https://www.jcu.edu.au/graduation/ceremonies",
    "graduations": [
      {
        "Date": "22 September",
        "Subjects": [
          "College of Business, Law & Governance"
        ]
      },
      {
        "Date": "18 December",
        "Subjects": [
          "Division of Tropical Health & Medicine"
        ]
      },
      {
        "Date": "20 December",
        "Subjects": [
          "College of Public Health, Medical & Vet Sciences, College of Medicine & Dentistry",
          "College of Health Care Science"
        ]
      },
      {
        "Date": "7 March 2018",
        "Subjects": [
          "College of Business, Law & Governance"
        ]
      },
      {
        "Date": "8 March 2018",
        "Subjects": [
          "College of Arts, Society & Education",
          "College of Science & Engineering",
          "Division of Tropical Health & Medicine"
        ]
      },
      {
        "Date": "13 March 2018",
        "Subjects": [
          "Division of Tropical Environments & Society",
          "Division of Tropical Health & Medicine"
        ]
      },
      {
        "Date": "19 December 2018",
        "Subjects": [
          "Division of Tropical Health & Medicine"
        ]
      }
    ]
  },

  "/uni/qld/usc": {
    "name": "University of The Sunshine Coast | USC Graduation Gowns",
    "searchName": "USC | University of the Sunshine Coast",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg",
    "altTag": "USC graduate wearing graduation gown set",
    "titleTag": "University of The Sunshine Coast Graduation Gowns | USC Gowns",
    "metaTag": "Why rent when you can buy your USC graduation gown, and for a better price? Gowning Street has several sets available for Bachelor & Masters degrees at rock bottom prices.",
    "abbreviation": "USC",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "http://www.usc.edu.au/learn/graduation",
    "graduations": [
      {
        "Date": " 28 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },


  "/uni/qld/griffith": {
    "name": "Griffith University Graduation Gowns",
    "searchName": "Griffith University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg",
    "altTag": "Griffith University graduate wearing graduation gown set",
    "titleTag": "Griffith University Graduation Gowns | Griffith Graduation Gowns",
    "metaTag": "Want to purchase Griffith university graduation gown? At Gowning Street you can buy for less then price of renting. Several sets of Griffith graduation gowns available. Call 1300 253 620",
    "abbreviation": "Griffith",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
    ],
    "graduations-ref": "https://www.griffith.edu.au/graduations/ceremony-information",
    "graduations": [
      {
        "Date": "9 & 10 November",
        "Subjects": [
          "All programs (except End-on Honours & Griffith Health)"
        ]
      },
      {
        "Date": "13 November",
        "Subjects": [
          "All programs (except End-on Honours & Griffith Health)"
        ]
      },
      {
        "Date": "26 December",
        "Subjects": [
          "End-on Hounours programs (Arts, Education and Law, Griffith Business School, Griffith Health, Griffith Sciences)",
          "Griffith Health programs"
        ]
      }
    ]
  },


  //VIC


  "/uni/vic/unimelb": {
    "name": "UniMelb Graduation Gowns | University of Melbourne",
    "searchName": "UniMelb | University of Melbourne",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008658/Uni%20Campus%20Photos/UniMelb/UniMelb_Building_2.jpg",
    "altTag": "UniMelb main courtyard",
    "titleTag": "UniMelb Graduation Gowns | University Of Melbourne Academic Gowns",
    "metaTag": "Buy full University of Melbourne (UniMelb) Graduation Gown Sets, all for $79 and get fast shipping across Australia. Purchase the very best quality gowns and caps.",
    "abbreviation": "UniMelb",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Bachelor Gown",
        "path": "/uni/bachelor-gown"
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business and Economics",
        "path": "/uni/vic/unimelb/bachelor-of-business-and-economics",
        "colors": ["lightblue"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/unimelb/graduation-gown/bachelor-of-arts/",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Biomedicine",
        "path": "/uni/vic/unimelb/graduation-gown/bachelor-of-biomedicine/",
        "colors": ["#556b2f", "red"]
      },
      {
        "level": "Bachelor",
        "name": "Environments",
        "path": "/uni/vic/unimelb/graduation-gown/bachelor-of-environments/",
        "colors": ["yellow"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/vic/unimelb/bachelor-of-science",
        "colors": ["#898A6B"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/vic/unimelb/master-of-engineering",
        "colors": ["yellow"]
      },
      {
        "level": "Master",
        "name": "Architecture, Building & Planning",
        "path": "/uni/vic/unimelb/graduation-gown/master-of-architecture",
        "colors": ["#FF00FF"]
      },
      {
        "level": "Master",
        "name": "Health Sciences & Nursing",
        "path": "/uni/vic/unimelb/graduation-gown/master-of-healthsci-nursing",
        "colors": ["#FF00FF"]
      },
      {
        "level": "Master",
        "name": "Medicine",
        "path": "/uni/vic/unimelb/master-of-medicine",
        "colors": ["#800000"]
      },
      {
        "level": "Master",
        "name": "MBA",
        "path": "/uni/vic/unimelb/MBA",
        "colors": ["#ADD8E6", "#FFFF00"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/vic/unimelb/master-of-arts",
        "colors": ["blue"]
      },
      {
        "level": "Master",
        "name": "Science",
        "path": "/uni/vic/unimelb/master-of-science",
        "colors": ["#5A5A00"]
      },
      {
        "level": "Master",
        "name": "Business & Economics",
        "path": "/uni/vic/unimelb/master-of-business-and-economics",
        "colors": ["#87CEEB"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/vic/unimelb/master-of-education",
        "colors": ["#014421"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/vic/unimelb/phd",
        "colors": ["red"]
      }
    ],
    "graduations-ref": "http://graduation.unimelb.edu.au/schedule",
    "graduations": [
      {
        "Date": "1 August",
        "Subjects": [
          "Faculty of Science (undergraduate programs)",
          "Faculty of Veterinary and Agricultural Sciences (undergraduate programs)"
        ]
      },
      {
        "Date": " 2 August",
        "Subjects": [
          "Bachelor of Environments",
          "Melbourne School of Design"
        ]
      },
      {
        "Date": " 3 August",
        "Subjects": [
          "Melbourne School of Engineering"
        ]
      },
      {
        "Date": "4 August",
        "Subjects": [
          "Faculty of Business and Economics (graduate programs)"
        ]
      },
      {
        "Date": " 5 August",
        "Subjects": [
          "Faculty of Business and Economics (graduate and undergraduate programs)",
          "Melbourne School of Engineering",
          "Faculty of Business and Economics (undergraduate programs)"
        ]
      },
      {
        "Date": " 18 September",
        "Subjects": [
          "Sir Robert Menzies Oration on Higher Education (PhD candidates only)"
        ]
      },
      {
        "Date": " 7 October",
        "Subjects": [
          "Melbourne Business School"
        ]
      },
      {
        "Date": "2 December",
        "Subjects": [
          "Melbourne Dental School",
          "Melbourne Medical School (MD program only)"
        ]
      },
      {
        "Date": "4 December",
        "Subjects": [
          "Melbourne Law School"
        ]
      },
      {
        "Date": " 5 December",
        "Subjects": [
          "Faculty of Veterinary and Agricultural Sciences"
        ]
      },
      {
        "Date": " 9 December",
        "Subjects": [
          "Faculty of Business and Economics (undergraduate and graduate programs)"
        ]
      },
      {
        "Date": " 11 December",
        "Subjects": [
          "Melbourne Graduate School of Education",
          "Bachelor of Environments",
          "Melbourne School of Design"
        ]
      },
      {
        "Date": " 12 December",
        "Subjects": [
          "Faculty of Science (undergraduate and graduate programs)"
        ]
      },
      {
        "Date": "13 December",
        "Subjects": [
          "Bachelor of Biomedicine",
          "Melbourne Medical School (all except MD)",
          "Melbourne School of Health Sciences",
          "Melbourne School of Population and Global Health",
          "Melbourne School of Psychological Sciences",
          "Melbourne School of Engineering"
        ]
      },
      {
        "Date": "14 December",
        "Subjects": [
          "Faculty of Arts (undergraduate and graduate programs)"
        ]
      },
      {
        "Date": "15 December",
        "Subjects": [
          "Victorian College of the Arts and the Melbourne Conservatorium of Music"
        ]
      }
    ]
  },


  "/uni/vic/swinburne": {
    "name": "Swinburne University Graduation Gown Sets",
    "searchName": "Swinburne University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008637/Uni%20Campus%20Photos/Swinburne/Swinburne_-_ADMC_Building.jpg",
    "altTag": "Swinburne - ADMC Building",
    "titleTag": "Swinburne University Graduation Gowns | Swinburne Graduation Gowns",
    "metaTag": "Completing your degree at Swinburne University? Purchase high quality Swinburne graduation gown, all graduation dress available in multiple sizes. Order yours today.",
    "abbreviation": "Swinburne",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Advanced Diploma",
        "name": "Business",
        "path": "/uni/vic/swinburne/advanced-diploma-of-business",
        "colors": ["#DFB383"]
      },
      {
        "level": "Advanced Diploma",
        "name": "Engineering",
        "path": "/uni/vic/swinburne/advanced-diploma-of-engineering",
        "colors": ["#55669A"]
      },
      {
        "level": "Advanced Diploma",
        "name": "IT",
        "path": "/uni/vic/swinburne/advanced-diploma-of-it",
        "colors": ["#FB8C58"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/swinburne/bachelor-of-arts",
        "colors": ["#FF004F"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/vic/swinburne/bachelor-of-business",
        "colors": ["#DFB383"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/vic/swinburne/bachelor-of-engineering",
        "colors": ["#55669A"]
      },
      {
        "level": "Bachelor",
        "name": "IT",
        "path": "/uni/vic/swinburne/bachelor-of-it",
        "colors": ["#FB8C58"]
      }
    ],
    "graduations-ref": "https://www.swinburne.edu.au/current-students/manage-course/graduation-completion/ceremony-dates-venues/",
    "graduations": [
      {
        "Date": "28 August",
        "Subjects": [
          "Faculty of Science, Engineering and Technology",
          "Swinburne Research",
          "Faculty of Business and Law",
          "Graduate Research",
          "Pathways and Vocational Education",
          "Faculty of Health",
          "Arts and Design"
        ]
      },
      {
        "Date": "19  & 20 December",
        "Subjects": [
          "Graduating disciplines to be determined at a closer date"
        ]
      },
      {
        "Date": "22  & 23 August 2018",
        "Subjects": [
          "Graduating disciplines to be determined at a closer date"
        ]
      },
      {
        "Date": "21  & 22 August 2018",
        "Subjects": [
          "Graduating disciplines to be determined at a closer date"
        ]
      },
    ]
  },

  "/uni/vic/rmit": {
    "name": "RMIT Graduation Gown Sets",
    "searchName": "RMIT University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008738/Uni%20Campus%20Photos/RMIT/RMIT_-_Storey_Hall.jpg",
    "altTag": "RMIT building",
    "titleTag": "RMIT University Graduation Gowns | RMIT Graduation Gown Australia",
    "metaTag": "Are you in the end of your graduation year? Looking for RMIT graduation gowns in Australia? Contact Gowning Street, we are offering the best quality and guaranteed best price.",
    "abbreviation": "RMIT",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/vic/rmit/master-of-education",
        "colors": ["#30D5C8"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/vic/rmit/master-of-arts",
        "colors": ["hsl(300,100%,50%)"]
      },
      {
        "level": "Master",
        "name": "Business",
        "path": "/uni/vic/rmit/graduation-gown/master-of-business",
        "colors": ["#df73ff"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/vic/rmit/graduation-gown/master-of-engineering",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Architecture",
        "path": "/uni/vic/rmit/bachelor-of-architecture",
        "colors": ["#FFB7C5"]
      },
      {
        "level": "Bachelor",
        "name": "Social Science",
        "path": "/uni/vic/rmit/bachelor-of-social-science",
        "colors": ["#F2F35E"]
      },
      {
        "level": "Bachelor",
        "name": "Social Work",
        "path": "/uni/vic/rmit/bachelor-of-social-work",
        "colors": ["lightblue"]
      },
      {
        "level": "Bachelor",
        "name": "Technology",
        "path": "/uni/vic/rmit/bachelor-of-technology",
        "colors": ["#299F7A"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/vic/rmit/bachelor-of-business",
        "colors": ["violet"]
      },
      {
        "level": "Bachelor",
        "name": "Design",
        "path": "/uni/vic/rmit/bachelor-of-design",
        "colors": ["silver"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/vic/rmit/bachelor-of-education",
        "colors": ["hsl(162,100%,25%)"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/vic/rmit/bachelor-of-engineering",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/vic/rmit/bachelor-of-law",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Nursing",
        "path": "/uni/vic/rmit/bachelor-of-nursing",
        "colors": ["violet"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/rmit/bachelor-of-arts",
        "colors": ["hsl(300,100%,50%)"]
      },
      {
        "level": "PhD",
        "name": "PhD",
        "path": "/uni/vic/rmit/phd",
        "colors": ["red"]
      }
    ],
    "graduations-ref": "https://www.rmit.edu.au/students/student-essentials/graduation/graduation-options",
    "graduations": [
      {
        "Date": " 13 December",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },


  "/uni/vic/victoria-university": {
    "name": "Victoria University Graduation Gowns",
    "searchName": "Victoria University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg",
    "altTag": "Victoria University graduate wearing graduation gown set",
    "titleTag": "Victoria University Graduation Gowns | Victoria University Gowns",
    "metaTag": "Why rent when you can buy your Victoria University graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at Victoria University.",
    "abbreviation": "Victoria Uni",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business or Business Administration",
        "path": "/uni/vic/victoria-university/bachelor-of-business-or-business-administration",
        "colors": ["#224DAC"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/vic/victoria-university/bachelor-of-engineering",
        "colors": ["silver"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/victoria-university/bachelor-of-arts",
        "colors": ["#bb0a1e"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/vic/victoria-university/bachelor-of-law",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/vic/victoria-university/bachelor-of-education",
        "colors": ["#C40000"]
      },
      {
        "level": "Bachelor",
        "name": "Health Science",
        "path": "/uni/vic/victoria-university/bachelor-of-healthsci",
        "colors": ["#c08081"]
      },
      {
        "level": "Bachelor",
        "name": "Social Work",
        "path": "/uni/vic/victoria-university/bachelor-of-social-work",
        "colors": ["yellow"]
      },
      {
        "level": "Bachelor",
        "name": "Psychology",
        "path": "/uni/vic/victoria-university/bachelor-of-psychology",
        "colors": ["#DFB383"]
      },
      {
        "level": "Bachelor",
        "name": "Science or Applied Sciences",
        "path": "/uni/vic/victoria-university/bachelor-of-science-or-applied-sciences",
        "colors": ["#277A6B"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/vic/victoria-university/graduation-gown/master-of-arts",
        "colors": ["#bb0a1e"]
      },
      {
        "level": "Master",
        "name": "Business",
        "path": "/uni/vic/victoria-university/graduation-gown/master-of-business",
        "colors": ["blue"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/vic/victoria-university/graduation-gown/master-of-education",
        "colors": ["#790604"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/vic/victoria-university/graduation-gown/master-of-engineering",
        "colors": ["#C0C0C0"]
      },
    ],
    "graduations-ref": "https://www.vu.edu.au/current-students/your-course/graduation/attend-a-graduation-ceremony",
    "graduations": [
      {
        "Date": "1 August",
        "Subjects": [
          "College of Arts and Education",
          "Victoria Polytechnic"]
      },
      {
        "Date": "2 August",
        "Subjects": [
          "College of Health & Biomedicine (Nursing, Midwifery, Paramedicine & Osteopathy)",
          "College of Health & Biomedicine (All others)",
          "College of Engineering & Science",
          "College of Law & Justice",
          "College of Sport & Exercise Science"
        ]
      }, {
        "Date": "3 August",
        "Subjects": [
          "College of Business (Undergraduate Accounting, Banking & Finance, & Financial Risk combinations)",
          "College of Business (Postgraduate & remaining undergraduate specialisations)",
          "VU College"
        ]
      }
    ]
  },

  "/uni/vic/federation-university": {
    "name": "Federation University Graduation Gown Sets",
    "searchName": "Federation University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg",
    "altTag": "Federation University graduate wearing graduation gown set",
    "titleTag": "Federation University Graduation Gowns | Federation University Gowns",
    "metaTag": "Why rent when you can buy your Federation University graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at Federation University.",
    "abbreviation": "Federation Uni",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/federation-university/bachelor-of-arts",
        "colors": ["#FF00FF"]
      },
      {
        "level": "Bachelor",
        "name": "Business",
        "path": "/uni/vic/federation-university/bachelor-of-business",
        "colors": ["#BA55D3"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/vic/federation-university/bachelor-of-education",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/vic/federation-university/master-of-education",
        "colors": ["green"]
      },
    ],
    "graduations-ref": "http://federation.edu.au/current-students/essential-info/administration/graduations/ceremony-dates-and-times",
    "graduations": [
      {
        "Date": " 6-8 December",
        "Subjects": [
          "Final ceremony schedules & faculties will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },

  "/uni/vic/monash": {
    "name": "Monash University | Monash Graduation Gown",
    "searchName": "Monash University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008635/Uni%20Campus%20Photos/Monash/Monash_Main_Building.jpg",
    "altTag": "Monash University orange building",
    "titleTag": "Monash University Graduation Gowns | Monash Graduation Gown Australia",
    "metaTag": "Browse a wide range of gowns for your bachelor or master degree ceremony. We are offering quality Monash graduation gowns at the very best prices. Call Now 1300 253 620",
    "abbreviation": "Monash",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/vic/monash/master-of-arts",
        "colors": ["hsl(0,50%,50%)"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/vic/monash/master-of-education",
        "colors": ["#d4af37"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/vic/monash/graduation-gown/master-of-engineering",
        "colors": ["#bc8f8f"]
      },
      {
        "level": "Master",
        "name": "Information Technology",
        "path": "/uni/vic/monash/graduation-gown/master-of-it",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Science",
        "path": "/uni/vic/monash/graduation-gown/master-of-science",
        "colors": ["#F3CF55"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/vic/monash/bachelor-of-arts",
        "colors": ["hsl(0,50%,50%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business & Economics",
        "path": "/uni/vic/monash/bachelor-of-business-and-economics",
        "colors": ["hsl(162,100%,30%)"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/vic/monash/bachelor-of-education",
        "colors": ["#d4af37"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/vic/monash/bachelor-of-engineering",
        "colors": ["hsl(359,76%,55%)"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/vic/monash/bachelor-of-information-technology",
        "colors": ["darkgreen"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/vic/monash/bachelor-of-law",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine, Nursing & Health Sciences",
        "path": "/uni/vic/monash/bachelor-of-medicine-nursing-and-health-sciences",
        "colors": ["#000080"]
      }
    ],
    "graduations-ref": "https://www.cqu.edu.au/student-life/new-students/graduations",
    "graduations": [
      {
        "Date": "23 October",
        "Subjects": [
          "Final ceremony schedules and faculties will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "24 October",
        "Subjects": [
          "Final ceremony schedules and faculties will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "25 October",
        "Subjects": [
          "Final ceremony schedules and faculties will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "26 October",
        "Subjects": [
          "Final ceremony schedules and faculties will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "27 October",
        "Subjects": [
          "Final ceremony schedules and faculties will be available closer to the ceremony commencement date"
        ]
      },
      {
        "Date": "12 December",
        "Subjects": [
          "Medicine, Nursing and Health Sciences"
        ]
      }
    ]
  },

  "/uni/vic/isn": {
    "name": "ISN Psychology Graduation Gowns",
    "searchName": "ISN",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1701296696/Screenshot_2023-11-29_at_22.22.28.png",
    "altTag": "ISN main building",
    "titleTag": "ISN Graduation Gowns | Buy ISN Psychology Academic Gowns",
    "metaTag": "Find your ISN faculty’s graduation gown here at Gowning Street, where buying is cheaper than renting! Browse the ranges of ISN Psychology graduation gowns. Call 1300 253 618.",
    "abbreviation": "ISN",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Bachelor of Psychological Science (Honors)",
        "path": "/uni/vic/isn/bachelor-psychological-science-honors",
        "colors": ["#1F5BBD"]
      },
      {
        "level": "Advanced Diploma",
        "name": "Graduate Diploma of Psychological Studies",
        "path": "/uni/vic/isn/graduate-diploma-psychological-studies",
        "colors": ["#ADD8E6"]
      },
      {
        "level": "Master",
        "name": "Master of Professional Psychology",
        "path": "/uni/vic/isn/master-professional-psychology",
        "colors": ["pink"]
      },
      {
        "level": "Master",
        "name": "Master of Psychology (Clinical)",
        "path": "/uni/vic/isn/master-psychology-clinical",
        "colors": ["#800000"]
      },
      {
        "level": "Master",
        "name": "Master of Psychology (Sport & Exercise)",
        "path": "/uni/vic/isn/master-psychology-sport-exercise",
        "colors": ["yellow"]
      },
      {
        "level": "PhD",
        "name": "Doctor of Psychology (Sport & Exercise, Clinical)",
        "path": "/uni/vic/isn/doctor-of-psychology",
        "colors": ["red"]
      },
    ]

  },


  //South Aus

  "/uni/sa/flinders": {
    "name": "Flinders University Graduation Gown Sets",
    "searchName": "Flinders University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008729/Uni%20Campus%20Photos/Flinders/Flinders3.jpg",
    "altTag": "Flinders University Building",
    "titleTag": "Flinders University Graduation Gowns | Buy Full Sets from $79",
    "metaTag": "Why rent when you can buy your Flinders University graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at Flinders University.",
    "abbreviation": "Flinders Uni",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Business, Law & Government",
        "path": "/uni/sa/flinders/bachelor-of-buss-law",
        "colors": ["white", "#00FFFF"]
      },
      {
        "level": "Bachelor",
        "name": "Education, Psychology & Social Work",
        "path": "/uni/sa/flinders/bachelor-of-educ-soc-psych",
        "colors": ["white", "orange"]
      },
      {
        "level": "Bachelor",
        "name": "Humanities, Arts & Social Sciences",
        "path": "/uni/sa/flinders/bachelor-of-arts",
        "colors": ["white", "#FFD700"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine & Public Health",
        "path": "/uni/sa/flinders/bachelor-of-med-health",
        "colors": ["white", "green"]
      },
      {
        "level": "Bachelor",
        "name": "Nursing & Health Sciences",
        "path": "/uni/sa/flinders/bachelor-of-nurse-health",
        "colors": ["white", "red"]
      },
      {
        "level": "Bachelor",
        "name": "Science & Engineering",
        "path": "/uni/sa/flinders/bachelor-of-sci-engg",
        "colors": ["white", "  #9932CC"]
      },
      {
        "level": "Master",
        "name": "Education, Psychology & Social Work",
        "path": "/uni/sa/flinders/master-of-educ-soc-psych",
        "colors": ["white", "orange"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "http://www.flinders.edu.au/current-students/graduation-beyond/graduation/graduate-information.cfm",
    "graduations": [
      {
        "Date": " 20 & 21 September",
        "Subjects": [
          "Final ceremony schedules will be available closer to the ceremony commencement date"
        ]
      }
    ]
  },

  //ACT

  "/uni/act/anu": {
    "name": "Australian National University Graduation | ANU Graduation Gowns",
    "searchName": "ANU | Australian National University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1635008721/Uni%20Campus%20Photos/ANU/ANU_Main_Building.jpg",
    "altTag": "ANU Main Building",
    "titleTag": "Australian National University Graduation Gowns | ANU Graduation Gowns",
    "metaTag": "Gowning Street offers a wide range of ANU graduation gowns for Bachelor and Masters degrees. ANU graduation gowns are available in a variety of sizes to ensure you are comfortable.",
    "abbreviation": "ANU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Arts & Social Sciences",
        "path": "/uni/act/anu/bachelor-of-arts-and-social-sciences",
        "colors": ["#1F5BBD"]
      },
      {
        "level": "Bachelor",
        "name": "Business & Economics",
        "path": "/uni/act/anu/bachelor-of-business-and-economics",
        "colors": ["#F1C74F"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering & Computer Science",
        "path": "/uni/act/anu/bachelor-of-engineering-and-computer-science",
        "colors": ["#CB2A3F"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine",
        "path": "/uni/act/anu/bachelor-of-medicine",
        "colors": ["#009BC8"]
      },
      {
        "level": "Bachelor",
        "name": "Natural Sciences",
        "path": "/uni/act/anu/bachelor-of-natural-sciences",
        "colors": ["#039D74"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/act/anu/bachelor-of-law",
        "colors": ["purple"]
      },
      {
        "level": "Bachelor",
        "name": "Multidisciplinary Awards",
        "path": "/uni/act/anu/bachelor-multidisciplinary",
        "colors": ["white"]
      },
      {
        "level": "Bachelor",
        "name": "Asia & Pacific",
        "path": "/uni/act/anu/bachelor-of-asia-and-pacific",
        "colors": ["orange"]
      },
      {
        "level": "Master",
        "name": "Arts & Social Sciences",
        "path": "/uni/act/anu/master-of-arts-and-social-sciences",
        "colors": ["#255FC2"]
      },
      {
        "level": "Master",
        "name": "Business & Economics",
        "path": "/uni/act/anu/master-of-business-and-economics",
        "colors": ["#F1C74F"]
      },
      {
        "level": "Master",
        "name": "Engineering & Computer Science",
        "path": "/uni/act/anu/master-of-engineering-and-computer-science",
        "colors": ["#CB2A3F"]
      },
      {
        "level": "Master",
        "name": "Law",
        "path": "/uni/act/anu/master-of-law",
        "colors": ["purple"]
      },
      {
        "level": "Master",
        "name": "Medicine & Health Studies",
        "path": "/uni/act/anu/master-of-medicine",
        "colors": ["#009BC8"]
      },
      {
        "level": "Master",
        "name": "Natural Sciences",
        "path": "/uni/act/anu/master-of-natural-sciences",
        "colors": ["#039D74"]
      },
      {
        "level": "Master",
        "name": "Asia & Pacific",
        "path": "/uni/act/anu/master-of-asia-pacific",
        "colors": ["orange"]
      },
      {
        "level": "Master",
        "name": "Multidisciplinary",
        "path": "/uni/act/anu/master-multidisciplinary",
        "colors": ["white"]
      }
    ],
    "graduations-ref": "http://www.anu.edu.au/students/communities-events/december-2017-graduation-ceremonies-1",
    "graduations": [
      {
        "Date": "12 December",
        "Subjects": [
          "College of Medicine, Biology and Environment (Undergraduate students only - excl. Medical School)",
          "College of Physical and Mathematical Sciences (Undergraduate students only)",
          "College of Engineering and Computer Science"]
      },
      {
        "Date": "13 December",
        "Subjects": [
          "College of Asia and the Pacific",
          "College of Medicine, Biology and Environments (Medical School + Graduate and Research students only)",
          "College of Physical and Mathematical Sciences (Graduate and Research students only)",
          "College of Law"]
      },
      {
        "Date": "14 December",
        "Subjects": [
          "College of Business and Economics (Graduate and Research students only)",
        ]
      },
      {
        "Date": "15 December",
        "Subjects": [
          "College of Arts and Social Sciences"
        ]
      }
    ]
  },

  //Canberra

  "/uni/act/uc": {
    "name": "University of Canberra Graduation Gown | UC Gowns",
    "searchName": "UC | University of Canberra",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag": "UC graduate wearing graduation gown set",
    "titleTag": "University of Canberra Graduation Gowns | Buy UC Gowns Online",
    "metaTag": "Why rent a second-hand gown from UC, when you can buy for much less? Order today from Gowning Street and make your graduation much less stressful.",
    "abbreviation": "UC",
    "degrees": [
      {
        "level": "Master",
        "name": "All Master Degrees",
        "path": "/uni/act/uc/all-masters",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "3 & 3.5 Years",
        "path": "/uni/act/uc/bachelor-3-years",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "4 Years",
        "path": "/uni/act/uc/bachelor-4-years",
        "colors": ["maroon"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "http://www.canberra.edu.au/current-students/graduation/ceremony-dates",
    "graduations": [
      {
        "Date": " 27 September",
        "Subjects": [
          "Faculty of Arts and Design",
          "BGL - School of Information Systems & Accounting",
          "BGL - School of Law & Justice"
        ]
      },
      {
        "Date": " 28 September",
        "Subjects": [
          "Faculty of Health",
          "BGL - School of Management",
          "BGL - School of Government & Policy"
        ]
      }
    ]
  },

  // Western Aus

  "/uni/wa/curtin": {
    "name": "Curtin University Graduation Gown",
    "searchName": "Curtin University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag": "Curtin University graduate wearing graduation gown set",
    "titleTag": "Curtin University Graduation Gowns | Curtin University Gowns Australia",
    "metaTag": "Buy a professional Curtin University graduation gown at Gowning Street. We offer high quality, quick delivery and low price graduation gowns, ragalia, dress, etc.",
    "abbreviation": "Curtin",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "https://graduations.curtin.edu.au/ceremonydates.cfm",
    "graduations": [
      {
        "Date": "2 September",
        "Subjects": [
          "Curtin Business School",
          "Accounting",
          "Law",
          "Economics and Finance",
          "Information Systems",
          "Faculty of Science and Engineering",
          "All schools",
          "WASM"
        ]
      },
      {
        "Date": "3 September",
        "Subjects": [
          "Faculty of Humanities",
          "All schools",
          "Centre for Aboriginal Studies"
        ]
      },
      {
        "Date": "4 September",
        "Subjects": [
          "Curtin Business School",
          "Graduation School of Business",
          "Management",
          "Marketing"
        ]
      },
      {
        "Date": "5 September",
        "Subjects": [
          "Faculty of Health Sciences",
          "All schools"
        ]
      }
    ]
  },

  "/uni/wa/uwa": {
    "name": "University of Western Australia | UWA Graduation Gowns",
    "searchName": "UWA | University of Western Australia",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "altTag": "UWA graduate wearing graduation gown set",
    "titleTag": "University of Western Australia Graduation Gowns | UWA Graduation Gowns",
    "metaTag": "Graduating soon from University of Western Australia? Buy online UWA graduation gown at Gowning street. We offer fast shipping and guarantee the best price.",
    "abbreviation": "UWA",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Bachelor",
        "name": "Arts",
        "path": "/uni/wa/uwa/bachelor-arts",
        "colors": ["blue"]
      },
      {
        "level": "Bachelor",
        "name": "Medicine",
        "path": "/uni/wa/uwa/bachelor-medicine",
        "colors": ["red"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/wa/uwa/bachelor-engineering",
        "colors": ["gold"]
      },
      {
        "level": "Bachelor",
        "name": "Science",
        "path": "/uni/wa/uwa/bachelor-science",
        "colors": ["#008000"]
      },
      {
        "level": "Bachelor",
        "name": "Law",
        "path": "/uni/wa/uwa/bachelor-law",
        "colors": ["purple"]
      },
      {
        "level": "Bachelor",
        "name": "Commerce & Economics",
        "path": "/uni/wa/uwa/graduation-gown/bachelor-commerce",
        "colors": ["#B77B82"]
      },
      {
        "level": "Master",
        "name": "Arts",
        "path": "/uni/wa/uwa/graduation-gown/master-of-arts",
        "colors": ["blue"]
      },
      {
        "level": "Master",
        "name": "MBA",
        "path": "/uni/wa/uwa/graduation-gown/MBA",
        "colors": ["#DA70D6"]
      },
      {
        "level": "Master",
        "name": "Science",
        "path": "/uni/wa/uwa/graduation-gown/master-of-science",
        "colors": ["#008000"]
      },
      {
        "level": "Master",
        "name": "Commerce & Economics",
        "path": "/uni/wa/uwa/graduation-gown/master-of-comm-eco",
        "colors": ["#B77B82"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/wa/uwa/graduation-gown/master-of-engineering",
        "colors": ["gold"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "http://www.student.uwa.edu.au/graduation/conferment",
    "graduations": [
      {
        "Date": "12 December",
        "Subjects": [
          "Faculty of Health and Medical Sciences"
        ]
      },
      {
        "Date": "13 December",
        "Subjects": [
          "All other faculties"
        ]
      }
    ]
  },

  "/uni/wa/ecu": {
    "name": "ECU Graduation Gown | Edith Cowan University",
    "searchName": "ECU | Edith Cowan University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "ECU graduate wearing graduation gown set",
    "titleTag": "Edith Cowan University Graduation Gowns | Edith Cowan University Gowns",
    "metaTag": "Order the best quality ECU graduation gowns & caps at Gowning Street. If you want to order graduation regalia now, then call us at 1300 253 618. Fast shipping!",
    "abbreviation": "ECU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Sciences, Agriculture & Environments",
        "path": "/uni/wa/ecu/bachelor-of-sciences",
        "colors": ["green"]
      },
      {
        "level": "Bachelor",
        "name": "Education",
        "path": "/uni/wa/ecu/bachelor-of-education",
        "colors": ["#CCFFFF"]
      },
      {
        "level": "Bachelor",
        "name": "Creative Arts",
        "path": "/uni/wa/ecu/bachelor-of-creative-arts",
        "colors": ["#00FFFF"]
      },
      {
        "level": "Bachelor",
        "name": "Health",
        "path": "/uni/wa/ecu/bachelor-of-health",
        "colors": ["#990000"]
      },
      {
        "level": "Bachelor",
        "name": "Information Technology",
        "path": "/uni/wa/ecu/bachelor-of-it",
        "colors": ["#7E00C5"]
      },
      {
        "level": "Bachelor",
        "name": "Society & Culture",
        "path": "/uni/wa/ecu/bachelor-of-society-and-culture",
        "colors": ["#0080FF"]
      },
      {
        "level": "Bachelor",
        "name": "Engineering",
        "path": "/uni/wa/ecu/bachelor-of-engineering",
        "colors": ["#EBC523"]
      },
      {
        "level": "Bachelor",
        "name": " Management & Commerce",
        "path": "/uni/wa/ecu/bachelor-of-management-commerce",
        "colors": ["#FFF600"]
      },
      {
        "level": "Master",
        "name": "Sciences, Agriculture & Environments",
        "path": "/uni/wa/ecu/master-of-sciences",
        "colors": ["green"]
      },
      {
        "level": "Master",
        "name": "Health",
        "path": "/uni/wa/ecu/master-of-health",
        "colors": ["#990000"]
      },
      {
        "level": "Master",
        "name": "Information Technology",
        "path": "/uni/wa/ecu/master-of-it",
        "colors": ["#7E00C5"]
      },
      {
        "level": "Master",
        "name": "Society & Culture",
        "path": "/uni/wa/ecu/master-of-soc",
        "colors": ["#0080FF"]
      },
      {
        "level": "Master",
        "name": "Engineering",
        "path": "/uni/wa/ecu/master-of-engg",
        "colors": ["gold"]
      },
      {
        "level": "Master",
        "name": "Education",
        "path": "/uni/wa/ecu/master-of-education",
        "colors": ["#CCFFFF"]
      },
      {
        "level": "Master",
        "name": "Creative Arts",
        "path": "/uni/wa/ecu/master-of-creative-arts",
        "colors": ["#00FFFF"]
      },
      {
        "level": "Master",
        "name": "Management & Commerce",
        "path": "/uni/wa/ecu/master-of-mgmt-commerce",
        "colors": ["#FFF600"]
      },
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "http://www.ecu.edu.au/graduations/key-dates",
    "graduations": [
      {
        "Date": "12 December",
        "Subjects": [
          "School of Business and Law",
          "School of Engineering",
          "School of Arts and Humanities",
          "School of Education",
          "Western Australian Academy of Performing Arts",
          "School of Medical and Health Sciences",
          "School of Science",
          "School of Nursing and Midwifery"
        ]
      }
    ]
  },

  //North Territory

  "/uni/nt/cdu": {
    "name": "CDU | Charles Darwin University",
    "searchName": "CDU | Charles Darwin University",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "altTag": "CDU graduate wearing graduation gown set",
    "titleTag": "Charles Darwin University Graduation Gowns | Charles Darwin University Gowns",
    "metaTag": "Why rent when you can buy your CDU graduation gown, and for a better price? Gowning Street has several sets available for Bachelor and Masters degrees at CDU.",
    "abbreviation": "CDU",
    "degrees": [
      {
        "level": "Bachelor",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/bachelor-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      },
      {
        "level": "Master",
        "name": "Gown & Cap Set (Hood not incl.)",
        "path": "/uni/master-cap-and-gown",
        "colors": ["hsl(0,0%,15%)"]
      }
    ],
    "graduations-ref": "http://www.cdu.edu.au/graduation",
    "graduations": [
      {
        "Date": "13 October",
        "Subjects": [
          "Faculty of Engineering, Health, Science and the Environment",
          "Menzies School of Health Research",
          "Doctor of Philosophy",
          "Faculty of vocational Education and Training",
          "School of Primary Industries",
          "School of Community and Children's Services",
          "School of Trades",
          "Faculty of Law, Education, Business and Arts",
          "Australian Centre for Indigenous Knowledge's and Training",
          "Doctor of Philosphy",
          "Faculty of Vocational Education and Training",
          "School of Service Industries"
        ]
      }
    ]
  }

};
