import React from 'react';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../constants';

const UniDescriptions = ({uniName}) => {

    var description = null;
    var contactNumber = GOWNING_STREET_PHONE_NUMBER;

    switch(uniName){
      case "University of Queensland | UQ Gowns" :
        description = (
          <div className="su-description">
            <h2>FINEST UQ GRADUATION GOWNS FOR SALE</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008701/Uni%20Campus%20Photos/UQ/UQ_graduate.jpg" alt="University of Queensland Bachelor graduate photo" />
            <p className="para">Being properly recognised for the effort that you put in is important. One of the biggest things that we achieve in our lives is successfully completing our studies at university, at which time a graduation ceremony occurs to do exactly that. Whether you achieved HD’s across the board or you just passed, it’s a really happy time. Your determination and dedication has finally paid off, and you’re now prepared with the necessary means to enter your chosen industry and work well.</p>
            <p className="para">Before you hit the ground running and become a part of the work force, you’ll be attending your graduation ceremony and as such need the proper outfit to do so. At Gowning Street we have UQ gowns that takes care of all different Bachelor degrees, and better yet we have it at a great price.</p>
            <h3>WHAT ARE THE BENEFITS OF BUYING YOUR UQ GOWN?</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008703/Uni%20Campus%20Photos/UQ/UQ_LS_3.jpg" alt="University of Queensland building with lake view" />
            <p className="para">Aside from the fact that you’re able to hang onto it for many years to come as a souvenir you can show your kids and/or friends and reminisce about the good old uni days, there are a few more reasons that you’d benefit from purchasing from Gowning Street:</p>
            <div className="list">
              <p>If your friends and/or family attend UQ they will be able to use it as well</p>
              <p>Don’t stress about wasting your time in a line waiting for a rental (both picking up and dropping off)</p>
              <p>Get your uni friends involved as well and <Link to="/save-with-friends">save money</Link> on every set that’s bought</p>
              <p>We’ll have it sent straight to your door</p>
            </div>
            <p className="para">The products that we have available are all of exceptional quality, and priced cheaper than many of the rental establishments charge!</p>
            <h3>PROVIDING GOWNS FOR STUDENTS ALL OVER THE COUNTRY</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008704/Uni%20Campus%20Photos/UQ/UQ_LS_4.jpg" alt="University of Queensland main building" />
            <p className="para">Our range doesn’t just stop at graduation gowns for <Link to="/uni/qld/uq">UQ</Link> – we are proud to supply attire to suit students graduating from the <Link to="/uni/nsw/usyd">University of Sydney</Link>, <Link to="/uni/tas/utas">Tasmania</Link> and a whole lot more</p>
            <p className="para">We are always here to help, and if you need any more information in relation to what we have in supply or something else that you’re a bit unclear of, be sure to use our <Link to="/contact">contact form</Link> online and we’ll respond hastily. If you’d like to speak with us directly, phone {contactNumber}.</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008702/Uni%20Campus%20Photos/UQ/UQ_LS_2.jpg" alt="University of Queensland main building" />
          </div>
        )
        break;

      case "QUT | Queensland University of Technology":
        description = (
          <div className="su-description">
            <h2>QUT GRADUATION GOWNS</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Placement%20Photos/uni-archway-wall-leaning.jpg" alt="QUT graduate learning against wall wearing graduation gown" />
            <p className="para">So you’ve finally come to the end of your Queensland University of Technology course – congratulations! There’s a lot to be excited about, and celebrations to look forward to. You’ve worked incredibly hard to get it all done and dusted, staying up all night and day to make sure all of the finishing touches have been applied to your assignments. The extensive study sessions for impending examinations have come to a close, and you’re ready to become part of the workforce. First, though, is the graduation ceremony!</p>
            <p className="para">You can’t just turn up in jeans and a t-shirt – the proper attire is required. When you’re in need of the right clothing to suit this momentous occasion, you’re in the right place when you come to Gowning Street!</p>
            <h3>GOWNS TO SUIT MANY DIFFERENT FACULTIES AT QUT</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008732/Uni%20Campus%20Photos/QUT/QUT_LS_2.jpg" alt="QUT courtyard view from above" />
            <p className="para">Did you know that there are many different colours to represent different areas of study that you’re graduating from? We cater for everything from the Bachelor of Science and Engineering to Bachelor of Creative industries and more. For full flexibility we also stock a generic gown and cap that’s coloured black, suitable for several different uses.</p>
            <h3>WHY BUY FROM GOWNING STREET?</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008733/Uni%20Campus%20Photos/QUT/QUT_LS_3.jpg" alt="QUT courtyard view" />
            <p className="para">There are many different factors that favour buying graduation gowns over renting, especially from Gowning Street. Some of these include:</p>
            <div className="list">
              <p>It’s a souvenir to remind you of your dedication and accomplishment</p>
              <p>If you buy more than one we offer a <Link to="/save-with-friends">discounted price</Link> (up to 10, giving you $10 off every set!)</p>
              <p>Able to be shipped to the vast majority of Australia</p>
            </div>
            <p className="para">Your choices of institution and location don’t just stop at QUT though. We supply the <Link to="/uni/qld/uq">University of Queensland</Link>, <Link to="/uni/nsw/usyd">Sydney</Link>, and many more.</p>
            <h3>WOULD YOU LIKE TO KNOW MORE?</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008734/Uni%20Campus%20Photos/QUT/QUT_LS_4.jpg" alt="Building at QUT" />
            <p className="para">Do you have any questions about any of the QUT graduation gowns you see here or anything else that you can’t find the answer to on our <Link to="/about">About</Link> page? We’re always happy to clear up anything you don’t understand, so please call our customer service line on {contactNumber}. Otherwise, use our <Link to="/contact">online enquiry</Link> to send us a message and we’ll be in touch ASAP.</p>
          </div>
        )
        break;

      case "University of Southern Queensland | USQ Graduation Gown":
        description = (
          <div className="su-description">
            <h2>USQ GRADUATION GOWN</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008743/Uni%20Campus%20Photos/USQ/USQ_-_Gowning_Street-4.jpg" alt="USQ building" />
            <p className="para">Gowning Street provides full graduation gown sets for Bachelor and Masters students at the University of Southern Queensland.</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008742/Uni%20Campus%20Photos/USQ/USQ_-_Gowning_Street-3.jpg" alt="USQ building" />
            <p className="para">Our gown, cap and hood matches University specifications and can be worn during your graduation. All gown sets are delivered to your door, hassle free!</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008741/Uni%20Campus%20Photos/USQ/USQ_-_Gowning_Street-2.jpg" alt="USQ building" />
            <p className="para">Our prices are much more affordable than University prices and other independent suppliers. So what are you waiting for? Order now to get your full regalia set in time for graduation</p>
          </div>
        )
        break;

      case "Flinders University Graduation Gown Sets":
        description = (
          <div className="su-description">
            <h2>FLINDERS UNI GRADUATION GOWN</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008728/Uni%20Campus%20Photos/Flinders/Flinders2.jpg" alt="Flinders Uni building" />
            <p className="para">So you have reached the pinnacle of your Flinders University course. It’s time to graduate! What an exciting time, all the years of studying, exam time pressures and caring friendships have brought you to this day- your graduation ceremony. You will soon embark on the next stage of your life, but those years of studying at Flinders Uni, you will never forget. Your career path will take you to new places, some of you will leave Adelaide and the next stage of your life will bring a lot of new adventures and learnings.</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008730/Uni%20Campus%20Photos/Flinders/Flinders4.jpg" alt="Flinders Uni building" />
            <h2>GOWNS FOR ALL BACHELOR DEGREES PROVIDED FOR FLINDERS UNI</h2>
            <p className="para">We have gowns for all bachelor faculties at flinders uni, whether it is a Bachelor of  Law, Bachelor of Medicine or Bachelor of Nursing degrees, we have you covered! Just ask our friendly team and they will assist you in choosing the correct attire.</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008727/Uni%20Campus%20Photos/Flinders/Flinders1.jpg" alt="Flinders Uni building" />
            <h2>WHY BUY FROM US?</h2>
            <p className="para">Gowning Street has served 1000’s of happy customers. Each piece is meticulously crafted and ensured that it complies with Flinders Uni academic dress standards. Furthermore, purchasing your gown is at a similar price point to hiring. This will allow you to pass on your gown to friends, family or you may simply just decide to keep it as a lifelong memento.</p>
            <p className="para">We also provide <Link to="/save-with-friends">student deals</Link> so if you purchase more than one gown you will get a discount for each additional gown you buy! You can receive up to $10 off per gown. Your choice of University doesn't just stop at Flinders uni, we also supply <Link to="/uni/nsw/uws">UWS</Link> and <Link to="/uni/vic/federation-university">Federation uni</Link> and many more!</p>
            <p className="para">*Gowning Street is not officially affiliated with and does not represent this institution in any way</p>
          </div>
        )
        break;

      case "Swinburne University Graduation Gown Sets":
        description = (
          <div className="su-description">
            <h2>SWINBURNE GRADUATION GOWNS</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008638/Uni%20Campus%20Photos/Swinburne/Swinburne_-_George_Building.jpg" alt="Swinburne George Building" />
            <p className="para">Gowning Street provides full graduation gown sets for Bachelor and Masters students at Swinburne University</p>
            <p className="para">Our gown, cap and hood matches University specifications and can be worn during your graduation. All gown sets are delivered to your door, hassle free!</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008639/Uni%20Campus%20Photos/Swinburne/Swinburne_-_H2O_building.jpg" alt="Swinburne H2O building" />
            <p className="para">Our prices are much more affordable than University prices and other independent suppliers. So what are you waiting for? Order now to get your full regalia set in time for graduation</p>
          </div>
        )
        break;

      case "UTAS Graduation Gown | University of Tasmania Graduation Gown Sets":
        description = (
          <div className="su-description">
            <h2>A HUGE SELECTION OF GOWNS FOR UTAS GRADUATION</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Placement%20Photos/uni-archway-wall-leaning.jpg" alt="UTAS graduate learning against wall wearing graduation gown" />
            <p className="para">The countless hours of study sessions, thousands upon thousands of typed words and all of that time spent researching your work was not in vain. You’ve spent years working hard, and all of your determination to succeed has come to fruition. While there’s a lot to think about regarding your future endeavours and employment opportunities available, don’t let that get in the way of your happiness and pride for finishing what you started!</p>
            <p className="para">UTAS will hold a graduation ceremony for all of its students who have successfully completed their degree, and you need to make sure you’re dressed appropriately in order to attend. That means you need a graduation gown!</p>
            <h3>CHOOSE GOWNING STREET FOR A SELECTION OF GRADUATION GOWNS TO BUY</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008711/Uni%20Campus%20Photos/UTas/UTAS_Building.jpg" alt="UTAS building" />
            <p className="para">There’s a choice between both buying and renting the attire necessary for your graduation. It may surprise you that deciding to rent isn’t always the cheaper option. In fact, when you take advantage of the Gowning Street <Link to="/save-with-friends">group deal</Link> you can get up to and including $10 reduced from each of the sets that you buy.</p>
            <p className="para">Still not convinced that buying’s a better option? What about these additional benefits:</p>
            <div className="list">
              <p>It’s a great way to hold onto something that will remind you in the future about how hard you worked and all of the good times you had</p>
              <p>Forget about having to queue up for a long time to either pick up or return a rented one</p>
              <p>Don’t concern yourself with late drop-off or dry cleaning charges</p>
            </div>
            <h3>A WIDE SELECTION COVERING MANY DIFFERENT INSTITUTIONS</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008712/Uni%20Campus%20Photos/UTas/UTAS_Building_2.jpg" alt="UTAS building" />
            <p className="para">Not only do we have the UTAS range you see on this page, but we also stock a myriad of other options from many Australian states. Pick up one of many <Link to="/uni/vic">Victorian</Link> universities’ colour schemes from all manners of faculties, a list of <Link to="/uni/sa">South Australian</Link> choices and plenty more.</p>
            <h3>FAST DELIVERY STRAIGHT TO YOU</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008713/Uni%20Campus%20Photos/UTas/UTAS_Building_3.jpg" alt="UTAS building" />
            <p className="para">Our shipping proudly covers virtually all of Australia, and is done so in an incredibly efficient manner that’s bound to impress. For any further information required, please call {contactNumber} now.</p>
            <p className="para">*Gowning Street is not officially affiliated with and does not represent this institution in any way</p>
          </div>
        )
        break;

      case "USyd | University of Sydney Academic Gowns":
        description = (
          <div className="su-description">
            <h2>OUR COLLECTION OF USYD GRADUATION GOWNS</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Placement%20Photos/graduate-graduation-gown-set-red-hood.jpg" alt="USyd graduate learning sitting on stairs" />
            <p className="para">Completing a course at university is literally years in the making. It’s a process that takes a lot of determination and, just as importantly, hard work, to successfully finish. Stressful exam periods, assignments that seem to take an eternity to finish, and the infamous group task that ended up being a solo effort… it’s all over! Exciting times lay ahead, but one of the first things that you need to make sure you do is celebrate appropriately – you earned it!</p>
            <p className="para">As an official ceremony, you’ll be attending graduation, and will do so in one of the USYD gowns that you can see here at Gowning Street. Why would you choose to buy over rent? A few reasons:</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008746/Uni%20Campus%20Photos/USyd/USyd_Building_2.jpg" alt="USyd main building wide view" />
            <div className="list">
              <p>Keep it forever. Use it as a memento and a reminder of your graduation and your university days</p>
              <p>Remove the stress of standing in long queues to pick it up (and return it) and don’t worry yourself about late or dry cleaning fees</p>
              <p>Have it delivered directly to you</p>
              <p>Buy it at an even <Link to="/save-with-friends">lower price</Link> if you get your university friends involved (each additional one sold on top of yours will give everyone a dollar off, up to a max $10 discount on each set)</p>
            </div>
            <p className="para">Considering the discount, you really can pick it up at a cheaper price than what you’d pay to rent one, so why would you bother renting?</p>
            <h3>USYD IS JUST THE START OF OUR COLLECTION OF GRADUATION GOWNS</h3>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008745/Uni%20Campus%20Photos/USyd/USyd_Building.jpg" alt="USyd main building side view" />
            <p className="para">We have a great range of different areas of study for USYD, including the Bachelor of Architecture through to Bachelor of Science and everything in between. Not only do we have gowns for the University of Sydney, but also the <Link to="/uni/tas/utas">University of Tasmania</Link> as well as a range of institutions in <Link to="/uni/vic">Victoria</Link> and much more.</p>
            <h3>INTERESTED IN LEARNING MORE?</h3>
            <p className="para">You’re always welcome to call our customer service line on {contactNumber} to speak to one of our friendly and helpful staff members about anything that’s not clear. We also provide an <Link to="/contact">online query page</Link> if you’d rather type out your message, and we’ll get back to you hastily.</p>
          </div>
        )
        break;

      case "University of Canberra Graduation Gown | UC Gowns":
        description = (
          <div className="su-description">
            <h2>A WIDE RANGE OF UC GRADUATION GOWNS</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Placement%20Photos/graduate-graduation-gown-set-red-hood.jpg" alt="UC graduate learning sitting on stairs" />
            <p className="para">It’s one of the most important events of your life; don’t make it more stressful than it has to be. Gowning Street helps soon-to-be-graduates of the University of Canberra save time, money and effort in getting a properly fitted, high-quality academic robe, hood and mortar board.</p>
            <p className="para">Between studying for exams, finishing off final assessments, sending off resumes, planning a relaxing getaway and commiserating with friends, you’ll have your hands full in your final semester. Complicated university rental programs don’t help with cash-only payments, designed pick up and drop off hours and vicious late fees. Wouldn’t it be so much better if you could get your gown as easily as you buy a new jacket?</p>
            <h2>Affordable graduation gowns helping you look your very best</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="UC graduate with family" />
            <p className="para">That’s where <Link to="/">Gowning Street</Link> comes in. We make it easy to get the right academic dress in the right size with the right colours for your ceremony. Different institutions will mandate different styles of robe with different colour linings in the hood, so it’s important to ensure you get the right one.</p>
            <p className="para">Fortunately, our company’s close ties to region’s universities including <Link to="/uni/act/anu">ANU</Link> and UC means that we can offer you the right graduation gown for your ceremony. Save as much as 50 per cent on the total cost of hiring a gown. You won’t have to stand in massive return queues minutes after you receive your degree. Walk away from the latest chapter in your education with your head held high and a piece of memorabilia that will last a lifetime.</p>
            <p className="para">Place your order today, or get in touch with our customer service department via email at enquiries@gowningstreet.com or over the phone on {contactNumber}.</p>
          </div>
        )
        break;

      case "JCU | James Cook University Graduation Gown":
        description = (
          <div className="su-description">
            <h2>BACHELORS AND MASTERS GRADUATION GOWNS FOR JCU GRADUATES</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-park-bench.jpg" alt="JCU graduate photo" />
            <p className="para">Cut the stress around the end of your education. Gowning Street offers high-quality gowns, trenchers and hoods for Bachelor and Bachelor with Honours graduates from James Cook University. Save money and skip the queues with our affordable range, delivered quickly anywhere in the country.</p>
            <p className="para">Getting your gown is often one of the last things on your list in your final semester. Save hours you’d otherwise waste going in for fittings, standing in line to pick up and drop off your hire and avoid the stress of dodging a late fee with us. Most university students already enjoy the convenience of buying entire wardrobes online; why should academic dress be any different? Shop with us and spend your time where it will mean the most – whether that’s finishing off assignments, planning a well-deserved holiday or applying for graduate jobs.</p>
            <h2>Take your graduation gown with you and remember your time forever</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008587/Uni%20Campus%20Photos/JCU/JCU_Building_2.jpg" alt="JCU building" />
            <p className="para">The Gowning Street name is one that students and universities across the country can trust. <Link to="/">Our company</Link> supplies high-quality gowns for graduates at institutions across Queensland including <Link to="/uni/qld/uq">UQ</Link> and JCU, helping more people look their very best on this special day.</p>
            <p className="para">Graduation is one of the most memorable occasions in a person’s life; don’t be rushing around at the last moment paying big money for second-hand garments. Pay less, stress less and take home a beautiful memento from your time as a student.</p>
            <p className="para">Order today through our site, or get in touch with any questions. Call us directly on {contactNumber} or send us a message through our <Link to="/contact">enquiry form</Link> or via email at enquiries@gowningstreet.com.</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008588/Uni%20Campus%20Photos/JCU/JCU_Building_3.jpg" alt="JCU building" />
          </div>
        )
        break;

      case "Australian National University Graduation | ANU Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>QUALITY ANU BACHELOR & MASTER GRADUATION REGALIA</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Placement%20Photos/graduate-on-uni-stairs.jpg" alt="ANU graduate wearing graduation gown set" />
            <p className="para">Look your very best during one of the most important events of your life. Gowning Street helps graduates take the stress and complexity out of their graduation ceremony, helping them get high quality academic gowns, mortar boards and hoods compliant with ANU regulations. Offered in bachelors and masters styles and in colours to suit all colleges and disciplines, soon-to-be-graduates can find the solution they need.</p>
            <p className="para">We know that amidst examinations, final assessments and planning for the future, something as simple as getting your graduation gown has the habit of falling by the wayside until the very last minute, meaning you’re standing in line for hours to pay too much to hire a second-hand robe and hood. <Link to="/">Our business</Link> makes shopping for a gown as easy as shopping for any other piece of clothing online. Just choose your size based on your height, click, and it’ll be delivered to you.</p>
            <h2>An affordable, stress-free way to get your graduation gown</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008720/Uni%20Campus%20Photos/ANU/ANU_Building_2.jpg" alt="ANU building" />
            <p className="para">Save as much as half on the total cost of hiring from your university and keep a beautiful memento of your time at university with Gowning Street. We stock a full range of <Link to="/uni/act/uc">UC</Link> and ANU graduation gowns in a variety of sizes ensuring that people in institutes across the ACT can feel comfortable during their ceremony.</p>
            <p className="para">For more information on our range, or to learn more about our company, please don’t hesitate to get in touch. Call our office on {contactNumber}, or send us a message either via email at enquiries@gowningstreet.com or through our <Link to="/contact">contact page</Link>.</p>
          </div>
        )
        break;

      case "ACU | Australian Catholic University Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>ACU graduation gowns make the best graduation memorabilia</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008585/Uni%20Campus%20Photos/ACU/ACU-Sunset.jpg" alt="ACU building during sunset" />
            <p className="para">
              Congratulations! You have fulfilled all the requirements of your course and will soon be known as an ACU graduate. No mean feat considering that ACU is ranked one of the top 5 universities in Australia for skills development and one of the top 10 in teaching quality. No doubt that ACU expects its students to work hard, think out of the box, innovate and contribute to society before they can call themselves an ACU graduate. You have accomplished all this and more – you deserve to celebrate! Your ACU graduation day is an opportunity to look back on your past accomplishments and look ahead at your chosen career. Your ACU graduation gown is a symbol of both your past and future successes.
            </p>
            <h2>Why rental ACU graduation gowns just don't make the cut</h2>
            <p className="para">
              Renting seems like an easier option because the ACU graduation registration fee includes the cost of gown rentals, and most students rent their gown. However, it is important to note that ACU will refund more than half of the registration fee back to you if you purchase your gown from a third party provider. You can use this refund amount to purchase a custom fit ACU graduation gown from us. Here are 5 reasons why this is a much better option:
            </p>
            <div className="list">
              <p>1. It will be home delivered to you well in time for your ceremony</p>
              <p>2. You do not have to spend the better part of graduation day standing in long lines to collect and return the gown</p>
              <p>3. You don't have to worry about staining or damaging your gown during celebrations</p>
              <p>4. You can take unlimited pictures all around the campus – stage photo shoots with friends before graduation day to avoid the crowds at favourite photo spots!</p>
              <p>5. Most importantly, you get to keep your ACU graduation gown forever. Why rent when you can buy – at the same price?</p>
            </div>
            <h2>Expert ACU graduation gown design that meets uni requirements</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008584/Uni%20Campus%20Photos/ACU/ACU-Night.jpg" alt="ACU building at night" />
            <p className="para">
              There is an unspoken fear that third party gown suppliers can’t meet university quality standards. This is just not true! We are experts in academic dress design and supply graduation gowns to all the top universities across Australia – including <Link to="/uni/vic/unimelb">University of Melbourne</Link>, <Link to="/uni/nsw/une">New England</Link>, <Link to="/uni/nsw/uon">New Castle</Link> and <Link to="/uni/sa/unisa">South Australia</Link> (among many others). We understand the subtleties of ACU graduation gown design and take care of every detail from the colour of the hood to the depth of the trimming. Rest assured your ACU graduation gown is in safe hands!
            </p>
          </div>
        )
        break;

      case "University of Adelaide Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>Best price Adelaide uni graduation gowns</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008716/Uni%20Campus%20Photos/Adelaide/Barr_Smith_Library.jpg" alt="Adelaide Uni Barr Smith Library" />
            <p className="para">
              Adelaide University is consistently ranked in the top 1% of the world’s universities and its graduates are also globally recognised for the impact they make once they leave its hallowed portals. Becoming an Adelaide Uni graduate is no mean feat - this University has produced world leaders, CEOs, Nobel Laureates, Rhodes Scholars, innovative medical researchers, international conservationists and Olympians and the next recognised alumni could be you! Your Adelaide uni graduation ceremony is not mere formality – it is a grand celebration marking your entry into the exclusive club of Adelaide uni graduates.
          </p>
            <h2>High quality Adelaide uni graduation gowns made to specification</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008717/Uni%20Campus%20Photos/Adelaide/Bonython_Hall_Doors.jpg" alt="Adelaide Uni Bonython Hall Doors" />
            <p className="para">
              You deserve to be dressed perfectly for the occasion and our Adelaide Uni graduation gowns are designed to meet your needs. We make sure that our gown design meets the stringent requirements of Adelaide University. <Link to="/about">We take care of every detail</Link>, from the length and sleeve measurements to the correct colours of the stole and tassel. You only have to select your size and faculty, and we will deliver a complete set of academic regalia, including the hood, mortarboard and bonnet, to your doorstep!
          </p>
            <h2>Adelaide uni graduation gown – much more than a dress for hire</h2>
            <p className="para">
              Why spend money on renting your gown and purchasing graduation memorabilia separately? Your Adelaide uni graduation gown symbolizes your hard work, determination and talent and celebrates your achievements. Years from now, just looking at it will fill your heart with pride and your eyes with joyful tears as you reminisce your student days.  When you purchase your Adelaide uni graduation gown from us you receive group discounts, <Link to="/pricebeat">lowest price guarantee</Link> and a high quality, hand crafted dress that will last forever. We have years of experience designing graduation gowns for <Link to="/uni">universities across Australia</Link> and you can trust us to meet the high standards of Adelaide University!
          </p>
          </div>
        )

        break;

      case "Charles Sturt University | CSU Graduation Gown":
        description = (
          <div className="su-description">
            <h2>Buy CSU Graduation Gowns At Affordable Rates</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="CSU Graduates Celebrating" />
            <p className="para">
              Charles Sturt University  has a strong history of producing graduates who achieve success in their chosen industries with a current graduate employment rate that is 12% above the national average. Having completed your studies from CSU, no wonder that you are on the threshold of a successful career ahead. The road so far has been rocky, but you have overcome all obstacles through sheer hard work and determination. It is definitely time to celebrate! Your graduation day is an opportunity for CSU to celebrate their success through yours, and you deserve the ceremony.
            </p>
            <h2>Why rent when you can purchase your CSU graduation gown?</h2>
            <p className="para">
              CSU graduates are charged an academic dress hire fee as part of their graduation ceremony costs. But do you know that the hire charges will be refunded to you when you purchase your CSU graduation gown from us? You can use that refunded amount to purchase the full academic regalia from us and keep it with you for life! Your CSU graduation gown will always serve as a memento of your university days. Imagine finding it one day, years from now, at the back of your cupboard. It will fill your heart with memories and your eyes with tears, as you remember your student day achievements.
            </p>
            <h2>Enjoy your graduation day more by pre-purchasing your CSU graduation gown</h2>
            <p className="para">
              Relax on your graduation day, avoid arriving at the ceremony hours in advance and long collection queues – your CSU graduation gown will be home delivered to you days in advance! Even better – you can take staged photos in all your favourite campus spots well before graduation day – avoiding the crowds and confusion altogether. We have years of experience in gown design, and make sure your CSU graduation gown meets the high standards expected of it. For example:
            </p>
            <div className="list">
              <p>1. The stole consists of two tails each one metre long and 10cm wide</p>
              <p>2. Graduate hoods lined 10cm and bound 12mm over the cowl with silk-like fabric of the faculty colour</p>
              <p>3. Master hoods lined and bound 20mm over the cowl with silk-like fabric in the faculty colour</p>
            </div>
            <p className="para">
              There are many such detailed CSU specifications that we cater to, ensuring you get a fully customised and handcrafted CSU graduation gown – at incredibly <Link to="/pricebeat">affordable rates</Link>!
            </p>
          </div>
        )

        break;

      case "ECU Graduation Gown | Edith Cowan University":
        description = (
          <div className="su-description">
            <h2>Custom-made ECU graduation gowns that meet ECU criteria</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="ECU Graduates Celebrating" />
            <p className="para">
              Graduating from one of the top 100 Asia-Pacific Universities and one of the top 200 global young universities is no cakewalk. Getting a place is challenging enough, what to speak of meeting the stringent course completion criteria. No doubt you have worked hard, burnt the midnight oil to complete assignments, and used your research and critical thinking skills to complete your project. Edith Cowan University celebrates their success through your success – graduation day is a ceremonial rite of passage to call yourself ECU alumni. You deserve it!
            </p>
            <h2>Purchase your ECU graduation gown and relax on your big day</h2>
            <p className="para">
              Academic dress hire is not just expensive; it is stressful too. You need to show up hours before the actual ceremony only to stand in long queues to collect your ECU graduation gown. You have to keep your gown pristine, or else pay extra fees for damage. This probably means you have to skip those champagne popping photos and watch your step throughout the occasion. And after the ceremony, you still can’t rest! You need to stand in long queues once again to make sure you return your rented ECU graduation gown on time.
            </p>
            <br></br>
            <p className="para">Do you want your graduation day to revolve around your dress or you? If you purchase your ECU graduation gown from us, you can:</p>
            <div className="list">
              <p>1. Get it home delivered well in time for graduation</p>
              <p>2. Take unlimited photos – even stage a few at your favourite hangout before the crowds on big day</p>
              <p>3. Enjoy the day and let any stains or marks become a part of the grand celebration</p>
              <p>4. Relax after the ceremony to reminisce about the past, dream of the future, or spend more time with family – without the hassle of returns</p>
            </div>

            <h2>ECU graduation gowns are cheaper to buy than rent</h2>
            <p className="para">
              Because we are <Link to="/faq">experts at gown design</Link>, and because we have wholesale tie ups with tailors and fabric vendors, we can get you your academic regalia for the same price as your rental fee! When you purchase your ECU graduation gown in a group with friends, we can give you <Link to="save-with-friends">some great deals</Link> – even the best in the market. We supply high quality products to <Link to="/uni">universities across Australia</Link>, and make sure your ECU graduation gown meets all ECU specifications. From the width of the trimming, to the colour of the hood – we pay meticulous attention to every detail. You can order confidently, knowing that you will not be disappointed!
            </p>
          </div>
        )

        break;

      case "University of Western Australia | UWA Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>Hand stitched UWA graduation gowns that will last forever</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="UWA Graduates Celebrating" />
            <p className="para">
              Graduating from the top 1% universities in the world requires you to be in the top 1% too. The consistently high entry scores for University of Western Australia mean that the UWA cohort is the most academically talented of any <Link to="uni/wa">Western Australian university</Link> and are recognised as being of the highest quality in Australia. UWA graduates consistently exceed the national average starting salaries and State graduate employment rates. Obviously, all of this does not come on a platter. You have worked hard, succeeded both academically and practically and are finally qualified to call yourself UWA alumni. Your graduation ceremony is a rite of passage celebrating your entry into this exclusive club. Congratulations!
            </p>
            <h2>We design UWA graduation gowns that meet all requirements</h2>
            <p className="para">
              We are experts in gown design and understand the nitty-gritty details that make your University of Western Australia graduation gown unique. The length of the trim, the colour of the hood lining and the width of the sleeve differ based on your level and field of study. Similarly, accessories like the mortarboard and sash may or may not be present, or may vary in design. Having supplied graduation gowns to universities across Australia, we know how to create gowns that fit your size while meeting university criteria too. You can rest assured that your UWA graduation gown will be approved once purchased from us.
            </p>
            <h2>Purchased UWA graduation gowns make lifelong memorabilia</h2>
            <p className="para">Your UWA days are special and worth treasuring forever. Your UWA graduation gown will serve as a lifelong reminder of your hard work, talent and determination. You can use it as a confidence booster when times are tough, or just something to cheer you up when you are down. Rental gowns just don’t make the cut and worse, they add extra stress to graduation day. Why stand in line hours before the ceremony – just to collect your UWA graduation gown? You can better use that time reminiscing with friends and family, or taking staged photographs in your favourite hangout spots. After all, you will be dressed up way before anybody else!</p>
            <p className="para">
              If you <Link to="/save-with/friends">purchase in a group</Link>, your UWA graduation gown will cost same as the rental fee. UWA graduates know a good deal when they see one – Why rent, when you can just buy it instead?
            </p>
          </div>
        )

        break;

      case "MQ Graduation Gown | Macquarie University Graduation Gown":
        description = (
          <div className="su-description">
            <h2>Handcrafted MQ Graduation Gowns At Unbeatable Prices</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="MQ Graduates Celebrating" />
            <p className="para">
              Graduating from Macquarie University is no easy task – it can be achieved only after countless hours of study, internship and project work. MQ students are expected to innovate and explore, break new ground and defy academic boundaries. All that research, volunteering and industry tie-ups requires hard work and the ability to continue despite all odds. You have done it and are finally about to enter that prestigious group of MQ graduates. Little wonder that this day is celebrated as graduation day. You deserve a ceremony to mark your success!
            </p>
            <h2>Purchase MQ graduation gowns as a lifetime memento</h2>
            <p className="para">
              Years from now, when you look back on your university days, your eyes will surely moisten at the countless memories – conversations with friends, late night study sessions and the challenges of project work. What if you could relive all those memories just by holding your graduation gown in your hand? Your heart fill with pride when you hold your MQ graduation gown in your hand and recount your graduation ceremony sometime in the future? That is why we design MQ graduation gowns that will last forever.
            </p>
            <h2>MQ graduation gowns designed by experts</h2>
            <p className="para">
              We have been supplying graduation gowns to <Link to="/uni">universities across Australia</Link>, and we understand the ins and outs of gown design. Macquarie University graduation gowns purchased from external suppliers must be approved by graduation staff prior to the ceremony. Our products have always passed these checks with flying colours! We pay attention to every last detail, from the colour of the hood and stole, to the breadth of the sleeve and the depth of the trimming. We follow all university specifications to create MQ graduation gowns that meet the high standards of MQ University.
            </p>
            <p className="para">
              Just give us your size, field and level of study, and we will send you your customised MQ graduation gown, in time and on budget. Even better, order with friends to enjoy <Link to="/save-with-friends">group discounts</Link> – so you can have a grand graduation ceremony at lowest prices!
            </p>
          </div>
        )

        break;

        case "UWS Graduation Gown | University of Western Sydney":
          description = (
            <div className="su-description">
              <h2>QUALITY UWS GRADUATION GOWN FOR SALE</h2>
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="UWS Graduates Celebrating" />
              <p className="para">
              Congratulations UWS graduates, you have achieved a huge life milestone, you are graduating. This is a time to cherish where you can sit back and reminisce about the fun times you had at uni, and let’s not forget those late night study sessions or early morning tutes, yes it was a fun time, but getting your degree requires a lot of hard work and dedication. Your UWS course will no doubt equip you for the next stage of your life- your career. At this time, you will be full on into preparing for your UWS graduation ceremony, organising friends and family’s graduation tickets, making a booking at a restaurant or bar, and of-course organising your UWS graduation gown. Well you have come to the right place, at Gowning Street we provide the finest quality UWS academic dress at affordable prices.
              </p>
              <h2>WHAT ARE THE ADVANTAGES OF BUYING YOUR UWS GOWN?</h2>
              <p className="para">
              You might find that buying your gown is the same price as hiring. Furthermore, you can escape the gown hire queue on graduation day and take your graduation photography at a time more convenient. Furthermore, by purchasing your gown, you can pass it down to friends and family for their future graduations, or you may simply decide to keep it as a souvenir. Our UWS graduation regalia is manufactured with immense precision and quality, ensuring you are looking sharp on your graduation day and we are priced affordably compared to hiring from the University. If you get together with friends, you can save up to <Link to="/save-with-friends">$10 per graduation set</Link>.
              </p>
              <h2>WE CATER TO UNIVERSITIES ACROSS AUSTRALIA</h2>
              <p className="para">
              Our services don’t just stop at UWS, we provide regalia to Universities across NSW, the <Link to="/uni/nsw/usyd">University of Sydney</Link>, <Link to="/uni/nsw/mq">Macquarie University</Link> and the <Link to="/uni/nsw/une">University of New England</Link> are just some of the institutions that we provide regalia for. If you are looking for a quality establishment to purchase your UWS academic gown then you have come to the right place, Gowning Street  serves 1000’s of Happy Aussie students every year, and we provide excellent customer service.  If you need anymore information, please use our contact us form [insert link] and a member from our friendly graduations team will be in touch.
              </p>
            </div>
          )

          break;

      case "University of New England | UNE Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>Handcrafted UNE Graduation gowns for sale</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="UNE Graduates Celebrating" />
            <p className="para">
              Completing your graduation from the University of New England requires both academic and practical skills. A university that is consistently rated five stars, expects a five star performance from its students. Everything from gaining admission to researching new solutions is a challenge. There are many obstacles to reaching the finishing line but you have crossed all of them! So many assignments, essays, projects and tests – hours of toil - but you have finally done it. Congratulations!
            </p>
            <h2>Expert UNE graduation gown design</h2>
            <p className="para">
              Finally you can celebrate. You are on the threshold of embarking into a new career. Your graduation ceremony is an opportunity to give yourself a much needed pat on the back – and you can dress up for the occasion without having to spend a bomb! University of New England graduation gowns that meet all the university requirements can be purchased at lowest rates from us. We have the expertise and experience of supplying high quality graduation gowns to universities across Australia.
            </p>
            <p className="para">
              We understand that universities have very specific requirements in academic dress design. Everything from the length of the sleeve to the colour of the hood lining is dictated by stringent rules and standards. For example, a Bachelor in Applied Health would wear a UNE graduation gown with peony red stoles while a Bachelor in Economics would wear a peacock blue one. Similarly, Bachelor hoods are lined to a depth of 15 cm while Master hoods are edged to a depth of 5cm. All such details are painstakingly taken care of in your UNE graduation gown design. You only have to tell us your level and field of study along with your size and we will take care of the rest!
            </p>
            <h2>Buy your UNE graduation gown to enjoy a stress-free ceremony</h2>
            <p className="para">
              You can order anytime and have your gown delivered well in advance of your graduation ceremony – right to your doorstep. You don’t have to stress over waiting in long lines before the ceremony to collect your gown from the official provider. Moreover, you get the best deal in the market when you purchase your UNE graduation gown from us. We even offer <Link to="/save-with-friends">large discounts for group purchases</Link>, ensuring that your graduation memento stays well within budget. You do not have to buy your UNE graduation gown from UNE’s official partner. Buy straight from us to enjoy competitive rates, <Link to="/about">better service</Link> and higher quality deals!
            </p>
          </div>
        )

        break;

      case "University of South Australia | UniSA Graduation Gown":
        description = (
          <div className="su-description">
            <h2>High Quality UniSA Graduation Gowns</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008664/Uni%20Campus%20Photos/UniSA/uni-archways-leaning-wide.jpg" alt="UniSA graduate photo" />
            <p className="para">
              With University of South Australia being declared as one of the top four rising stars in Nature Index’s 2016 Asia-Pacific index, we can safely state that UniSA graduates are rising stars too. After all, it takes intense hard work, effort and determination for 97% of student research to be assessed as world standard or above! If you have managed to secure a place and complete your degree from the university that is ranked 32 in the top 150 under 50, then you definitely need to celebrate.
            </p>
            <h2>A UniSA graduation gown is the best keepsake</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008664/Uni%20Campus%20Photos/UniSA/UniSA_Building.jpg" alt="UniSA building" />
            <p className="para">
              Your UniSA graduation day celebrates your dedication and your accomplishments. As you stand on the threshold of a brand new career you deserve to look and feel your best. A rented gown will not live up to the occasion – mainly because of the stress around procuring and returning it. Do you really want to stand in long queues, pay tons of cash and still not click enough pictures – because you had to return your gown within two hours? And even worse, do you want to spend your graduation day worrying about not damaging your gown or risk incurring additional damage fees?
            </p>
            <p className="para">
              Of course not! It makes more sense to purchase your UniSA graduation gown online, have it delivered to you well in time for your big day and keep it forever – a treasured keepsake of your unique UniSA experience.
            </p>
            <h2>Large discounts on group purchase of UniSA graduation gowns</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008666/Uni%20Campus%20Photos/UniSA/UniSA_Building_2.jpg" alt="UniSA building" />
            <p className="para">
              When you do a group order of your University of South Australia graduation gown from us, you receive a discount that makes the purchase of your gown cost much less than hiring it! UniSA students know a good deal when they see it – order your set of gowns today!
            </p>
            <p className="para">
              All our UniSA graduation gowns are designed according to UniSA specifications. Every detail from the colour of the stole to the length of the sleeve is taken care of – so you have nothing to worry about on your big day! <Link to="/contact">Contact us</Link> to learn how we can help you get the best quality gowns for you and your friends today.
            </p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008667/Uni%20Campus%20Photos/UniSA/UniSA_Building_3.jpg" alt="UniSA building" />
          </div>
        )

        break;

      case "University of Technology Sydney | UTS Graduation Gown":
        description = (
          <div className="su-description">
            <h2>High Quality UTS graduation gowns for your big day</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008723/Uni%20Campus%20Photos/UTS/UTS1.jpg" alt="UTS building in Sydney" />
            <p className="para">
              Graduating from the highest performing university in Australia under 50 years of age is no mean feat – you have to be a high achiever yourself! You have worked hard, submitted some world-class research, volunteered and interned at industry events and are now on the threshold of entering the career of your dreams. Making it to the finish line requires both talent and fortitude – you have done it! This event calls for grand celebration and your University of Technology of Sydney graduation gown will only make your day more memorable!
            </p>
            <h2>Your UTS graduation gown can be purchased at rental rates</h2>
            <p className="para">
              Your UTS graduation gown rental fee is 50% of your UTS graduation ceremony fee. This amount will be refunded to you in full when you purchase your academic regalia from us. We will <Link to="/about">home deliver</Link> your customised UTS graduation gown for same or close to this rental fee. When you purchase from us, you avoid:
            </p>
            <div className="list">
              <p>1. Long queues and gown fitting stress on the day of your graduation</p>
              <p>2. The stress of having to return your gown within 1 hour of end of ceremony</p>
              <p>3. Anxiety around gown damage or accidental stains when you pop yet another celebratory champagne bottle</p>
            </div>
            <p className="para">
              Instead, you can take unlimited photographs and truly cherish the occasion with loved ones. Moreover, your graduation gown will stay with you forever – becoming a precious memento of your student days.
            </p>
            <h2>Our UTS graduation gown design meets university requirements</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008725/Uni%20Campus%20Photos/UTS/UTS3.jpg" alt="UTS building in Sydney" />
            <p className="para">
              We have been supplying graduation gowns for years now, and are experts in the process! From the colour of the lining and stole to the width of the sleeve and mortar, we understand the intricacies of gown design. For example, UTS graduates from the faculty of education sport jade green colours while those from Information Technology sport an electric blue. Similarly, UTS hoods are uniquely tailored around the shoulder and have small weights in the front to balance the heavy fabric at the back. Such subtleties are fully taken care of when we tailor and send your UTS graduation gown to you!
            </p>
          </div>
        )

        break;

      case "Monash University | Monash Graduation Gown":
        description = (
          <div className="su-description">
            <h2>Monash Graduation Gowns Home Delivered</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008634/Uni%20Campus%20Photos/Monash/Monash_Graduate_Gown_Set_Photo.jpg" alt="Monash Bachelor graduate photo" />
            <p className="para">
              Monash journalism students win five Ossie awards, Monash science students crack decade old stem cell mystery, Monash law student receives Schwarzman Scholarship – the list is practically endless. Monash Uni graduates are changing lives of people all around the world. If you have made it to the end of your Monash journey, you will discover that your graduation ceremony is not just a formality – it is a momentous occasion to celebrate the entering of this very prestigious group – Monash Uni graduates!
            </p>
            <h2>Purchase Monash graduation gowns to celebrate your achievements</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008632/Uni%20Campus%20Photos/Monash/Monash_Building_2.jpg" alt="Monash building" />
            <p className="para">
              Your graduation gown is a keepsake worth having – a symbol of not only the incredible effort you put in to be offered a place at Monash, but also the talent, innovative thinking and sheer determination that saw you through to the end of your journey. A rental Monash graduation gown, used once and forgotten forever, is never going to make the cut – because it will never give you the opportunity to hold a piece of your university days in your hands and relive every glorious moment of being a Monash student – moments that will never come back again.
            </p>
            <p className="para">
              Monash graduates are smart enough to know a <Link to="/save-with-friends">good deal</Link> when they see it. Why would you pay more for renting your Monash graduation gown when you can purchase it for less and keep it forever?
            </p>
            <h2>Monash graduation gown design that meets university specification</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008633/Uni%20Campus%20Photos/Monash/Monash_Building_3.jpg" alt="Monash building" />
            <p className="para">
              Monash graduates are game changers and super achievers and only deserve the best. At <Link to="/about">Gowning Street</Link>, we work closely with universities to make sure our academic regalia meets university standards. Our Monash graduation gowns are full length, with flowing sleeves and open drapes as required by university. The Monash hood is turquoise with trimmings that match the colours of your Monash faculty. For example, education graduates with sport ‘banana’ coloured trimmings and stoles while business and economics graduates would wear peacock-green. Similar subtle variations in design exist for different levels of Monash graduates. All such details and complexities are incorporated into our gown design so that you receive the perfect Monash graduation gown!
            </p>
          </div>
        )

        break;

      case "UniMelb Graduation Gowns | University of Melbourne":
        description = (
          <div className="su-description">
            <h2>Premium Quality Unimelb Graduation Gowns</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008657/Uni%20Campus%20Photos/UniMelb/uni-archways-walking-gown-set.jpg" alt="UniMelb graduate wearing gown set" />
            <p className="para">
              Congratulations on completing your studies in one of the most prestigious universities in Australia! Nothing about Melbourne University is for the faint-hearted – it takes courage, grit and determination to gain admissions, complete challenging assignments and dream up innovative projects before that degree lands in your hand. After burning the midnight oil for years, you are finally ready to enter professional life – but not before a proper celebration!
            </p>
            <p className="para">You deserve accolades for sticking to your guns and reaching that coveted finishing line – and your Unimelb graduation ceremony is just about that. You deserve to look your best and feel your best on this momentous occasion and rental gowns just don't cut it.</p>
            <h2>Why Purchase Your Unimelb Graduation Gown?</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008658/Uni%20Campus%20Photos/UniMelb/UniMelb_Building_2.jpg" alt="UniMelb building" />
            <p className="para">
              Renting academic regalia is not only unbelievably expensive, but also incredibly stressful. Why shell out loads of money only to end up waiting in long lines and risking missing out on your ceremony? Worse, you have the added anxiety of not damaging your gown and making sure you return it on time. On the other hand, purchasing your Unimelb graduation gown from us ensures peace of mind, and gives you a special memento to cherish your university experience for life.
            </p>
            <h2>Handcrafted Unimelb Graduation Gowns For Every Department</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008659/Uni%20Campus%20Photos/UniMelb/UniMelb_Building_3.jpg" alt="UniMelb building" />
            <p className="para">We understand the intricacies and subtle differences in gown design for Unimelb scholars graduating different courses and different fields of study. We take gown design seriously and make sure all Unimelb academic regalia meets the exacting specifications required of all Unimelb graduation gowns. We will deliver your gown to you on time and in budget. Your graduation ceremony is going to be the hallmark of your academic journey, and our graduation gowns will let you cherish and remember this milestone forever.</p>
            <p className="para">Order your Unimelb graduation gown today!</p>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008660/Uni%20Campus%20Photos/UniMelb/UniMelb_Building_4.jpg" alt="UniMelb building" />
          </div>
        )

        break;

      case "Victoria University Graduation Gowns":
        description = (
          <div className="su-description">
            <h2>Purchase Hand Crafted Victoria University graduation gowns</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008657/Uni%20Campus%20Photos/UniMelb/uni-archways-walking-gown-set.jpg" alt="Vic Uni graduate wearing gown set" />
            <p className="para">
              Ranked in the top 2% universities in the world, Victoria University is known for its world-class educational facilities and multicultural campus environment. Graduating from this university is no mean feat – it requires a combination of hard work, talent and extra curricular skills to make it to the finish line. Now that you are on the threshold of completing your studies and embarking on a brand new career – perhaps it is a good time to pause and retrospect?
            </p>
            <p className="para">
              Looking back, no doubt, your university days are filled with special moments – working all night to complete that assignment, talking away the many hours meant for project work and hanging out with buddies at the cafeteria. If your eyes get all misty at the thought of your university days, surely your graduation ceremony is worth celebrating grandly?
            </p>
            <h2>Graduate in a custom made Vic Uni graduation gown</h2>
            <p className="para">Your graduation ceremony will mark the end of an era – a time never to return. It is a celebration of your determination, your grit and your ability to stick it through the hardest times. It is also a time to remember friendships made and lost, teachers who changed your life an events that transformed you into a more confident and capable individual. All this, and more can be saved forever as your Victoria University Graduation Gown.</p>
            <p className="para">
              Is it worth spending hundreds of dollars in rental fee for a gown you will never see again? Or is it a smarter choice to buy a memento that will last you forever?
            </p>
            <h2>Authorised Vic Uni graduation gown design</h2>
            <p className="para">
              Sometimes students feel that only the official university gown rentals are correctly made to fit university specifications. This is far from true. At Gowning Street, we take gown making very seriously and make sure that every Victoria University graduation gown meets the strict design criteria set down by Victoria University. You only have to enter your field of study and size requirement, and the perfect gown will be home delivered to you, on time and in budget.
            </p>
            <p className="para">
              Enjoy a hassle free graduation, take unlimited pictures without worrying about returning your gown on time and savour your final moments as a Victoria University student – you have definitely earned it!
            </p>
          </div>
        )

        break;

      case "RMIT Graduation Gown Sets":
        description = (
          <div className="su-description">
            <h2>RMIT Graduation gowns at unbeatable prices</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008738/Uni%20Campus%20Photos/RMIT/RMIT_-_Storey_Hall.jpg" alt="RMIT Storey Hall" />
            <p className="para">
              RMIT graduates are the cream of the crop, meeting the highest standards set by their chosen industry and profession. Becoming a global leader is no mean feat – and your graduation ceremony is an occasion to reflect on and savour your academic success. All your efforts, your talent and your ability to overcome difficult challenges will be celebrated as RMIT throws a final party in your honour! Our finely tailored RMIT graduation gowns are perfect for this occasion and will let you treasure your special day forever, plus save $60 off your graduation ticket by purchasing your gown off us!
            </p>
            <h2>RMIT graduations gowns – only the best for the best</h2>
            <p className="para">
              We work hard to ensure every gown is handcrafted and customised to perfection. From premium quality fabric to the colour of your sleeve lining and stole – everything is selected and designed to meet the exacting standards of RMIT university. For example, architects are required to wear an RMIT graduation gown with cherry coloured hoods and stoles while engineering graduates wear theirs in spectrum blue. RMIT has similar <Link to="/regalia-regulations/RMIT">rigorous criteria</Link> on gown sizes, mortarboard sizes and more and we ensure complete compliance to every tiny detail
              </p>
            <h2>RMIT graduation gowns are worth keeping forever</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1635008738/Uni%20Campus%20Photos/RMIT/RMIT_-_Design_Hub.jpg" alt="RMIT Design Hub" />
            <p className="para">
              We believe that RMIT graduation gowns are much more than just ceremonial attire – they are a symbol of your talent and your hard work. They acknowledge not only the fact that you could secure a place in this global institution but also your ability to finish the journey - no mean feat considering that so many students drop out along the way! Is a two-hour rental gown going to remind you of your university days, filling your heart with joy and pride at a moment’s notice?
              </p>
            <p className="para">
              Your RMIT experience is worth treasuring forever, and that is why we have gone the extra mile to include RMIT graduation gowns in our academic gown collection. Simply select your area of study and your size and have your RMIT graduation gown delivered to your doorstep. Buy a set of gowns for <Link to="/save-with-friends">your friends</Link> and enjoy a discount that will make your RMIT gown purchase cost less than the rental fee!
              </p>
            <p className="para">
              RMIT students know a good deal when they see it and so our RMIT graduation gowns are always in high demand! Buy yours in time to avoid disappointment
              </p>

          </div>
        )

        break;

      case "UoN | University of Newcastle":
        description = (
          <div className="su-description">
            <h2>Why hire when you can buy your UON graduation gown for less?</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="UoN Students Celebrating" />
            <p className="para">
              The University of Newcastle has a global reputation of being one of the best universities and it expects the highest academic standards from its students too. Talent is not enough; it takes hard work, grit and determination to overcome challenges and pass out successfully from one of the top 200 universities in the world. You have to be in the top 1% to pass out from the top 1% - no wonder it is a momentous occasion when you can finally call yourself an UON graduate. Your UON graduation ceremony is an opportunity to celebrate your achievements and look forward to an exciting future ahead. You deserve it!
            </p>
            <h2>Hiring your UON graduation gown is more stressful than you think</h2>
            <p className="para">
              It may seem stress-free to hire a gown from your university’s official partner – but is it? UON graduates have to pay an additional charge if the rental gown is not returned on the day of the ceremony. Additional fees are charged for damaged and lost items. You not only have to worry about keeping your gown pristine and returning it on time, you also have to deal with long collection queues. Students have to wait in collection lines 90 minutes prior to start of ceremony if they rent their University of Newcastle graduation gown!
            </p>
            <h2>UON graduation gowns that meet UON specifications</h2>
            <p className="para">
              Do you really want to spend your entire graduation day focussed on your clothing? Instead you can just purchase your University of Newcastle graduation gown from us and leave everything to the experts! We will home deliver your customised gown, on time and in budget. Wear it knowing that it will be yours forever. Use the extra time on graduation day to dress nicely for the occasion, click dozens of snaps with friends or simply reminisce about the past and the future. Don’t worry about any wear and tear – every mark and stain on your gown will simply become a precious memory of graduation day.
            </p>
            <p className="para">
              We understand that your UON graduation gown design is a very big deal and we ensure we follow all university standards. For example, a Bachelor of Law graduate must wear a hood lined to a depth of 15 cm in ‘waratah’ red while a Masters in Marketing would wear one fully lined in turquoise blue. Having supplied gowns to dozens of Universities around Australia, you can rest assured that your UON graduation gown is in safe hands!
            </p>
          </div>
        )

        break;

      case "Southern Cross University | SCU Graduation Gown":
        description = (
          <div className="su-description">
            <h2>Premium quality SCU Graduation gowns for sale</h2>
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="SCU Students Celebrating" />
            <p className="para">
              Southern Cross University graduation cannot happen without a considerable commitment of time, energy and personal sacrifice. SCU has been rated well above world standards, consistently for year and is ranked in the top 150 under 50 universities in the world. It takes sheer hard work, grit and determination on your part to complete your course to such high standards and your SCU graduation ceremony is an acknowledgement of these efforts. It is the University's way of congratulating you and welcoming you to membership of the SCU community, celebrating your success as an evidence of their success. This important day should not be blighted with stress around your academic dress!
            </p>
            <h2>Your SCU graduation gown can be a memento for life</h2>
            <p className="para">
              Graduation day is an opportunity for you to reflect both on the past and on the future.  Your Southern Cross University graduation gown will forever represent the ups and downs of your university days along with the spirit of adventure that lies ahead. Imagine taking it out from your cupboard a decade later, and finding your heart fill with renewed hope and optimism – your SCU graduation gown will remind you of your accomplishments and cheer you up if needed. A rental gown is just not going to make the cut!
            </p>
            <h2>Official SCU graduation gown design at unofficial prices</h2>
            <p className="para">
              If you have checked out the SCU academic dress section, you will have noted that there are plenty of subtle variations in gown design. For example, SCU business graduates wear hoods with sunflower yellow linings and sunflower yellow stole linings while SCU health science graduates sport signal red colours in their design. We make sure every such little detail is accounted for, and deliver your SCU graduation gown to you in time and on budget.  You can purchase the gown at less than the cost of renting it, and we will <Link to="/pricebeat">beat any price on offer</Link>.
            </p>
            <h2>SCU graduates know a good deal when they see one</h2>
            <p className="para">
              Don't waste money and energy behind a rental gown, you will save $70 off your SCU graduation ticket buy purchasing your gown through us.. Buy a living memory of your SCU university days from us today!
            </p>

          </div>
        )

        break;

        case "Federation University Graduation Gown Sets":
          description = (
            <div className="su-description">
              <h2>FED UNI GRADUATION GOWN</h2>
              <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Federation Uni Students Celebrating" />
              <p className="para">
              Congratulations Fed Uni graduates, you have made it! All those years of toil and determination have brought you to this special day, that’s right! Your graduation ceremony. Now the next stage of your life begins and you will be equipped with all the knowledge and experience your course at fed uni has taught you. All those years of writing assignments, preparing for exams and undertaking practicals will now be invaluable when it comes to applying for a job. Some of you may decide to go overseas, some will choose to go interstate, no doubt the next stage of your life will bring in many different experiences. However, now is the time to sit back and enjoy the moment, it’s time to get together with friends and fam and enjoy your graduation ceremony.
              </p>
              <h2>WE HAVE YOUR FED UNI ACADEMIC DRESS NEEDS COVERED</h2>
              <p className="para">
              Whether it’s a bachelor of education or business, we have the right gown for you. If you are unsure which fed uni academic gown you need, please get in touch with someone from our team. We have available a 3 piece set, which includes your fed uni gown and cap + academic hood. This three piece set is just $94 + $11 Shipping.
              </p>
              <h2>WHY PURCHASE YOUR FED UNI REGALIA FROM US?</h2>
              <p className="para">
              At Gowning Street, we pride ourselves on our quality and customer service. If you order before 1pm, we provide same day dispatch, which means you can receive your fed uni gown in 2-3 days! Also, you may find that purchasing your fed uni academic dress is around the same price is hiring. So purchasing your gown allows you to pass it to friends/family for their graduation or you may just decide to keep it as a life-long momento.
              </p>
              <h2>Need more info?</h2>
              <p className="para">
              Please contact our friendly sales team if you need any help with selecting your fed uni academic gown, our <Link to="/contact">contact details</Link> can be found here.
              </p>

            </div>
          )

          break;

          case "Bond University":
            description = (
              <div className="su-description">
                <h2>THE WIDEST COLLECTION OF BOND UNI GRADUATION GOWNS</h2>
                <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Bond Uni Students Celebrating" />
                <p className="para">
                You have found our website, which can only mean one thing: you have achieved a massive milestone and you completed your university course. Congratulations! You have put in hard work and were determined, and your tireless effort did not go without a reward. Now, it is time to consider the more fun part of it: your graduation ceremony.
                </p>
                <p className="para">
                However, this is an official ceremony, therefore it required a very special attire: a Bond Uni Graduation gown. And you are in the best hands here at Gowning Street.
                </p>
                <p className="para">
                And why would you choose us over a Bond Uni graduation gown hire? Well, here are a few very good reasons:
                </p>
                <p className="para">
                It is yours to keep! Why not keep the memento of your graduation day and be able to show it to your family who couldn’t attend your ceremony?
                </p>
                <p className="para">
                Wave goodbye to the pickup and drop off queues and have your Bond University academic dress delivered straight to your door – no hassle!
                </p>
                <p className="para">
                And here is the best thing: if you get your university classmates involved as well, you get a nice little <Link to="/save-with-friends">discount!</Link>
                </p>
                <h2>WHAT IS BEYOND BOND UNI GRADUATION GOWNS?</h2>
                <p className="para">
                Our extensive range doesn’t stop at Bond University – we supply for a range of other universities across Australia and we take great pride in the quality of our products, so you can suggest us to your friends at other universities.
                </p>
                <p className="para">
                If you have any further questions or just want to know what else we do here at Gowning Street, we are easily contactable on our <Link to="/contact">contact form</Link> – one of our lovely advisors will be in touch with you in absolutely no time! Or, if that is more your style, give us a ring!
                </p>

              </div>
            )

            break;

      default:
        description = null;

    }

    return (
      <div className="uni-description">
        {description}
      </div>
    )

}

export default UniDescriptions;