import styled from 'styled-components';
import { typography, colors, sizes } from './theme'
import { mediaQueries } from './mediaQueries';

export const SectionContainer = styled.div`
  padding-top: ${sizes.sectionPaddingSize};
  text-align: center;
  ${mediaQueries.tablet`
    h1 {
      font-size: ${typography.alpha};
    }
  `};
`;

export const AlphaHeader = styled.h2`
  color:${colors.primary};
  font-size: ${typography.alpha};
  padding: ${typography.alpha};
  text-align: center;
`;

export const BetaHeader = styled.h3`
  color:${colors.primary};
  font-size: ${typography.beta};
  padding: ${typography.micro};
`;