import React from 'react';
import { withFormsy } from 'formsy-react';

const CustomInput = (props) => {
  const changeValue = (event) => {
    props.setValue(event.currentTarget.value);
    props.handleChange(event.currentTarget.value);
  }
  const className = props.showRequired ? 'required' : props.showError ? 'error' : '';

  const formatError = (props) => {
    let showErrorMessage = false
    if (props.isFormSubmitted) {
      if (props.showRequired && !props.value) {
          showErrorMessage = true
          return (
            <span style={{display: 'block', color: '#cc0000', fontSize: '12px', padding: '1px 5px 10px', textAlign: 'left'}}>
              This field is required
            </span>
          )
      }
      if (!props.isValid) {
        showErrorMessage = true
        return (
          <span style={{display: 'block', color: '#cc0000', fontSize: '12px', padding: '1px 5px 10px', textAlign: 'left'}}>
            { props.errorMessage || props.validationErrors.equalsField }
          </span>
        )
      }
    }
  }


  return (
    <div className={className}>
      <input
        type="text"
        placeholder={props.placeholder}
        onChange={changeValue}
        value={props.value || ''}
      />
      { formatError(props) }
    </div>
  );

};

export default withFormsy(CustomInput);