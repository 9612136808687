export const typography = {
  fontFamily: 'Raleway',
  lineHeight: '24px',
  mega: '36px',
  alpha: '32px',
  beta: '22px',
  gamma: '14px',
  delta: '12px',
  milli: '10px',
  micro: '8px',
  light: '300',
  regular: '400',
  bold: '700',
}

export const sizes = {
  baseSizeSmall: '6px',
  baseSize: '12px',
  baseSizeLarge: '24px',
  baseRadius: '5px',
  marginSize: '12px',
  marginSizeLarge: '36px',
  paddingSize: '12px',
  sectionPaddingSize: '18px',
  lineSize: '1px',
  modalMaxWidth: '60vw',
  modalMinWidth: '40vw',
  modalMaxHeight: '80vh',
}

export const colors = {
  primary: 'black',
  secondary: '#033649',
  background: '#E8DDCB',
  panel: '#CDB380',
  overlay: '#445363',
  placeholder: '#5A6775',
  success: '#23A7A0',
  warning: '#C1914A',
  danger: '#D93146',
  successSecondary: '#CFFEFC',
  warningSecondary: '#FADFB6',
  dangerSecondary: '#FBBEC5',
  contrast: '#FFFFFF',
  highlight: '#B2E0E2',
  header: '#036564',
  headerOverlay: '#2A3442',
  label: '#485462',
  disabledText: '#989898',
  graphLine: '#94E2C3',
  graphName: '#E6D284',
  modalOverlay: 'rgba(26,33,43,0.7)',
}
