const {
  BACHELOR_GOWN_PRICE,
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_CAP_PRICE,
  ISN_SET_PRICE,
  CAP_PRICE,
  GARMENT_BAG,
  HOOD_PRICE,
  MASTER_GOWN_CAP_PRICE,
  MASTER_GOWN_PRICE,
  PHD_BONNET_PRICE,
  PHD_GOWN_PRICE,
  PUPPY_LARGE_PRICE,
  PUPPY_SMALL_PRICE,
  TEDDY_SMALL_PRICE,
  MASTER_SET_PRICE,
  PHD_SET_PRICE,
  BARRISTER_GOWN_PRICE,
  ADMISSIONS_GOWN_PRICE,
  BARRISTER_WIG_PRICE,
  BENCHER_WIG_PRICE,
} = require('../../constants')

export const Products = {
  // UQ

  "/uni/qld/uq/bachelor-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008480/Aus-Uni-Photos/UQ/uq-bach-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008481/Aus-Uni-Photos/UQ/uq-bach-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008479/Aus-Uni-Photos/UQ/uq-bach-all-back.jpg"],
    "metaTag" : "Buy your UQ graduation gown set for all bachelor degrees with Gowning Street",
    "altTags" : ["UQ graduation gown set - All Bachelor Degrees - Front view", "UQ graduation gown set - All Bachelor Degrees - Side view", "UQ graduation gown set - All Bachelor Degrees - Back view"],
    "name" : "UQ Graduation Gown Set - All Bachelor Degrees",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Queensland Hood - All Bachelor Degrees",
    "hood-style" : "Black, Cambridge Style, Edged with Pearl White Silk",
    "colour" : "Edged with Pearl White Silk",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO70101",
    "productType" : "bachelor-full-set",
    "colors" : ["white"]
  },
  "/uni/qld/uq/master-degrees" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592482356/Uni%20Regalia%20Photos/UQ/uq-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592482356/Uni%20Regalia%20Photos/UQ/uq-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592482356/Uni%20Regalia%20Photos/UQ/uq-mast-all-back.jpg"],
    "metaTag" : "Buy your UQ graduation gown set for all master degrees with gowning street",
    "altTags" : ["UQ graduation gown set - All Master Degrees - Front view", "UQ graduation gown set - All Master Degrees - Side view", "UQ graduation gown set - All Master Degrees - Back view"],
    "name" : "UQ Graduation Gown Set - All Master Degrees",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Queensland Hood - All Master Degrees",
    "hood-style" : "Black, Cambridge Style, Edged with Rich Blue Silk",
    "colour" : "Edged with Rich Blue Silk",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO70111",
    "productType" : "master-full-set",
    "colors" : ["#267CD9"]
  },
  "/uni/qld/uq/phd" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008488/Aus-Uni-Photos/UQ/uq-phd-set-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008490/Aus-Uni-Photos/UQ/uq-phd-set-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008489/Aus-Uni-Photos/UQ/uq-phd-set-back.jpg"],
    "metaTag" : "Buy your University of Queensland PhD graduation gown set from Gowning Street!",
    "altTags" : ["University of Queensland PhD graduation gown set - Front view", "University of Queensland PhD graduation gown set - Side view", "University of Queensland PhD graduation gown set - Back view"],
    "name" : "UQ Graduation Gown Set - Doctor of Philosophy",
    "price" : PHD_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Queensland Hood - PhD",
    "hood-style" : "Black hood, lined/trimmed with Scarlet",
    "colour" : "lined/trimmed with Scarlet",
    "gownStyle" : "PhD",
    "phdStyle" : "satin",
    "level" : "PhD",
    "hood-code" : "HOO70121",
    "productType" : "phd-full-set",
    "colors" : ["red"],
    "hatType" : "red"
  },

// New South Wales

// Macquarie University

  "/uni/nsw/mq/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008002/Aus-Uni-Photos/MQ/mq-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008003/Aus-Uni-Photos/MQ/mq-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008001/Aus-Uni-Photos/MQ/mq-bach-arts-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University bachelor of arts graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University bachelor of arts graduation gown set - Front view", "Macquarie University bachelor of arts graduation gown set - Side view", "Macquarie University bachelor of arts graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Arts",
    "hood-style" : "Gold, Cambridge-style, edged with White.",
    "colour" : "Edged with White",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20401",
    "productType" : "bachelor-full-set",
    "colors" : ["white"]
  },
  "/uni/nsw/mq/bachelor-of-science-and-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008017/Aus-Uni-Photos/MQ/mq-bach-sciengg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008018/Aus-Uni-Photos/MQ/mq-bach-sciengg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008016/Aus-Uni-Photos/MQ/mq-bach-sciengg-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University bachelor of Science & Engineering graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Bachelor of Science & Engineering graduation gown set - Front view", "Macquarie University Bachelor of Science & Engineering graduation gown set - Side view", "Macquarie University Bachelor of Science & Engineering graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Science & Engineering",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Science & Engineering",
    "hood-style" : "Gold, Cambridge-style, edged with Tartan Green",
    "colour" : "Edged with Tartan Green",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20402",
    "productType" : "bachelor-full-set",
    "colors" : ["#64C850"]
  },
  "/uni/nsw/mq/graduation-gown/bachelor-of-business-and-economics" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008005/Aus-Uni-Photos/MQ/mq-bach-buss-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008006/Aus-Uni-Photos/MQ/mq-bach-buss-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008004/Aus-Uni-Photos/MQ/mq-bach-buss-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University bachelor of Business & Economics graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Bachelor of Business & Economics graduation gown set - Front view", "Macquarie University Bachelor of Business & Economics graduation gown set - Side view", "Macquarie University Bachelor of Business & Economics graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Business & Economics",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Business & Economics",
    "hood-style" : "Gold, Cambridge-style, edged with Electric Blue",
    "colour" : "Edged with Electric Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20403",
    "productType" : "bachelor-full-set",
    "colors" : ["blue"]
  },
  "/uni/nsw/mq/graduation-gown/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008011/Aus-Uni-Photos/MQ/mq-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008012/Aus-Uni-Photos/MQ/mq-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008010/Aus-Uni-Photos/MQ/mq-bach-law-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University bachelor of Law graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Bachelor of Law graduation gown set - Front view", "Macquarie University Bachelor of Law graduation gown set - Side view", "Macquarie University Bachelor of Law graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Law",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Law",
    "hood-style" : "Gold, Cambridge-style, edged with Amethyst (Lilac)",
    "colour" : "Edged with Amethyst (Lilac)",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20404",
    "productType" : "bachelor-full-set",
    "colors" : ["#CC99FF"]
  },
  "/uni/nsw/mq/graduation-gown/bachelor-of-human-sciences" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008008/Aus-Uni-Photos/MQ/mq-bach-human-sciences-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008009/Aus-Uni-Photos/MQ/mq-bach-human-sciences-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008007/Aus-Uni-Photos/MQ/mq-bach-human-sciences-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Bachelor of Human Sciences graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Bachelor of Human Sciences graduation gown set - Front view", "Macquarie University Bachelor of Human Sciences graduation gown set - Side view", "Macquarie University Bachelor of Human Sciences graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Human Sciences",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Human Sciences",
    "hood-style" : "Gold, Cambridge-style, edged with Dioptase (Turquoise Green)",
    "colour" : "Edged with Dioptase (Turquoise Green)",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20405",
    "productType" : "bachelor-full-set",
    "colors" : ["#00ced1"]
  },
  "/uni/nsw/mq/graduation-gown/bachelor-of-medhealthsci" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008014/Aus-Uni-Photos/MQ/mq-bach-medhealthsci-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008015/Aus-Uni-Photos/MQ/mq-bach-medhealthsci-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008013/Aus-Uni-Photos/MQ/mq-bach-medhealthsci-back.png"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Bachelor of Medicine & Health Sciences graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Bachelor of Medicine & Health Sciences graduation gown set - Front view", "Macquarie University Bachelor of Medicine & Health Sciences graduation gown set - Side view", "Macquarie University Bachelor of Medicine & Health Sciences graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Bachelor of Medicine & Health Sciences",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Bachelor of Medicine & Health Sciences",
    "hood-style" : "Gold, Cambridge-style, edged with Pipeline Grey (Silver)",
    "colour" : "Edged with Pipeline Grey (Silver)",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20406",
    "productType" : "bachelor-full-set",
    "colors" : ["#C0C0C0"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-business-and-economics" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008024/Aus-Uni-Photos/MQ/mq-mast-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008025/Aus-Uni-Photos/MQ/mq-mast-business-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008023/Aus-Uni-Photos/MQ/mq-mast-business-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Business & Economics graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Business & Economics graduation gown set - Front view", "Macquarie University Master of Business & Economics graduation gown set - Side view", "Macquarie University Master of Business & Economics graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Business & Economics",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Business & Economics",
    "hood-style" : "Gold, Cambridge-style, edged with Electric Blue",
    "colour" : "Edged with Electric Blue",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20411",
    "productType" : "master-full-set",
    "colors" : ["blue"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-human-sciences" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008027/Aus-Uni-Photos/MQ/mq-mast-human-sciences-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008028/Aus-Uni-Photos/MQ/mq-mast-human-sciences-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008026/Aus-Uni-Photos/MQ/mq-mast-human-sciences-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Human Sciences graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Human Sciences graduation gown set - Front view", "Macquarie University Master of Human Sciences graduation gown set - Side view", "Macquarie University Master of Human Sciences graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Human Sciences",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Human Sciences",
    "hood-style" : "Gold, Cambridge-style, edged with Dioptase (Turquoise green)",
    "colour" : "Edged with Dioptase (Turquoise green)",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20412",
    "productType" : "master-full-set",
    "colors" : ["#00ced1"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008021/Aus-Uni-Photos/MQ/mq-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008022/Aus-Uni-Photos/MQ/mq-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008020/Aus-Uni-Photos/MQ/mq-mast-arts-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Arts graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Arts graduation gown set - Front view", "Macquarie University Master of Arts graduation gown set - Side view", "Macquarie University Master of Arts graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Arts",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Arts",
    "hood-style" : "Gold, Cambridge-style, edged with White",
    "colour" : "Edged with White",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20413",
    "productType" : "master-full-set",
    "colors" : ["white"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-science-and-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008036/Aus-Uni-Photos/MQ/mq-mast-science-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008037/Aus-Uni-Photos/MQ/mq-mast-science-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008035/Aus-Uni-Photos/MQ/mq-mast-science-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Science & Engineering graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Science & Engineering graduation gown set - Front view", "Macquarie University Master of Science & Engineering graduation gown set - Side view", "Macquarie University Master of Science & Engineering graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Science & Engineering",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Science & Engineering",
    "hood-style" : "Gold, Cambridge-style, edged with Tartan Green",
    "colour" : "Edged with Tartan Green",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20414",
    "productType" : "master-full-set",
    "colors" : ["green"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-management" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008033/Aus-Uni-Photos/MQ/mq-mast-mgmt-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008034/Aus-Uni-Photos/MQ/mq-mast-mgmt-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008032/Aus-Uni-Photos/MQ/mq-mast-mgmt-back.jpg"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Management graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Management graduation gown set - Front view", "Macquarie University Master of Management graduation gown set - Side view", "Macquarie University Master of Management graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Management",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Management",
    "hood-style" : "Gold, Cambridge-style, edged with Smalt Blue",
    "colour" : "Edged with Smalt Blue",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20415",
    "productType" : "master-full-set",
    "colors" : ["#1e90ff"]
  },
  "/uni/nsw/mq/graduation-gown/master-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008030/Aus-Uni-Photos/MQ/mq-mast-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008031/Aus-Uni-Photos/MQ/mq-mast-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008029/Aus-Uni-Photos/MQ/mq-mast-law-back.png"],
    "metaTag" : "Graduated from Macquarie University? Buy your Macquarie University Master of Law graduation gown set with Gowning Street",
    "altTags" : ["Macquarie University Master of Law graduation gown set - Front view", "Macquarie University Master of Law graduation gown set - Side view", "Macquarie University Master of Law graduation gown set - Back view"],
    "name" : "Macquarie Uni Graduation Gown Set - Master of Law",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "Macquarie University Hood - Master of Law",
    "hood-style" : "Gold, Cambridge-style, edged with Amethyst (Lilac)",
    "colour" : "Edged with Amethyst (Lilac)",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20416",
    "productType" : "master-full-set",
    "colors" : ["#CC99FF"]
  },

//Uni of Sydney

  "/uni/nsw/usyd/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008525/Aus-Uni-Photos/USyd/usyd-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008526/Aus-Uni-Photos/USyd/usyd-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008524/Aus-Uni-Photos/USyd/usyd-bach-arts-back.jpg"],
    "metaTag" : "Graduated from USyd? Buy your USyd bachelor of arts graduation gown set with Gowning Street",
    "altTags" : ["USyd bachelor of arts graduation gown set - Front view", "USyd bachelor of arts graduation gown set - Side view", "USyd bachelor of arts graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Arts",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Arts",
    "hood-style" : "Black, Cambridge-style, edged with White Fur",
    "colour" : "Edged with White Fur",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20105",
    "productType" : "bachelor-full-set",
    "colors" : ["white"]
  },
  "/uni/nsw/usyd/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008544/Aus-Uni-Photos/USyd/usyd-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008545/Aus-Uni-Photos/USyd/usyd-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008543/Aus-Uni-Photos/USyd/usyd-bach-law-back.jpg"],
    "metaTag" : "Buy your USyd Bachelor of Law graduation gown set from Gowning Street!",
    "altTags" : ["USyd bachelor of Law graduation gown set - Front view", "USyd bachelor of Law graduation gown set - Side view", "USyd Bachelor of Law graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Law",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Law",
    "hood-style" : "Black, Cambridge Style, edged with Blue",
    "colour" : "Edged with Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20102",
    "productType" : "bachelor-full-set",
    "colors" : ["lightblue"]
  },
  "/uni/nsw/usyd/bachelor-of-architecture" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592481752/Uni%20Regalia%20Photos/USyd/usyd-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592481752/Uni%20Regalia%20Photos/USyd/usyd-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592481752/Uni%20Regalia%20Photos/USyd/usyd-bach-arch-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of Design in Architecture graduation gown set with Gowning Street",
    "altTags" : ["USyd bachelor of architecture graduation gown set - Front view", "USyd bachelor of architecture graduation gown set - Side view", "USyd bachelor of architecture graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Design in Architecture",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Design in Architecture",
    "hood-style" : "Black, Cambridge Style, edged with White and Brick Red",
    "colour" : "Edged with White and Brick Red",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20104",
    "productType" : "bachelor-full-set",
    "colors" : ["white", "#E05834"]
  },
  "/uni/nsw/usyd/bachelor-of-commerce" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008528/Aus-Uni-Photos/USyd/usyd-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008529/Aus-Uni-Photos/USyd/usyd-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008527/Aus-Uni-Photos/USyd/usyd-bach-comm-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of commerce graduation gown set with Gowning Street",
    "altTags" : ["USyd bachelor of commerce graduation gown set - Front view", "USyd bachelor of commerce graduation gown set - Side view", "USyd bachelor of commerce graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Commerce",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Commerce",
    "hood-style" : "Black, Cambridge Style, edged with White and Copper",
    "colour" : "Edged with White and Copper",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code": "HOO20101",
    "productType" : "bachelor-full-set",
    "colors" : ["#E05834", "white"]
  },
  "/uni/nsw/usyd/bachelor-of-economics" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008531/Aus-Uni-Photos/USyd/usyd-bach-eco-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008532/Aus-Uni-Photos/USyd/usyd-bach-eco-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008530/Aus-Uni-Photos/USyd/usyd-bach-eco-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of economics graduation gown set with Gowning Street",
    "altTags" : ["USyd bachelor of economics graduation gown set - Front view", "USyd bachelor of economics graduation gown set - Side view", "USyd bachelor of economics graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Economics",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Economics",
    "hood-style" : "Black, Cambridge Style, edged with Copper",
    "colour" : "Edged with Copper",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20106",
    "productType" : "bachelor-full-set",
    "colors" : [ "#E05834"]
  },
  "/uni/nsw/usyd/bachelor-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008534/Aus-Uni-Photos/USyd/usyd-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008535/Aus-Uni-Photos/USyd/usyd-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008533/Aus-Uni-Photos/USyd/usyd-bach-edu-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of education graduation gown set with Gowning Street!",
    "altTags" : ["USyd bachelor of education graduation gown set - Front view", "USyd bachelor of education graduation gown set - Side view", "USyd bachelor of education graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Education",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Education",
    "hood-style" : "Black, Cambridge Style, edged with White",
    "colour" : "Edged with White",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO70101",
    "productType" : "bachelor-full-set",
    "colors" : ["white"]
  },
  "/uni/nsw/usyd/bachelor-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008554/Aus-Uni-Photos/USyd/usyd-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008555/Aus-Uni-Photos/USyd/usyd-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008553/Aus-Uni-Photos/USyd/usyd-bach-sci-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of science graduation gown set with Gowning Street!",
    "altTags" : ["USyd bachelor of science graduation gown set - Front view", "USyd bachelor of science graduation gown set - Side view", "USyd bachelor of science graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Science",
    "hood-style" : "Black, Cambridge Style, edged with Amber",
    "colour" : "Edged with Amber",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20103",
    "productType" : "bachelor-full-set",
    "colors" : ["#EBDB39"]
  },
  "/uni/nsw/usyd/bachelor-of-medicine" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008557/Aus-Uni-Photos/USyd/usyd-doc-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008558/Aus-Uni-Photos/USyd/usyd-doc-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008556/Aus-Uni-Photos/USyd/usyd-doc-med-back.jpg"],
    "metaTag" : "Just graduated from a bachelor of medicine degree at USyd? Buy your graduation gown set with Gowning Street!",
    "altTags" : ["USyd bachelor of medicine graduation gown set - Front view", "USyd bachelor of medicine graduation gown set - Side view", "USyd bachelor of medicine graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Medicine",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "USyd Graduation Gown Set - Bachelor of Medicine",
    "hood-style" : "Black, Cambridge Style, edged with Purple and Scarlet",
    "colour" : "Edged with Purple and Scarlet",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20107",
    "productType" : "bachelor-full-set",
    "colors" : ["#FD4E4D", "#8535A3"]
  },
  "/uni/nsw/usyd/bachelor-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008538/Aus-Uni-Photos/USyd/usyd-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008539/Aus-Uni-Photos/USyd/usyd-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008537/Aus-Uni-Photos/USyd/usyd-bach-engg-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of engineering graduation gown set from Gowning Street!",
    "altTags" : ["USyd bachelor of engineering graduation gown set - Front view", "USyd bachelor of engineering graduation gown set - Side view", "USyd bachelor of engineering graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Engineering",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Engineering",
    "hood-style" : "Black, Cambridge Style, edged with Light Maroon",
    "colour" : "Edged with Light Maroon",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20108",
    "productType" : "bachelor-full-set",
    "colors" : ["hsl(0, 100%, 25%)"]
  },
  "/uni/nsw/usyd/bachelor-of-health-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008541/Aus-Uni-Photos/USyd/usyd-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008542/Aus-Uni-Photos/USyd/usyd-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008540/Aus-Uni-Photos/USyd/usyd-bach-health-back.jpg"],
    "metaTag" : "Buy your USyd bachelor of Health Science graduation gown set from Gowning Street!",
    "altTags" : ["USyd bachelor of Health Science graduation gown set - Front view", "USyd bachelor of Health Science graduation gown set - Side view", "USyd Bachelor of Health Science graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Health Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Health Science",
    "hood-style" : "Black, Cambridge Style, edged with Amber and Dark Blue",
    "colour" : "Edged with Amber and Dark Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20109",
    "productType" : "bachelor-full-set",
    "colors" : ["#EBDB39", "hsl(200,100%,15%)"]
  },
  "/uni/nsw/usyd/master-of-commerce" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008562/Aus-Uni-Photos/USyd/usyd-mast-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008563/Aus-Uni-Photos/USyd/usyd-mast-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008561/Aus-Uni-Photos/USyd/usyd-mast-comm-back.jpg"],
    "metaTag" : "Buy your USyd master of commerce graduation gown set from Gowning Street",
    "altTags" : ["USyd master of commerce graduation gown set - Front view", "USyd master of commerce graduation gown set - Side view", "USyd master of commerce graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Commerce",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Commerce",
    "hood-style" : "Black, Cambridge Style, lined and edged with Copper and White",
    "colour" : "Lined and Edged with Copper and White",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20111",
    "productType" : "master-full-set",
    "colors" : ["#E05834", "white"]
  },
  "/uni/nsw/usyd/master-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008565/Aus-Uni-Photos/USyd/usyd-mast-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008566/Aus-Uni-Photos/USyd/usyd-mast-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008564/Aus-Uni-Photos/USyd/usyd-mast-edu-back.jpg"],
    "metaTag" : "Buy your USyd master of education graduation gown set from Gowning Street!",
    "altTags" : ["USyd master of education graduation gown set - Front view", "USyd master of education graduation gown set - Side view", "USyd master of education graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Education",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Education",
    "hood-style" : "Black, Cambridge Style, lined with White",
    "colour" : "Lined with White",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20112",
    "productType" : "master-full-set",
    "colors" : ["white"]
  },
  "/uni/nsw/usyd/master-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008568/Aus-Uni-Photos/USyd/usyd-mast-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008569/Aus-Uni-Photos/USyd/usyd-mast-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008567/Aus-Uni-Photos/USyd/usyd-mast-law-back.jpg"],
    "metaTag" : "Buy your USyd master of law graduation gown set from Gowning Street!",
    "altTags" : ["USyd master of law graduation gown set - Front view", "USyd master of law graduation gown set - Side view", "USyd master of law graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Law",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Law",
    "hood-style" : "Black, Cambridge Style, lined with Ultramarine",
    "colour" : "Lined with Ultramarine",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20113",
    "productType" : "master-full-set",
    "colors" : ["#2460CA"]
  },
  "/uni/nsw/usyd/graduation-gown/bachelor-of-nursing" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008551/Aus-Uni-Photos/USyd/usyd-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008552/Aus-Uni-Photos/USyd/usyd-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008550/Aus-Uni-Photos/USyd/usyd-bach-nurse-back.jpg"],
    "metaTag" : "Buy your USyd Bachelor of Nursing graduation gown set from Gowning Street!",
    "altTags" : ["USyd Bachelor of Nursing graduation gown set - Front view", "USyd Bachelor of Nursing graduation gown set - Side view", "USyd Bachelor of Nursing graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Nursing",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Nursing",
    "hood-style" : "Black, Cambridge Style, lined with White and Turquoise",
    "colour" : "Lined with White and Turquoise",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20110",
    "productType" : "bachelor-full-set",
    "colors" : ["#D9DCE5", "#0A9ACE"]
  },
  "/uni/nsw/usyd/graduation-gown/bachelor-of-applied-sci" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008551/Aus-Uni-Photos/USyd/usyd-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008552/Aus-Uni-Photos/USyd/usyd-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008550/Aus-Uni-Photos/USyd/usyd-bach-nurse-back.jpg"],
    "metaTag" : "Buy your USyd Bachelor of Applied Science graduation gown set from Gowning Street!",
    "altTags" : ["USyd Bachelor of Applied Science graduation gown set - Front view", "USyd Bachelor of Applied Science graduation gown set - Side view", "USyd Bachelor of Applied Science graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Bachelor of Applied Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Bachelor of Applied Science",
    "hood-style" : "Black, Cambridge Style, lined with White and Turquoise",
    "colour" : "Lined with White and Turquoise",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20117",
    "productType" : "bachelor-full-set",
    "colors" : ["#D9DCE5", "#0A9ACE"]
  },
  "/uni/nsw/usyd/master-of-nursing" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008571/Aus-Uni-Photos/USyd/usyd-mast-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008572/Aus-Uni-Photos/USyd/usyd-mast-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008570/Aus-Uni-Photos/USyd/usyd-mast-nurse-back.jpg"],
    "metaTag" : "Buy your USyd master of nursing graduation gown set from Gowning Street!",
    "altTags" : ["USyd master of nursing graduation gown set - Front view", "USyd master of nursing graduation gown set - Side view", "USyd master of nursing graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Nursing",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Nursing",
    "hood-style" : "Black, Cambridge Style, lined with White and Turquoise",
    "colour" : "Lined with White and Turquoise",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20114",
    "productType" : "master-full-set",
    "colors" : ["#D9DCE5", "#0A9ACE"]
  },
  "/uni/nsw/usyd/graduation-gown/master-of-prof-accounting" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008574/Aus-Uni-Photos/USyd/usyd-mast-prof-account-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008575/Aus-Uni-Photos/USyd/usyd-mast-prof-account-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008573/Aus-Uni-Photos/USyd/usyd-mast-prof-account-back.jpg"],
    "metaTag" : "Buy your USyd Master of Professional Accounting graduation gown set from Gowning Street!",
    "altTags" : ["USyd Master of Professional Accounting graduation gown set - Front view", "USyd Master of Professional Accounting graduation gown set - Side view", "USyd Master of Professional Accounting graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Professional Accounting",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Professional Accounting",
    "hood-style" : "Black, Cambridge Style, lined with Copper, White and Green",
    "colour" : "Lined with Copper, White and Green",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20115",
    "productType" : "master-full-set",
    "colors" : ["#E05834", "green"]
  },
  "/uni/nsw/usyd/graduation-gown/master-of-teaching" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008577/Aus-Uni-Photos/USyd/usyd-mast-teach-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008578/Aus-Uni-Photos/USyd/usyd-mast-teach-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008576/Aus-Uni-Photos/USyd/usyd-mast-teach-back.png"],
    "metaTag" : "Buy your USyd Master of Teaching graduation gown set from Gowning Street!",
    "altTags" : ["USyd Master of Teaching graduation gown set - Front view", "USyd Master of Teaching graduation gown set - Side view", "USyd Master of Teaching graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Master of Teaching",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Master of Teaching",
    "hood-style" : "Black, Cambridge Style, lined with Crushed Strawberry",
    "colour" : "Lined with Crushed Strawberry",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20116",
    "productType" : "master-full-set",
    "colors" : ["#FF9999"]
  },
  "/uni/nsw/usyd/graduation-gown/doctor-of-medicine" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008557/Aus-Uni-Photos/USyd/usyd-doc-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008558/Aus-Uni-Photos/USyd/usyd-doc-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008556/Aus-Uni-Photos/USyd/usyd-doc-med-back.jpg"],
    "metaTag" : "Buy your USyd Doctor of Medicine graduation gown set from Gowning Street!",
    "altTags" : ["USyd Doctor of Medicine graduation gown set - Front view", "USyd Doctor of Medicine graduation gown set - Side view", "USyd Doctor of Medicine graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Doctor of Medicine",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - Doctor of Medicine",
    "hood-style" : "Black, Cambridge Style, lined with Purple & Scarlet",
    "colour" : "Lined with Purple & Scarlet",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20107",
    "productType" : "master-full-set",
    "colors" : ["#FD4E4D", "#8535A3"]
  },
  "/uni/nsw/usyd/phd" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008581/Aus-Uni-Photos/USyd/usyd-phd-set-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008582/Aus-Uni-Photos/USyd/usyd-phd-set-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008580/Aus-Uni-Photos/USyd/usyd-phd-set-back.jpg"],
    "metaTag" : "Buy your University of Sydney PhD graduation gown set from Gowning Street!",
    "altTags" : ["University of Sydney PhD graduation gown set - Front view", "University of Sydney PhD graduation gown set - Side view", "University of Sydney PhD graduation gown set - Back view"],
    "name" : "USyd Graduation Gown Set - Doctor of Philosophy",
    "price" : PHD_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Sydney Hood - PhD",
    "hood-style" : "Scarlet hood, lined/trimmed with Black",
    "colour" : "Lined/trimmed with Black",
    "gownStyle" : "PhD",
    "phdStyle" : "cloth",
    "level" : "PhD",
    "hood-code" : "HOO20121",
    "productType" : "phd-full-set",
    "colors" : ["black"],
    "hatType" : "bachelor"
  },


//UTS
  "/uni/nsw/uts/bachelor-of-business" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008642/Aus-Uni-Photos/UTS/uts-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008643/Aus-Uni-Photos/UTS/uts-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008641/Aus-Uni-Photos/UTS/uts-bach-bus-back.jpg"],
    "metaTag" : "Buy your UTS Bachelor of Business graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Business graduation gown set - Front view", "UTS Bachelor of Business graduation gown set - Side view", "UTS Bachelor of Business graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Business",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Business",
    "hood-style" : "Black, Oxford Style, lined with Earl De Nil Grey",
    "colour" : "Lined with Earl De Nil Grey",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20601",
    "productType" : "bachelor-full-set",
    "colors" : ["#B0D088"]
  },
  "/uni/nsw/uts/bachelor-of-engineering" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008649/Aus-Uni-Photos/UTS/uts-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008650/Aus-Uni-Photos/UTS/uts-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008647/Aus-Uni-Photos/UTS/uts-bach-engg-back.jpg"],
    "metaTag" : "Buy your UTS Bachelor of Engineering graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Engineering graduation gown set - Front view", "UTS Bachelor of Engineering graduation gown set - Side view", "UTS Bachelor of Engineering graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Engineering",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Engineering",
    "hood-style" : "Black, Oxford Style, lined with Scarlet",
    "colour" : "Lined with Scarlet",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20602",
    "productType" : "bachelor-full-set",
    "colors" : ["red"]
  },
  "/uni/nsw/uts/bachelor-of-health" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008652/Aus-Uni-Photos/UTS/uts-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008653/Aus-Uni-Photos/UTS/uts-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008651/Aus-Uni-Photos/UTS/uts-bach-health-back.jpg"],
    "metaTag" : "Buy your UTS Bachelor of Health graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Health graduation gown set - Front view", "UTS Bachelor of Health graduation gown set - Side view", "UTS Bachelor of Health graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Health",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Health",
    "hood-style" : "Black, Oxford Style, lined with Fuschia",
    "colour" : "Lined with Fuschia",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20603",
    "productType" : "bachelor-full-set",
    "colors" : ["#FF0080"]
  },
  "/uni/nsw/uts/bachelor-of-it" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008655/Aus-Uni-Photos/UTS/uts-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635011080/Aus-Uni-Photos/UTS/uts-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008654/Aus-Uni-Photos/UTS/uts-bach-it-back.jpg"],
    "metaTag" : "Buy your UTS Bachelor of Information Technology graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Information Technology graduation gown set - Front view", "UTS Bachelor of Information Technology graduation gown set - Side view", "UTS Bachelor of Information Technology graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Information Technology",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Information Technology",
    "hood-style" : "Black, Oxford Style, lined with Electric Blue",
    "colour" : "Lined with Electric Blue",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20604",
    "productType" : "bachelor-full-set",
    "colors" : ["#0942d0"]
  },
  "/uni/nsw/uts/bachelor-of-design" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008645/Aus-Uni-Photos/UTS/uts-bach-design-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008646/Aus-Uni-Photos/UTS/uts-bach-design-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008644/Aus-Uni-Photos/UTS/uts-bach-design-back.png"],
    "metaTag" : "Buy your UTS Bachelor of Design graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Design graduation gown set - Front view", "UTS Bachelor of Design graduation gown set - Side view", "UTS Bachelor of Design graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Design",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Design",
    "hood-style" : "Black, Oxford Style, lined with Chartreuse",
    "colour" : "Lined with Chartreuse",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20605",
    "productType" : "bachelor-full-set",
    "colors" : ["#FFFFCC"]
  },
  "/uni/nsw/uts/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635011183/Aus-Uni-Photos/UTS/uts-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635011184/Aus-Uni-Photos/UTS/uts-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635011182/Aus-Uni-Photos/UTS/uts-bach-law-back.png"],
    "metaTag" : "Buy your UTS Bachelor of Law graduation gown set from Gowning Street!",
    "altTags" : ["UTS Bachelor of Law graduation gown set - Front view", "UTS Bachelor of Law graduation gown set - Side view", "UTS Bachelor of Law graduation gown set - Back view"],
    "name" : "UTS Graduation Gown Set - Bachelor of Law",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Technology Sydney Hood - Bachelor of Law",
    "hood-style" : "Black, Oxford Style, lined with Amethyst",
    "colour" : "Lined with Amethyst",
    "gownStyle" : "Oxford",
    "level" : "Bachelor",
    "hood-code" : "HOO20606",
    "productType" : "bachelor-full-set",
    "colors" : ["#330066"]
  },

//UWS | Uni Western Sydney

  "/uni/nsw/uws/bachelor-of-arts" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008457/Aus-Uni-Photos/UWS/uws-bach-arts-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008458/Aus-Uni-Photos/UWS/uws-bach-arts-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008460/Aus-Uni-Photos/UWS/uws-bach-comm-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Humanities, Communications & Arts graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Humanities, Communications & Arts graduation gown set - Front view", "UWS Bachelor of Humanities, Communications & Arts graduation gown set - Side view", "UWS Bachelor of Humanities, Communications & Arts graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Humanities, Communications & Arts",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Humanities, Communications & Arts",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Fuschia",
    "colour" : "Lined with University Blue and Fuschia",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20701",
    "productType" : "bachelor-full-set",
    "colors" : ["#FF00FF"]
  },
  "/uni/nsw/uws/bachelor-of-commerce" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008461/Aus-Uni-Photos/UWS/uws-bach-comm-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008462/Aus-Uni-Photos/UWS/uws-bach-comm-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008460/Aus-Uni-Photos/UWS/uws-bach-comm-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Commerce, Business, Economics & Administration graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Commerce, Business, Economics & Administration graduation gown set - Front view", "UWS Bachelor of Commerce, Business, Economics & Administration graduation gown set - Side view", "UWS Bachelor of Commerce, Business, Economics & Administration graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Commerce, Business, Economics & Administration",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Commerce, Business, Economics & Administration",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Royal Blue",
    "colour" : "Lined with University Blue and Royal Blue",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20702",
    "productType" : "bachelor-full-set",
    "colors" : ["blue"]
  },
  "/uni/nsw/uws/bachelor-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008464/Aus-Uni-Photos/UWS/uws-bach-educ-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008465/Aus-Uni-Photos/UWS/uws-bach-educ-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008466/Aus-Uni-Photos/UWS/uws-bach-law-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Education graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Education graduation gown set - Front view", "UWS Bachelor of Education graduation gown set - Side view", "UWS Bachelor of Education graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Education",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Education",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Orange",
    "colour" : "Lined with University Blue and Orange",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20703",
    "productType" : "bachelor-full-set",
    "colors" : ["orange"]
  },
  "/uni/nsw/uws/bachelor-of-health-nursing" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008470/Aus-Uni-Photos/UWS/uws-bach-nurse-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008471/Aus-Uni-Photos/UWS/uws-bach-nurse-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008469/Aus-Uni-Photos/UWS/uws-bach-nurse-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Health, Nursing graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Health, Nursing graduation gown set - Front view", "UWS Bachelor of Health, Nursing graduation gown set - Side view", "UWS Bachelor of Health, Nursing graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Health, Nursing",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Health, Nursing",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Jade",
    "colour" : "Lined with University Blue and Jade",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20704",
    "productType" : "bachelor-full-set",
    "colors" : ["#20B2AA"]
  },
  "/uni/nsw/uws/bachelor-of-law" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008467/Aus-Uni-Photos/UWS/uws-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008468/Aus-Uni-Photos/UWS/uws-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008466/Aus-Uni-Photos/UWS/uws-bach-law-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Law & Legal Studies graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Law & Legal Studies graduation gown set - Front view", "UWS Bachelor of Law & Legal Studies graduation gown set - Side view", "UWS Bachelor of Law & Legal Studies graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Law & Legal Studies",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Law & Legal Studies",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Purple",
    "colour" : "Lined with University Blue and Purple",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20705",
    "productType" : "bachelor-full-set",
    "colors" : ["purple"]
  },
  "/uni/nsw/uws/bachelor-of-science" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008473/Aus-Uni-Photos/UWS/uws-bach-sci-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008474/Aus-Uni-Photos/UWS/uws-bach-sci-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008472/Aus-Uni-Photos/UWS/uws-bach-sci-back.png"],
    "metaTag" : "Buy your UWS Bachelor of Science graduation gown set from Gowning Street!",
    "altTags" : ["UWS Bachelor of Science graduation gown set - Front view", "UWS Bachelor of Science graduation gown set - Side view", "UWS Bachelor of Science graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Bachelor of Science",
    "price" : BACHELOR_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Bachelor of Science",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Crimson",
    "colour" : "Lined with University Blue and Crimson",
    "gownStyle" : "Cambridge",
    "level" : "Bachelor",
    "hood-code" : "HOO20706",
    "productType" : "bachelor-full-set",
    "colors" : ["#8B0000"]
  },
  "/uni/nsw/uws/master-of-education" : {
    "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592468037/Uni%20Regalia%20Photos/UWS/uws-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592468038/Uni%20Regalia%20Photos/UWS/uws-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592468036/Uni%20Regalia%20Photos/UWS/uws-mast-educ-back.jpg"],
    "metaTag" : "Buy your UWS Master of Education graduation gown set from Gowning Street!",
    "altTags" : ["UWS Master of Education graduation gown set - Front view", "UWS Master of Education graduation gown set - Side view", "UWS Master of Education graduation gown set - Back view"],
    "name" : "UWS Graduation Gown Set - Master of Education",
    "price" : MASTER_SET_PRICE,
    "show" : ["gown", "cap", "hood"],
    "hood" : "University of Western Sydney Hood - Master of Education",
    "hood-style" : "Black, Cambridge Style, lined with University Blue and Orange",
    "colour" : "Lined with University Blue and Orange",
    "gownStyle" : "Cambridge",
    "level" : "Master",
    "hood-code" : "HOO20711",
    "productType" : "master-full-set",
    "colors" : ["orange"]
  },


//southern cross university

"/uni/scu/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008149/Aus-Uni-Photos/SCU/scu-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008150/Aus-Uni-Photos/SCU/scu-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008148/Aus-Uni-Photos/SCU/scu-bach-arts-back.jpg"],
  "metaTag" : "Buy your SCU bachelor of arts graduation gown set from Gowning Street!",
  "altTags" : ["SCU bachelor of arts graduation gown set - Front view", "SCU bachelor of arts graduation gown set - Side view", "SCU bachelor of arts graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Arts",
  "hood-style" : "Black, Cambridge Style, partially lined with White",
  "colour" : "Partially Lined with White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70101",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/scu/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008152/Aus-Uni-Photos/SCU/scu-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008153/Aus-Uni-Photos/SCU/scu-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008151/Aus-Uni-Photos/SCU/scu-bach-bus-back.jpg"],
  "metaTag" : "Buy your SCU bachelor of business graduation gown set from Gowning Street!",
  "altTags" : ["SCU bachelor of business graduation gown set - Front view", "SCU bachelor of business graduation gown set - Side view", "SCU bachelor of business graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Business",
  "hood-style" : "Black, Cambridge Style, partially lined with Sunflower Yellow",
  "colour" : "Partially Lined with Sunflower Yellow",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20302",
  "productType" : "bachelor-full-set",
  "colors" : ["#FBFD85"]
},
"/uni/scu/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008155/Aus-Uni-Photos/SCU/scu-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008156/Aus-Uni-Photos/SCU/scu-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008154/Aus-Uni-Photos/SCU/scu-bach-edu-back.jpg"],
  "metaTag" : "Buy your SCU bachelor of education graduation gown set from Gowning Street!",
  "altTags" : ["SCU bachelor of education graduation gown set - Front view", "SCU bachelor of education graduation gown set - Side view", "SCU bachelor of education graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Education",
  "hood-style" : "Black, Cambridge Style, partially lined with Bluebell Blue",
  "colour" : "Partially Lined with Bluebell Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20303",
  "productType" : "bachelor-full-set",
  "colors" : ["#385DBD"]
},
"/uni/scu/bachelor-of-health-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008158/Aus-Uni-Photos/SCU/scu-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008159/Aus-Uni-Photos/SCU/scu-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008157/Aus-Uni-Photos/SCU/scu-bach-health-back.jpg"],
  "metaTag" : "Buy your SCU bachelor of health sciences graduation gown set from Gowning Street!",
  "altTags" : ["SCU bachelor of health-sciences graduation gown set - Front view", "SCU bachelor of health-sciences graduation gown set - Side view", "SCU bachelor of health-sciences graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Health Sciences",
  "hood-style" : "Black, Cambridge Style, partially lined with Signal Red",
  "colour" : "Partially Lined with Signal Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20304",
  "productType" : "bachelor-full-set",
  "colors" : ["#EB4D5C"]
},
"/uni/scu/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008164/Aus-Uni-Photos/SCU/scu-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008165/Aus-Uni-Photos/SCU/scu-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008163/Aus-Uni-Photos/SCU/scu-bach-sci-back.jpg"],
  "metaTag" : "Buy your SCU bachelor of science graduation gown set from Gowning Street!",
  "altTags" : ["SCU bachelor of science graduation gown set - Front view", "SCU bachelor of science graduation gown set - Side view", "SCU bachelor of science graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Science",
  "hood-style" : "Black, Cambridge Style, Partially lined with Turquoise",
  "colour" : "Partially Lined with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20305",
  "productType" : "bachelor-full-set",
  "colors" : ["#3DC2E1"]
},
"/uni/scu/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008161/Aus-Uni-Photos/SCU/scu-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008162/Aus-Uni-Photos/SCU/scu-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008160/Aus-Uni-Photos/SCU/scu-bach-law-back.png"],
  "metaTag" : "Buy your SCU Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["SCU Bachelor of Law graduation gown set - Front view", "SCU Bachelor of Law graduation gown set - Side view", "SCU Bachelor of Law graduation gown set - Back view"],
  "name" : "SCU Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Southern Cross University Hood - Bachelor of Law",
  "hood-style" : "Black, Cambridge Style, Partially lined with Magenta",
  "colour" : "Partially Lined with Magenta",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20307",
  "productType" : "bachelor-full-set",
  "colors" : ["#8B0000"]
},

//newcastle

"/uni/nsw/uon/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008346/Aus-Uni-Photos/UON/uon-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008347/Aus-Uni-Photos/UON/uon-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008345/Aus-Uni-Photos/UON/uon-bach-arts-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Arts & Social Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Arts & Social Sciences graduation gown set - Front view", "UoN Bachelor of Arts & Social Sciences graduation gown set - Side view", "UoN Bachelor of Arts & Social Sciences graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Arts & Social Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Arts & Social Sciences",
  "hood-style" : "Black, Cambridge Style, Partially lined with Pearl",
  "colour" : "Partially Lined with Pearl",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70101",
  "productType" : "bachelor-full-set",
  "colors" : ["#F9F9F9"]
},
"/uni/nsw/uon/bachelor-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008343/Aus-Uni-Photos/UON/uon-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008344/Aus-Uni-Photos/UON/uon-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008342/Aus-Uni-Photos/UON/uon-bach-arch-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Architecture & Building graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Architecture & Building graduation gown set - Front view", "UoN Bachelor of Architecture & Building graduation gown set - Side view", "UoN Bachelor of Architecture & Building graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Architecture & Building",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Architecture & Building",
  "hood-style" : "Black, Cambridge Style, Partially lined with Garnet",
  "colour" : "Partially Lined with Garnet",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20501",
  "productType" : "bachelor-full-set",
  "colors" : ["#800000"]
},
"/uni/nsw/uon/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008352/Aus-Uni-Photos/UON/uon-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008353/Aus-Uni-Photos/UON/uon-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008351/Aus-Uni-Photos/UON/uon-bach-educ-back.jpg"],
  "metaTag" : "Buy your UoN bachelor of education graduation gown set from Gowning Street!",
  "altTags" : ["UoN bachelor of education graduation gown set - Front view", "UoN bachelor of education graduation gown set - Side view", "UoN bachelor of education graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Education",
  "hood-style" : "Black, Cambridge Style, Partially lined with Shamrock",
  "colour" : "Partially lined with Shamrock",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20504",
  "productType" : "bachelor-full-set",
  "colors" : ["#009D7B"]
},
"/uni/nsw/uon/bachelor-of-computer-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008358/Aus-Uni-Photos/UON/uon-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008359/Aus-Uni-Photos/UON/uon-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008357/Aus-Uni-Photos/UON/uon-bach-it-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Computer Science & IT graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Computer Science & IT graduation gown set - Front view", "UoN Bachelor of Computer Science & IT graduation gown set - Side view", "UoN Bachelor of Computer Science & IT graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Computer Science & IT",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Computer Science & IT",
  "hood-style" : "Black, Cambridge Style, Partially lined with Violet",
  "colour" : "Partially Lined with Violet",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20506",
  "productType" : "bachelor-full-set",
  "colors" : ["#7450BD"]
},
"/uni/nsw/uon/bachelor-of-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008349/Aus-Uni-Photos/UON/uon-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008350/Aus-Uni-Photos/UON/uon-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008348/Aus-Uni-Photos/UON/uon-bach-comm-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Commerce graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Commerce graduation gown set - Front view", "UoN Bachelor of Commerce graduation gown set - Side view", "UoN Bachelor of Commerce graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Commerce",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Commerce",
  "hood-style" : "Black, Cambridge Style, Partially lined with Turquoise",
  "colour" : "Partially Lined with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20503",
  "productType" : "bachelor-full-set",
  "colors" : ["turquoise"]
},
"/uni/nsw/uon/bachelor-of-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008349/Aus-Uni-Photos/UON/uon-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008350/Aus-Uni-Photos/UON/uon-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008348/Aus-Uni-Photos/UON/uon-bach-comm-back.jpg"],
  "metaTag" : "Buy your UoN bachelor of economics graduation gown set from Gowning Street!",
  "altTags" : ["UoN bachelor of economics graduation gown set - Front view", "UoN bachelor of economics graduation gown set - Side view", "UoN bachelor of economics graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Economics",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Economics",
  "hood-style" : "Black, Cambridge Style, Partially lined with Turquoise",
  "colour" : "Partially Lined with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20503",
  "productType" : "bachelor-full-set",
  "colors" : ["turquoise"]
},
"/uni/nsw/uon/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008355/Aus-Uni-Photos/UON/uon-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008356/Aus-Uni-Photos/UON/uon-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008354/Aus-Uni-Photos/UON/uon-bach-engg-back.jpg"],
  "metaTag" : "Buy your UoN bachelor of engineering graduation gown set from Gowning Street!",
  "altTags" : ["UoN bachelor of engineering graduation gown set - Front view", "UoN bachelor of engineering graduation gown set - Side view", "UoN bachelor of engineering graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Engineering",
  "hood-style" : "Black, Cambridge Style, Partially lined with Lapis Lazuli",
  "colour" : "Partially Lined with Lapis Lazuli",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20505",
  "productType" : "bachelor-full-set",
  "colors" : ["#4144A6"]
},
"/uni/nsw/uon/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008361/Aus-Uni-Photos/UON/uon-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008362/Aus-Uni-Photos/UON/uon-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008360/Aus-Uni-Photos/UON/uon-bach-law-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Law graduation gown set - Front view", "UoN Bachelor of Law graduation gown set - Side view", "UoN Bachelor of Law graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Law",
  "hood-style" : "Black, Cambridge Style, Partially lined with Waratah",
  "colour" : "Partially Lined with Waratah",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20507",
  "productType" : "bachelor-full-set",
  "colors" : ["#DC143C"]
},
"/uni/nsw/uon/bachelor-of-med-healthsci" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008364/Aus-Uni-Photos/UON/uon-bach-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008366/Aus-Uni-Photos/UON/uon-bach-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008363/Aus-Uni-Photos/UON/uon-bach-med-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Medicine & Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Medicine & Health Sciences graduation gown set - Front view", "UoN Bachelor of Medicine & Health Sciences graduation gown set - Side view", "UoN Bachelor of Medicine & Health Sciences graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Medicine & Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Medicine & Health Sciences",
  "hood-style" : "Black, Cambridge Style, Partially lined with Rhodochrosite",
  "colour" : "Partially Lined with Rhodochrosite",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20508",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFC0CB"]
},
"/uni/nsw/uon/bachelor-of-music" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008368/Aus-Uni-Photos/UON/uon-bach-mus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008369/Aus-Uni-Photos/UON/uon-bach-mus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008367/Aus-Uni-Photos/UON/uon-bach-mus-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Music graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Music graduation gown set - Front view", "UoN Bachelor of Music graduation gown set - Side view", "UoN Bachelor of Music graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Music",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Music",
  "hood-style" : "Black, Cambridge Style, Partially lined with Lilac",
  "colour" : "Partially Lined with Lilac",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20509",
  "productType" : "bachelor-full-set",
  "colors" : ["#C8A2C8"]
},
"/uni/nsw/uon/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008374/Aus-Uni-Photos/UON/uon-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008375/Aus-Uni-Photos/UON/uon-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008373/Aus-Uni-Photos/UON/uon-bach-sci-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Science graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Science graduation gown set - Front view", "UoN Bachelor of Science graduation gown set - Side view", "UoN Bachelor of Science graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Science",
  "hood-style" : "Black, Cambridge Style, Partially lined with Topaz",
  "colour" : "Partially Lined with Topaz",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20510",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFF47C"]
},
"/uni/nsw/uon/bachelor-of-nursing" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008371/Aus-Uni-Photos/UON/uon-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008372/Aus-Uni-Photos/UON/uon-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008370/Aus-Uni-Photos/UON/uon-bach-nurse-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Nursing graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Nursing graduation gown set - Front view", "UoN Bachelor of Nursing graduation gown set - Side view", "UoN Bachelor of Nursing graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Nursing",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Nursing",
  "hood-style" : "Black, Cambridge Style, Partially lined with International Orange",
  "colour" : "Partially Lined with International Orange",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20511",
  "productType" : "bachelor-full-set",
  "colors" : ["#FF4500"]
},
"/uni/nsw/uon/bachelor-of-visual-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008377/Aus-Uni-Photos/UON/uon-bach-visarts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008378/Aus-Uni-Photos/UON/uon-bach-visarts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008376/Aus-Uni-Photos/UON/uon-bach-visarts-back.jpg"],
  "metaTag" : "Buy your UoN Bachelor of Visual Arts graduation gown set from Gowning Street!",
  "altTags" : ["UoN Bachelor of Visual Arts graduation gown set - Front view", "UoN Bachelor of Visual Arts graduation gown set - Side view", "UoN Bachelor of Visual Arts graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Bachelor of Visual Arts, Design & Comms",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Bachelor of Visual Arts, Design & Comms",
  "hood-style" : "Black, Cambridge Style, Partially lined with Wattle",
  "colour" : "Partially Lined with Wattle",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20512",
  "productType" : "bachelor-full-set",
  "colors" : ["yellow"]
},
"/uni/nsw/uon/graduation-gown/master-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008381/Aus-Uni-Photos/UON/uon-mast-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008382/Aus-Uni-Photos/UON/uon-mast-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008380/Aus-Uni-Photos/UON/uon-mast-arch-back.jpg"],
  "metaTag" : "Buy your UoN Master of Architecture & Building graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Architecture & Building graduation gown set - Front view", "UoN Master of Architecture & Building graduation gown set - Side view", "UoN Master of Architecture & Building graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Architecture & Building",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Architecture & Building",
  "hood-style" : "Black, Cambridge Style, Lined with Garnet",
  "colour" : "Lined with Garnet",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20513",
  "productType" : "master-full-set",
  "colors" : ["#800000"]
},
"/uni/nsw/uon/graduation-gown/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008384/Aus-Uni-Photos/UON/uon-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008385/Aus-Uni-Photos/UON/uon-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008383/Aus-Uni-Photos/UON/uon-mast-arts-back.jpg"],
  "metaTag" : "Buy your UoN Master of Arts & Social Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Arts & Social Sciences graduation gown set - Front view", "UoN Master of Arts & Social Sciences graduation gown set - Side view", "UoN Master of Arts & Social Sciences graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Arts & Social Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Arts & Social Sciences",
  "hood-style" : "Black, Cambridge Style, Lined with Pearl",
  "colour" : "Lined with Pearl",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20514",
  "productType" : "master-full-set",
  "colors" : ["white"]
},
"/uni/nsw/uon/graduation-gown/master-of-commerce-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008387/Aus-Uni-Photos/UON/uon-mast-econ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008388/Aus-Uni-Photos/UON/uon-mast-econ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008386/Aus-Uni-Photos/UON/uon-mast-econ-back.jpg"],
  "metaTag" : "Buy your UoN Master of Commerce & Economics graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Commerce & Economics graduation gown set - Front view", "UoN Master of Commerce & Economics graduation gown set - Side view", "UoN Master of Commerce & Economics graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Commerce & Economics",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Commerce & Economics",
  "hood-style" : "Black, Cambridge Style, Lined with Turquoise",
  "colour" : "Lined with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20515",
  "productType" : "master-full-set",
  "colors" : ["#40e0d0"]
},
"/uni/nsw/uon/graduation-gown/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008390/Aus-Uni-Photos/UON/uon-mast-education-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008391/Aus-Uni-Photos/UON/uon-mast-education-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008389/Aus-Uni-Photos/UON/uon-mast-education-back.jpg"],
  "metaTag" : "Buy your UoN Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Education graduation gown set - Front view", "UoN Master of Education graduation gown set - Side view", "UoN Master of Education graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Education",
  "hood-style" : "Black, Cambridge Style, Lined with Shamrock",
  "colour" : "Lined with Shamrock",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20516",
  "productType" : "master-full-set",
  "colors" : ["green"]
},
"/uni/nsw/uon/graduation-gown/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008394/Aus-Uni-Photos/UON/uon-mast-engineering-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008394/Aus-Uni-Photos/UON/uon-mast-engineering-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008392/Aus-Uni-Photos/UON/uon-mast-engineering-back.jpg"],
  "metaTag" : "Buy your UoN Master of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Engineering graduation gown set - Front view", "UoN Master of Engineering graduation gown set - Side view", "UoN Master of Engineering graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Engineering",
  "hood-style" : "Black, Cambridge Style, Lined with Lapis Lazuli",
  "colour" : "Lined with Lapis Lazuli",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20517",
  "productType" : "master-full-set",
  "colors" : ["#4144A6"]
},
"/uni/nsw/uon/graduation-gown/master-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008396/Aus-Uni-Photos/UON/uon-mast-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008397/Aus-Uni-Photos/UON/uon-mast-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008395/Aus-Uni-Photos/UON/uon-mast-it-back.jpg"],
  "metaTag" : "Buy your UoN Master of Information Technology graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Information Technology graduation gown set - Front view", "UoN Master of Information Technology graduation gown set - Side view", "UoN Master of Information Technology graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Information Technology",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Information Technology",
  "hood-style" : "Black, Cambridge Style, Lined with Violet",
  "colour" : "Lined with Violet",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20518",
  "productType" : "master-full-set",
  "colors" : ["#4B0082"]
},
"/uni/nsw/uon/graduation-gown/master-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008399/Aus-Uni-Photos/UON/uon-mast-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008401/Aus-Uni-Photos/UON/uon-mast-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008399/Aus-Uni-Photos/UON/uon-mast-law-back.jpg"],
  "metaTag" : "Buy your UoN Master of Law graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Law graduation gown set - Front view", "UoN Master of Law graduation gown set - Side view", "UoN Master of Law graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Law",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Law",
  "hood-style" : "Black, Cambridge Style, Lined with Waratah",
  "colour" : "Lined with Waratah",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20519",
  "productType" : "master-full-set",
  "colors" : ["#DC143C"]
},
"/uni/nsw/uon/graduation-gown/master-of-med-healthsci" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008403/Aus-Uni-Photos/UON/uon-mast-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008404/Aus-Uni-Photos/UON/uon-mast-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008402/Aus-Uni-Photos/UON/uon-mast-med-back.jpg"],
  "metaTag" : "Buy your UoN Master of Medicine & Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Medicine & Health Sciences graduation gown set - Front view", "UoN Master of Medicine & Health Sciences graduation gown set - Side view", "UoN Master of Medicine & Health Sciences graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Medicine & Health Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Medicine & Health Sciences",
  "hood-style" : "Black, Cambridge Style, Lined with Rhodochrosite",
  "colour" : "Lined with Rhodochrosite",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20520",
  "productType" : "master-full-set",
  "colors" : ["#FFC0CB"]
},
"/uni/nsw/uon/graduation-gown/master-of-music" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008406/Aus-Uni-Photos/UON/uon-mast-music-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008407/Aus-Uni-Photos/UON/uon-mast-music-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008405/Aus-Uni-Photos/UON/uon-mast-music-back.jpg"],
  "metaTag" : "Buy your UoN Master of Music graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Music graduation gown set - Front view", "UoN Master of Music graduation gown set - Side view", "UoN Master of Music graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Music",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Music",
  "hood-style" : "Black, Cambridge Style, Lined with Lilac",
  "colour" : "Lined with Lilac",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20521",
  "productType" : "master-full-set",
  "colors" : ["#c8a2c8"]
},
"/uni/nsw/uon/graduation-gown/master-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008412/Aus-Uni-Photos/UON/uon-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008413/Aus-Uni-Photos/UON/uon-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008411/Aus-Uni-Photos/UON/uon-mast-sci-back.jpg"],
  "metaTag" : "Buy your UoN Master of Natural & Physical Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Natural & Physical Sciences graduation gown set - Front view", "UoN Master of Natural & Physical Sciences graduation gown set - Side view", "UoN Master of Natural & Physical Sciences graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Natural & Physical Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Natural & Physical Sciences",
  "hood-style" : "Black, Cambridge Style, Lined with Topaz",
  "colour" : "Lined with Topaz",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20522",
  "productType" : "master-full-set",
  "colors" : ["#ffc87c"]
},
"/uni/nsw/uon/graduation-gown/master-of-nursing" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008409/Aus-Uni-Photos/UON/uon-mast-nursing-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008410/Aus-Uni-Photos/UON/uon-mast-nursing-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008408/Aus-Uni-Photos/UON/uon-mast-nursing-back.jpg"],
  "metaTag" : "Buy your UoN Master of Nursing graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Nursing graduation gown set - Front view", "UoN Master of Nursing graduation gown set - Side view", "UoN Master of Nursing graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Nursing",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Nursing",
  "hood-style" : "Black, Cambridge Style, Lined with International Orange",
  "colour" : "Lined with International Orange",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20523",
  "productType" : "master-full-set",
  "colors" : ["orange"]
},
"/uni/nsw/uon/graduation-gown/master-of-visual-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008415/Aus-Uni-Photos/UON/uon-mast-visarts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008416/Aus-Uni-Photos/UON/uon-mast-visarts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008414/Aus-Uni-Photos/UON/uon-mast-visarts-back.jpg"],
  "metaTag" : "Buy your UoN Master of Visual Arts, Designs & Comms graduation gown set from Gowning Street!",
  "altTags" : ["UoN Master of Visual Arts, Designs & Comms graduation gown set - Front view", "UoN Master of Visual Arts, Designs & Comms graduation gown set - Side view", "UoN Master of Visual Arts, Designs & Comms graduation gown set - Back view"],
  "name" : "UoN Graduation Gown Set - Master of Visual Arts, Designs & Comms",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Newcastle Hood - Master of Visual Arts, Designs & Comms",
  "hood-style" : "Black, Cambridge Style, Lined with Wattle",
  "colour" : "Lined with Wattle",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20524",
  "productType" : "master-full-set",
  "colors" : ["yellow"]
},


//New England

"/uni/nsw/une/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008243/Aus-Uni-Photos/UNE/une-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008244/Aus-Uni-Photos/UNE/une-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008242/Aus-Uni-Photos/UNE/une-bach-bus-back.jpg"],
  "metaTag" : "Buy your UNE bachelor of business graduation gown set from Gowning Street!",
  "altTags" : ["UNE bachelor of business graduation gown set - Front view", "UNE bachelor of business graduation gown set - Side view", "UNE bachelor of business graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Business",
  "hood-style" : "Black, Lined with Peacock Blue, Edged with Gold",
  "colour" : "Lined with Peacock Blue, Edged with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20201",
  "productType" : "bachelor-full-set",
  "colors" : ["#56D8FE", "#E09952"]
},
"/uni/nsw/une/bachelor-of-agriculture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008237/Aus-Uni-Photos/UNE/une-bach-agri-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008238/Aus-Uni-Photos/UNE/une-bach-agri-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008236/Aus-Uni-Photos/UNE/une-bach-agri-back.jpg"],
  "metaTag" : "Buy your UNE bachelor of agriculture graduation gown set from Gowning Street!",
  "altTags" : ["UNE bachelor of agriculture graduation gown set - Front view", "UNE bachelor of agriculture graduation gown set - Side view", "UNE bachelor of agriculture graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Agriculture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Agriculture",
  "hood-style" : "Black, Lined with Dioptase",
  "colour" : "Lined with Dioptase",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20202",
  "productType" : "bachelor-full-set",
  "colors" : ["darkgreen"]
},
"/uni/nsw/une/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008240/Aus-Uni-Photos/UNE/une-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008241/Aus-Uni-Photos/UNE/une-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008239/Aus-Uni-Photos/UNE/une-bach-arts-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Arts graduation gown set - Front view", "UNE Bachelor of Arts graduation gown set - Side view", "UNE Bachelor of Arts graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Arts",
  "hood-style" : "Black, Lined with White",
  "colour" : "Lined with White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20203",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/nsw/une/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008247/Aus-Uni-Photos/UNE/une-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008247/Aus-Uni-Photos/UNE/une-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008246/Aus-Uni-Photos/UNE/une-bach-edu-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Education graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Education graduation gown set - Front view", "UNE Bachelor of Education graduation gown set - Side view", "UNE Bachelor of Education graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Education",
  "hood-style" : "Black, Lined with Violet",
  "colour" : "Lined with Violet",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20204",
  "productType" : "bachelor-full-set",
  "colors" : ["violet"]
},
"/uni/nsw/une/bachelor-of-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008250/Aus-Uni-Photos/UNE/une-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008250/Aus-Uni-Photos/UNE/une-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008249/Aus-Uni-Photos/UNE/une-bach-health-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Health graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Health graduation gown set - Front view", "UNE Bachelor of Health graduation gown set - Side view", "UNE Bachelor of Health graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Health",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Health",
  "hood-style" : "Black, Lined with Peony Red",
  "colour" : "Lined with Peony Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20205",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/nsw/une/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008252/Aus-Uni-Photos/UNE/une-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008254/Aus-Uni-Photos/UNE/une-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008252/Aus-Uni-Photos/UNE/une-bach-law-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Law graduation gown set - Front view", "UNE Bachelor of Law graduation gown set - Side view", "UNE Bachelor of Law graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Law",
  "hood-style" : "Black, Lined with Ultramarine",
  "colour" : "Lined with Ultramarine",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20206",
  "productType" : "bachelor-full-set",
  "colors" : ["blue"]
},
"/uni/nsw/une/bachelor-of-music" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008256/Aus-Uni-Photos/UNE/une-bach-mus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008257/Aus-Uni-Photos/UNE/une-bach-mus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008255/Aus-Uni-Photos/UNE/une-bach-mus-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Music graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Music graduation gown set - Front view", "UNE Bachelor of Music graduation gown set - Side view", "UNE Bachelor of Music graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Music",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Music",
  "hood-style" : "Black, Lined with Spectrum Green",
  "colour" : "Lined with Spectrum Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20207",
  "productType" : "bachelor-full-set",
  "colors" : ["darkgreen"]
},
"/uni/nsw/une/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008259/Aus-Uni-Photos/UNE/une-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008260/Aus-Uni-Photos/UNE/une-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008258/Aus-Uni-Photos/UNE/une-bach-sci-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Science graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Science graduation gown set - Front view", "UNE Bachelor of Science graduation gown set - Side view", "UNE Bachelor of Science graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Science",
  "hood-style" : "Black, Lined with Straw",
  "colour" : "Lined with Straw",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20208",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(42,98%,84%)"]
},
"/uni/nsw/une/bachelor-of-social-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008262/Aus-Uni-Photos/UNE/une-bach-socsci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008263/Aus-Uni-Photos/UNE/une-bach-socsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008261/Aus-Uni-Photos/UNE/une-bach-socsci-back.jpg"],
  "metaTag" : "Buy your UNE Bachelor of Social Science graduation gown set from Gowning Street!",
  "altTags" : ["UNE Bachelor of Social Science graduation gown set - Front view", "UNE Bachelor of Social Science graduation gown set - Side view", "UNE Bachelor of Social Science graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Bachelor of Social Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Bachelor of Social Science",
  "hood-style" : "Black, Lined with Old Rose",
  "colour" : "Lined with Old Rose",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20209",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(0,50%,50%)"]
},
"/uni/nsw/une/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008266/Aus-Uni-Photos/UNE/une-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008267/Aus-Uni-Photos/UNE/une-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008264/Aus-Uni-Photos/UNE/une-mast-arts-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Arts graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Arts graduation gown set - Front view", "University of New England Master of Arts graduation gown set - Side view", "University of New England Master of Arts graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Arts",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with White.",
  "colour" : "Lined with White",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20211",
  "productType" : "master-full-set",
  "colors" : ["white"]
},
"/uni/nsw/une/master-of-business-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008269/Aus-Uni-Photos/UNE/une-mast-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008270/Aus-Uni-Photos/UNE/une-mast-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008268/Aus-Uni-Photos/UNE/une-mast-bus-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Business, Commerce & Economics graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Business, Commerce & Economics graduation gown set - Front view", "University of New England Master of Business, Commerce & Economics graduation gown set - Side view", "University of New England Master of Business, Commerce & Economics graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Business, Commerce & Economics",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Business, Commerce & Economics",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with Peacock Blue.",
  "colour" : "Lined with Peacock Blue",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20212",
  "productType" : "master-full-set",
  "colors" : ["#56D8FE", "#E09952"]
},
"/uni/nsw/une/master-of-education-teaching" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008272/Aus-Uni-Photos/UNE/une-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008273/Aus-Uni-Photos/UNE/une-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008271/Aus-Uni-Photos/UNE/une-mast-educ-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Education & Teaching graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Education & Teaching graduation gown set - Front view", "University of New England Master of Education & Teaching graduation gown set - Side view", "University of New England Master of Education & Teaching graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Education & Teaching",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Education & Teaching",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with Violet.",
  "colour" : "Lined with Violet",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20213",
  "productType" : "master-full-set",
  "colors" : ["#A619A6"]
},
"/uni/nsw/une/master-of-health-pharmacy" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008275/Aus-Uni-Photos/UNE/une-mast-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008276/Aus-Uni-Photos/UNE/une-mast-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008274/Aus-Uni-Photos/UNE/une-mast-health-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Health & Pharmacy graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Health & Pharmacy graduation gown set - Front view", "University of New England Master of Health & Pharmacy graduation gown set - Side view", "University of New England Master of Health & Pharmacy graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Health & Pharmacy",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Health & Pharmacy",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with Peony Red.",
  "colour" : "Lined with Peony Red",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20214",
  "productType" : "master-full-set",
  "colors" : ["red"]
},
"/uni/nsw/une/master-of-music-theatre" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008281/Aus-Uni-Photos/UNE/une-mast-mus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008283/Aus-Uni-Photos/UNE/une-mast-mus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008281/Aus-Uni-Photos/UNE/une-mast-mus-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Music & Theatre graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Music & Theatre graduation gown set - Front view", "University of New England Master of Music & Theatre graduation gown set - Side view", "University of New England Master of Music & Theatre graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Music & Theatre",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Music & Theatre",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with Spectrum Green.",
  "colour" : "Lined with Spectrum Green",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20216",
  "productType" : "master-full-set",
  "colors" : ["#006906"]
},
"/uni/nsw/une/master-of-biomedicine-exercise" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008288/Aus-Uni-Photos/UNE/une-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008289/Aus-Uni-Photos/UNE/une-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008287/Aus-Uni-Photos/UNE/une-mast-sci-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Science, Biomedicine, Exercise & Sport graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Science, Biomedicine, Exercise & Sport graduation gown set - Front view", "University of New England Master of Science, Biomedicine, Exercise & Sport graduation gown set - Side view", "University of New England Master of Science, Biomedicine, Exercise & Sport graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Science, Biomedicine, Exercise & Sport",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Science, Biomedicine, Exercise & Sport",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with Straw.",
  "colour" : "Lined with Straw",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20217",
  "productType" : "master-full-set",
  "colors" : ["#E4D96F"]
},
"/uni/nsw/une/master-of-social-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008285/Aus-Uni-Photos/UNE/une-mast-psych-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008286/Aus-Uni-Photos/UNE/une-mast-psych-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008284/Aus-Uni-Photos/UNE/une-mast-psych-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Social Science, Psychology & Criminology graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Social Science, Psychology & Criminology graduation gown set - Front view", "University of New England Master of Social Science, Psychology & Criminology graduation gown set - Side view", "University of New England Master of Social Science, Psychology & Criminology graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Social Science, Psychology & Criminology",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Social Science, Psychology & Criminology",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with  Old Rose.",
  "colour" : "Lined with  Old Rose",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20218",
  "productType" : "master-full-set",
  "colors" : ["#D96FE4"]
},
"/uni/nsw/une/master-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008278/Aus-Uni-Photos/UNE/une-mast-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008279/Aus-Uni-Photos/UNE/une-mast-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008277/Aus-Uni-Photos/UNE/une-mast-law-back.jpg"],
  "metaTag" : "Buy your University of New England Master of Law graduation gown set from Gowning Street!",
  "altTags" : ["University of New England Master of Law graduation gown set - Front view", "University of New England Master of Law graduation gown set - Side view", "University of New England Master of Law graduation gown set - Back view"],
  "name" : "UNE Graduation Gown Set - Master of Law",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of New England Hood - Master of Law",
  "hood-style" : "Black, Cambridge-style, lined/trimmed with  Ultramarine",
  "colour" : "Lined with  Ultramarine",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20215",
  "productType" : "master-full-set",
  "colors" : ["#4169E1"]
},

//QLD

//Queensland Uni of Technology

"/uni/qld/qut/bachelor-degrees" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479477/Uni%20Regalia%20Photos/QUT/QUT-bach-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479477/Uni%20Regalia%20Photos/QUT/QUT-bach-business-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479477/Uni%20Regalia%20Photos/QUT/QUT-bach-business-back.jpg"],
  "metaTag" : "Buy your QUT Bachelor graduation gown set from Gowning Street!",
  "altTags" : ["QUT Bachelor graduation gown set - Front view", "QUT Bachelor graduation gown set - Side view", "QUT Bachelor graduation gown set - Back view"],
  "name" : "QUT Graduation Gown Set - All Bachelor Degrees",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Queensland University of Technology Hood - All Bachelor Degrees",
  "hood-style" : "Black, Cambridge Style, partially lined with Blue",
  "colour" : "Partially Lined with Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70401",
  "productType" : "bachelor-full-set",
  "colors" : ["#0157D5"]
},
"/uni/qld/qut/master-degrees" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008115/Aus-Uni-Photos/RMIT/rmit-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008113/Aus-Uni-Photos/RMIT/rmit-bach-engg-back.jpg"],
  "metaTag" : "Buy your QUT Masters graduation gown set from Gowning Street!",
  "altTags" : ["QUT Masters graduation gown set - Front view", "QUT Masters graduation gown set - Side view", "QUT Masters graduation gown set - Back view"],
  "name" : "QUT Graduation Gown Set - All Master Degrees",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Queensland University of Technology Hood - All Master Degrees",
  "hood-style" : "Black, Cambridge Style, fully lined with Blue",
  "colour" : "Fully Lined with Blue",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO70411",
  "productType" : "master-full-set",
  "colors" : ["#0043A9"]
},
"/uni/qld/qut/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008075/Aus-Uni-Photos/QUT/qut-phd-set-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008077/Aus-Uni-Photos/QUT/qut-phd-set-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008075/Aus-Uni-Photos/QUT/qut-phd-set-back.jpg"],
  "metaTag" : "Buy your Queensland University of Technology PhD graduation gown set from Gowning Street!",
  "altTags" : ["Queensland University of Technology PhD graduation gown set - Front view", "Queensland University of Technology PhD graduation gown set - Side view", "Queensland University of Technology PhD graduation gown set - Back view"],
  "name" : "QUT Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Queensland University of Technology Hood - PhD",
  "hood-style" : "Black hood, lined/trimmed with Red",
  "colour" : "lined/trimmed with Red",
  "gownStyle" : "PhD",
  "phdStyle" : "satin",
  "level" : "PhD",
  "hood-code" : "HOO70221",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "red"
},

//University of Southern Queensland

"/uni/qld/usq/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008498/Aus-Uni-Photos/USQ/usq-bach-buss-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008499/Aus-Uni-Photos/USQ/usq-bach-buss-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008497/Aus-Uni-Photos/USQ/usq-bach-buss-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of business graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of business graduation gown set - Front view", "USQ bachelor of business graduation gown set - Side view", "USQ bachelor of business graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Business",
  "hood-style" : "Black, Cambridge Style, Partially lined with French Grey",
  "colour" : "Partially Lined with French Grey",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70204",
  "productType" : "bachelor-full-set",
  "colors" : ["#A9B3BD"]
},
"/uni/qld/usq/bachelor-of-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008501/Aus-Uni-Photos/USQ/usq-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008502/Aus-Uni-Photos/USQ/usq-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008500/Aus-Uni-Photos/USQ/usq-bach-comm-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of commerce graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of commerce graduation gown set - Front view", "USQ bachelor of commerce graduation gown set - Side view", "USQ bachelor of commerce graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Commerce",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Commerce",
  "hood-style" : "Black, Cambridge Style, Partially lined with Indian Yellow",
  "colour" : "Partially Lined with Indian Yellow",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20302",
  "productType" : "bachelor-full-set",
  "colors" : ["#DFE454"]
},
"/uni/qld/usq/bachelor-of-creative-arts-and-media" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008495/Aus-Uni-Photos/USQ/usq-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008496/Aus-Uni-Photos/USQ/usq-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008494/Aus-Uni-Photos/USQ/usq-bach-arts-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of creative arts and media graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of creative arts and media graduation gown set - Front view", "USQ bachelor of creative arts and media graduation gown set - Side view", "USQ bachelor of creative arts and media graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Creative Arts and Media",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Creative Arts and Media",
  "hood-style" : "Black, Cambridge Style, Partially lined with Pearl White",
  "colour" : "Partially Lined with Pearl White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70101",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/qld/usq/bachelor-of-health-and-community" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008504/Aus-Uni-Photos/USQ/usq-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008505/Aus-Uni-Photos/USQ/usq-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008503/Aus-Uni-Photos/USQ/usq-bach-health-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of health and community graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of health and community graduation gown set - Front view", "USQ bachelor of health and community graduation gown set - Side view", "USQ bachelor of health and community graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Health and Community",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Health and Community",
  "hood-style" : "Black, Cambridge Style, pPartially lined with Peacock Green",
  "colour" : "Partially Lined with Peacock Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20305",
  "productType" : "bachelor-full-set",
  "colors" : ["turquoise"]
},
"/uni/qld/usq/bachelor-of-humanities-and-communication" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008507/Aus-Uni-Photos/USQ/usq-bach-hums-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008508/Aus-Uni-Photos/USQ/usq-bach-hums-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008506/Aus-Uni-Photos/USQ/usq-bach-hums-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of humanities and communication graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of humanities and communication graduation gown set - Front view", "USQ bachelor of humanities and communication graduation gown set - Side view", "USQ bachelor of humanities and communication graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Humanities and Communication",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Humanities and Communication",
  "hood-style" : "Black, Cambridge Style, Partially lined with Pearl White",
  "colour" : "Partially Lined with Pearl White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70101",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/qld/usq/bachelor-of-information-technology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008511/Aus-Uni-Photos/USQ/usq-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008512/Aus-Uni-Photos/USQ/usq-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008509/Aus-Uni-Photos/USQ/usq-bach-it-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of information technology graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of information technology graduation gown set - Front view", "USQ bachelor of information technology graduation gown set - Side view", "USQ bachelor of information technology graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Information Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Information Technology",
  "hood-style" : "Black, Cambridge Style, Partially lined with Signal Red",
  "colour" : "Partially Lined with Signal Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO20304",
  "productType" : "bachelor-full-set",
  "colors" : ["#CF3540"]
},
"/uni/qld/usq/bachelor-of-law-and-justice" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479758/Uni%20Regalia%20Photos/USQ/usq-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479758/Uni%20Regalia%20Photos/USQ/usq-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479758/Uni%20Regalia%20Photos/USQ/usq-bach-law-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of law and justice graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of law and justice graduation gown set - Front view", "USQ bachelor of law and justice graduation gown set - Side view", "USQ bachelor of law and justice graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Law and Justice",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Law and Justice",
  "hood-style" : "Black, Cambridge Style, Partially lined with Spectrum Violet",
  "colour" : "Partially Lined with Spectrum Violet",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50101",
  "productType" : "bachelor-full-set",
  "colors" : ["#C159B8"]
},
"/uni/qld/usq/bachelor-of-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592481135/Uni%20Regalia%20Photos/USQ/usq-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592481135/Uni%20Regalia%20Photos/USQ/usq-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592481135/Uni%20Regalia%20Photos/USQ/usq-bach-sci-back.jpg"],
  "metaTag" : "Buy your USQ bachelor of sciences graduation gown set from Gowning Street!",
  "altTags" : ["USQ bachelor of sciences graduation gown set - Front view", "USQ bachelor of sciences graduation gown set - Side view", "USQ bachelor of sciences graduation gown set - Back view"],
  "name" : "USQ Graduation Gown Set - Bachelor of Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Southern Queensland Hood - Bachelor of Sciences",
  "hood-style" : "Black, Cambridge Style, Partially lined with Spectrum Blue",
  "colour" : "Partially Lined with Spectrum Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70205",
  "productType" : "bachelor-full-set",
  "colors" : ["#49B7E4"]
},

    //VIC

//Uni of Melb

"/uni/vic/unimelb/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008304/Aus-Uni-Photos/Unimelb/unimelb-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008305/Aus-Uni-Photos/Unimelb/unimelb-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008303/Aus-Uni-Photos/Unimelb/unimelb-bach-sci-back.jpg"],
  "metaTag" : "Buy your Melbourne University bachelor of science graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb bachelor of science graduation gown set - Front view", "UniMelb bachelor of science graduation gown set - Side view", "UniMelb bachelor of science graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Bachelor of Science",
  "hood-style" : "Black, Oxford Style, Lined with Olive Green",
  "colour" : "Lined with Olive Green",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30202",
  "productType" : "bachelor-no-trencher",
  "colors" : ["#948A6B"]
},
"/uni/vic/unimelb/graduation-gown/bachelor-of-arts/" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008292/Aus-Uni-Photos/Unimelb/unimelb-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008293/Aus-Uni-Photos/Unimelb/unimelb-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008291/Aus-Uni-Photos/Unimelb/unimelb-bach-arts-back.jpg"],
  "metaTag" : "Buy your Melbourne University Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Bachelor of Arts graduation gown set - Front view", "UniMelb Bachelor of Arts graduation gown set - Side view", "UniMelb Bachelor of Arts graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford Style, Lined with Stewart Blue",
  "colour" : "Lined with Stewart Blue",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30205",
  "productType" : "bachelor-no-trencher",
  "colors" : ["blue"]
},
"/uni/vic/unimelb/graduation-gown/bachelor-of-biomedicine/" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008295/Aus-Uni-Photos/Unimelb/unimelb-bach-biomed-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008296/Aus-Uni-Photos/Unimelb/unimelb-bach-biomed-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008294/Aus-Uni-Photos/Unimelb/unimelb-bach-biomed-back.jpg"],
  "metaTag" : "Buy your Melbourne University Bachelor of Biomedicine graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Bachelor of Biomedicine graduation gown set - Front view", "UniMelb Bachelor of Biomedicine graduation gown set - Side view", "UniMelb Bachelor of Biomedicine graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Bachelor of Biomedicine",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Bachelor of Biomedicine",
  "hood-style" : "Black, Oxford Style, Lined with Olive Green and Red",
  "colour" : "Lined with Olive Green and Red",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30207",
  "productType" : "bachelor-no-trencher",
  "colors" : ["#556b2f","red"]
},
"/uni/vic/unimelb/graduation-gown/bachelor-of-environments/" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008301/Aus-Uni-Photos/Unimelb/unimelb-bach-environments-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008302/Aus-Uni-Photos/Unimelb/unimelb-bach-environments-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008300/Aus-Uni-Photos/Unimelb/unimelb-bach-environments-back.jpg"],
  "metaTag" : "Buy your Melbourne University Bachelor of Environments graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Bachelor of Environments graduation gown set - Front view", "UniMelb Bachelor of Environments graduation gown set - Side view", "UniMelb Bachelor of Environments graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Bachelor of Environments",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Bachelor of Environments",
  "hood-style" : "Black, Oxford Style, Lined with Saffron Yellow",
  "colour" : "Lined with Saffron Yellow",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30208",
  "productType" : "bachelor-no-trencher",
  "colors" : ["yellow"]
},
"/uni/vic/unimelb/bachelor-of-business-and-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008298/Aus-Uni-Photos/Unimelb/unimelb-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008299/Aus-Uni-Photos/Unimelb/unimelb-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008297/Aus-Uni-Photos/Unimelb/unimelb-bach-bus-back.jpg"],
  "metaTag" : "Buy your University of Melbourne bachelor of business and economics graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb bachelor of business and economics graduation gown set - Front view", "UniMelb bachelor of business and economics graduation gown set - Side view", "UniMelb bachelor of business and economics graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Bachelor of Business and Economics",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Bachelor of Business and Economics",
  "hood-style" : "Black, Oxford Style, Lined with Sky Blue",
  "colour" : "Lined with Sky Blue",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30204",
  "productType" : "bachelor-no-trencher",
  "colors" : ["lightblue"]
},
"/uni/vic/unimelb/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008321/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008322/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008320/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Engineering graduation gown set - Front view", "UniMelb Master of Engineering graduation gown set - Side view", "UniMelb Master of Engineering graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Engineering",
  "hood-style" : "Black, Oxford Style, Lined with Gold",
  "colour" : "Lined with Gold",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30211",
  "productType" : "master-full-set",
  "colors" : ["yellow"]
},
"/uni/vic/unimelb/graduation-gown/master-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008308/Aus-Uni-Photos/Unimelb/unimelb-mast-architecture-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008309/Aus-Uni-Photos/Unimelb/unimelb-mast-architecture-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008307/Aus-Uni-Photos/Unimelb/unimelb-mast-architecture-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Architecture, Building & Planning graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Architecture, Building & Planning graduation gown set - Front view", "UniMelb Master of Architecture, Building & Planning graduation gown set - Side view", "UniMelb Master of Architecture, Building & Planning graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Architecture, Building & Planning",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Architecture, Building & Planning",
  "hood-style" : "Black, Oxford Style, Lined with Magenta",
  "colour" : "Lined with Magenta",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30218",
  "productType" : "master-full-set",
  "colors" : ["#FF00FF"]
},
"/uni/vic/unimelb/graduation-gown/master-of-healthsci-nursing" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008324/Aus-Uni-Photos/Unimelb/unimelb-mast-healthsci-nursing-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008325/Aus-Uni-Photos/Unimelb/unimelb-mast-healthsci-nursing-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008323/Aus-Uni-Photos/Unimelb/unimelb-mast-healthsci-nursing-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Health Sciences & Nursing graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Health Sciences & Nursing graduation gown set - Front view", "UniMelb Master of Health Sciences & Nursing graduation gown set - Side view", "UniMelb Master of Health Sciences & Nursing graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Health Sciences & Nursing",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Health Sciences & Nursing",
  "hood-style" : "Black, Oxford Style, Lined with Petunia",
  "colour" : "Lined with Petunia",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30219",
  "productType" : "master-full-set",
  "colors" : ["#FF00FF"]
},
"/uni/vic/unimelb/master-of-medicine" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008327/Aus-Uni-Photos/Unimelb/unimelb-mast-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008328/Aus-Uni-Photos/Unimelb/unimelb-mast-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008326/Aus-Uni-Photos/Unimelb/unimelb-mast-med-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Medicine graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Medicine graduation gown set - Front view", "UniMelb Master of Medicine graduation gown set - Side view", "UniMelb Master of Medicine graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Medicine",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Medicine",
  "hood-style" : "Black, Oxford Style, Lined with Cardinal Red",
  "colour" : "Lined with Cardinal Red",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30212",
  "productType" : "master-full-set",
  "colors" : ["#800000"]
},
"/uni/vic/unimelb/MBA" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008333/Aus-Uni-Photos/Unimelb/unimelb-MBA-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008334/Aus-Uni-Photos/Unimelb/unimelb-MBA-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008332/Aus-Uni-Photos/Unimelb/unimelb-MBA-back.jpg"],
  "metaTag" : "Buy your University of Melbourne MBA graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb MBA graduation gown set - Front view", "UniMelb MBA graduation gown set - Side view", "UniMelb MBA graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - MBA",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - MBA",
  "hood-style" : "Black, Oxford Style, Lined with Sky Blue and Gold",
  "colour" : "Lined with Sky Blue and Gold",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30213",
  "productType" : "master-full-set",
  "colors" : ["#ADD8E6", "#FFFF00"]
},
"/uni/vic/unimelb/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008311/Aus-Uni-Photos/Unimelb/unimelb-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008312/Aus-Uni-Photos/Unimelb/unimelb-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008310/Aus-Uni-Photos/Unimelb/unimelb-mast-arts-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Arts graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Arts graduation gown set - Front view", "UniMelb Master of Arts graduation gown set - Side view", "UniMelb Master of Arts graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Arts",
  "hood-style" : "Black, Oxford Style, Lined with Stewart Blue",
  "colour" : "Lined with Stewart Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30214",
  "productType" : "master-full-set",
  "colors" : ["blue"]
},
"/uni/vic/unimelb/master-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008330/Aus-Uni-Photos/Unimelb/unimelb-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008331/Aus-Uni-Photos/Unimelb/unimelb-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008329/Aus-Uni-Photos/Unimelb/unimelb-mast-sci-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Science graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Science graduation gown set - Front view", "UniMelb Master of Science graduation gown set - Side view", "UniMelb Master of Science graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Science",
  "hood-style" : "Black, Oxford Style, Lined with Olive Green",
  "colour" : "Lined with Olive Green",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30215",
  "productType" : "master-full-set",
  "colors" : ["#5A5A00"]
},
"/uni/vic/unimelb/master-of-business-and-economics" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008314/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008315/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008313/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-back.jpg"
  ],
  "metaTag" : "Buy your University of Melbourne Master of Business & Economics graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Business & Economics graduation gown set - Front view", "UniMelb Master of Business & Economics graduation gown set - Side view", "UniMelb Master of Business & Economics graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Business & Economics",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Business & Economics",
  "hood-style" : "Black, Oxford Style, Lined with Sky Blue",
  "colour" : "Lined with Sky Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30216",
  "productType" : "master-full-set",
  "colors" : ["#87CEEB"]
},
"/uni/vic/unimelb/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008317/Aus-Uni-Photos/Unimelb/unimelb-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008319/Aus-Uni-Photos/Unimelb/unimelb-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008317/Aus-Uni-Photos/Unimelb/unimelb-mast-educ-back.jpg"],
  "metaTag" : "Buy your University of Melbourne Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["UniMelb Master of Education graduation gown set - Front view", "UniMelb Master of Education graduation gown set - Side view", "UniMelb Master of Education graduation gown set - Back view"],
  "name" : "University of Melbourne Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Melbourne Hood - Master of Education",
  "hood-style" : "Black, Oxford Style, Lined with Malachite Green",
  "colour" : "Lined with Malachite Green",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30217",
  "productType" : "master-full-set",
  "colors" : ["#014421"]
},
"/uni/vic/unimelb/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008336/Aus-Uni-Photos/Unimelb/unimelb-phd-set-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008337/Aus-Uni-Photos/Unimelb/unimelb-phd-set-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008335/Aus-Uni-Photos/Unimelb/unimelb-phd-set-back.png"],
  "metaTag" : "Buy your Melbourne University PhD graduation gown set from Gowning Street!",
  "altTags" : ["Melbourne University PhD graduation gown set - Front view", "Melbourne University PhD graduation gown set - Side view", "Melbourne University PhD graduation gown set - Back view"],
  "name" : "Melbourne Uni Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Melbourne University Hood - PhD",
  "hood-style" : "Cherry hood, lined/trimmed with Cherry",
  "colour" : "lined/trimmed with Cherry",
  "gownStyle" : "PhD",
  "phdStyle" : "red satin",
  "level" : "PhD",
  "hood-code" : "HOO30221",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "red"
},

// Swinburne

"/uni/vic/swinburne/advanced-diploma-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008169/Aus-Uni-Photos/Swinburne/swinburne-ad-buss-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008170/Aus-Uni-Photos/Swinburne/swinburne-ad-buss-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008168/Aus-Uni-Photos/Swinburne/swinburne-ad-buss-back.jpg"],
  "metaTag" : "Buy your Swinburne advanced diploma of business graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne advanced diploma of business graduation gown set - Front view", "Swinburne advanced diploma of business graduation gown set - Side view", "Swinburne advanced diploma of business graduation gown set - Back view"],
  "name" : "Swinburne Uni Graduation Gown Set - Advanced Diploma of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Stole - Advanced Diploma of Business",
  "hood-style" : "Stole, Lined with Buff",
  "colour" : "Stole, Lined with Buff",
  "gownStyle" : "Cambridge",
  "level" : "Advanced Diploma",
  "hood-code" : "STO30101",
  "productType" : "bachelor-full-set",
  "colors" : ["#DFB383"]
},
"/uni/vic/swinburne/advanced-diploma-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008172/Aus-Uni-Photos/Swinburne/swinburne-ad-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008173/Aus-Uni-Photos/Swinburne/swinburne-ad-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008171/Aus-Uni-Photos/Swinburne/swinburne-ad-engg-back.jpg"],
  "metaTag" : "Buy your Swinburne advanced diploma of engineering graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne advanced diploma of engineering graduation gown set - Front view", "Swinburne advanced diploma of engineering graduation gown set - Side view", "Swinburne advanced diploma of engineering graduation gown set - Back view"],
  "name" : "Swinburne Uni Graduation Gown Set - Advanced Diploma of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Stole - Advanced Diploma of Engineering",
  "hood-style" : "Stole, Lined with Blue",
  "colour" : "Stole, Lined with Blue",
  "gownStyle" : "Cambridge",
  "level" : "Advanced Diploma",
  "hood-code" : "STO30102",
  "productType" : "bachelor-full-set",
  "colors" : ["#55669A"]
},
"/uni/vic/swinburne/advanced-diploma-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008176/Aus-Uni-Photos/Swinburne/swinburne-ad-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008177/Aus-Uni-Photos/Swinburne/swinburne-ad-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008174/Aus-Uni-Photos/Swinburne/swinburne-ad-it-back.jpg"],
  "metaTag" : "Buy your Swinburne advanced diploma of IT graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne advanced diploma of IT graduation gown set - Front view", "Swinburne advanced diploma of IT graduation gown set - Side view", "Swinburne advanced diploma of IT graduation gown set - Back view"],
  "name" : "Swinburne Uni Graduation Gown Set - Advanced Diploma of IT",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Stole - Advanced Diploma of IT",
  "hood-style" : "Stole, lined with Tangerine",
  "colour" : "Stole, lined with Tangerine",
  "gownStyle" : "Cambridge",
  "level" : "Advanced Diploma",
  "hood-code" : "STO30103",
  "productType" : "bachelor-full-set",
  "colors" : ["#FB8C58"]
},
"/uni/vic/swinburne/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008179/Aus-Uni-Photos/Swinburne/swinburne-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008180/Aus-Uni-Photos/Swinburne/swinburne-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008178/Aus-Uni-Photos/Swinburne/swinburne-bach-arts-back.jpg"],
  "metaTag" : "Buy your Swinburne Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne Bachelor of Arts graduation gown set - Front view", "Swinburne Bachelor of Arts graduation gown set - Side view", "Swinburne Bachelor of Arts graduation gown set - Back view"],
  "name" : "Swinburne Uni Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Cranberry.",
  "colour" : "Lined with Cranberry",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30104",
  "productType" : "bachelor-full-set",
  "colors" : ["#FF004F"]
},
"/uni/vic/swinburne/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008182/Aus-Uni-Photos/Swinburne/swinburne-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008183/Aus-Uni-Photos/Swinburne/swinburne-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008181/Aus-Uni-Photos/Swinburne/swinburne-bach-bus-back.jpg"],
  "metaTag" : "Buy your Swinburne bachelor of business graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne bachelor of business graduation gown set - Front view", "Swinburne bachelor of business graduation gown set - Side view", "Swinburne bachelor of business graduation gown set - Back view"],
  "name" : "Swinburne University Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Hood - Bachelor of Business",
  "hood-style" : "Black, Oxford Style, lined with Buff",
  "colour" : "Lined with Buff",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30101",
  "productType" : "bachelor-full-set",
  "colors" : ["#DFB383"]
},
"/uni/vic/swinburne/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008185/Aus-Uni-Photos/Swinburne/swinburne-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008186/Aus-Uni-Photos/Swinburne/swinburne-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008184/Aus-Uni-Photos/Swinburne/swinburne-bach-engg-back.jpg"],
  "metaTag" : "Buy your Swinburne bachelor of engineering graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne bachelor of engineering graduation gown set - Front view", "Swinburne bachelor of engineering graduation gown set - Side view", "Swinburne bachelor of engineering graduation gown set - Back view"],
  "name" : "Swinburne University Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford Style, lined with Blue",
  "colour" : "Lined with Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30102",
  "productType" : "bachelor-full-set",
  "colors" : ["#55669A"]
},
"/uni/vic/swinburne/bachelor-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008188/Aus-Uni-Photos/Swinburne/swinburne-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008189/Aus-Uni-Photos/Swinburne/swinburne-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008187/Aus-Uni-Photos/Swinburne/swinburne-bach-it-back.jpg"],
  "metaTag" : "Buy your Swinburne bachelor of IT graduation gown set from Gowning Street!",
  "altTags" : ["Swinburne bachelor of IT graduation gown set - Front view", "Swinburne bachelor of IT graduation gown set - Side view", "Swinburne bachelor of IT graduation gown set - Back view"],
  "name" : "Swinburne University Bachelor of IT",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Swinburne University Hood - Bachelor of IT",
  "hood-style" : "Black, Oxford Style, lined with Tangerine",
  "colour" : "Lined with Tangerine",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30103",
  "productType" : "bachelor-full-set",
  "colors" : ["#FB8C58"]
},

//rmit
"/uni/vic/rmit/bachelor-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008099/Aus-Uni-Photos/RMIT/rmit-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008100/Aus-Uni-Photos/RMIT/rmit-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008098/Aus-Uni-Photos/RMIT/rmit-bach-arch-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Architecture graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Architecture graduation gown set - Front view", "RMIT Bachelor of Architecture graduation gown set - Side view", "RMIT Bachelor of Architecture graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Architecture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Architecture",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Cherry, White",
  "colour" : "Lined with Cherry, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30407",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFB7C5"]
},
"/uni/vic/rmit/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008133/Aus-Uni-Photos/RMIT/rmit-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008134/Aus-Uni-Photos/RMIT/rmit-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008132/Aus-Uni-Photos/RMIT/rmit-mast-arts-back.jpg"],
  "metaTag" : "Buy your RMIT Master of Arts graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Master of Arts graduation gown set - Front view", "RMIT Master of Arts graduation gown set - Side view", "RMIT Master of Arts graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Master of Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Magenta",
  "colour" : "Lined with Magenta",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30411",
  "productType" : "master-full-set",
  "colors" : ["hsl(300,100%,50%)"]
},
"/uni/vic/rmit/graduation-gown/master-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008136/Aus-Uni-Photos/RMIT/rmit-mast-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008138/Aus-Uni-Photos/RMIT/rmit-mast-business-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008135/Aus-Uni-Photos/RMIT/rmit-mast-business-back.jpg"],
  "metaTag" : "Buy your RMIT Master of Business graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Master of Business graduation gown set - Front view", "RMIT Master of Business graduation gown set - Side view", "RMIT Master of Business graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Master of Business",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Master of Business",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Heliotrope",
  "colour" : "Lined with Heliotrope",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30413",
  "productType" : "master-full-set",
  "colors" : ["#df73ff"]
},
"/uni/vic/rmit/graduation-gown/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008143/Aus-Uni-Photos/RMIT/rmit-mast-engineering-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008144/Aus-Uni-Photos/RMIT/rmit-mast-engineering-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008142/Aus-Uni-Photos/RMIT/rmit-mast-engineering-back.jpg"],
  "metaTag" : "Buy your RMIT Master of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Master of Engineering graduation gown set - Front view", "RMIT Master of Engineering graduation gown set - Side view", "RMIT Master of Engineering graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Master of Engineering",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Spectrum Blue",
  "colour" : "Lined with Spectrum Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30414",
  "productType" : "master-full-set",
  "colors" : ["blue"]
},
"/uni/vic/rmit/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008140/Aus-Uni-Photos/RMIT/rmit-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008141/Aus-Uni-Photos/RMIT/rmit-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008138/Aus-Uni-Photos/RMIT/rmit-mast-educ-back.jpg"],
  "metaTag" : "Buy your RMIT Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Master of Education graduation gown set - Front view", "RMIT Master of Education graduation gown set - Side view", "RMIT Master of Education graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Master of Education",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Turquoise",
  "colour" : "Lined with Turquoise",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30412",
  "productType" : "master-full-set",
  "colors" : ["hsl(162,100%,25%)"]
},
"/uni/vic/rmit/bachelor-of-social-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008123/Aus-Uni-Photos/RMIT/rmit-bach-sosci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008124/Aus-Uni-Photos/RMIT/rmit-bach-sosci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008122/Aus-Uni-Photos/RMIT/rmit-bach-sosci-back.jpg"],
  "metaTag" : "Buy your RMIT bachelor of social science graduation gown set from Gowning Street!",
  "altTags" : ["RMIT bachelor of social science graduation gown set - Front view", "RMIT bachelor of social science graduation gown set - Side view", "RMIT bachelor of social science graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Social Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Social Science",
  "hood-style" : "Black, Oxford Style, Partially lined with Buttercup",
  "colour" : "Lined with Buttercup",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30201",
  "productType" : "bachelor-full-set",
  "colors" : ["#F2F35E"]
},
"/uni/vic/rmit/bachelor-of-social-work" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008126/Aus-Uni-Photos/RMIT/rmit-bach-sowork-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008127/Aus-Uni-Photos/RMIT/rmit-bach-sowork-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008126/Aus-Uni-Photos/RMIT/rmit-bach-sowork-back.jpg"],
  "metaTag" : "Buy your RMIT bachelor of social work graduation gown set from Gowning Street!",
  "altTags" : ["RMIT bachelor of social work graduation gown set - Front view", "RMIT bachelor of social work graduation gown set - Side view", "RMIT bachelor of social work graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Social Work",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Social Work",
  "hood-style" : "Black, Oxford Style, Lined with Powder Blue",
  "colour" : "Lined with Powder Blue",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30204",
  "productType" : "bachelor-full-set",
  "colors" : ["lightblue"]
},
"/uni/vic/rmit/bachelor-of-technology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008129/Aus-Uni-Photos/RMIT/rmit-bach-tech-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008130/Aus-Uni-Photos/RMIT/rmit-bach-tech-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008128/Aus-Uni-Photos/RMIT/rmit-bach-tech-back.png"],
  "metaTag" : "Buy your RMIT bachelor of technology graduation gown set from Gowning Street!",
  "altTags" : ["RMIT bachelor of technology graduation gown set - Front view", "RMIT bachelor of technology graduation gown set - Side view", "RMIT bachelor of technology graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Technology",
  "hood-style" : "Black, Oxford Style, Lined with Spectrum Green",
  "colour" : "Lined with Spectrum Green",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30203",
  "productType" : "bachelor-full-set",
  "colors" : ["#299F7A"]
},
"/uni/vic/rmit/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008105/Aus-Uni-Photos/RMIT/rmit-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008106/Aus-Uni-Photos/RMIT/rmit-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008104/Aus-Uni-Photos/RMIT/rmit-bach-bus-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Business graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Business graduation gown set - Front view", "RMIT Bachelor of Business graduation gown set - Side view", "RMIT Bachelor of Business graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Business",
  "hood-style" : "Black, Oxford Style, Lined with Heliotrope, White",
  "colour" : "Lined with Heliotrope, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30401",
  "productType" : "bachelor-full-set",
  "colors" : ["violet"]
},
"/uni/vic/rmit/bachelor-of-design" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008108/Aus-Uni-Photos/RMIT/rmit-bach-design-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008109/Aus-Uni-Photos/RMIT/rmit-bach-design-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008107/Aus-Uni-Photos/RMIT/rmit-bach-design-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Design graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Design graduation gown set - Front view", "RMIT Bachelor of Design graduation gown set - Side view", "RMIT Bachelor of Design graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Design",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Design",
  "hood-style" : "Black, Oxford Style, Lined with Silver Grey, White",
  "colour" : "Lined with Silver Grey, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30402",
  "productType" : "bachelor-full-set",
  "colors" : ["silver"]
},
"/uni/vic/rmit/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008111/Aus-Uni-Photos/RMIT/rmit-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008112/Aus-Uni-Photos/RMIT/rmit-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008110/Aus-Uni-Photos/RMIT/rmit-bach-edu-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Education graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Education graduation gown set - Front view", "RMIT Bachelor of Education graduation gown set - Side view", "RMIT Bachelor of Education graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford Style, Lined with Turquoise, White",
  "colour" : "Lined with Turquoise, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30403",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(162,100%,25%)"]
},
"/uni/vic/rmit/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008114/Aus-Uni-Photos/RMIT/rmit-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008115/Aus-Uni-Photos/RMIT/rmit-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008113/Aus-Uni-Photos/RMIT/rmit-bach-engg-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Engineering graduation gown set - Front view", "RMIT Bachelor of Engineering graduation gown set - Side view", "RMIT Bachelor of Engineering graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford Style, Lined with Spectrum Blue, White",
  "colour" : "Lined with Spectrum Blue, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30404",
  "productType" : "bachelor-full-set",
  "colors" : ["blue"]
},
"/uni/vic/rmit/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008117/Aus-Uni-Photos/RMIT/rmit-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008118/Aus-Uni-Photos/RMIT/rmit-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008116/Aus-Uni-Photos/RMIT/rmit-bach-law-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Law graduation gown set - Front view", "RMIT Bachelor of Law graduation gown set - Side view", "RMIT Bachelor of Law graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Law",
  "hood-style" : "Black, Oxford Style, Lined with White",
  "colour" : "Lined with White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30405",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/vic/rmit/bachelor-of-nursing" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008120/Aus-Uni-Photos/RMIT/rmit-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008121/Aus-Uni-Photos/RMIT/rmit-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008119/Aus-Uni-Photos/RMIT/rmit-bach-nurse-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Nursing graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Nursing graduation gown set - Front view", "RMIT Bachelor of Nursing graduation gown set - Side view", "RMIT Bachelor of Nursing graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Nursing",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Nursing",
  "hood-style" : "Black, Oxford Style, Lined with Fuschia, White",
  "colour" : "Lined with Fuschia, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30406",
  "productType" : "bachelor-full-set",
  "colors" : ["violet"]
},
"/uni/vic/rmit/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008102/Aus-Uni-Photos/RMIT/rmit-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008103/Aus-Uni-Photos/RMIT/rmit-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008101/Aus-Uni-Photos/RMIT/rmit-bach-arts-back.jpg"],
  "metaTag" : "Buy your RMIT Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["RMIT Bachelor of Arts graduation gown set - Front view", "RMIT Bachelor of Arts graduation gown set - Side view", "RMIT Bachelor of Arts graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT University Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford Style, Lined with Magenta, White",
  "colour" : "Lined with Magenta, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30408",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(300,100%,50%)"]
},
"/uni/vic/rmit/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008146/Aus-Uni-Photos/RMIT/rmit-phd-set-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008147/Aus-Uni-Photos/RMIT/rmit-phd-set-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008145/Aus-Uni-Photos/RMIT/rmit-phd-set-back.png"],
  "metaTag" : "Buy your RMIT PhD graduation gown set from Gowning Street!",
  "altTags" : ["RMIT PhD graduation gown set - Front view", "RMIT PhD graduation gown set - Side view", "RMIT PhD graduation gown set - Back view"],
  "name" : "RMIT Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "RMIT Hood - PhD",
  "hood-style" : "Scarlet hood, lined/trimmed with Scarlett",
  "colour" : "lined/trimmed with Scarlet",
  "gownStyle" : "PhD",
  "phdStyle" : "red satin",
  "level" : "PhD",
  "hood-code" : "HOO30221",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "red"
},


//victoria uni

"/uni/vic/victoria-university/bachelor-of-business-or-business-administration" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008675/Aus-Uni-Photos/VICU/vicu-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008676/Aus-Uni-Photos/VICU/vicu-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008673/Aus-Uni-Photos/VICU/vicu-bach-bus-back.jpg"],
  "metaTag" : "Buy your Victoria University bachelor of business or business administration graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University bachelor of business or business administration graduation gown set - Front view", "Victoria University bachelor of business or business administration graduation gown set - Side view", "Victoria University bachelor of business or business administration graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Business or Business Administration",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Business or Business Administration",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Ultramarine",
  "colour" : "Lined/Trimmed with Ultramarine",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30602",
  "productType" : "bachelor-full-set",
  "colors" : ["#224DAC"]
},
"/uni/vic/victoria-university/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008681/Aus-Uni-Photos/VICU/vicu-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008682/Aus-Uni-Photos/VICU/vicu-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008680/Aus-Uni-Photos/VICU/vicu-bach-engg-back.jpg"],
  "metaTag" : "Buy your Victoria University bachelor of engineering graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University bachelor of engineering graduation gown set - Front view", "Victoria University bachelor of engineering graduation gown set - Side view", "Victoria University bachelor of engineering graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Silver Grey",
  "colour" : "Lined/Trimmed with Silver Grey",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO70204",
  "productType" : "bachelor-full-set",
  "colors" : ["silver"]
},
"/uni/vic/victoria-university/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008671/Aus-Uni-Photos/VICU/vicu-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008672/Aus-Uni-Photos/VICU/vicu-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008670/Aus-Uni-Photos/VICU/vicu-bach-arts-back.jpg"],
  "metaTag" : "Buy your Victoria University Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Bachelor of Arts graduation gown set - Front view", "Victoria University Bachelor of Arts graduation gown set - Side view", "Victoria University Bachelor of Arts graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Ruby",
  "colour" : "Lined/Trimmed with Ruby",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30603",
  "productType" : "bachelor-full-set",
  "colors" : ["#bb0a1e"]
},
"/uni/vic/victoria-university/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008678/Aus-Uni-Photos/VICU/vicu-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008679/Aus-Uni-Photos/VICU/vicu-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008677/Aus-Uni-Photos/VICU/vicu-bach-educ-back.jpg"],
  "metaTag" : "Buy your Victoria University Bachelor of Education graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Bachelor of Education graduation gown set - Front view", "Victoria University Bachelor of Education graduation gown set - Side view", "Victoria University Bachelor of Education graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Cherry",
  "colour" : "Lined/Trimmed with Cherry",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30604",
  "productType" : "bachelor-full-set",
  "colors" : ["#C40000"]
},
"/uni/vic/victoria-university/bachelor-of-healthsci" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008684/Aus-Uni-Photos/VICU/vicu-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008685/Aus-Uni-Photos/VICU/vicu-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008683/Aus-Uni-Photos/VICU/vicu-bach-health-back.jpg"],
  "metaTag" : "Buy your Victoria University Bachelor of Health Science graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Bachelor of Health Science graduation gown set - Front view", "Victoria University Bachelor of Health Science graduation gown set - Side view", "Victoria University Bachelor of Health Science graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Health Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Health Science",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Old Rose",
  "colour" : "Lined/Trimmed with Old Rose",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30605",
  "productType" : "bachelor-full-set",
  "colors" : ["#c08081"]
},
"/uni/vic/victoria-university/bachelor-of-social-work" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008697/Aus-Uni-Photos/VICU/vicu-bach-sw-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008698/Aus-Uni-Photos/VICU/vicu-bach-sw-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008696/Aus-Uni-Photos/VICU/vicu-bach-sw-back.jpg"],
  "metaTag" : "Buy your Victoria University Bachelor of Social Work graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Bachelor of Social Work graduation gown set - Front view", "Victoria University Bachelor of Social Work graduation gown set - Side view", "Victoria University Bachelor of Social Work graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Social Work",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Social Work",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Gold",
  "colour" : "Lined/Trimmed with Gold",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30606",
  "productType" : "bachelor-full-set",
  "colors" : ["yellow"]
},
"/uni/vic/victoria-university/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008687/Aus-Uni-Photos/VICU/vicu-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008688/Aus-Uni-Photos/VICU/vicu-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008686/Aus-Uni-Photos/VICU/vicu-bach-law-back.png"],
  "metaTag" : "Buy your Victoria University Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Bachelor of Law graduation gown set - Front view", "Victoria University Bachelor of Law graduation gown set - Side view", "Victoria University Bachelor of Law graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Law",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Parchment",
  "colour" : "Lined/Trimmed with Parchment",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30607",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/vic/victoria-university/bachelor-of-psychology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008690/Aus-Uni-Photos/VICU/vicu-bach-psych-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008691/Aus-Uni-Photos/VICU/vicu-bach-psych-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008690/Aus-Uni-Photos/VICU/vicu-bach-psych-back.jpg"],
  "metaTag" : "Buy your Victoria University bachelor of psychology graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University bachelor of psychology graduation gown set - Front view", "Victoria University bachelor of psychology graduation gown set - Side view", "Victoria University bachelor of psychology graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Psychology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Psychology",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Buff",
  "colour" : "Lined/Trimmed with Buff",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30101",
  "productType" : "bachelor-full-set",
  "colors" : ["#DFB383"]
},
"/uni/vic/victoria-university/bachelor-of-science-or-applied-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008694/Aus-Uni-Photos/VICU/vicu-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008695/Aus-Uni-Photos/VICU/vicu-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008693/Aus-Uni-Photos/VICU/vicu-bach-sci-back.jpg"],
  "metaTag" : "Buy your Victoria University bachelor of science or applied sciences graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University bachelor of science or applied sciences graduation gown set - Front view", "Victoria University bachelor of science or applied sciences graduation gown set - Side view", "Victoria University bachelor of science or applied sciences graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Bachelor of Science or Applied Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Bachelor of Science or Applied Sciences",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Spectrum Green",
  "colour" : "Lined/Trimmed with Spectrum Green",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30601",
  "productType" : "bachelor-full-set",
  "colors" : ["#277A6B"]
},
"/uni/vic/victoria-university/graduation-gown/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008700/Aus-Uni-Photos/VICU/vicu-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635016946/Aus-Uni-Photos/VICU/vicu-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008699/Aus-Uni-Photos/VICU/vicu-mast-arts-back.jpg"],
  "metaTag" : "Buy your Victoria University Master of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Master of Arts graduation gown set - Front view", "Victoria University Master of Arts graduation gown set - Side view", "Victoria University Master of Arts graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Master of Arts",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Ruby",
  "colour" : "Lined/Trimmed with Ruby",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30611",
  "productType" : "master-full-set",
  "colors" : ["#bb0a1e"]
},
"/uni/vic/victoria-university/graduation-gown/master-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-business-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-business-side.jpg"],
  "metaTag" : "Buy your Victoria University Master of Business graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Master of Business graduation gown set - Front view", "Victoria University Master of Business graduation gown set - Side view", "Victoria University Master of Business graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Master of Business",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Master of Business",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Ultramarine",
  "colour" : "Lined/Trimmed with Ultramarine",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30612",
  "productType" : "master-full-set",
  "colors" : ["blue"]
},
"/uni/vic/victoria-university/graduation-gown/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008678/Aus-Uni-Photos/VICU/vicu-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008679/Aus-Uni-Photos/VICU/vicu-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008677/Aus-Uni-Photos/VICU/vicu-bach-educ-back.jpg"],
  "metaTag" : "Buy your Victoria University Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Master of Education graduation gown set - Front view", "Victoria University Master of Education graduation gown set - Side view", "Victoria University Master of Education graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Master of Education",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Cherry",
  "colour" : "Lined/Trimmed with Cherry",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30613",
  "productType" : "master-full-set",
  "colors" : ["#790604"]
},
"/uni/vic/victoria-university/graduation-gown/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-engineering-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-engineering-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635017008/Aus-Uni-Photos/VICU/vicu-mast-engineering-back.jpg"],
  "metaTag" : "Buy your Victoria University Master of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["Victoria University Master of Engineering graduation gown set - Front view", "Victoria University Master of Engineering graduation gown set - Side view", "Victoria University Master of Engineering graduation gown set - Back view"],
  "name" : "Victoria University Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Victoria University Hood - Master of Engineering",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Silver Grey",
  "colour" : "Lined/Trimmed with Silver Grey",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30614",
  "productType" : "master-full-set",
  "colors" : ["#C0C0C0"]
},

// Federation Uni

"/uni/vic/federation-university/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007925/Aus-Uni-Photos/Federation/federation-bach-arts-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007926/Aus-Uni-Photos/Federation/federation-bach-arts-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007924/Aus-Uni-Photos/Federation/federation-bach-arts-back.png"],
  "metaTag" : "Buy your Federation University Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Federation University Bachelor of Arts graduation gown set - Front view", "Federation University Bachelor of Arts graduation gown set - Side view", "Federation University Bachelor of Arts graduation gown set - Back view"],
  "name" : "Federation University Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Federation University Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Magenta",
  "colour" : "Lined/Trimmed with Magenta",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30701",
  "productType" : "bachelor-full-set",
  "colors" : ["#FF00FF"]
},
"/uni/vic/federation-university/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007928/Aus-Uni-Photos/Federation/federation-bach-business-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007929/Aus-Uni-Photos/Federation/federation-bach-business-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007927/Aus-Uni-Photos/Federation/federation-bach-business-back.png"],
  "metaTag" : "Buy your Federation University Bachelor of Business graduation gown set from Gowning Street!",
  "altTags" : ["Federation University Bachelor of Business graduation gown set - Front view", "Federation University Bachelor of Business graduation gown set - Side view", "Federation University Bachelor of Business graduation gown set - Back view"],
  "name" : "Federation University Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Federation University Hood - Bachelor of Business",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Heliotrope",
  "colour" : "Lined/Trimmed with Heliotrope",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30702",
  "productType" : "bachelor-full-set",
  "colors" : ["#BA55D3"]
},
"/uni/vic/federation-university/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007931/Aus-Uni-Photos/Federation/federation-bach-education-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007932/Aus-Uni-Photos/Federation/federation-bach-education-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007930/Aus-Uni-Photos/Federation/federation-bach-education-back.png"],
  "metaTag" : "Buy your Federation University Bachelor of Education graduation gown set from Gowning Street!",
  "altTags" : ["Federation University Bachelor of Education graduation gown set - Front view", "Federation University Bachelor of Education graduation gown set - Side view", "Federation University Bachelor of Education graduation gown set - Back view"],
  "name" : "Federation University Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Federation University Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Heliotrope",
  "colour" : "Lined/Trimmed with Heliotrope",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30703",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/vic/federation-university/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007935/Aus-Uni-Photos/Federation/federation-mast-education-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007936/Aus-Uni-Photos/Federation/federation-mast-education-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007934/Aus-Uni-Photos/Federation/federation-mast-education-back.png"],
  "metaTag" : "Buy your Federation University Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["Federation University Master of Education graduation gown set - Front view", "Federation University Master of Education graduation gown set - Side view", "Federation University Master of Education graduation gown set - Back view"],
  "name" : "Federation University Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Federation University Hood - Master of Education",
  "hood-style" : "Black, Oxford Style, Lined/Trimmed with Heliotrope",
  "colour" : "Lined/Trimmed with Heliotrope",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30711",
  "productType" : "master-full-set",
  "colors" : ["green"]
},

// Monash
"/uni/vic/monash/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008062/Aus-Uni-Photos/Monash/monash-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008063/Aus-Uni-Photos/Monash/monash-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008061/Aus-Uni-Photos/Monash/monash-mast-arts-back.jpg"],
  "metaTag" : "Buy your Monash University Master of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Master of Arts graduation gown set - Front view", "Monash University Master of Arts graduation gown set - Side view", "Monash University Master of Arts graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Master of Arts",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Old Rose",
  "colour" : "Lined with Old Rose",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30511",
  "productType" : "master-full-set",
  "colors" : ["hsl(0,50%,50%)"]
},
"/uni/vic/monash/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592460580/Uni%20Regalia%20Photos/Monash/monash-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592460595/Uni%20Regalia%20Photos/Monash/monash-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592460617/Uni%20Regalia%20Photos/Monash/monash-mast-educ-back.jpg"],
  "metaTag" : "Buy your Monash University Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Master of Education graduation gown set - Front view", "Monash University Master of Education graduation gown set - Side view", "Monash University Master of Education graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Master of Education",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Banana",
  "colour" : "Lined with Banana",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30515",
  "productType" : "master-full-set",
  "colors" : ["hsl(0,50%,50%)"]
},
"/uni/vic/monash/graduation-gown/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008066/Aus-Uni-Photos/Monash/monash-mast-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008067/Aus-Uni-Photos/Monash/monash-mast-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008064/Aus-Uni-Photos/Monash/monash-mast-engg-back.jpg"],
  "metaTag" : "Buy your Monash University Master of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Master of Engineering graduation gown set - Front view", "Monash University Master of Engineering graduation gown set - Side view", "Monash University Master of Engineering graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Master of Engineering",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Rose Beige",
  "colour" : "Lined with Rose Beige",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30512",
  "productType" : "master-full-set",
  "colors" : ["#bc8f8f"]
},
"/uni/vic/monash/graduation-gown/master-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008068/Aus-Uni-Photos/Monash/monash-mast-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008070/Aus-Uni-Photos/Monash/monash-mast-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008068/Aus-Uni-Photos/Monash/monash-mast-it-back.jpg"],
  "metaTag" : "Buy your Monash University Master of Information Technology graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Master of Information Technology graduation gown set - Front view", "Monash University Master of Information Technology graduation gown set - Side view", "Monash University Master of Information Technology graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Master of Information Technology",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Master of Information Technology",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Spectrum Green",
  "colour" : "Lined with Spectrum Green",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30513",
  "productType" : "master-full-set",
  "colors" : ["green"]
},
"/uni/vic/monash/graduation-gown/master-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008072/Aus-Uni-Photos/Monash/monash-mast-science-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008073/Aus-Uni-Photos/Monash/monash-mast-science-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008071/Aus-Uni-Photos/Monash/monash-mast-science-back.jpg"],
  "metaTag" : "Buy your Monash University Master of Science graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Master of Science graduation gown set - Front view", "Monash University Master of Science graduation gown set - Side view", "Monash University Master of Science graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Master of Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Master of Science",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Primrose",
  "colour" : "Lined with Primrose",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30514",
  "productType" : "master-full-set",
  "colors" : ["#F3CF55"]
},
"/uni/vic/monash/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008040/Aus-Uni-Photos/Monash/monash-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008041/Aus-Uni-Photos/Monash/monash-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008039/Aus-Uni-Photos/Monash/monash-bach-arts-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Arts graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Arts graduation gown set - Front view", "Monash University Bachelor of Arts graduation gown set - Side view", "Monash University Bachelor of Arts graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Arts",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Old Rose",
  "colour" : "Lined with Old Rose",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30501",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(0,50%,50%)"]
},
"/uni/vic/monash/bachelor-of-business-and-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008043/Aus-Uni-Photos/Monash/monash-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008044/Aus-Uni-Photos/Monash/monash-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008042/Aus-Uni-Photos/Monash/monash-bach-bus-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Business & Economics graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Business & Economics graduation gown set - Front view", "Monash University Bachelor of Business & Economics graduation gown set - Side view", "Monash University Bachelor of Business & Economics graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Business & Economics",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Business & Economics",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Peacock Green",
  "colour" : "Lined with Peacock Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30502",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(162,100%,30%)"]
},
"/uni/vic/monash/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008046/Aus-Uni-Photos/Monash/monash-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008047/Aus-Uni-Photos/Monash/monash-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008045/Aus-Uni-Photos/Monash/monash-bach-edu-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Education graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Education graduation gown set - Front view", "Monash University Bachelor of Education graduation gown set - Side view", "Monash University Bachelor of Education graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Education",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Banana",
  "colour" : "Lined with Banana",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30503",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(51,71%,62%)"]
},
"/uni/vic/monash/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008049/Aus-Uni-Photos/Monash/monash-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008050/Aus-Uni-Photos/Monash/monash-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008048/Aus-Uni-Photos/Monash/monash-bach-engg-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Engineering graduation gown set - Front view", "Monash University Bachelor of Engineering graduation gown set - Side view", "Monash University Bachelor of Engineering graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Engineering",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Rose Beige",
  "colour" : "Lined with Rose Beige",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30504",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(359,76%,55%)"]
},
"/uni/vic/monash/bachelor-of-information-technology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008052/Aus-Uni-Photos/Monash/monash-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008053/Aus-Uni-Photos/Monash/monash-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008051/Aus-Uni-Photos/Monash/monash-bach-it-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Information Technology graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Information Technology graduation gown set - Front view", "Monash University Bachelor of Information Technology graduation gown set - Side view", "Monash University Bachelor of Information Technology graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Information Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Information Technology",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Spectrum Green",
  "colour" : "Lined with Spectrum Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30505",
  "productType" : "bachelor-full-set",
  "colors" : ["darkgreen"]
},
"/uni/vic/monash/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008055/Aus-Uni-Photos/Monash/monash-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008056/Aus-Uni-Photos/Monash/monash-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008054/Aus-Uni-Photos/Monash/monash-bach-law-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Law graduation gown set - Front view", "Monash University Bachelor of Law graduation gown set - Side view", "Monash University Bachelor of Law graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Law",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with White",
  "colour" : "Lined with White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30506",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/vic/monash/bachelor-of-medicine-nursing-and-health-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008058/Aus-Uni-Photos/Monash/monash-bach-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008059/Aus-Uni-Photos/Monash/monash-bach-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008057/Aus-Uni-Photos/Monash/monash-bach-med-back.jpg"],
  "metaTag" : "Buy your Monash University Bachelor of Medicine, Nursing & Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Monash University Bachelor of Medicine, Nursing & Health Sciences graduation gown set - Front view", "Monash University Bachelor of Medicine, Nursing & Health Sciences graduation gown set - Side view", "Monash University Bachelor of Medicine, Nursing & Health Sciences graduation gown set - Back view"],
  "name" : "Monash Uni Graduation Gown Set - Bachelor of Medicine, Nursing & Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Monash University Hood - Bachelor of Medicine, Nursing & Health Sciences",
  "hood-style" : "Turquoise Blue, Cambridge Style, Lined with Vitrix Blue",
  "colour" : "Lined with Vitrix Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30507",
  "productType" : "bachelor-full-set",
  "colors" : ["lightblue"]
},

// ISN

"/uni/vic/isn/bachelor-psychological-science-honors" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007661/Aus-Uni-Photos/ANU/anu-bach-arts-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007662/Aus-Uni-Photos/ANU/anu-bach-arts-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007660/Aus-Uni-Photos/ANU/anu-bach-arts-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Bachelor of Psychological Science Honors graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Bachelor of Psychological Science Honors graduation gown set - Front view",
    "ISN Psychology Bachelor of Psychological Science Honors graduation gown set - Side view",
    "ISN Psychology Bachelor of Psychological Science Honors graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Bachelor of Psychological Science Honors",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Bachelor of Psychological Science Honors",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "Blue sash",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30414",
  "productType" : "bachelor-full-set",
  "colors" : ["#1F5BBD"],
  "isIsn": true,
},
"/uni/vic/isn/graduate-diploma-psychological-studies" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008314/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008315/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008313/Aus-Uni-Photos/Unimelb/unimelb-mast-bus-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Graduate Diploma of Psychological Studies graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Graduate Diploma of Psychological Studies graduation gown set - Front view",
    "ISN Psychology Graduate Diploma of Psychological Studies graduation gown set - Side view",
    "ISN Psychology Graduate Diploma of Psychological Studies graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Graduate Diploma of Psychological Studies",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Graduate Diploma of Psychological Studies",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "Lined with Sky Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO30216",
  "productType" : "bachelor-full-set",
  "colors" : ["#87CEEB"],
  "isIsn": true,
},
"/uni/vic/isn/master-professional-psychology" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008444/Aus-Uni-Photos/unisa/unisa-bach-sci-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008445/Aus-Uni-Photos/unisa/unisa-bach-sci-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008443/Aus-Uni-Photos/unisa/unisa-bach-sci-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Master of Professional Psychology graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Master of Professional Psychology graduation gown set - Front view",
    "ISN Psychology Master of Professional Psychology graduation gown set - Side view",
    "ISN Psychology Master of Professional Psychology graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Master of Professional Psychology",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Master of Professional Psychology",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "Old Rose/pink sash",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO50210",
  "productType" : "master-full-set",
  "colors" : ["pink"],
  "isIsn": true,
},
"/uni/vic/isn/master-psychology-clinical" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008327/Aus-Uni-Photos/Unimelb/unimelb-mast-med-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008328/Aus-Uni-Photos/Unimelb/unimelb-mast-med-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008326/Aus-Uni-Photos/Unimelb/unimelb-mast-med-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Master of Psychology (Clinical) graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Master of Psychology (Clinical) graduation gown set - Front view",
    "ISN Psychology Master of Psychology (Clinical) graduation gown set - Side view",
    "ISN Psychology Master of Psychology (Clinical) graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Master of Psychology (Clinical)",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Master of Psychology (Clinical)",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "Claret sash",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30212",
  "productType" : "master-full-set",
  "colors" : ["#800000"],
  "isIsn": true,
},
"/uni/vic/isn/master-psychology-sport-exercise" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008321/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008322/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635008320/Aus-Uni-Photos/Unimelb/unimelb-mast-engg-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Master of Psychology (Sport & Exercise) graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Master of Psychology (Sport & Exercise) graduation gown set - Front view",
    "ISN Psychology Master of Psychology (Sport & Exercise) graduation gown set - Side view",
    "ISN Psychology Master of Psychology (Sport & Exercise) graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Master of Psychology (Sport & Exercise)",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Master of Psychology (Sport & Exercise)",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "Gold/yellow sash",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO30211",
  "productType" : "master-full-set",
  "colors" : ["yellow"],
  "isIsn": true,
},
"/uni/vic/isn/doctor-of-psychology" : {
  "photos" : [
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007731/Aus-Uni-Photos/AU/au-phd-set-front.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007732/Aus-Uni-Photos/AU/au-phd-set-side.jpg",
    "https://res.cloudinary.com/gowningstreet/image/upload/v1635007730/Aus-Uni-Photos/AU/au-phd-set-back.jpg"
  ],
  "metaTag" : "Buy your ISN Psychology Doctor of Psychology (Sport & Exercise, Clinical) graduation gown set from Gowning Street!",
  "altTags" : [
    "ISN Psychology Doctor of Psychology (Sport & Exercise, Clinical) graduation gown set - Front view",
    "ISN Psychology Doctor of Psychology (Sport & Exercise, Clinical) graduation gown set - Side view",
    "ISN Psychology Doctor of Psychology (Sport & Exercise, Clinical) graduation gown set - Back view"
  ],
  "name" : "ISN Psychology Graduation Gown Set - Doctor of Psychology",
  "price" : ISN_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "ISN Psychology University Hood - Doctor of Psychology (Sport & Exercise, Clinical)",
  "hood-style" : "ISN Psychology prescribed graduation hood",
  "colour" : "lined/trimmed with Scarlet",
  "gownStyle" : "PhD",
  "phdStyle" : "cloth",
  "level" : "PhD",
  "hood-code" : "HOO50121",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "yellow",
  "isIsn": true,
},




  // South Australia

//Uni of South Australia

"/uni/sa/unisa/bachelor-of-architecture-and-building" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008419/Aus-Uni-Photos/unisa/unisa-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008420/Aus-Uni-Photos/unisa/unisa-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008418/Aus-Uni-Photos/unisa/unisa-bach-arch-back.jpg"],
  "metaTag" : "Buy your UniSA bachelor of architecture and building graduation gown set from Gowning Street!",
  "altTags" : ["UniSA bachelor of architecture and building graduation gown set - Front view", "UniSA bachelor of architecture and building graduation gown set - Side view", "UniSA bachelor of architecture and building graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Architecture and Building",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Architecture and Building",
  "hood-style" : "Black, Oxford Style, Partially lined with Silver",
  "colour" : "Lined with Silver",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50205", //remember to change Coghlan name
  "productType" : "bachelor-full-set",
  "colors" : ["#DCE0E3"]
},
"/uni/sa/unisa/bachelor-of-society-and-culture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008447/Aus-Uni-Photos/unisa/unisa-bach-soc-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008448/Aus-Uni-Photos/unisa/unisa-bach-soc-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008446/Aus-Uni-Photos/unisa/unisa-bach-soc-back.jpg"],
  "metaTag" : "Buy your UniSA bachelor of society and culture graduation gown set from Gowning Street!",
  "altTags" : ["UniSA bachelor of society and culture graduation gown set - Front view", "UniSA bachelor of society and culture graduation gown set - Side view", "UniSA bachelor of society and culture graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Society and Culture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Society and Culture",
  "hood-style" : "Black, Oxford Style, lined with Yellow",
  "colour" : "Lined with Yellow",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50201",
  "productType" : "bachelor-full-set",
  "colors" : ["#F9E241"]
},
"/uni/sa/unisa/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008428/Aus-Uni-Photos/unisa/unisa-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008429/Aus-Uni-Photos/unisa/unisa-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008427/Aus-Uni-Photos/unisa/unisa-bach-edu-back.jpg"],
  "metaTag" : "Buy your UniSA bachelor of education graduation gown set from Gowning Street!",
  "altTags" : ["UniSA bachelor of education graduation gown set - Front view", "UniSA bachelor of education graduation gown set - Side view", "UniSA bachelor of education graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford Style, lined with Purple",
  "colour" : "Lined with Purple",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50204",
  "productType" : "bachelor-full-set",
  "colors" : ["#7B32B7"]
},
"/uni/sa/unisa/bachelor-of-natural-and-physical-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008444/Aus-Uni-Photos/unisa/unisa-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008445/Aus-Uni-Photos/unisa/unisa-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008443/Aus-Uni-Photos/unisa/unisa-bach-sci-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Natural & Physical Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Natural & Physical Sciences graduation gown set - Front view", "UniSA Bachelor of Natural & Physical Sciences graduation gown set - Side view", "UniSA Bachelor of Natural & Physical Sciences graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Natural & Physical Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Natural & Physical Sciences",
  "hood-style" : "Black, Oxford Style, lined with Pink",
  "colour" : "Lined with Pink",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50210",
  "productType" : "bachelor-full-set",
  "colors" : ["pink"]
},
"/uni/sa/unisa/bachelor-of-health-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008437/Aus-Uni-Photos/unisa/unisa-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008438/Aus-Uni-Photos/unisa/unisa-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008436/Aus-Uni-Photos/unisa/unisa-bach-health-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Health Sciences graduation gown set - Front view", "UniSA Bachelor of Health Sciences graduation gown set - Side view", "UniSA Bachelor of Health Sciences graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Health Sciences",
  "hood-style" : "Black, Oxford Style, lined with Red",
  "colour" : "Lined with Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50202",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/sa/unisa/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008431/Aus-Uni-Photos/unisa/unisa-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008432/Aus-Uni-Photos/unisa/unisa-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008430/Aus-Uni-Photos/unisa/unisa-bach-engg-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Engineering graduation gown set - Front view", "UniSA Bachelor of Engineering graduation gown set - Side view", "UniSA Bachelor of Engineering graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford Style, lined with Green",
  "colour" : "Lined with Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50203",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/sa/unisa/bachelor-of-information-technology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008441/Aus-Uni-Photos/unisa/unisa-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008442/Aus-Uni-Photos/unisa/unisa-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008439/Aus-Uni-Photos/unisa/unisa-bach-it-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Information Technology graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Information Technology graduation gown set - Front view", "UniSA Bachelor of Information Technology graduation gown set - Side view", "UniSA Bachelor of Information Technology graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Information Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Information Technology",
  "hood-style" : "Black, Oxford Style, lined with Beige",
  "colour" : "Lined with Beige",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50206",
  "productType" : "bachelor-full-set",
  "colors" : ["beige"]
},
"/uni/sa/unisa/bachelor-of-business-and-mgmt" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008425/Aus-Uni-Photos/unisa/unisa-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008426/Aus-Uni-Photos/unisa/unisa-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008424/Aus-Uni-Photos/unisa/unisa-bach-bus-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Business & Management graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Business & Management graduation gown set - Front view", "UniSA Bachelor of Business & Management graduation gown set - Side view", "UniSA Bachelor of Business & Management graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Business & Management",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Business & Management",
  "hood-style" : "Black, Oxford Style, lined with Burgundy",
  "colour" : "Lined with Burgundy",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50207",
  "productType" : "bachelor-full-set",
  "colors" : ["hsl(359,94%,32%)"]
},
"/uni/sa/unisa/bachelor-of-environmental-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008434/Aus-Uni-Photos/unisa/unisa-bach-env-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008435/Aus-Uni-Photos/unisa/unisa-bach-env-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008433/Aus-Uni-Photos/unisa/unisa-bach-env-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Environmental Science graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Environmental Science graduation gown set - Front view", "UniSA Bachelor of Environmental Science graduation gown set - Side view", "UniSA Bachelor of Environmental Science graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Environmental Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Environmental Science",
  "hood-style" : "Black, Oxford Style, lined with Orange",
  "colour" : "Lined with Orange",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50208",
  "productType" : "bachelor-full-set",
  "colors" : ["orange"]
},
"/uni/sa/unisa/bachelor-of-creative-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008422/Aus-Uni-Photos/unisa/unisa-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008423/Aus-Uni-Photos/unisa/unisa-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008421/Aus-Uni-Photos/unisa/unisa-bach-arts-back.jpg"],
  "metaTag" : "Buy your UniSA Bachelor of Creative Arts graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Bachelor of Creative Arts graduation gown set - Front view", "UniSA Bachelor of Creative Arts graduation gown set - Side view", "UniSA Bachelor of Creative Arts graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Bachelor of Creative Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Bachelor of Creative Arts",
  "hood-style" : "Black, Oxford Style, lined with Light Blue",
  "colour" : "Lined with Light Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50209",
  "productType" : "bachelor-full-set",
  "colors" : ["lightblue"]
},
"/uni/sa/unisa/masters-standard" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008451/Aus-Uni-Photos/unisa/unisa-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008452/Aus-Uni-Photos/unisa/unisa-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008450/Aus-Uni-Photos/unisa/unisa-mast-all-back.jpg"],
  "metaTag" : "Buy your UniSA Standard Masters Degrees graduation gown set from Gowning Street!",
  "altTags" : ["UniSA Standard Masters Degrees graduation gown set - Front view", "UniSA Standard Masters Degrees graduation gown set - Side view", "UniSA Standard Masters Degrees graduation gown set - Back view"],
  "name" : "UniSA Graduation Gown Set - Standard Masters Degrees",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of South Australia Hood - Standard Masters Degrees",
  "hood-style" : "Black, Oxford Style, lined with Blue, Gold",
  "colour" : "Lined with Blue, Gold",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO50211",
  "productType" : "bachelor-full-set",
  "colors" : ["blue", "gold"]
},

//uni of Adelaide

"/uni/sa/adelaide-uni/all-masters" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007727/Aus-Uni-Photos/AU/au-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007729/Aus-Uni-Photos/AU/au-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007727/Aus-Uni-Photos/AU/au-mast-all-back.jpg"],
  "metaTag" : "Buy your Adelaide University Masters graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University Masters graduation gown set - Front view", "Adelaide University Masters graduation gown set - Side view", "Adelaide University Masters graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Masters",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Masters",
  "hood-style" : "Black, Cambridge Style, Partially lined/trimmed with White",
  "colour" : "Partially lined/trimmed with White",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO50111",
  "productType" : "master-full-set",
  "colors" : ["white"]
},
"/uni/sa/adelaide-uni/bachelor-of-engineering-related-technologies" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479611/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479611/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479611/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-engg-back.jpg"],
  "metaTag" : "Buy your Adelaide University bachelor of computer science graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University bachelor of Engineering & Related Technologies graduation gown set - Front view", "Adelaide University bachelor of Engineering & Related Technologies graduation gown set - Side view", "Adelaide University bachelor of Engineering & Related Technologies graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Bachelor of Engineering & Related Technologies",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Bachelor of Engineering & Related Technologies (including Information Technology and Mathematics)",
  "hood-style" : "Black, Cambridge Style, Partially lined with True Purple",
  "colour" : "Partially Lined with True Purple",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50101",
  "productType" : "bachelor-full-set",
  "colors" : ["#993881"]
},
"/uni/sa/adelaide-uni/bachelor-of-business-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479383/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479383/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-bussiness-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479383/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-bussiness-back.jpg"],
  "metaTag" : "Buy your Adelaide University bachelor of Business (including Commerce) graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University bachelor of Business (including Commerce) graduation gown set - Front view", "Adelaide University bachelor of Business (including Commerce) graduation gown set - Side view", "Adelaide University bachelor of Business (including Commerce) graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Bachelor of Business (including Commerce)",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Bachelor of Business (including Commerce)",
  "hood-style" : "Black, Cambridge Style, Partially lined with Helvetia Blue",
  "colour" : "Partially Lined with Helvetia Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50102",
  "productType" : "bachelor-full-set",
  "colors" : ["#2460CA"]
},
"/uni/sa/adelaide-uni/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479258/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479258/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479258/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-sci-back.jpg"],
  "metaTag" : "Buy your Adelaide University bachelor of Natural & Physical Sciences (including Agricultural and Vetinary Science) graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University bachelor of Natural & Physical Sciences graduation gown set - Front view", "Adelaide University bachelor of Natural & Physical Sciences graduation gown set - Side view", "Adelaide University bachelor of Natural & Physical Sciences graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Bachelor of Natural & Physical Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Bachelor of Natural & Physical Sciences (incl. Agricultural and Vetinary Science)",
  "hood-style" : "Black, Cambridge Style, Partially lined with Primuline Yellow",
  "colour" : "Partially lined with Primuline Yellow",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50103",
  "productType" : "bachelor-full-set",
  "colors" : ["#FEDE2C"]
},
"/uni/sa/adelaide-uni/bachelor-of-health-medical-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479155/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479155/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479155/Uni%20Regalia%20Photos/Adelaide/adelaide-bach-med-back.jpg"],
  "metaTag" : "Buy your Adelaide University bachelor of Health & Medical Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University bachelor of Health & Medical Sciences graduation gown set - Front view", "Adelaide University bachelor of Health & Medical Sciences graduation gown set - Side view", "Adelaide University bachelor of Health & Medical Sciences graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Bachelor of Health & Medical Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Bachelor of Health & Medical Sciences",
  "hood-style" : "Black, Cambridge Style, edged with Eosin Pink.",
  "colour" : "Edged with Eosin Pink",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50104",
  "productType" : "bachelor-full-set",
  "colors" : ["#FF69B4"]
},
"/uni/sa/adelaide-uni/bachelor-of-law-soc-educ" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007718/Aus-Uni-Photos/AU/au-bach-law-educ-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007719/Aus-Uni-Photos/AU/au-bach-law-educ-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007717/Aus-Uni-Photos/AU/au-bach-law-educ-back.png"],
  "metaTag" : "Buy your Adelaide University bachelor of Society, Culture, Education & Law graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University bachelor of Society, Culture, Education & Law graduation gown set - Front view", "Adelaide University bachelor of Society, Culture, Education & Law graduation gown set - Side view", "Adelaide University bachelor of Society, Culture, Education & Law graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Bachelor of Society, Culture, Education & Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Adelaide Hood - Bachelor of Society, Culture, Education & Law",
  "hood-style" : "Black, Cambridge Style, edged with Pale Violet Gray",
  "colour" : "Edged with Pale Violet Gray",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50105",
  "productType" : "bachelor-full-set",
  "colors" : ["grey"]
},
"/uni/sa/adelaide-uni/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007731/Aus-Uni-Photos/AU/au-phd-set-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007732/Aus-Uni-Photos/AU/au-phd-set-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007730/Aus-Uni-Photos/AU/au-phd-set-back.jpg"],
  "metaTag" : "Buy your Adelaide University PhD graduation gown set from Gowning Street!",
  "altTags" : ["Adelaide University PhD graduation gown set - Front view", "Adelaide University PhD graduation gown set - Side view", "Adelaide University PhD graduation gown set - Back view"],
  "name" : "Adelaide Uni Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Adelaide University Hood - PhD",
  "hood-style" : "Scarlet hood, lined/trimmed with Scarlet",
  "colour" : "lined/trimmed with Scarlet",
  "gownStyle" : "PhD",
  "phdStyle" : "cloth",
  "level" : "PhD",
  "hood-code" : "HOO50121",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "yellow"
},

//Flinders

"/uni/sa/flinders/bachelor-of-buss-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592479024/Uni%20Regalia%20Photos/Flinders/flinders-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479024/Uni%20Regalia%20Photos/Flinders/flinders-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592479024/Uni%20Regalia%20Photos/Flinders/flinders-bach-law-back.jpg"],
  "metaTag" : "Buy your Flinders University Bachelor of Business, Law & Government graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Business, Law & Government graduation gown set - Front view", "Flinders University bachelor of Business, Law & Government graduation gown set - Side view", "Flinders University bachelor of Business, Law & Government graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Business, Law & Government",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Business, Law & Government",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Blue",
  "colour" : "Dark blue hood, Lined with white with a Blue edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50301",
  "productType" : "bachelor-full-set",
  "colors" : ["#00FFFF"]
},
"/uni/sa/flinders/bachelor-of-educ-soc-psych" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592478810/Uni%20Regalia%20Photos/Flinders/flinders-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592478810/Uni%20Regalia%20Photos/Flinders/flinders-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592478810/Uni%20Regalia%20Photos/Flinders/flinders-bach-educ-back.jpg"],
  "metaTag" : "Buy your Flinders University Bachelor of Education, Psychology & Social Work graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Education, Psychology & Social Work graduation gown set - Front view", "Flinders University bachelor of Education, Psychology & Social Work graduation gown set - Side view", "Flinders University bachelor of Education, Psychology & Social Work graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Education, Psychology & Social Work",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Education, Psychology & Social Work",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Orange",
  "colour" : "Dark blue hood, Lined with white with a Orange edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50302",
  "productType" : "bachelor-full-set",
  "colors" : ["orange"]
},
"/uni/sa/flinders/master-of-educ-soc-psych" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592470057/Uni%20Regalia%20Photos/Flinders/flinders-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470057/Uni%20Regalia%20Photos/Flinders/flinders-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470057/Uni%20Regalia%20Photos/Flinders/flinders-mast-educ-back.jpg"],
  "metaTag" : "Buy your Flinders University Master of Education, Psychology & Social Work graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University Master of Education, Psychology & Social Work graduation gown set - Front view", "Flinders University Master of Education, Psychology & Social Work graduation gown set - Side view", "Flinders University Master of Education, Psychology & Social Work graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Master of Education, Psychology & Social Work",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Master of Education, Psychology & Social Work",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Orange",
  "colour" : "Dark blue hood, Lined with white with a Orange edge",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO50311",
  "productType" : "master-full-set",
  "colors" : ["orange"]
},
"/uni/sa/flinders/bachelor-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592478700/Uni%20Regalia%20Photos/Flinders/flinders-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592478700/Uni%20Regalia%20Photos/Flinders/flinders-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592478700/Uni%20Regalia%20Photos/Flinders/flinders-bach-arts-back.jpg"],
  "metaTag" : "Buy your Flinders University Bachelor of Humanities, Arts & Social Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Humanities, Arts & Social Sciences graduation gown set - Front view", "Flinders University bachelor of Humanities, Arts & Social Sciences graduation gown set - Side view", "Flinders University bachelor of Humanities, Arts & Social Sciences graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Humanities, Arts & Social Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Humanities, Arts & Social Sciences",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Yellow",
  "colour" : "Dark blue hood, Lined with white with a Yellow edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50303",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFD700"]
},
"/uni/sa/flinders/bachelor-of-med-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007949/Aus-Uni-Photos/Flinders/flinders-bach-med-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007951/Aus-Uni-Photos/Flinders/flinders-bach-med-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007948/Aus-Uni-Photos/Flinders/flinders-bach-med-back.png"],
  "metaTag" : "Buy your Flinders University Bachelor of Medicine & Public Health graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Medicine & Public Health graduation gown set - Front view", "Flinders University bachelor of Medicine & Public Health graduation gown set - Side view", "Flinders University bachelor of Medicine & Public Health graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Medicine & Public Health",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Medicine & Public Health",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Green",
  "colour" : "Dark blue hood, Lined with white with a Green edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50304",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/sa/flinders/bachelor-of-nurse-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592472585/Uni%20Regalia%20Photos/Flinders/flinders-bach-nurse-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592472585/Uni%20Regalia%20Photos/Flinders/flinders-bach-nurse-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592472585/Uni%20Regalia%20Photos/Flinders/flinders-bach-nurse-back.jpg"],
  "metaTag" : "Buy your Flinders University Bachelor of Nursing & Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Nursing & Health Sciences graduation gown set - Front view", "Flinders University bachelor of Nursing & Health Sciences graduation gown set - Side view", "Flinders University bachelor of Nursing & Health Sciences graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Nursing & Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Nursing & Health Sciences",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Red",
  "colour" : "Dark blue hood, Lined with white a coloured piping of Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50305",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/sa/flinders/bachelor-of-sci-engg" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592472425/Uni%20Regalia%20Photos/Flinders/flinders-bach-sciengg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592472425/Uni%20Regalia%20Photos/Flinders/flinders-bach-sciengg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592472425/Uni%20Regalia%20Photos/Flinders/flinders-bach-sciengg-back.jpg"],
  "metaTag" : "Buy your Flinders University Bachelor of Science & Engineering graduation gown set from Gowning Street!",
  "altTags" : ["Flinders University bachelor of Science & Engineering graduation gown set - Front view", "Flinders University bachelor of Science & Engineering graduation gown set - Side view", "Flinders University bachelor of Science & Engineering graduation gown set - Back view"],
  "name" : "Flinders Uni Graduation Gown Set - Bachelor of Science & Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Flinders Hood - Bachelor of Science & Engineering",
  "hood-style" : "Dark Blue hood, lined with white and a coloured piping of Purple",
  "colour" : "Dark blue hood, Lined with white with a Purple edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO50306",
  "productType" : "bachelor-full-set",
  "colors" : [" #9932CC"]
},

//Tasmania

//Uni of Tasmania

"/uni/tas/utas/bachelor-of-arts-humanities" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008593/Aus-Uni-Photos/UTAS/utas-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008594/Aus-Uni-Photos/UTAS/utas-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008592/Aus-Uni-Photos/UTAS/utas-bach-arts-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of arts and humanities graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of arts humanities graduation gown set - Front view", "UTas bachelor of arts humanities graduation gown set - Side view", "UTas bachelor of arts humanities graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Arts, Humanities & Social Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Arts and Humanities",
  "hood-style" : "Black, Oxford Style, Partially lined with Dark Blue",
  "colour" : "Partially lined with Dark Blue",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30301",
  "productType" : "bachelor-full-set",
  "colors" : ["darkblue"]
},
"/uni/tas/utas/bachelor-of-biomedicine" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008596/Aus-Uni-Photos/UTAS/utas-bach-biom-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008597/Aus-Uni-Photos/UTAS/utas-bach-biom-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008595/Aus-Uni-Photos/UTAS/utas-bach-biom-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of biomedicine graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of biomedicine graduation gown set - Front view", "UTas bachelor of biomedicine graduation gown set - Side view", "UTas bachelor of biomedicine graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Biomedicine",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Biomedicine",
  "hood-style" : "Black, Oxford Style, Partially lined with Jacaranda",
  "colour" : "Partially lined with Jacaranda",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30304",
  "productType" : "bachelor-full-set",
  "colors" : ["#E3DEFA"]
},
"/uni/tas/utas/bachelor-of-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008599/Aus-Uni-Photos/UTAS/utas-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008600/Aus-Uni-Photos/UTAS/utas-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008598/Aus-Uni-Photos/UTAS/utas-bach-comm-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of commerce graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of commerce graduation gown set - Front view", "UTas bachelor of commerce graduation gown set - Side view", "UTas bachelor of commerce graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Commerce",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Commerce",
  "hood-style" : "Black, Oxford Style, Partially lined with Orange",
  "colour" : "Partially lined with Orange",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30302",
  "productType" : "bachelor-full-set",
  "colors" : ["#FE5001"]
},
"/uni/tas/utas/bachelor-of-psychology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008608/Aus-Uni-Photos/UTAS/utas-bach-psych-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008609/Aus-Uni-Photos/UTAS/utas-bach-psych-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008607/Aus-Uni-Photos/UTAS/utas-bach-psych-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of psychology graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of psychology graduation gown set - Front view", "UTas bachelor of psychology graduation gown set - Side view", "UTas bachelor of psychology graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Psychology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Psychology",
  "hood-style" : "Black, Oxford Style, Partially lined with White",
  "colour" : "Partially lined with White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30305",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/tas/utas/bachelor-of-fine-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592470717/Uni%20Regalia%20Photos/UTAS/utas-bach-fine-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470718/Uni%20Regalia%20Photos/UTAS/utas-bach-fine-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470717/Uni%20Regalia%20Photos/UTAS/utas-bach-fine-arts-back.jpg"],
  "metaTag" : "Buy your UTAS Bachelor of Fine Arts graduation gown set from Gowning Street!",
  "altTags" : ["UTas Bachelor of Fine Arts graduation gown set - Front view", "UTas Bachelor of Fine Arts graduation gown set - Side view", "UTas Bachelor of Fine Arts graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Fine Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Fine Arts",
  "hood-style" : "Black, Oxford Style, Partially lined with Satinwood",
  "colour" : "Partially lined with Satinwood",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30309",
  "productType" : "bachelor-full-set",
  "colors" : ["#b08d57"]
},
"/uni/tas/utas/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592470946/Uni%20Regalia%20Photos/UTAS/utas-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470946/Uni%20Regalia%20Photos/UTAS/utas-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592470946/Uni%20Regalia%20Photos/UTAS/utas-bach-engg-back.jpg"],
  "metaTag" : "Buy your UTAS Bachelor of Engineering graduation gown set from Gowning Street!",
  "altTags" : ["UTas Bachelor of Engineering graduation gown set - Front view", "UTas Bachelor of Engineering graduation gown set - Side view", "UTas Bachelor of Engineering graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford Style, Partially lined with Green",
  "colour" : "Partially lined with Green",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30308",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/tas/utas/bachelor-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592471158/Uni%20Regalia%20Photos/UTAS/utas-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592471158/Uni%20Regalia%20Photos/UTAS/utas-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592471158/Uni%20Regalia%20Photos/UTAS/utas-bach-arch-back.jpg"],
  "metaTag" : "Buy your UTAS Bachelor of Architecture graduation gown set from Gowning Street!",
  "altTags" : ["UTas Bachelor of Architecture graduation gown set - Front view", "UTas Bachelor of Architecture graduation gown set - Side view", "UTas Bachelor of Architecture graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Architecture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Architecture",
  "hood-style" : "Black, Oxford Style, Partially lined with Dark Pink",
  "colour" : "Partially lined with Dark Pink",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30307",
  "productType" : "bachelor-full-set",
  "colors" : ["#e75480"]
},
"/uni/tas/utas/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592471521/Uni%20Regalia%20Photos/UTAS/utas-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592471521/Uni%20Regalia%20Photos/UTAS/utas-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592471521/Uni%20Regalia%20Photos/UTAS/utas-bach-law-back.jpg"],
  "metaTag" : "Buy your UTAS Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["UTas Bachelor of Law graduation gown set - Front view", "UTas Bachelor of Law graduation gown set - Side view", "UTas Bachelor of Law graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Law",
  "hood-style" : "Black, Oxford Style, Partially lined with Crimson",
  "colour" : "Partially lined with Crimson",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30306",
  "productType" : "bachelor-full-set",
  "colors" : ["#7a0019"]
},
"/uni/tas/utas/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008602/Aus-Uni-Photos/UTAS/utas-bach-edu-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008603/Aus-Uni-Photos/UTAS/utas-bach-edu-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008601/Aus-Uni-Photos/UTAS/utas-bach-edu-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of education graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of education graduation gown set - Front view", "UTas bachelor of education graduation gown set - Side view", "UTas bachelor of education graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford Style, Partially lined with Light Blue",
  "colour" : "Partially lined with Light Blue",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30303",
  "productType" : "bachelor-full-set",
  "colors" : ["#9BCAD8"]
},
"/uni/tas/utas/bachelor-of-health-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008606/Aus-Uni-Photos/UTAS/utas-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008606/Aus-Uni-Photos/UTAS/utas-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008604/Aus-Uni-Photos/UTAS/utas-bach-health-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of health science graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of health science graduation gown set - Front view", "UTas bachelor of health science graduation gown set - Side view", "UTas bachelor of health science graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Health Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Health Science",
  "hood-style" : "Black, Oxford Style, Partially lined with Jacaranda",
  "colour" : "Partially lined with Jacaranda",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30304",
  "productType" : "bachelor-full-set",
  "colors" : ["#CEC8E4"]
},
"/uni/tas/utas/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008612/Aus-Uni-Photos/UTAS/utas-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008613/Aus-Uni-Photos/UTAS/utas-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008611/Aus-Uni-Photos/UTAS/utas-bach-sci-back.jpg"],
  "metaTag" : "Buy your UTAS bachelor of science graduation gown set from Gowning Street!",
  "altTags" : ["UTas bachelor of science graduation gown set - Front view", "UTas bachelor of science graduation gown set - Side view", "UTas bachelor of science graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Bachelor of Science",
  "hood-style" : "Black, Oxford Style, Partially lined with White",
  "colour" : "Partially lined with White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO30305",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/tas/utas/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008620/Aus-Uni-Photos/UTAS/utas-mast-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008621/Aus-Uni-Photos/UTAS/utas-mast-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008619/Aus-Uni-Photos/UTAS/utas-mast-educ-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Education graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Education graduation gown set - Front view", "UTas Master of Education graduation gown set - Side view", "UTas Master of Education graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Education",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Light Blue",
  "colour" : "Lined/trimmed lined with Light Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30311",
  "productType" : "master-full-set",
  "colors" : ["lightblue"]
},
"/uni/tas/utas/master-of-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008617/Aus-Uni-Photos/UTAS/utas-mast-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008618/Aus-Uni-Photos/UTAS/utas-mast-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008616/Aus-Uni-Photos/UTAS/utas-mast-comm-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Commerce graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Commerce graduation gown set - Front view", "UTas Master of Commerce graduation gown set - Side view", "UTas Master of Commerce graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Commerce",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Commerce",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Orange",
  "colour" : "Lined/trimmed lined with Orange",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30312",
  "productType" : "master-full-set",
  "colors" : ["#FE5001"]
},
"/uni/tas/utas/master-of-arts-humanities-social-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008623/Aus-Uni-Photos/UTAS/utas-mast-hums-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008624/Aus-Uni-Photos/UTAS/utas-mast-hums-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008622/Aus-Uni-Photos/UTAS/utas-mast-hums-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Arts, Humanities & Social Science graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Arts, Humanities & Social Science graduation gown set - Front view", "UTas Master of Arts, Humanities & Social Science graduation gown set - Side view", "UTas Master of Arts, Humanities & Social Science graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Arts, Humanities & Social Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Arts, Humanities & Social Science",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Dark Blue",
  "colour" : "Lined/trimmed lined with Dark Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30313",
  "productType" : "master-full-set",
  "colors" : ["darkblue"]
},
"/uni/tas/utas/master-of-science-comp-psych" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008626/Aus-Uni-Photos/UTAS/utas-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008627/Aus-Uni-Photos/UTAS/utas-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008625/Aus-Uni-Photos/UTAS/utas-mast-sci-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Science, Computing & Psychology graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Science, Computing & Psychology graduation gown set - Front view", "UTas Master of Science, Computing & Psychology graduation gown set - Side view", "UTas Master of Science, Computing & Psychology graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Science, Computing & Psychology",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Science, Computing & Psychology",
  "hood-style" : "Black, Oxford Style, lined/trimmed with White",
  "colour" : "Lined/trimmed lined with White",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30314",
  "productType" : "master-full-set",
  "colors" : ["white"]
},
"/uni/tas/utas/master-of-fine-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592468360/Uni%20Regalia%20Photos/UTAS/utas-mast-finearts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592468361/Uni%20Regalia%20Photos/UTAS/utas-mast-finearts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592468360/Uni%20Regalia%20Photos/UTAS/utas-mast-finearts-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Fine Arts graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Fine Arts graduation gown set - Front view", "UTas Master of Fine Arts graduation gown set - Side view", "UTas Master of Fine Arts graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Fine Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Fine Arts",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Satinwood",
  "colour" : "Lined/trimmed lined with Satinwood",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30318",
  "productType" : "master-full-set",
  "colors" : ["#b08d57"]
},
"/uni/tas/utas/master-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592469410/Uni%20Regalia%20Photos/UTAS/utas-mast-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469410/Uni%20Regalia%20Photos/UTAS/utas-mast-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469410/Uni%20Regalia%20Photos/UTAS/utas-mast-arch-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Architecture graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Architecture graduation gown set - Front view", "UTas Master of Architecture graduation gown set - Side view", "UTas Master of Architecture graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Architecture",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Architecture",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Dark Pink",
  "colour" : "Lined/trimmed lined with Dark Pink",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30317",
  "productType" : "master-full-set",
  "colors" : ["#e75480"]
},
"/uni/tas/utas/master-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592469371/Uni%20Regalia%20Photos/UTAS/utas-mast-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469371/Uni%20Regalia%20Photos/UTAS/utas-mast-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469370/Uni%20Regalia%20Photos/UTAS/utas-mast-law-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Law graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Law graduation gown set - Front view", "UTas Master of Law graduation gown set - Side view", "UTas Master of Law graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Law",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Law",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Crimson",
  "colour" : "Lined/trimmed lined with Crimson",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30316",
  "productType" : "master-full-set",
  "colors" : ["#DC143C"]
},
"/uni/tas/utas/master-of-nursing" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592469631/Uni%20Regalia%20Photos/UTAS/utas-mast-nursing-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469631/Uni%20Regalia%20Photos/UTAS/utas-mast-nursing-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592469631/Uni%20Regalia%20Photos/UTAS/utas-mast-nursing-back.jpg"],
  "metaTag" : "Buy your UTAS Master of Nursing graduation gown set from Gowning Street!",
  "altTags" : ["UTas Master of Nursing graduation gown set - Front view", "UTas Master of Nursing graduation gown set - Side view", "UTas Master of Nursing graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Master of Nursing",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Tasmania Hood - Master of Nursing",
  "hood-style" : "Black, Oxford Style, lined/trimmed with Blue-Green",
  "colour" : "Lined/trimmed lined with Blue-Green",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO30315",
  "productType" : "master-full-set",
  "colors" : ["#00868B"]
},
"/uni/tas/utas/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008629/Aus-Uni-Photos/UTAS/utas-phd-set-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008630/Aus-Uni-Photos/UTAS/utas-phd-set-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008628/Aus-Uni-Photos/UTAS/utas-phd-set-back.png"],
  "metaTag" : "Buy your UTAS PhD graduation gown set from Gowning Street!",
  "altTags" : ["UTAS PhD graduation gown set - Front view", "UTAS PhD graduation gown set - Side view", "UTAS PhD graduation gown set - Back view"],
  "name" : "UTAS Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "UTAS Hood - PhD",
  "hood-style" : "Scarlet hood, lined/trimmed with Scarlett",
  "colour" : "lined/trimmed with Scarlet",
  "gownStyle" : "PhD",
  "phdStyle" : "red satin",
  "level" : "PhD",
  "hood-code" : "HOO30221",
  "productType" : "phd-full-set",
  "colors" : ["red"],
  "hatType" : "red"
},

//ANU
"/uni/act/anu/bachelor-of-arts-and-social-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007661/Aus-Uni-Photos/ANU/anu-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007662/Aus-Uni-Photos/ANU/anu-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007660/Aus-Uni-Photos/ANU/anu-bach-arts-back.jpg"],
  "metaTag" : "Buy your ANU bachelor of arts and social sciences graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of arts and social sciences graduation gown set - Front view", "ANU bachelor of arts and social sciences graduation gown set - Side view", "ANU bachelor of arts and social sciences graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Arts & Social Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Arts & Social Sciences",
  "hood-style" : "Black, Oxford-style, edged with Union Jack Blue",
  "colour" : "Edged with Union Jack Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80203",
  "productType" : "bachelor-full-set",
  "colors" : ["#1F5BBD"]
},
"/uni/act/anu/bachelor-of-asia-and-pacific" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007664/Aus-Uni-Photos/ANU/anu-bach-asia-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007665/Aus-Uni-Photos/ANU/anu-bach-asia-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007663/Aus-Uni-Photos/ANU/anu-bach-asia-back.png"],
  "metaTag" : "Buy your ANU bachelor of Asia and Pacific graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of Asia and Pacific graduation gown set - Front view", "ANU bachelor of Asia and Pacific graduation gown set - Side view", "ANU bachelor of arts and social sciences graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Asia and Pacific",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Asia & Pacific",
  "hood-style" : "Black, Oxford-style, edged with Terracotta",
  "colour" : "Edged with Terracotta",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80208",
  "productType" : "bachelor-full-set",
  "colors" : ["orange"]
},
"/uni/act/anu/bachelor-of-business-and-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007667/Aus-Uni-Photos/ANU/anu-bach-busseco-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007668/Aus-Uni-Photos/ANU/anu-bach-busseco-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007666/Aus-Uni-Photos/ANU/anu-bach-busseco-back.jpg"],
  "metaTag" : "Buy your ANU bachelor of business and economics graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of business and economics graduation gown set - Front view", "ANU bachelor of business and economics graduation gown set - Side view", "ANU bachelor of business and economics graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Business & Economics",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Business & Economics",
  "hood-style" : "Black, Oxford-style, edged with Gold",
  "colour" : "Edged with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80202",
  "productType" : "bachelor-full-set",
  "colors" : ["#F1C74F"]
},
"/uni/act/anu/bachelor-of-engineering-and-computer-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007670/Aus-Uni-Photos/ANU/anu-bach-engg-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007671/Aus-Uni-Photos/ANU/anu-bach-engg-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007669/Aus-Uni-Photos/ANU/anu-bach-engg-back.png"],
  "metaTag" : "Buy your ANU bachelor of engineering and computer science graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of engineering and computer science graduation gown set - Front view", "ANU bachelor of engineering and computer science graduation gown set - Side view", "ANU bachelor of engineering and computer science graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Engineering & Computer Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Engineering & Computer Science",
  "hood-style" : "Black, Oxford-style, edged with Claret",
  "colour" : "Edged with Claret",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80204",
  "productType" : "bachelor-full-set",
  "colors" : ["#CB2A3F"]
},
"/uni/act/anu/bachelor-of-medicine" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007676/Aus-Uni-Photos/ANU/anu-bach-med-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007677/Aus-Uni-Photos/ANU/anu-bach-med-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007675/Aus-Uni-Photos/ANU/anu-bach-med-back.png"],
  "metaTag" : "Buy your ANU bachelor of medicine graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of medicine graduation gown set - Front view", "ANU bachelor of medicine graduation gown set - Side view", "ANU bachelor of medicine graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Medicine",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Medicine",
  "hood-style" : "Black, Oxford-style, edged with Turquoise",
  "colour" : "Edged with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80205",
  "productType" : "bachelor-full-set",
  "colors" : ["#009BC8"]
},
"/uni/act/anu/bachelor-of-natural-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007682/Aus-Uni-Photos/ANU/anu-bach-sci-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007683/Aus-Uni-Photos/ANU/anu-bach-sci-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007681/Aus-Uni-Photos/ANU/anu-bach-sci-back.png"],
  "metaTag" : "Buy your ANU bachelor of natural sciences graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor of natural sciences graduation gown set - Front view", "ANU bachelor of natural sciences graduation gown set - Side view", "ANU bachelor of natural sciences graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Natural Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Natural Sciences",
  "hood-style" : "Black, Oxford-style, edged with Green",
  "colour" : "Edged with Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80206",
  "productType" : "bachelor-full-set",
  "colors" : ["#039D74"]
},
"/uni/act/anu/bachelor-multidisciplinary" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007679/Aus-Uni-Photos/ANU/anu-bach-multi-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007680/Aus-Uni-Photos/ANU/anu-bach-multi-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007678/Aus-Uni-Photos/ANU/anu-bach-multi-back.jpg"],
  "metaTag" : "Buy your ANU multidisciplinary bachelor degree graduation gown set from Gowning Street!",
  "altTags" : ["ANU bachelor-multidisciplinary graduation gown set - Front view", "ANU bachelor-multidisciplinary graduation gown set - Side view", "ANU bachelor-multidisciplinary graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor - Multidisciplinary",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor - Multidisciplinary",
  "hood-style" : "Black, Oxford-style, edged with White",
  "colour" : "Edged with White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80207",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/act/anu/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007673/Aus-Uni-Photos/ANU/anu-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007674/Aus-Uni-Photos/ANU/anu-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007672/Aus-Uni-Photos/ANU/anu-bach-law-back.png"],
  "metaTag" : "Buy your ANU Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["ANU Bachelor of Law gown set - Front view", "ANU Bachelor of Law gown set - Side view", "ANU Bachelor of Law gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Bachelor of Law",
  "hood-style" : "Black, Oxford-style, edged with Purple",
  "colour" : "Edged with Purple",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80209",
  "productType" : "bachelor-full-set",
  "colors" : ["purple"]
},
"/uni/act/anu/master-of-arts-and-social-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007686/Aus-Uni-Photos/ANU/anu-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007687/Aus-Uni-Photos/ANU/anu-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007685/Aus-Uni-Photos/ANU/anu-mast-arts-back.jpg"],
  "metaTag" : "Buy your ANU master of arts and social sciences graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of arts and social sciences graduation gown set - Front view", "ANU master of arts and social sciences graduation gown set - Side view", "ANU master of arts and social sciences graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Arts & Social Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Arts & Social Sciences",
  "hood-style" : "Black, Oxford-style, lined with Union Jack Blue",
  "colour" : "Lined with Union Jack Blue",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80214",
  "productType" : "master-full-set",
  "colors" : ["#255FC2"]
},
"/uni/act/anu/master-of-business-and-economics" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007692/Aus-Uni-Photos/ANU/anu-mast-busseco-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007693/Aus-Uni-Photos/ANU/anu-mast-busseco-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007691/Aus-Uni-Photos/ANU/anu-mast-busseco-back.jpg"],
  "metaTag" : "Buy your ANU master of business and economics graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of business and economics graduation gown set - Front view", "ANU master of business and economics graduation gown set - Side view", "ANU master of business and economics graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Business and Economics",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Business and Economics",
  "hood-style" : "Black, Oxford-style, lined with Gold",
  "colour" : "Lined with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80212",
  "productType" : "master-full-set",
  "colors" : ["#F1C74F"]
},
"/uni/act/anu/master-of-engineering-and-computer-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007695/Aus-Uni-Photos/ANU/anu-mast-engg-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007696/Aus-Uni-Photos/ANU/anu-mast-engg-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007694/Aus-Uni-Photos/ANU/anu-mast-engg-back.png"],
  "metaTag" : "Buy your ANU master of engineering and computer science graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of engineering and computer science graduation gown set - Front view", "ANU master of engineering and computer science graduation gown set - Side view", "ANU master of engineering and computer science graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Engineering & Computer Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Engineering & Computer Science",
  "hood-style" : "Black, Oxford-style, lined with Claret",
  "colour" : "Lined with Claret",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80213",
  "productType" : "master-full-set",
  "colors" : ["#CB2A3F"]
},
"/uni/act/anu/master-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007698/Aus-Uni-Photos/ANU/anu-mast-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007699/Aus-Uni-Photos/ANU/anu-mast-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007697/Aus-Uni-Photos/ANU/anu-mast-law-back.png"],
  "metaTag" : "Buy your ANU master of law graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of law graduation gown set - Front view", "ANU master of law graduation gown set - Side view", "ANU master of law graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Law",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Law",
  "hood-style" : "Black, Oxford-style, lined with Purple",
  "colour" : "Lined with Purple",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80211",
  "productType" : "master-full-set",
  "colors" : ["purple"]
},
"/uni/act/anu/master-of-medicine" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007701/Aus-Uni-Photos/ANU/anu-mast-med-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007702/Aus-Uni-Photos/ANU/anu-mast-med-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007700/Aus-Uni-Photos/ANU/anu-mast-med-back.png"],
  "metaTag" : "Buy your ANU master of medicine graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of medicine graduation gown set - Front view", "ANU master of medicine graduation gown set - Side view", "ANU master of medicine graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Medicine & Health Studies",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Medicine & Health Studies",
  "hood-style" : "Black, Oxford-style, lined with Turquoise",
  "colour" : "Lined with Turquoise",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80215",
  "productType" : "master-full-set",
  "colors" : ["#009BC8"]
},
"/uni/act/anu/master-of-natural-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007708/Aus-Uni-Photos/ANU/anu-mast-sci-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007708/Aus-Uni-Photos/ANU/anu-mast-sci-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007707/Aus-Uni-Photos/ANU/anu-mast-sci-back.png"],
  "metaTag" : "Buy your ANU master of natural sciences graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of natural sciences graduation gown set - Front view", "ANU master of natural sciences graduation gown set - Side view", "ANU master of natural sciences graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Natural Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Natural Sciences",
  "hood-style" : "Black, Oxford-style, lined with Green",
  "colour" : "Lined with Green",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80216",
  "productType" : "master-full-set",
  "colors" : ["#039D74"]
},
"/uni/act/anu/master-of-asia-pacific" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007689/Aus-Uni-Photos/ANU/anu-mast-asia-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007690/Aus-Uni-Photos/ANU/anu-mast-asia-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007688/Aus-Uni-Photos/ANU/anu-mast-asia-back.png"],
  "metaTag" : "Buy your ANU master of Asia and Pacific graduation gown set from Gowning Street!",
  "altTags" : ["ANU master of Asia and Pacific graduation gown set - Front view", "ANU master of Asia and Pacific graduation gown set - Side view", "ANU master of Asia and Pacific graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master of Asia and Pacific",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master of Asia and Pacific",
  "hood-style" : "Black, Oxford-style, lined with Terracotta",
  "colour" : "Lined with Terracotta",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80217",
  "productType" : "master-full-set",
  "colors" : ["orange"]
},
"/uni/act/anu/master-multidisciplinary" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007705/Aus-Uni-Photos/ANU/anu-mast-multi-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007706/Aus-Uni-Photos/ANU/anu-mast-multi-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007704/Aus-Uni-Photos/ANU/anu-mast-multi-back.jpg"],
  "metaTag" : "Buy your ANU multidisciplinary master degree graduation gown set from Gowning Street!",
  "altTags" : ["ANU master-multidisciplinary graduation gown set - Front view", "ANU master-multidisciplinary graduation gown set - Side view", "ANU master-multidisciplinary graduation gown set - Back view"],
  "name" : "ANU Graduation Gown Set - Master - Multidisciplinary",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian National University Hood - Master - Multidisciplinary",
  "hood-style" : "Black, Oxford-style, lined with White",
  "colour" : "Edged with White",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO20112",
  "productType" : "master-full-set",
  "colors" : ["white"]
},

//University of Canberra
"/uni/act/uc/all-masters" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008230/Aus-Uni-Photos/UC/uc-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008231/Aus-Uni-Photos/UC/uc-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008229/Aus-Uni-Photos/UC/uc-mast-all-back.jpg"],
  "metaTag" : "Buy your University of Canberra Masters graduation gown set from Gowning Street!",
  "altTags" : ["University of Canberra Masters graduation gown set - Front view", "University of Canberra Masters graduation gown set - Side view", "University of Canberra Masters graduation gown set - Back view"],
  "name" : "University of Canberra Graduation Gown Set - Masters",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Canberra Hood - Masters",
  "hood-style" : "Royal Blue, Cambridge-style, fully lined with Horizon",
  "colour" : "Fully lined/trimmed with Horizon",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80111",
  "productType" : "masters-full-set",
  "colors" : ["#00FFFF"]
},
"/uni/act/uc/bachelor-3-years" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008222/Aus-Uni-Photos/UC/uc-bach-3-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008223/Aus-Uni-Photos/UC/uc-bach-3-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008222/Aus-Uni-Photos/UC/uc-bach-3-back.jpg"],
  "metaTag" : "Buy your UC 3 and 3.5 year bachelor degree graduation gown set from Gowning Street!",
  "altTags" : ["UC 3 and 3 and a half year bachelor degree graduation gown set - Front view", "UC 3 and 3 and a half year bachelor degree graduation gown set - Side view", "UC 3 and 3 and a half year bachelor degree graduation gown set - Back view"],
  "name" : "University of Canberra Graduation Gown Set - Bachelor - 3 & 3.5 Years",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Canberra Hood - Bachelor 3 & 3.5 Years",
  "hood-style" : "Black, Cambridge-style, lined with Red",
  "colour" : "Lined with Red",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80102",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/act/uc/bachelor-4-years" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635008226/Aus-Uni-Photos/UC/uc-bach-4-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008227/Aus-Uni-Photos/UC/uc-bach-4-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635008225/Aus-Uni-Photos/UC/uc-bach-4-back.jpg"],
  "metaTag" : "Buy your UC 4 year bachelor degree graduation gown set from Gowning Street!",
  "altTags" : ["UC 4 year bachelor degree graduation gown set - Front view", "UC 4 year bachelor degree graduation gown set - Side view", "UC 4 year bachelor degree graduation gown set - Back view"],
  "name" : "University of Canberra Graduation Gown Set - Bachelor - 4 Years",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Canberra Hood - Bachelor 4 Years",
  "hood-style" : "Black, Cambridge-style, lined with Maroon",
  "colour" : "Lined with Maroon",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80101",
  "productType" : "bachelor-full-set",
  "colors" : ["maroon"]
},

// JCU

"/uni/qld/jcu/bachelor-standard" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007964/Aus-Uni-Photos/JCU/jcu-bach-standard-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007965/Aus-Uni-Photos/JCU/jcu-bach-standard-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007963/Aus-Uni-Photos/JCU/jcu-bach-standard-back.jpg"],
  "metaTag" : "Buy your JCU standard bachelor degree graduation gown set from Gowning Street!",
  "altTags" : ["JCU standard bachelor degree graduation gown set - Front view", "JCU standard bachelor degree graduation gown set - Side view", "JCU standard bachelor degree graduation gown set - Back view"],
  "name" : "JCU Graduation Gown Set - Bachelor (Standard)",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "James Cook University Hood - Bachelor (Standard)",
  "hood-style" : "Black, Oxford-style, lined with Blue",
  "colour" : "Lined with Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80301",
  "productType" : "bachelor-full-set",
  "colors" : ["#005FCA"]
},
"/uni/qld/jcu/bachelor-honours" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007961/Aus-Uni-Photos/JCU/jcu-bach-hons-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007962/Aus-Uni-Photos/JCU/jcu-bach-hons-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007960/Aus-Uni-Photos/JCU/jcu-bach-hons-back.jpg"],
  "metaTag" : "Buy your JCU Masters degree graduation gown set from Gowning Street!",
  "altTags" : ["JCU honours bachelor degree graduation gown set - Front view", "JCU honours bachelor degree graduation gown set - Side view", "JCU honours bachelor degree graduation gown set - Back view"],
  "name" : "JCU Graduation Gown Set - Bachelor (Honours)",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "James Cook University Hood - Bachelor (Honours)",
  "hood-style" : "Black, Oxford-style, lined with Blue and Gold",
  "colour" : "Lined with Blue and Gold",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO80201", //NOTE - This has been changed from HOO80302, which is actually full blue
  "productType" : "bachelor-full-set",
  "colors" : ["#FAC748", "#005FCA"]
},
"/uni/qld/jcu/masters-all" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007968/Aus-Uni-Photos/JCU/jcu-mast-all-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007969/Aus-Uni-Photos/JCU/jcu-mast-all-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007967/Aus-Uni-Photos/JCU/jcu-mast-all-back.jpg"],
  "metaTag" : "Buy your JCU Masters degree graduation gown set from Gowning Street!",
  "altTags" : ["JCU Masters degree graduation gown set - Front view", "JCU Masters degree graduation gown set - Side view", "JCU Masters degree graduation gown set - Back view"],
  "name" : "JCU Graduation Gown Set - Masters",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "James Cook University Hood - Masters",
  "hood-style" : "Black, Oxford-style, fully lined with gold satin",
  "colour" : "Fully lined with Gold Satin",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO80311",
  "productType" : "master-full-set",
  "colors" : ["gold"]
},

//Bond Uni
"/uni/qld/bond/graduation-gown/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007737/Aus-Uni-Photos/BOND/bond-bach-business-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007738/Aus-Uni-Photos/BOND/bond-bach-business-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007736/Aus-Uni-Photos/BOND/bond-bach-business-back.png"],
  "metaTag" : "Buy your Bond University Bachelor of Business graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Business graduation gown set - Front view", "Bond University Bachelor of Business graduation gown set - Side view", "Bond University Bachelor of Business graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Business",
  "hood-style" : "Black, Cambridge-style, lined with Buttercup",
  "colour" : "Lined with Buttercup",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70301",
  "productType" : "bachelor-full-set",
  "colors" : ["gold"]
},
"/uni/qld/bond/graduation-gown/bachelor-of-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007740/Aus-Uni-Photos/BOND/bond-bach-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007742/Aus-Uni-Photos/BOND/bond-bach-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007739/Aus-Uni-Photos/BOND/bond-bach-health-back.jpg"],
  "metaTag" : "Buy your Bond University Bachelor of Health Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Health Sciences graduation gown set - Front view", "Bond University Bachelor of Health Sciences graduation gown set - Side view", "Bond University Bachelor of Health Sciences graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Health Sciences",
  "hood-style" : "Black, Cambridge-style, lined with Green",
  "colour" : "Lined with Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70302",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/qld/bond/graduation-gown/bachelor-of-humanities-and-scoial-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007744/Aus-Uni-Photos/BOND/bond-bach-hums-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007745/Aus-Uni-Photos/BOND/bond-bach-hums-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007743/Aus-Uni-Photos/BOND/bond-bach-hums-back.jpg"],
  "metaTag" : "Buy your Bond University Bachelor of Humanities & Social Sciences graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Humanities & Social Sciences graduation gown set - Front view", "Bond University Bachelor of Humanities & Social Sciences graduation gown set - Side view", "Bond University Bachelor of Humanities & Social Sciences graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Humanities & Social Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Humanities & Social Sciences",
  "hood-style" : "Black, Cambridge-style, lined with White",
  "colour" : "Lined with White",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70303",
  "productType" : "bachelor-full-set",
  "colors" : ["white"]
},
"/uni/qld/bond/graduation-gown/bachelor-of-information-technology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007747/Aus-Uni-Photos/BOND/bond-bach-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007748/Aus-Uni-Photos/BOND/bond-bach-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007746/Aus-Uni-Photos/BOND/bond-bach-it-back.jpg"],
  "metaTag" : "Buy your Bond University Bachelor of Information Technology graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Information Technology graduation gown set - Front view", "Bond University Bachelor of Information Technology graduation gown set - Side view", "Bond University Bachelor of Information Technology graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Information Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Information Technology",
  "hood-style" : "Black, Cambridge-style, lined with Cherry",
  "colour" : "Lined with Cherry",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70304",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/qld/bond/graduation-gown/bachelor-of-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007750/Aus-Uni-Photos/BOND/bond-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007751/Aus-Uni-Photos/BOND/bond-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007749/Aus-Uni-Photos/BOND/bond-bach-law-back.jpg"],
  "metaTag" : "Buy your Bond University Bachelor of Law graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Law graduation gown set - Front view", "Bond University Bachelor of Law graduation gown set - Side view", "Bond University Bachelor of Law graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Law",
  "hood-style" : "Black, Cambridge-style, lined with Lilac",
  "colour" : "Lined with Lilac",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70305",
  "productType" : "bachelor-full-set",
  "colors" : ["#c8a2c8"]
},
"/uni/qld/bond/graduation-gown/bachelor-of-architecture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007734/Aus-Uni-Photos/BOND/bond-bach-arch-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007735/Aus-Uni-Photos/BOND/bond-bach-arch-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007733/Aus-Uni-Photos/BOND/bond-bach-arch-back.jpg"],
  "metaTag" : "Buy your Bond University Bachelor of Sustainable Development & Architecture graduation gown set from Gowning Street!",
  "altTags" : ["Bond University Bachelor of Sustainable Development & Architecture graduation gown set - Front view", "Bond University Bachelor of Sustainable Development & Architecture graduation gown set - Side view", "Bond University Bachelor of Sustainable Development & Architecture graduation gown set - Back view"],
  "name" : "Bond Graduation Gown Set - Bachelor of Sustainable Development & Architecture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Bond University Hood - Bachelor of Sustainable Development & Architecture",
  "hood-style" : "Black, Cambridge-style, lined with Aqua",
  "colour" : "Lined with Aqua",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO70306",
  "productType" : "bachelor-full-set",
  "colors" : ["#00D4FF"]
},




//Western Australia

//Edith Cowan
"/uni/wa/ecu/bachelor-of-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007892/Aus-Uni-Photos/ECU/ecu-bach-sci-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007894/Aus-Uni-Photos/ECU/ecu-bach-sci-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007890/Aus-Uni-Photos/ECU/ecu-bach-sci-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Sciences, Agriculture & Environments degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Sciences, Agriculture & Environments graduation gown set - Front view", "Edith Cowan University Bachelor of Sciences, Agriculture & Environments graduation gown set - Side view", "Edith Cowan University Bachelor of Sciences, Agriculture & Environments graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Sciences, Agriculture & Environments",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Sciences, Agriculture & Environments",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Emerald Green, White",
  "colour" : "lined/trimmed with Emerald Green, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90201",
  "productType" : "bachelor-full-set",
  "colors" : ["#13AE94"]
},
"/uni/wa/ecu/bachelor-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007878/Aus-Uni-Photos/ECU/ecu-bach-edu-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007879/Aus-Uni-Photos/ECU/ecu-bach-edu-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007877/Aus-Uni-Photos/ECU/ecu-bach-edu-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Education degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Education graduation gown set - Front view", "Edith Cowan University Bachelor of Education graduation gown set - Side view", "Edith Cowan University Bachelor of Education graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Sky Blue, White",
  "colour" : "lined/trimmed with Sky Blue, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90202",
  "productType" : "bachelor-full-set",
  "colors" : ["#6694FA"]
},
"/uni/wa/ecu/bachelor-of-creative-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007871/Aus-Uni-Photos/ECU/ecu-bach-arts-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007872/Aus-Uni-Photos/ECU/ecu-bach-arts-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007870/Aus-Uni-Photos/ECU/ecu-bach-arts-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Creative Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Creative Arts graduation gown set - Front view", "Edith Cowan University Bachelor of Creative Arts graduation gown set - Side view", "Edith Cowan University Bachelor of Creative Arts graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Creative Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Creative Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Peacock Blue, White",
  "colour" : "lined/trimmed with Peacock Blue, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90203",
  "productType" : "bachelor-full-set",
  "colors" : ["#1337AE"]
},
"/uni/wa/ecu/bachelor-of-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007884/Aus-Uni-Photos/ECU/ecu-bach-health-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007885/Aus-Uni-Photos/ECU/ecu-bach-health-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007883/Aus-Uni-Photos/ECU/ecu-bach-health-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Health degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Health graduation gown set - Front view", "Edith Cowan University Bachelor of Health graduation gown set - Side view", "Edith Cowan University Bachelor of Health graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Health",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Health",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Ruby, White",
  "colour" : "lined/trimmed with Ruby, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90204",
  "productType" : "bachelor-full-set",
  "colors" : ["#E0115F"]
},
"/uni/wa/ecu/bachelor-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007887/Aus-Uni-Photos/ECU/ecu-bach-it-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007888/Aus-Uni-Photos/ECU/ecu-bach-it-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007886/Aus-Uni-Photos/ECU/ecu-bach-it-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Information Technology degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Information Technology graduation gown set - Front view", "Edith Cowan University Bachelor of Information Technology graduation gown set - Side view", "Edith Cowan University Bachelor of Information Technology graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Information Technology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Information Technology",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Purple, White",
  "colour" : "lined/trimmed with Royal Purple, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90205",
  "productType" : "bachelor-full-set",
  "colors" : ["#7E00C5"]
},
"/uni/wa/ecu/bachelor-of-society-and-culture" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007896/Aus-Uni-Photos/ECU/ecu-bach-soc-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007897/Aus-Uni-Photos/ECU/ecu-bach-soc-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007895/Aus-Uni-Photos/ECU/ecu-bach-soc-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Society & Culture degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Society & Culture graduation gown set - Front view", "Edith Cowan University Bachelor of Society & Culture graduation gown set - Side view", "Edith Cowan University Bachelor of Society & Culture graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Society & Culture",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Society & Culture",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Blue, White",
  "colour" : "lined/trimmed with Royal Blue, White",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90206",
  "productType" : "bachelor-full-set",
  "colors" : ["#3C00C5"]
},
"/uni/wa/ecu/bachelor-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007881/Aus-Uni-Photos/ECU/ecu-bach-engg-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007882/Aus-Uni-Photos/ECU/ecu-bach-engg-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007880/Aus-Uni-Photos/ECU/ecu-bach-engg-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Engineering degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Engineering graduation gown set - Front view", "Edith Cowan University Bachelor of Engineering graduation gown set - Side view", "Edith Cowan University Bachelor of Engineering graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Gold",
  "colour" : "lined/trimmed with Gold",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90207",
  "productType" : "bachelor-full-set",
  "colors" : ["#EBC523"]
},
"/uni/wa/ecu/bachelor-of-management-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007875/Aus-Uni-Photos/ECU/ecu-bach-comm-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007876/Aus-Uni-Photos/ECU/ecu-bach-comm-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007873/Aus-Uni-Photos/ECU/ecu-bach-comm-back.png"],
  "metaTag" : "Buy your Edith Cowan University Bachelor of Management & Commerce degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Bachelor of Management & Commerce graduation gown set - Front view", "Edith Cowan University Bachelor of Management & Commerce graduation gown set - Side view", "Edith Cowan University Bachelor of Management & Commerce graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Bachelor of Management & Commerce",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Bachelor of Management & Commerce",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Tuscan Yellow",
  "colour" : "lined/trimmed with Tuscan Yellow",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO90208",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFF600"]
},
"/uni/wa/ecu/master-of-sciences" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007918/Aus-Uni-Photos/ECU/ecu-mast-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007919/Aus-Uni-Photos/ECU/ecu-mast-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007919/Aus-Uni-Photos/ECU/ecu-mast-sci-side.jpg"],
  "metaTag" : "Buy your Edith Cowan University Master of Sciences, Agriculture & Environments degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Sciences, Agriculture & Environments graduation gown set - Front view", "Edith Cowan University Master of Sciences, Agriculture & Environments graduation gown set - Side view", "Edith Cowan University Master of Sciences, Agriculture & Environments graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Sciences, Agriculture & Environments",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Sciences, Agriculture & Environments",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Emerald Green",
  "colour" : "lined/trimmed with Emerald Green",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90211",
  "productType" : "master-full-set",
  "colors" : ["#50C878"]
},
"/uni/wa/ecu/master-of-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007909/Aus-Uni-Photos/ECU/ecu-mast-health-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007910/Aus-Uni-Photos/ECU/ecu-mast-health-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007908/Aus-Uni-Photos/ECU/ecu-mast-health-back.jpg"],
  "metaTag" : "Buy your Edith Cowan University Master of Health degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Health graduation gown set - Front view", "Edith Cowan University Master of Health graduation gown set - Side view", "Edith Cowan University Master of Health graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Health",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Health",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Ruby",
  "colour" : "lined/trimmed with Ruby",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90212",
  "productType" : "master-full-set",
  "colors" : ["#E0115F"]
},
"/uni/wa/ecu/master-of-it" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007912/Aus-Uni-Photos/ECU/ecu-mast-it-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007913/Aus-Uni-Photos/ECU/ecu-mast-it-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007911/Aus-Uni-Photos/ECU/ecu-mast-it-back.jpg"],
  "metaTag" : "Buy your Edith Cowan University Master of Information Technology degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Information Technology graduation gown set - Front view", "Edith Cowan University Master of Information Technology graduation gown set - Side view", "Edith Cowan University Master of Information Technology graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Information Technology",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Information Technology",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Purple",
  "colour" : "lined/trimmed with Royal Purple",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90213",
  "productType" : "master-full-set",
  "colors" : ["#7E00C5"]
},
"/uni/wa/ecu/master-of-soc" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007921/Aus-Uni-Photos/ECU/ecu-mast-soc-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007922/Aus-Uni-Photos/ECU/ecu-mast-soc-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007920/Aus-Uni-Photos/ECU/ecu-mast-soc-back.png"],
  "metaTag" : "Buy your Edith Cowan University Master of Society & Culture degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Society & Culture graduation gown set - Front view", "Edith Cowan University Master of Society & Culture graduation gown set - Side view", "Edith Cowan University Master of Society & Culture graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Society & Culture",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Society & Culture",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Blue",
  "colour" : "lined/trimmed with Royal Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90214",
  "productType" : "master-full-set",
  "colors" : ["#0080FF"]
},
"/uni/wa/ecu/master-of-engg" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007906/Aus-Uni-Photos/ECU/ecu-mast-engg-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007907/Aus-Uni-Photos/ECU/ecu-mast-engg-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007905/Aus-Uni-Photos/ECU/ecu-mast-engg-back.png"],
  "metaTag" : "Buy your Edith Cowan University Master of Engineering degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Engineering graduation gown set - Front view", "Edith Cowan University Master of Engineering graduation gown set - Side view", "Edith Cowan University Master of Engineering graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Engineering",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Gold",
  "colour" : "lined/trimmed with Gold",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90215",
  "productType" : "master-full-set",
  "colors" : ["gold"]
},
"/uni/wa/ecu/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007903/Aus-Uni-Photos/ECU/ecu-mast-educ-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007904/Aus-Uni-Photos/ECU/ecu-mast-educ-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007902/Aus-Uni-Photos/ECU/ecu-mast-educ-back.png"],
  "metaTag" : "Buy your Edith Cowan University Master of Education degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Education graduation gown set - Front view", "Edith Cowan University Master of Education graduation gown set - Side view", "Edith Cowan University Master of Education graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Education",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Sky Blue",
  "colour" : "lined/trimmed with Sky Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90216",
  "productType" : "master-full-set",
  "colors" : ["#CCFFFF"]
},
"/uni/wa/ecu/master-of-creative-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007900/Aus-Uni-Photos/ECU/ecu-mast-arts-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007901/Aus-Uni-Photos/ECU/ecu-mast-arts-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007899/Aus-Uni-Photos/ECU/ecu-mast-arts-back.png"],
  "metaTag" : "Buy your Edith Cowan University Master of Creative Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Creative Arts graduation gown set - Front view", "Edith Cowan University Master of Creative Arts graduation gown set - Side view", "Edith Cowan University Master of Creative Arts graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Creative Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Creative Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Peacock Blue",
  "colour" : "lined/trimmed with Peacock Blue",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90217",
  "productType" : "master-full-set",
  "colors" : ["#00FFFF"]
},
"/uni/wa/ecu/master-of-mgmt-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007915/Aus-Uni-Photos/ECU/ecu-mast-mgmtcomm-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007916/Aus-Uni-Photos/ECU/ecu-mast-mgmtcomm-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007914/Aus-Uni-Photos/ECU/ecu-mast-mgmtcomm-back.png"],
  "metaTag" : "Buy your Edith Cowan University Master of Management & Commerce degree graduation gown set from Gowning Street!",
  "altTags" : ["Edith Cowan University Master of Management & Commerce graduation gown set - Front view", "Edith Cowan University Master of Management & Commerce graduation gown set - Side view", "Edith Cowan University Master of Management & Commerce graduation gown set - Back view"],
  "name" : "ECU Graduation Gown Set - Master of Management & Commerce",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Edith Cowan University Hood - Master of Management & Commerce",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Tuscan Yellow",
  "colour" : "lined/trimmed with Tuscan Yellow",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO90218",
  "productType" : "master-full-set",
  "colors" : ["#FFF600"]
},


//UWA
"/uni/wa/uwa/bachelor-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-arts-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Arts graduation gown set - Front view", "University of Western Australia Bachelor of Arts graduation gown set - Side view", "University of Western Australia Bachelor of Arts graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Blue with White Edge",
  "colour" : "Lined/trimmed with Royal Blue with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90101",
  "productType" : "bachelor-full-set",
  "colors" : ["blue"]
},
"/uni/wa/uwa/bachelor-medicine" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-med-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-med-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-med-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Medicine degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Medicine graduation gown set - Front view", "University of Western Australia Bachelor of Medicine graduation gown set - Side view", "University of Western Australia Bachelor of Medicine graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Medicine",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Medicine",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Ruby with White Edge",
  "colour" : "Lined/trimmed with Ruby with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90102",
  "productType" : "bachelor-full-set",
  "colors" : ["red"]
},
"/uni/wa/uwa/bachelor-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021155/Aus-Uni-Photos/UWA/uwa-bach-engg-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021155/Aus-Uni-Photos/UWA/uwa-bach-engg-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021155/Aus-Uni-Photos/UWA/uwa-bach-engg-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Engineering degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Engineering graduation gown set - Front view", "University of Western Australia Bachelor of Engineering graduation gown set - Side view", "University of Western Australia Bachelor of Engineering graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Engineering",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Engineering",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Gold with White Edge",
  "colour" : "Lined/trimmed with Gold with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90103",
  "productType" : "bachelor-full-set",
  "colors" : ["gold"]
},
"/uni/wa/uwa/bachelor-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-sci-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Science degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Science graduation gown set - Front view", "University of Western Australia Bachelor of Science graduation gown set - Side view", "University of Western Australia Bachelor of Science graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Science",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Emerald Green with White Edge",
  "colour" : "Lined/trimmed with Emerald Green with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90104",
  "productType" : "bachelor-full-set",
  "colors" : ["#008000"]
},
"/uni/wa/uwa/bachelor-law" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021155/Aus-Uni-Photos/UWA/uwa-bach-law-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021156/Aus-Uni-Photos/UWA/uwa-bach-law-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021155/Aus-Uni-Photos/UWA/uwa-bach-law-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Law degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Law graduation gown set - Front view", "University of Western Australia Bachelor of Law graduation gown set - Side view", "University of Western Australia Bachelor of Law graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Law",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Law",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Purple with White Edge",
  "colour" : "Lined/trimmed with Royal Purple with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90105",
  "productType" : "bachelor-full-set",
  "colors" : ["purple"]
},
"/uni/wa/uwa/graduation-gown/bachelor-commerce" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-comm-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-comm-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021154/Aus-Uni-Photos/UWA/uwa-bach-comm-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Bachelor of Commerce & Economics degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Bachelor of Commerce & Economics graduation gown set - Front view", "University of Western Australia Bachelor of Commerce & Economics graduation gown set - Side view", "University of Western Australia Bachelor of Commerce & Economics graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Bachelor of Commerce & Economics",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Bachelor of Commerce & Economics",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Flamingo with White Edge",
  "colour" : "Lined/trimmed with Flamingo with White Edge",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO90106",
  "productType" : "bachelor-full-set",
  "colors" : ["#B77B82"]
},
"/uni/wa/uwa/graduation-gown/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-arts-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Master of Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Master of Arts graduation gown set - Front view", "University of Western Australia Master of Arts graduation gown set - Side view", "University of Western Australia Master of Arts graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Master of Arts",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Royal Blue",
  "colour" : "Lined/trimmed with Royal Blue",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO90111",
  "productType" : "master-full-set",
  "colors" : ["blue"]
},
"/uni/wa/uwa/graduation-gown/MBA" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mba-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mba-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mba-back.jpg"],
  "metaTag" : "Buy your University of Western Australia MBA degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia MBA graduation gown set - Front view", "University of Western Australia MBA graduation gown set - Side view", "University of Western Australia MBA graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - MBA",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - MBA",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Mauve",
  "colour" : "Lined/trimmed with Mauve",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO90112",
  "productType" : "master-full-set",
  "colors" : ["#DA70D6"]
},
"/uni/wa/uwa/graduation-gown/master-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mast-science-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mast-science-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mast-science-back.jpg"],
  "metaTag" : "Buy your University of Western Australia Master of Science degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Master of Science graduation gown set - Front view", "University of Western Australia Master of Science graduation gown set - Side view", "University of Western Australia Master of Science graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Master of Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Master of Science",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Emerald Green",
  "colour" : "Lined/trimmed with Emerald Green",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO90113",
  "productType" : "master-full-set",
  "colors" : ["#008000"]
},
"/uni/wa/uwa/graduation-gown/master-of-comm-eco" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-comm-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-comm-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-comm-back.png"],
  "metaTag" : "Buy your University of Western Australia Master of Commerce & Economics degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Master of Commerce & Economics graduation gown set - Front view", "University of Western Australia Master of Commerce & Economics graduation gown set - Side view", "University of Western Australia Master of Commerce & Economics graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Master of Commerce & Economics",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Master of Commerce & Economics",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Flamingo",
  "colour" : "Lined/trimmed with Flamingo",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO90114",
  "productType" : "master-full-set",
  "colors" : ["#B77B82"]
},

"/uni/wa/uwa/graduation-gown/master-of-engineering" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635021158/Aus-Uni-Photos/UWA/uwa-mast-engg-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-engg-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635021157/Aus-Uni-Photos/UWA/uwa-mast-engg-back.png"],
  "metaTag" : "Buy your University of Western Australia Master of Engineering degree graduation gown set from Gowning Street!",
  "altTags" : ["University of Western Australia Master of Engineering graduation gown set - Front view", "University of Western Australia Master of Engineering graduation gown set - Side view", "University of Western Australia Master of Engineering graduation gown set - Back view"],
  "name" : "UWA Graduation Gown Set - Master of Engineering",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "University of Western Australia Hood - Master of Engineering",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Gold",
  "colour" : "Lined/trimmed with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO90115",
  "productType" : "master-full-set",
  "colors" : ["gold"]
},

//Multiple state Universities

//CSU

"/uni/csu/bachelor-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007840/Aus-Uni-Photos/CSU/csu-bach-bus-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007841/Aus-Uni-Photos/CSU/csu-bach-bus-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007839/Aus-Uni-Photos/CSU/csu-bach-bus-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Bachelor of Business degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Bachelor of Business graduation gown set - Front view", "Charles Sturt University Bachelor of Business graduation gown set - Side view", "Charles Sturt University Bachelor of Business graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Bachelor of Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Bachelor of Business",
  "hood-style" : "Red, Cambridge-style, edged with Smalt Blue",
  "colour" : "edged with Smalt Blue",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO10202",
  "productType" : "bachelor-full-set",
  "colors" : ["#375CD4"]
},
"/uni/csu/bachelor-of-education-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007843/Aus-Uni-Photos/CSU/csu-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007844/Aus-Uni-Photos/CSU/csu-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007842/Aus-Uni-Photos/CSU/csu-bach-educ-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Bachelor of Education degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Bachelor of Education graduation gown set - Front view", "Charles Sturt University Bachelor of Education graduation gown set - Side view", "Charles Sturt University Bachelor of Education graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Bachelor of Education & Arts",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Bachelor of Education",
  "hood-style" : "Red, Cambridge-style, edged with Grass Green",
  "colour" : "edged with Grass Green",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO10203",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},
"/uni/csu/bachelor-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007846/Aus-Uni-Photos/CSU/csu-bach-sci-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007847/Aus-Uni-Photos/CSU/csu-bach-sci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007845/Aus-Uni-Photos/CSU/csu-bach-sci-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Bachelor of Science degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Bachelor of Science graduation gown set - Front view", "Charles Sturt University Bachelor of Science graduation gown set - Side view", "Charles Sturt University Bachelor of Science graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Bachelor of Science",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Bachelor of Science",
  "hood-style" : "Red, Cambridge-style, edged with Gold",
  "colour" : "edged with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : "HOO10204",
  "productType" : "bachelor-full-set",
  "colors" : ["#D49537"]
},
"/uni/csu/graduation-gown/master-of-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007850/Aus-Uni-Photos/CSU/csu-mast-arts-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007851/Aus-Uni-Photos/CSU/csu-mast-arts-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007849/Aus-Uni-Photos/CSU/csu-mast-arts-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Master of Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Master of Arts graduation gown set - Front view", "Charles Sturt University Master of Arts graduation gown set - Side view", "Charles Sturt University Master of Arts graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Master of Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Master of Arts",
  "hood-style" : "Red, Cambridge-style, Edged with White",
  "colour" : "Edged with White",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO10211",
  "productType" : "master-full-set",
  "colors" : ["white"]
},
"/uni/csu/graduation-gown/master-of-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007853/Aus-Uni-Photos/CSU/csu-mast-business-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007854/Aus-Uni-Photos/CSU/csu-mast-business-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007852/Aus-Uni-Photos/CSU/csu-mast-business-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Master of Business degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Master of Business graduation gown set - Front view", "Charles Sturt University Master of Business graduation gown set - Side view", "Charles Sturt University Master of Business graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Master of Business",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Master of Business",
  "hood-style" : "Red, Cambridge-style, Edged with Smalt Blue",
  "colour" : "Edged with Smalt Blue",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO10212",
  "productType" : "master-full-set",
  "colors" : ["blue"]
},
"/uni/csu/graduation-gown/master-of-science" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007859/Aus-Uni-Photos/CSU/csu-mast-science-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007860/Aus-Uni-Photos/CSU/csu-mast-science-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007858/Aus-Uni-Photos/CSU/csu-mast-science-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University Master of Science degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Master of Science graduation gown set - Front view", "Charles Sturt University Master of Science graduation gown set - Side view", "Charles Sturt University Master of Science graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Master of Science",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Master of Science",
  "hood-style" : "Red, Cambridge-style, Edged with Gold",
  "colour" : "Edged with Gold",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO10213",
  "productType" : "master-full-set",
  "colors" : ["#D49537"]
},
"/uni/csu/graduation-gown/master-of-education-arts" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007856/Aus-Uni-Photos/CSU/csu-mast-educ-arts-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007857/Aus-Uni-Photos/CSU/csu-mast-educ-arts-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007855/Aus-Uni-Photos/CSU/csu-mast-educ-arts-back.png"],
  "metaTag" : "Buy your Charles Sturt University Master of Education & Arts degree graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University Master of Education & Arts graduation gown set - Front view", "Charles Sturt University Master of Education & Arts graduation gown set - Side view", "Charles Sturt University Master of Education & Arts graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Master of Education & Arts",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - Master of Education & Arts",
  "hood-style" : "Red, Cambridge-style, Edged with Grass Green",
  "colour" : "Edged with Grass Green",
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : "HOO10214",
  "productType" : "master-full-set",
  "colors" : ["green"]
},
"/uni/csu/phd" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007862/Aus-Uni-Photos/CSU/csu-phd-set-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007863/Aus-Uni-Photos/CSU/csu-phd-set-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007861/Aus-Uni-Photos/CSU/csu-phd-set-back.jpg"],
  "metaTag" : "Buy your Charles Sturt University PhD graduation gown set from Gowning Street!",
  "altTags" : ["Charles Sturt University PhD graduation gown set - Front view", "Charles Sturt University PhD graduation gown set - Side view", "Charles Sturt University PhD graduation gown set - Back view"],
  "name" : "CSU Graduation Gown Set - Doctor of Philosophy",
  "price" : PHD_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Charles Sturt University Hood - PhD",
  "hood-style" : "Red, Cambridge-style, lined/trimmed with PhD Blue",
  "colour" : "lined/trimmed with PhD Blue",
  "gownStyle" : "PhD",
  "phdStyle" : "satin",
  "level" : "PhD",
  "hood-code" : "HOO10221",
  "productType" : "phd-full-set",
  "colors" : ["blue"],
  "hatType" : "red"
},



//ACU

"/uni/acu/bachelor-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-educ-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-educ-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-educ-back.jpg"],
  "metaTag" : "Buy your Australian Catholic University Bachelor of Education degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Bachelor of Education graduation gown set - Front view", "Australian Catholic University Bachelor of Education graduation gown set - Side view", "Australian Catholic University Bachelor of Education graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Bachelor of Education",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Bachelor of Education",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Malachite Green",
  "colour" : "Lined/trimmed with Malachite Green",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO10101",
  "productType" : "bachelor-full-set",
  "colors" : ["green"]
},

"/uni/acu/bachelor-healthsci" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592472031/Uni%20Regalia%20Photos/ACU/acu-bach-healthsci-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1592472031/Uni%20Regalia%20Photos/ACU/acu-bach-healthsci-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1592472031/Uni%20Regalia%20Photos/ACU/acu-bach-healthsci-back.jpg"],
  "metaTag" : "Buy your Australian Catholic University Bachelor of Health Sciences degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Bachelor of Health Sciences graduation gown set - Front view", "Australian Catholic University Bachelor of Health Sciences graduation gown set - Side view", "Australian Catholic University Bachelor of Health Sciences graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Bachelor of Health Sciences",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Bachelor of Health Sciences",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Spectrum Orange",
  "colour" : "Lined/trimmed with Spectrum Orange",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO10102",
  "productType" : "bachelor-full-set",
  "colors" : ["#FFDAB9"]
},

"/uni/acu/bachelor-law-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007494/Aus-Uni-Photos/ACU/acu-bach-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007494/Aus-Uni-Photos/ACU/acu-bach-law-back.png"],
  "metaTag" : "Buy your Australian Catholic University Bachelor of Law & Business degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Bachelor of Law & Business graduation gown set - Front view", "Australian Catholic University Bachelor of Law & Business graduation gown set - Side view", "Australian Catholic University Bachelor of Law & Business graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Bachelor of Law & Business",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Bachelor of Law & Business",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Burgundy",
  "colour" : "Lined/trimmed with Burgundy",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO10103",
  "productType" : "bachelor-full-set",
  "colors" : ["#800020"]
},
"/uni/acu/bachelor-theology" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-theo-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-theo-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-bach-theo-back.png"],
  "metaTag" : "Buy your Australian Catholic University Bachelor of Theology degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Bachelor of Theology graduation gown set - Front view", "Australian Catholic University Bachelor of Theology graduation gown set - Side view", "Australian Catholic University Bachelor of Theology graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Bachelor of Theology",
  "price" : BACHELOR_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Bachelor of Theology",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Violet/Purple",
  "colour" : "Lined/trimmed with  Violet/Purple",
  "gownStyle" : "Oxford",
  "level" : "Bachelor",
  "hood-code" : "HOO10104",
  "productType" : "bachelor-full-set",
  "colors" : ["#7F00FF"]
},
"/uni/acu/graduation-gown/master-of-education" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-mast-educ-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007496/Aus-Uni-Photos/ACU/acu-mast-educ-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007495/Aus-Uni-Photos/ACU/acu-mast-educ-back.png"],
  "metaTag" : "Buy your Australian Catholic University Master of Education degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Master of Education graduation gown set - Front view", "Australian Catholic University Master of Education graduation gown set - Side view", "Australian Catholic University Master of Education graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Master of Education",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Master of Education",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Malachite Green",
  "colour" : "Lined/trimmed with  Malachite Green",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO10111",
  "productType" : "master-full-set",
  "colors" : ["green"]
},
"/uni/acu/graduation-gown/master-of-health" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007630/Aus-Uni-Photos/ACU/acu-mast-health-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007496/Aus-Uni-Photos/ACU/acu-mast-health-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007629/Aus-Uni-Photos/ACU/acu-mast-health-back.png"],
  "metaTag" : "Buy your Australian Catholic University Master of Health Sciences degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Master of Health Sciences graduation gown set - Front view", "Australian Catholic University Master of Health Sciences graduation gown set - Side view", "Australian Catholic University Master of Health Sciences graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Master of Health Sciences",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Master of Health Sciences",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Spectrum Orange",
  "colour" : "Lined/trimmed with Spectrum Orange",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO10112",
  "productType" : "master-full-set",
  "colors" : ["orange"]
},
"/uni/acu/graduation-gown/master-of-law-business" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635007633/Aus-Uni-Photos/ACU/acu-mast-law-front.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007634/Aus-Uni-Photos/ACU/acu-mast-law-side.png", "https://res.cloudinary.com/gowningstreet/image/upload/v1635007632/Aus-Uni-Photos/ACU/acu-mast-law-back.png"],
  "metaTag" : "Buy your Australian Catholic University Master of Law & Business degree graduation gown set from Gowning Street!",
  "altTags" : ["Australian Catholic University Master of Law & Business graduation gown set - Front view", "Australian Catholic University Master of Law & Business graduation gown set - Side view", "Australian Catholic University Master of Law & Business graduation gown set - Back view"],
  "name" : "ACU Graduation Gown Set - Master of Law & Business",
  "price" : MASTER_SET_PRICE,
  "show" : ["gown", "cap", "hood"],
  "hood" : "Australian Catholic University Hood - Master of Law & Business",
  "hood-style" : "Black, Oxford-style, lined/trimmed with Burgundy",
  "colour" : "Lined/trimmed with Burgundy",
  "gownStyle" : "Oxford",
  "level" : "Master",
  "hood-code" : "HOO10113",
  "productType" : "master-full-set",
  "colors" : ["#800020"]
},

//Bachelor cap and gown only with uni

"/uni/bachelor-cap-and-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-back.jpg"],
  "metaTag" : "Buy your University bachelor style graduation gown and cap set from Gowning Street!",
  "altTags" : ["University Bachelor cap and gown set - Front view", "University Bachelor cap and gown set - Side view", "University Bachelor cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Bachelor Style",
  "price" : BACHELOR_GOWN_CAP_PRICE,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-gown-and-cap"
  },


//Bachelor cap and gown - no uni

"/graduation-gown/cap-and-gown-bachelor" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Regalia/bach-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-cap-gown-back.jpg"],
  "metaTag" : "Buy your bachelor style graduation gown and cap set from Gowning Street!",
  "altTags" : ["Bachelor cap and gown set - Front view", "Bachelor cap and gown set - Side view", "Bachelor cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Bachelor Style",
  "price" : BACHELOR_GOWN_CAP_PRICE,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-gown-and-cap"
  },

//master cap and gown with uni

"/uni/master-cap-and-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/master-cap-gown-set.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-back.jpg"],
  "metaTag" : "Buy your University master style graduation gown and cap set from Gowning Street!",
  "altTags" : ["University Master cap and gown set - Front view", "University Master cap and gown set - Side view", "University Master cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Master Style",
  "price" : MASTER_GOWN_CAP_PRICE,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : null,
  "productType" : "master-gown-and-cap"
  },

//master cap and gown - no uni

"/graduation-gown/cap-and-gown-master" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/master-cap-gown-set.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Regalia/mast-cap-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/mast-cap-gown-back.jpg"],
  "metaTag" : "Buy your master style graduation gown and cap set from Gowning Street!",
  "altTags" : ["Master cap and gown set - Front view", "Master cap and gown set - Side view", "Master cap and gown set - Back view"],
  "name" : "Graduation Gown Set - Master Style",
  "price" : MASTER_GOWN_CAP_PRICE,
  "show" : ["gown", "cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Master",
  "hood-code" : null,
  "productType" : "master-gown-and-cap"
  },

//Bachelor gown no uni
"/graduation-gown/gown-bachelor" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
  "metaTag" : "Buy your bachelor style graduation gown from Gowning Street!",
  "altTags" : ["Bachelor gown set - Front view", "Bachelor gown set - Side view", "Bachelor gown set - Back view"],
  "name" : "Graduation Gown - Bachelor Style",
  "price" : BACHELOR_GOWN_PRICE,
  "show" : ["gown"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-gown"
},

//Bachelor gown uni
"/uni/bachelor-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
  "metaTag" : "Buy your University bachelor style graduation gown from Gowning Street!",
  "altTags" : ["University Bachelor gown set - Front view", "University Bachelor gown set - Side view", "University Bachelor gown set - Back view"],
  "name" : "Graduation Gown - Bachelor Style",
  "price" : BACHELOR_GOWN_PRICE,
  "show" : ["gown"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-gown"
},

"/graduation-gown/master-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/mast-gown-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/mast-gown-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/mast-gown-back.jpg"],
  "metaTag" : "Buy your master style graduation gown from Gowning Street!",
  "altTags" : ["Master gown set - Front view", "Master gown set - Side view", "Master gown set - Back view"],
  "name" : "Graduation Gown - Master Style",
  "price" : MASTER_GOWN_PRICE,
  "show" : ["gown"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Master",
  "hood-code" : null,
  "productType" : "master-gown"
},

//phd - red cloth

"/graduation-gown/phd-gown-red-cloth" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-gown-rc-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-gown-rc-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022419/Regalia/phd-gown-rc-back.jpg"],
  "metaTag" : "Buy your PhD style graduation gown from Gowning Street!",
  "altTags" : ["PhD gown set - Front view", "PhD gown set - Side view", "PhD gown set - Back view"],
  "name" : "Graduation Gown - PhD (Red cloth lining)",
  "price" : PHD_GOWN_PRICE,
  "show" : ["gown"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "hood-code" : null,
  "gownStyle" : "PhD",
  "phdStyle" : "cloth",
  "level" : "PhD",
  "hood-code" : null,
  "productType" : "phd-gown",
  "hatType" : "red"
},

//phd - red satin

"/graduation-gown/phd-gown-red-satin" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635022419/Regalia/phd-gown-rs-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022419/Regalia/phd-gown-rs-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022419/Regalia/phd-gown-rs-back.jpg"],
  "metaTag" : "Buy your PhD style graduation gown from Gowning Street!",
  "altTags" : ["PhD gown set - Front view", "PhD gown set - Side view", "PhD gown set - Back view"],
  "name" : "Graduation Gown - PhD (Red satin lining)",
  "price" : PHD_GOWN_PRICE,
  "show" : ["gown"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "hood-code" : null,
  "gownStyle" : "PhD",
  "phdStyle" : "satin",
  "level" : "PhD",
  "hood-code" : null,
  "productType" : "phd-gown",
  "hatType" : "red"
},

//Cap only
"/graduation-gown/graduation-hat" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666354/Regalia/graduation-cap-2.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-3.jpg"],
  "metaTag" : "Buy your graduation hat from Gowning Street! Get any size of Graduation Hat at the very best prices. Browse graduation hats for school or university graduation.",
  "titleTag" : "Graduation Hat | Gowning Street Graduation Regalia in Australia",
  "altTags" : ["Graduation Hat - Front view", "Graduation Hat - Side view", "Graduation Hat - Back view"],
  "name" : "Graduation Hat",
  "price" : CAP_PRICE,
  "show" : ["cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : null,
  "productType" : "bachelor-cap"
},

//PhD red cap
"/graduation-gown/phd-bonnet-red" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635022417/Regalia/phd-bonnet-red-side.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022417/Regalia/phd-bonnet-red-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022417/Regalia/phd-bonnet-red-back.jpg"],
  "metaTag" : "Buy your PhD Graduation Bonnet from Gowning Street! Get any size of Graduation Bonnet at the very best prices. Browse Graduation Bonnets for your PhD University graduation.",
  "titleTag" : "PhD Hat - Red Tassel | Gowning Street Graduation Regalia in Australia",
  "altTags" : ["PhD Hat Red Tassel - Front view", "PhD Hat Red Tassel - Side view", "PhD Hat Red Tassel - Back view"],
  "name" : "PhD Hat (Red Tassel)",
  "price" : PHD_BONNET_PRICE,
  "show" : ["cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "PhD",
  "hood-code" : null,
  "productType" : "phd-cap",
  "hatType" : "red"
},

//PhD yellow cap
"/graduation-gown/phd-bonnet-yellow" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-bonnet-yellow-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-bonnet-yellow-front.jpg", "https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-bonnet-yellow-back.jpg"],
  "metaTag" : "Buy your PhD Graduation Bonnet from Gowning Street! Get any size of Graduation Bonnet at the very best prices. Browse Graduation Bonnets for your PhD University graduation.",
  "titleTag" : "PhD Hat - Yellow Tassel | Gowning Street Graduation Regalia in Australia",
  "altTags" : ["PhD Hat Yellow Tassel - Front view", "PhD Hat Yellow Tassel - Side view", "PhD Hat Yellow Tassel - Back view"],
  "name" : "PhD Hat (Yellow Tassel)",
  "price" : PHD_BONNET_PRICE,
  "show" : ["cap"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "PhD",
  "hood-code" : null,
  "productType" : "phd-cap",
  "hatType" : "yellow"
},



//Gifts
//!! Remember to update cart-items.js if the below are ever updated

"/graduation-gown/graduation-teddy-bear" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165133/Regalia/graduation-teddy-bear-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-3.jpg"],
  "metaTag" : "Add a graduation teddy bear to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
  "titleTag" : "Graduation Teddy Bear | Gowning Street Graduation Gifts in Australia",
  "altTags" : ["Graduation Teddy Bear - Front view", "Graduation Teddy Bear - Side view", "Graduation Teddy Bear - Back view"],
  "name" : "Graduation Teddy Bear",
  "price" : TEDDY_SMALL_PRICE,
  "show" : ["gift"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "TED00001",
  "productType" : "gift",
  "giftDescription" : "Graduation teddy: 17cm height"
},

"/graduation-gown/graduation-puppy-large" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-puppy-smooth-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-smooth-3.jpg"],
  "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
  "titleTag" : "Graduation Puppy - Large | Gowning Street Graduation Gifts in Australia",
  "altTags" : ["Graduation Puppy Large - Front view", "Graduation Puppy Large - Side view", "Graduation Puppy Large - Back view"],
  "name" : "Graduation Puppy - Large",
  "price" : PUPPY_LARGE_PRICE,
  "show" : ["gift"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "PUP00001",
  "productType" : "gift",
  "giftDescription" : "Large Graduation Puppy: 21cm height"
},

"/graduation-gown/graduation-puppy-small" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-2.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165135/Regalia/graduation-puppy-furry-3.jpg"],
  "metaTag" : "Add a graduation puppy to your graduation gown set for the full, memorable experience, or buy one as a gift for a friend!",
  "titleTag" : "Graduation Puppy | Gowning Street Graduation Gifts in Australia",
  "altTags" : ["Graduation Puppy Small - Front view", "Graduation Puppy Small - Side view", "Graduation Puppy Small - Back view"],
  "name" : "Graduation Puppy - Small",
  "price" : PUPPY_SMALL_PRICE,
  "show" : ["gift"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "PUP00002",
  "productType" : "gift",
  "giftDescription" : "Small Graduation Puppy: 17cm height"
},

"/graduation-gown/garment-bag" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1635022732/Regalia/graduation-gown-garment-bag.png"],
  "metaTag" : "Add a garment bag to your graduation gown to protect your regalia",
  "titleTag" : "Garment Bag | Garment bag to protect your graduation regalia",
  "altTags" : ["Graduation Garment Bag"],
  "name" : "Graduation Garment Bag",
  "price" : GARMENT_BAG,
  "show" : ["gift"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "BAG00001",
  "productType" : "gift",
  "giftDescription" : "Add in a graduation gown garment bag to protect and maintain your regalia"
},

"/legal-wear/barristers-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
  "metaTag" : "Our barrister gowns are great for the Australian climate offering a breathable polyester weave design. Our barrister gown is fully fluted back with a stiffened yolk. Our barrister robes are suitable for Australia and other Commonwealth jurisdictions",
  "titleTag" : "Barrister's Gown for Australia and other Commonwealth Jurisdictions | Order online from $199",
  "altTags" : ["Barrister's Gown - Front","Barrister's Gown - Side","Barrister's Gown - Back"],
  "name" : "Barrister's Gown",
  "price" : BARRISTER_GOWN_PRICE,
  "show" : ["legal"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "GOWL0001",
  "productType" : "legal",
  "giftDescription" : "Fully fluted gown with a stiffened yolk. Suitable for Australia and other Commonwealth jurisdictions"
},

"/legal-wear/legal-admissions-gown" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-front.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg","https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Regalia/bach-gown-back.jpg"],
  "metaTag" : "Our traditional legal gowns for admission are made from high quality polyester weave. Our gowns are crafted for comfort and will ensure you will look sharp on admissions day",
  "titleTag" : "Legal Admissions Gown for Australia and other Commonwealth Jurisdictions | Order online from $199",
  "altTags" : ["Legal Admissions Gown - Front","Legal Admissions Gown - Side","Legal Admissions Gown - Back"],
  "name" : "Legal Admissions Gown",
  "price" : ADMISSIONS_GOWN_PRICE,
  "show" : ["legal"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "GOWL0002",
  "productType" : "legal",
  "giftDescription" : "Hand crafted for comfort from high quality polyester weave. Suitable for Australia and other Commonwealth jurisdictions"
},

"/legal-wear/barristers-wigs" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"],
  "metaTag" : "Our Barrister’s wig is hand crafted from 100% pure horsehair and is sized according to head circumference.",
  "titleTag" : "Barrister's Wig for Australia and other Commonwealth Jurisdictions | Order online from $199",
  "altTags" : ["Barrister's Wig - Front","Barrister's Wig - Side","Barrister's Wig - Back"],
  "name" : "Barrister's Wig",
  "price" : BARRISTER_WIG_PRICE,
  "show" : ["legal"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "WIG00001",
  "productType" : "legal",
  "giftDescription" : "Hand crafted from 100% pure horsehair and sized according to head circumference. Suitable for Australia and other Commonwealth Jurisdictions"
},

"/legal-wear/benchers-wigs" : {
  "photos" : ["https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png","https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"],
  "metaTag" : "Gowning Street Bencher’s wigs are handcrafted from 100% pure horsehair and provide a comfortable fit",
  "titleTag" : "Bencher's Wig for Australia and other Commonwealth Jurisdictions | Order online from $199",
  "altTags" : ["Bencher's Wig - Front","Bencher's Wig - Side","Bencher's Wig - Back"],
  "name" : "Bencher's Wig",
  "price" : BENCHER_WIG_PRICE,
  "show" : ["legal"],
  "hood" : null,
  "hood-style" : null,
  "colour" : null,
  "gownStyle" : "Cambridge/Oxford",
  "level" : "Bachelor",
  "hood-code" : "WIG00002",
  "productType" : "legal",
  "giftDescription" : "Handcrafted from 100% pure horsehair to provide a comfortable, long-lasting fit. Suitable for Australia and other Commonwealth jurisdictions"
}


};
