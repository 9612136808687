import React from 'react';
import NavigationButton from './navigationButton';

const ExchangeFindOrder = (props) => {
  return (
    <div>
      <h1>Gowning Street Order Exchange Form</h1>
      <div className="returnInstructions">
        <h2>Return instructions:</h2>
        <p>Please use this form to submit a return request for the gown or cap you have ordered.</p>
        <p style={{color: '#C41E3A', fontWeight: 'bold'}}>All our hoods are one-size, so please DO NOT return the hood for exchange.</p>
        <p>For your exchange to be accepted, all items must be in their original packaging, with the correct barcode attached.</p>
        <p>The cost of the exchange is $10 per item exchanged plus shipping</p>
      </div>
      <h1>Step 1: Find Your Order</h1>
      <div className="findOrderInputGroup">
        <input
          type="text"
          placeholder="Order Number"
          value={props.find_ordernum}
          onChange={(event) => props.handleChange(event, "find_ordernum")}
        />
        {/* <input
          type="text"
          placeholder="First Name"
          value={props.find_firstname}
          onChange={(event) => props.handleChange(event, "find_firstname")}
        />
        <input
          type="text"
          placeholder="Last Name"
          value={props.find_lastname}
          onChange={(event) => props.handleChange(event, "find_lastname")}
        /> */}
        <input
          type="text"
          placeholder="Postcode"
          value={props.find_postcode}
          onChange={(event) => props.handleChange(event, "find_postcode")}
        />
      </div>
      <div className="navigateButtonGroup">
        <NavigationButton handleClick={props.handleSearch} label="Search" />
      </div>
      <p className="error">{props.error}</p>
    </div>
  )

}

export default ExchangeFindOrder;