import React, { useState, useEffect } from 'react';

const Cap = ({
  level = 'Bachelor',
  hatType = '',
  setCap = () => {},
  defaultHatCode
}) => {

  const [selections, setSelections] = useState(["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"]);
  const [selectedValue, setSelectedValue] = useState(defaultHatCode);

  useEffect(() => {
    const path = window.location.pathname;

    if(level === "Bachelor" || level === "Master") {
      setSelections(["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"]);
    }
    if(level === "PhD") {
      switch(hatType){
        case "bachelor":
          setSelections(["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"]);
          break;

        case "red":
          setSelections(["HAT00024", "HAT00025", "HAT00026"]);
          break;

        case "yellow":
          setSelections(["HAT00033", "HAT00034", "HAT00035"]);
          break;
      }
    }
    else {
      setSelections(["HAT00001", "HAT00002", "HAT00003", "HAT00004", "HAT00005"]);
    }

  }, [level, hatType]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    setCap(event.target.value);
  };

  const getSizings = () => {
    const sizing = {
      Bachelor: [
        "Extra Small (Less than 46cm)",
        "Small (47 - 50cm)",
        "Medium (51 - 54cm)",
        "Large (55 - 59cm)",
        "Extra Large (More than 59cm)",
      ],
      PhDred: [
        "Small (Less than 53cm)",
        "Medium (53 - 58cm)" ,
        "Large (More than 58cm)",
      ],
      PhDyellow: [
        "Small (Less than 49cm)",
        "Medium (49 - 53cm)",
        "Large (More than 53cm)",
      ]
    };

    if (level !== 'PhD') {
      return sizing.Bachelor;
    } else {
      if (hatType === 'red') {
        return sizing.PhDred;
      }
      if (hatType === 'yellow') {
        return sizing.PhDyellow;
      }
      if (hatType === 'bachelor') {
        return sizing.Bachelor
      }
    }
  }

  const sizings = getSizings();
  return (
   <div className="cap-size">
        <h3>Choose your Cap size</h3>
        <p className="sizedesc">
          Based on head circumference at the widest point. Click <a href="https://res.cloudinary.com/gowningstreet/image/upload/v1549425049/Utility/Gowning-Street-Printable-Measurement-Ruler.pdf" target="_blank">here</a> for a printable ruler
        </p>
        <select
          className="select-size"
          value={selectedValue}
          onChange={handleChange}
        >
          {
            selections.map((selection, index) => {
              return (
                <option value={selection}>
                  {sizings[index]}
                </option>
              )
            })
          }
          {/* <option value={selections[0]}>{sizing[levelDefaultBachelor].XS}</option>
          <option value={selections[1]}>{sizing[levelDefaultBachelor].S}</option>
          <option value={selections[2]}>{sizing[levelDefaultBachelor].M}</option>
          <option value={selections[3]}>{sizing[levelDefaultBachelor].L}</option>
          <option value={selections[4]}>{sizing[levelDefaultBachelor].XL}</option>
          {levelDefaultBachelor === "PhD" ? <option value={selections[5]}>{sizing[levelDefaultBachelor].XXL}</option> : null} */}
        </select>
      </div>
  )
};

export default Cap;