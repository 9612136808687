import React, { Component } from 'react';
import { connect } from 'react-redux';
import Formsy from 'formsy-react';

import CustomInput from './customInput';
import CustomDropdown from './customDropdown';

class CustomerDetails extends Component {
  constructor(props) {
    super(props);
    const { customer } = props;

    this.state = {
      canSubmit: true,
      isPristine: true,
      triggerError: false,
    }

    this.enableButton = this.enableButton.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleIntlShipping = this.toggleIntlShipping.bind(this);
  }

  enableButton() {
    this.setState({
      triggerError: false,
      canSubmit: true,
    });
  }

  onSubmit(event) {
    this.props.handleSubmit("shipping");
  }

  handleInvalidSubmit() {
    this.setState({
      triggerError: true,
      canSubmit: false
    })
  }

  handleChange(field, event) {

    var nextState = {};
    nextState.isPristine = false;
    nextState[field] = String(event);

    this.setState(nextState);

    this.props.updateState(field, String(event));

    if (field === "country") {
      this.toggleIntlShipping(event)
    }
  }

  toggleIntlShipping(country) {
    if (country !== "Australia") {
      this.props.updateState("shipping", "international");
    }
    else {
      this.props.updateState("shipping", "standard");
    }
  }

  render() {
    return (
      <div className="form-page">
        <Formsy
          onValidSubmit={this.onSubmit}
          onValid={this.enableButton}
          onInvalidSubmit={this.handleInvalidSubmit}
          className="customerform"
        >
          <h2>Customer Details</h2>
          <CustomInput
            name="firstname"
            placeholder="First name*"
            handleChange={this.handleChange.bind(this, 'firstname')}
            value={this.props.customer.firstname}
            validations="maxLength:35"
            validationError="Max 35 Characters"
            required
          />
          <CustomInput
            name="lastname"
            placeholder="Last name*"
            value={this.props.customer.lastname}
            validations="maxLength:35"
            validationError="Max 35 Characters"
            handleChange={this.handleChange.bind(this, 'lastname')}
            required
          />
          <CustomInput
            name="phone"
            value={this.props.customer.phone}
            placeholder="Phone number*"
            validations="maxLength:25"
            validationError="Max 25 Characters"
            handleChange={this.handleChange.bind(this, 'phone')}
            required
          />
          <CustomInput
            name="email"
            validations="isEmail"
            placeholder="Email*"
            validationError="This is not a valid email"
            handleChange={this.handleChange.bind(this, 'email')}
            value={this.props.customer.email}
            required
          />
           <CustomInput
            name="repeatedEmail"
            validations="equalsField:email"
            placeholder="Email Confirmation*"
            validationErrors={{equalsField:"Emails not identical"}}
            handleChange={this.handleChange.bind(this, 'repeatedEmail')}
            value={this.props.customer.repeatedEmail}
            required
          />
          <CustomDropdown
            name="referral"
            placeholder="How did you hear about us? (Optional)"
            value={this.props.customer.referral}
            handleChange={this.handleChange.bind(this, 'referral')}
            className="last"
          />
          <h2 className="break">Shipping Details</h2>
          <CustomInput
            name="address_line1"
            placeholder="Address line 1*"
            value={this.props.customer.address_line1}
            validations="maxLength:50"
            validationError="Max 50 Characters"
            handleChange={this.handleChange.bind(this, 'address_line1')}
            required
          />
          <CustomInput
            name="address_line2"
            placeholder="Address line 2 (Optional)"
            value={this.props.customer.address_line2}
            validations="maxLength:50"
            validationError="Max 50 Characters"
            handleChange={this.handleChange.bind(this, 'address_line2')}
          />
          <CustomInput
            name="city"
            placeholder="City/Town*"
            value={this.props.customer.city}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'city')}
            required
          />
          <CustomInput
            name="state"
            placeholder="State/Region*"
            value={this.props.customer.state}
            validations="maxLength:30"
            validationError="Max 30 Characters"
            handleChange={this.handleChange.bind(this, 'state')}
            required
          />
          <CustomDropdown
            name="country"
            placeholder="Country"
            value={this.props.customer.country}
            validations="maxLength:50"
            handleChange={this.handleChange.bind(this, 'country')}
            className="last"
            validationError="Please select a valid country"
            required
          />
          <CustomInput
            name="postcode"
            placeholder="Postcode*"
            value={this.props.customer.postcode}
            validations="maxLength:8"
            validationError="Max 8 Characters"
            handleChange={this.handleChange.bind(this, 'postcode')}
            state
            required
          />
          {/* <CustomInput
            name="comments"
            placeholder="Comments (Optional)"
            validations="maxLength:500"
            validationError="Max 500 Characters"
            value={this.state.comments}
            handleChange={this.handleChange.bind(this, 'comments')}
          /> */}
          { !this.state.canSubmit
            ? (
              <div className="errors">
                <p className="error">Please complete all required fields above</p>
                <p className="error">*Denotes required field</p>
              </div>
              )
            : null
          }
          <button type="submit" disabled={this.props.isFormSubmitted && !this.props.canSubmit}>Continue</button>
        </Formsy>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDetails);