import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';

//NOTE: CSS for this is exactly the same as "buy.js"

const Gifts = ({name}) => {
  return (
    <div className="shop-products">
      <Helmet
        title="Looking to Purchase a Graduation Gift? Shop at Gowning Street"
        meta={[
          {"name": "description", "content": "We have an array of Graduation Gifts at great prices! Fast Shipping Australia-Wide"},
        ]}
      >
      </Helmet>
      <h1>Graduation Gifts to complete your graduation experience</h1>
        <div className="subheading">
          <h2>Add a graduation bear, frame or cap to your graduation package!</h2>
        </div>
        <div className="catalogue">
          <div className="img-container">
            <div className="img-box">
            <Link to="/graduation-gown/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>$19</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>$25</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>$19</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/certificate-frame">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1566700903/Frames/Adelaide/Adelaide_Traditional_Maroon.jpg" alt="Degree certificate frame"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Certificate Frame</strong>
                  <p>$49</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-hat">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg" alt="Graduation hat"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Hat</strong>
                  <p>$49</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
          </div>
        </div>
        <div className="products-desc">
          <h2>Graduation Gifts</h2>
          <p>Looking for a graduation gift? You have come to the right place, Gowning Street has an assortment of great graduation gift ideas. Whether it's a <Link to="/certificate-frame">graduation frame</Link>, <Link to="/graduation-gown/graduation-teddy-bear">graduation teddy bear</Link>, or something special, we have something that can meet your needs. This is a time to celebrate that special moment and the team at Gowning Street are able to assist you in making it even better!
          </p>
          <h2>Needing to Buy a Graduation Present?</h2>
          <p>A graduation present is the perfect way to celebrate a loved one's special moment. When thinking of a graduation present idea, you want to buy something that is meaningful. We try and source items from individual artists that are custom made especially for graduation. You will find that each of our products, is well thought-out and appropriate for the significance of this occasion.</p>
          <h2>Graduation Gifts for Boys</h2>
          <p>We have available excellent graduation gifts for boys. From cufflinks to ties, we have something to suit everyone.  So when you are thinking about a graduation gift for him, you have come to the right place, we keep an active search for creative products that we think would make the perfect grad gift.</p>
          <h2>Graduation Gift for girls</h2>
          <p>Seeking a Graduation Gift for girls? This would be a special time in her life and a graduation gift for her would be an excellent way to mark the occasion. We have available, fun, creative grad gifts that we know she will appreciate. Take a look at our cute graduation puppy soft toys and our handcrafted certificate frames. We ship Australia-Wide and internationally too, and if you order before 12pm we can offer same day dispatch (subject to peak period) </p>
          <h2>Uni Graduation Gift</h2>
          <p>If you are looking for a uni graduation gift to purchase online you have come to the right place. Gowning Street stocks premium quality university graduation gift products. All of our products are selected carefully ensuring that you buy with confidence. We are confident that you will be buying a gift that will mark this occasion in your loved ones life appropriately</p>
          <h2>University Graduation Gift</h2>
          <p>If you are thinking about a University graduation gift, we have some lovely options for your perusal.  We have beautifully crafted pine- wood degree frames that would be a brilliant choice in preserving your special someone's University graduation certificate for life. We also have a range of grad teddies, grad puppies and other accessories that make graduation that much more memorable. Get in touch with someone from our team and we can give you some ideas!</p>
          <h2>Graduation Gift for Friend</h2>
          <p>A graduation gift for a friend is a lovely way to celebrate your friends significant milestone. your friend will treasure your graduation gift forever. This marks a significant milestone in their life and there are many graduation gifts that you could consider for your friend. A graduation frame is one of our best sellers,  our frames are made of premium pine wood and ensures that your friends graduation certificate will be well preserved for life. We also have cute teddy bears and graduation puppies available.</p>
          <h2>Graduation Gift for Daughter</h2>
          <p> You might be wondering what the best graduation gift for your daughter is? Gowning Street can help you with this decision, we have available many gift ideas for your daughter's graduation. as part of our sourcing, we look for local talent to produce unique individual items that signify this achievement as best as possible. We reach out to independent artists that produce ethically sustainable environmental friendly craft. You will no doubt be impressed by the quality of our products ensuring you buy the perfect graduation gift for your daughter.</p>
          <h2>Graduation Gift for Son</h2>
          <p>A graduation gift for your son is the perfect way to celebrate this special day with him. You might be wondering what the perfect graduation gift for your son could be? At Gowning Street we stock premium quality graduation gifts. All of our graduation gifts are well-thought out and present the perfect way to mark this occasion. Every year our team is on the lookout for the best products that signify this momentous occasion. We constantly tailor our products year on year to ensure you have the best range of products to choose from.</p>
        </div>
    </div>
  )

};

export default Gifts;