import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';

const About = () => {

    return (
      <div className="about">
        <div className="about-contents">
          <Helmet
            title="About Us | Gowning Street"
            meta={[
              { "name": "description", "content": "Gowning Street is committed to providing Australian University students with affordable, high quality graduation regalia." },
            ]}
          />
          <h1>About Us</h1>
          <p className="maindes">Gowning Street is committed to supplying Australian University students with high quality graduation regalia at extremely affordable prices. We believe that graduation is one of the most memorable and cherishable events of the University experience. So here’s how Gowning Street can make it better:</p>
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165128/Placement%20Photos/graduate-on-uni-stairs.jpg" alt="University graduate wearing gown set sitting on University steps" />
          <h3>A BRAND TRUSTED BY UNIVERSITIES AND STUDENTS ACROSS AUSTRALIA</h3>
          <p>Gowning Street is a premium Australian Owned company which is positioned to offer classical and approved Australian University graduation regalia with impeccable fits and an affordable price tag. Needless to say that our academic gowns are made only from premium quality fabric.</p>
          <h3>FAST AND EFFICIENT DELIVERY</h3>
          <p>Gowning Street is working with a state of the art logistics company based in Melbourne to ensure optimal package dispatch. This means you won’t be waiting long for your graduation set to arrive</p>
          <h3>MORE AFFORDABLE THAN HIRE</h3>
          <p>Don’t spend hours waiting in line on your graduation day to rent a second hand gown. Not to mention being extra careful to return within the two hour rental window to avoid the “late fee”.</p>
          <p>Take your graduation day into your own hands, take photographs at a time convenient to you. Buy your graduation set, have it delivered to your doorstep and have your peace of mind on your graduation day. This is your day to treasure.</p>
          <h3>NO STRESS GRADUATION DAY</h3>
          <p>Why spend hours waiting in line on your graduation day to rent a second hand gown? Not to mention being extra careful to avoid “damage” or “soiling”, or rushing to avoid a “late fee”. Buy your graduation set, have it delivered to your doorstep and have fun on your graduation day.</p>
          <h3>LIFELONG MEMORABILIA</h3>
          <p>To everyone who touched your life. To parents who stood by you no matter what. To teachers who tried to instill in you a passion for learning. To administrators who wanted to make your college experiences as amazing as possible. To advisors who helped you decide what path to take. To friends who were there for pizza parties, study sessions, and everything in between. And to all those others you depended on to earn your degree. Your graduation is a significant milestone, you have worked hard to earn that black graduation hat and gown and you deserve to keep and cherish it. Happy Graduation!</p>
          <p>With Love,</p>
          <p>The Gowning Street Team</p>
        </div>
      </div>
    )

};

export default About;