import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import HowItWorks from './HowItWorks';
import QualityGuarantee from './QualityGuarantee';
import PopularProducts from './PopularProducts';
import HomeContent from './homecontent';
import UniInfo from '../../sources/universities';


import { Products } from '../../sources/products'

const Home = () => {

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  const onChange = (event) => {
    setSearchValue(event.target.value.substr(0, 140));

    let results = [];

    for(var i in UniInfo) {
      const lowerCaseTerm = event.target.value.toLowerCase();
      if (UniInfo[i].searchName.toLowerCase().indexOf(lowerCaseTerm) !== -1) {
        results.push({
          name : UniInfo[i].searchName,
          path : i
        });
      }

      if(results.length === 4){
        break;
      }
    }

    if(results.length === 0) {
      results = [
      { name : 'Sorry, no results matched your search.',
        path :  null}
    ]

    }
    if(event.target.value.length < 2) {
      results = [];
    }
    setSearchResults(results)
  }

  return (
    <div className="homepage">
      <Helmet
        title="Buy Graduation Gown Sets Australia | Academic Gowns & Dress from Gowning Street"
        meta={[
          {"name": "description", "content": "Forget renting an academic gown or dress. At Gowning Street you can buy your graduation gowns and save money! Bachelor, master or doctoral gowns shipped to your door throughout Australia"},
        ]}
      />
      <div className="home">
        <h2>Hand Crafted Regalia</h2>
        <div id="title-search">
          <h2 className="maintitle">Gowning Street</h2>
          <h3 className="priceline">Buy Quality Regalia from $79</h3>
          <div className="navButtons">
            <div id="row1">
              <Link to="/graduation-gown"><button>Shop now!</button></Link>
              <Link to="/uni"><button>University Sets</button></Link>
            </div>
            <div id="popular-products">
              <h4>Popular Products:</h4>
              <div id="row2">
                <Link to="/uni/qld/uq"><button>UQ Gowns</button></Link>
                <Link to="/uni/qld/qut"><button>QUT Gowns</button></Link>
              </div>
              <div id="row2">
                <Link to="/uni/tas/utas"><button>UTAS Gowns</button></Link>
                <Link to="/uni/nsw/usyd"><button>USyd Gowns</button></Link>
              </div>
              <div id="row4">
                <Link to="/graduation-gown"><button>Regalia for Schools</button></Link>
                <Link to="/graduation-gown/graduation-hoods"><button>Graduation Hoods</button></Link>
              </div>
            </div>
          </div>
          <div id="main-input">
            <input
              placeholder="University Search"
              type="text"
              value={searchValue}
              onChange={onChange}
            />
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/search_grey_18x18.png"/>
          </div>
          <div id="mainsearchresults">
            {searchResults.length > 0 && searchResults.map((element) => {
                if(element.path !== null) {
                  return (
                    <Link to={element.path} key={element.path}>
                      <p key={element.name}>{element.name}</p>
                    </Link>
                    )
                }
                else {
                  return (
                    <p key={element.name}>{element.name}</p>
                  )
                }
              })
            }
          </div>
        </div>
      </div>
      <HowItWorks />
      <PopularProducts />
      <QualityGuarantee />
      <HomeContent />
    </div>
  )
};

export default Home;