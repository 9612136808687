module.exports = {
  "GOW00001" : "Bachelor Gown - XS",
  "GOW00002" : "Bachelor Gown - S",
  "GOW00003" : "Bachelor Gown - M",
  "GOW00004" : "Bachelor Gown - L",
  "GOW00005" : "Bachelor Gown - XL",
  "GOW00011" : "Master Gown - XS",
  "GOW00012" : "Master Gown - S",
  "GOW00013" : "Master Gown - M",
  "GOW00014" : "Master Gown - L",
  "GOW00015" : "Master Gown - XL",
  "GOW00021" : "PhD Gown - XS",
  "GOW00022" : "PhD Gown - S",
  "GOW00023" : "PhD Gown - M",
  "GOW00024" : "PhD Gown - L",
  "GOW00025" : "PhD Gown - XL",
  "GOW00031" : "PhD Gown - XS",
  "GOW00032" : "PhD Gown - S",
  "GOW00033" : "PhD Gown - M",
  "GOW00034" : "PhD Gown - L",
  "GOW00035" : "PhD Gown - XL",
  "GOW00041" : "PhD Gown - XS",
  "GOW00042" : "PhD Gown - S",
  "GOW00043" : "PhD Gown - M",
  "GOW00044" : "PhD Gown - L",
  "GOW00045" : "PhD Gown - XL",
  "HAT00001" : "Cap - XS",
  "HAT00002" : "Cap - S",
  "HAT00003" : "Cap - M",
  "HAT00004" : "Cap - L",
  "HAT00005" : "Cap - XL",
  // "HAT00021" : "PhD Hat (Red) - XS",
  // "HAT00022" : "PhD Hat (Red) - S",
  // "HAT00023" : "PhD Hat (Red) - M",
  "HAT00024" : "PhD Hat (Red) - S",
  "HAT00025" : "PhD Hat (Red) - M",
  "HAT00026" : "PhD Hat (Red) - L",
  // "HAT00031" : "PhD Hat (Yellow) - XS",
  // "HAT00032" : "PhD Hat (Yellow) - S",
  // "HAT00033" : "PhD Hat (Yellow) - M",
  "HAT00033" : "PhD Hat (Yellow) - S",
  "HAT00034" : "PhD Hat (Yellow) - M",
  "HAT00035" : "PhD Hat (Yellow) - L",
  "MAT00001" : "Matte - Manor Black",
  "MAT00002" : "Matte - Navy Blue",
  "MAT00003" : "Matte - Burgundy",
  "MAT00004" : "Matte - Forest Green",
  "MAT00005" : "Matte - Light Cream",
  "MAT00311" : "Matte - Manor Black",
  "MAT00312" : "Matte - Navy Blue",
  "MAT00313" : "Matte - Burgundy",
  "MAT00314" : "Matte - Forest Green",
  "MAT00315" : "Matte - Light Cream",
  "MAT00321" : "Matte - Manor Black",
  "MAT00322" : "Matte - Navy Blue",
  "MAT00323" : "Matte - Burgundy",
  "MAT00324" : "Matte - Forest Green",
  "MAT00325" : "Matte - Light Cream",
  "MAT00331" : "Matte - Manor Black",
  "MAT00332" : "Matte - Navy Blue",
  "MAT00333" : "Matte - Burgundy",
  "MAT00334" : "Matte - Forest Green",
  "MAT00335" : "Matte - Light Cream",
  "MAT00021" : "Matte - Manor Black",
  "MAT00022" : "Matte - Navy Blue",
  "MAT00023" : "Matte - Burgundy",
  "MAT00024" : "Matte - Forest Green",
  "MAT00025" : "Matte - Light Cream",
  "PUP00001" : "Large Puppy",
  "PUP00002" : "Small Puppy",
  "TED00001" : "Small Teddy",
};