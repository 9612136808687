import React, { Component } from 'react';
import NavigationButton from './navigationButton';

class ExchangeShipping extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <h1>Step 4: Confirm Delivery Address</h1>
        <div className="exchangeShippingContainer">
          <input
            type="text"
            placeholder="First Name"
            value={this.props.firstname}
            onChange={(event) => this.props.handleChange(event, "firstname")}
          />
          <input
            type="text"
            placeholder="Last Name"
            value={this.props.lastname}
            onChange={(event) => this.props.handleChange(event, "lastname")}
          />
          <input
            type="text"
            placeholder="Phone Number"
            value={this.props.phone}
            onChange={(event) => this.props.handleChange(event, "phone")}
          />
          <input
            type="text"
            placeholder="Email"
            value={this.props.email}
            onChange={(event) => this.props.handleChange(event, "email")}
          />
          <input
            type="text"
            placeholder="Address Line 1"
            value={this.props.address_line1}
            onChange={(event) => this.props.handleChange(event, "address_line1")}
          />
          <input
            type="text"
            placeholder="Address Line 2"
            value={this.props.address_line2}
            onChange={(event) => this.props.handleChange(event, "address_line2")}
          />
          <input
            type="text"
            placeholder="City"
            value={this.props.city}
            onChange={(event) => this.props.handleChange(event, "city")}
          />
          <input
            type="text"
            placeholder="State"
            value={this.props.state}
            onChange={(event) => this.props.handleChange(event, "state")}
          />
          <input
            type="text"
            placeholder="Postcode"
            value={this.props.postcode}
            onChange={(event) => this.props.handleChange(event, "postcode")}
          />
          <div className="shippingSelection">
            <p>Shipping:</p>
            <select
              className="dropdown"
              value={this.props.shipping}
              onChange={(event) => this.props.handleChange(event, "shipping")}
            >
              <option value="standard">
                Standard: Permission to leave on premises
              </option>
              <option value="standard-signature">
                Standard: Signature on receipt
              </option>
              <option value="express">
                Express: Permission to leave on premises
              </option>
              <option value="express-signature">
                Express: Signature on receipt
              </option>
            </select>
            <div className="navigateButtonGroup">
              <NavigationButton handleClick={this.props.navigateBack} label="Back" />
              <NavigationButton handleClick={this.props.confirmShipping} label="Next" />
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default ExchangeShipping;