import React, { useState } from 'react';
import Loader from './loader';

const customButton = ({ handleSubmit, dataFetched, content}) => {
  const [isClicked, setIsClicked] = useState(false);

  const submit = () => {
    if(!isClicked || dataFetched){
      setIsClicked(true)
      handleSubmit();
    }
  }
    return (
      <div className="custombutton">
        {
          isClicked && !dataFetched ?
            <Loader />
            : <button onClick={submit}>{content || "Submit"}</button>
        }
      </div>
    )
};

export default customButton;