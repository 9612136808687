import React from 'react';

const ShippingTable = () => (
  <div className="shipping-table">
    <p className="label">Number of business days:</p>
    <table>
      <thead>
        <tr>
          <th>Destination</th>
          <th>Standard</th>
          <th>Express</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>VIC - Metro</td>
          <td>3</td>
          <td>2</td>
        </tr>
        <tr>
          <td>VIC - Outer Regions</td>
          <td>4</td>
          <td>2-3</td>
        </tr>
        <tr>
          <td>NSW - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>NSW - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>QLD - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>QLD - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>SA - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>SA - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>TAS - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>TAS - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>ACT - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>ACT - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>WA - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>WA - Outer Regions</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>NT - Metro</td>
          <td>5-7</td>
          <td>3</td>
        </tr>
        <tr>
          <td>NT - Outer Regions</td>
          <td>13-14</td>
          <td>3-4</td>
        </tr>
        <tr>
          <td>International</td>
          <td>
            <a style={{cursor: 'pointer'}}  target="_blank" href="https://auspost.com.au/sending/delivery-speeds-and-coverage/international-delivery-times">
              See Auspost
            </a>
          </td>
          <td>N/A</td>
        </tr>
      </tbody>
    </table>
    <div className="description">
      <p>Please also allow 1 business day for dispatch from our warehouse, particularly for orders placed after 12 noon. Times shown above are in addition to dispatch times.</p>
      <p>Please note that these are the delivery times as suggested by Australia Post, and may vary during peak periods. We strongly suggest leaving a buffer prior to your graduation. Gowning Street cannot guarantee the above times and will not be held responsible for delays.</p>
      <p>
        For more information on Australia post delivery estimates please visit the
        <a href="http://auspost.com.au/parcels-mail/standard-delivery-timetables.html" target="_blank"> Australia Post website</a>
      </p>
    </div>
  </div>
)

export default ShippingTable;