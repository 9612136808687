module.exports = {
  "/uni/qld": {
    "name": "Queensland  | Graduation Gown sets for University Graduates in QLD",
    "abbrev": "Queensland",
    "path": "/uni/qld",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg",
    "alt": "Queensland University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Queensland",
        "path": "/uni/qld/uq",
        "abbrev": 'uq'
      },
      {
        "name": "Queensland University of Technology",
        "path": "/uni/qld/qut",
        "abbrev": 'qut'
      },
      {
        "name": "James Cook University",
        "path": "/uni/qld/jcu",
        "abbrev": 'jcu'
      },
      {
        "name": "Australian Catholic University",
        "path": "/uni/acu",
        "abbrev": 'acu'
      },
      {
        "name": "Bond University",
        "path": "/uni/qld/bond",
        "abbrev": 'bond'
      },
      {
        "name": "Southern Cross University",
        "path": "/uni/scu",
        "abbrev": 'scu'
      },
      {
        "name": "University of Southern Queensland",
        "path": "/uni/qld/usq",
        "abbrev": 'usq'
      },
      {
        "name": "Griffith University",
        "path": "/uni/qld/griffith",
        "abbrev": 'griffith'
      },
      {
        "name": "Central Queensland University",
        "path": "/uni/qld/cqu",
        "abbrev": 'cqu'
      },
      {
        "name": "University of the Sunshine Coast",
        "path": "/uni/qld/usc",
        "abbrev": 'usc'
      }
    ]
  },

  "/uni/nsw": {
    "name": "New South Wales | Graduation Gown sets for University Graduates in NSW",
    "abbrev": "New South Wales",
    "titleTag": "New South Wales | NSW Graduation Gown in Sydney | Select Gown Colours",
    "MetaTag": "At Gowning Street, you can select the colour of gown you desire. We offer fast and reliable delivery of NSW graduation gowns across Sydney & New South Wales. Discount on bulk orders!",
    "path": "/uni/nsw",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg",
    "alt": "New South Wales University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Sydney",
        "path": "/uni/nsw/usyd",
        "abbrev": 'usyd'
      },
      {
        "name": "University of New England",
        "path": "/uni/nsw/une",
        "abbrev": 'une'
      },
      {
        "name": "Macquarie University",
        "path": "/uni/nsw/mq",
        "abbrev": 'mq'
      },
      {
        "name": "Southern Cross University",
        "path": "/uni/scu",
        "abbrev": 'scu'
      },
      {
        "name": "University of Newcastle",
        "path": "/uni/nsw/uon",
        "abbrev": 'uon'
      },
      {
        "name": "Australian Catholic University",
        "path": "/uni/acu",
        "abbrev": 'acu'
      },
      {
        "name": "University of Western Sydney",
        "path": "/uni/nsw/uws",
        "abbrev": 'uws'
      },
      {
        "name": "Charles Sturt University",
        "path": "/uni/csu",
        "abbrev": 'csu'
      },
      {
        "name": "University of Technology Sydney",
        "path": "/uni/nsw/uts",
        "abbrev": 'uts'
      },
      {
        "name": "University of New South Wales",
        "path": "/uni/nsw/unsw",
        "abbrev": 'unsw'
      },
      {
        "name": "University of Notre Dame",
        "path": "/uni/notre-dame",
        "abbrev": 'notre-dame'
      }
    ]
  },

  "/uni/vic": {
    "name": "Victoria | Graduation Gown sets for  University Graduates in VIC",
    "abbrev": "Victoria",
    "path": "/uni/vic",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg",
    "alt": "Victorian University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Melbourne",
        "path": "/uni/vic/unimelb",
        "abbrev": 'unimelb'
      },
      {
        "name": "Australian Catholic University",
        "path": "/uni/acu",
        "abbrev": 'acu'
      },
      {
        "name": "RMIT University",
        "path": "/uni/vic/rmit",
        "abbrev": 'rmit'
      },
      {
        "name": "Federation University",
        "path": "/uni/vic/federation-university",
        "abbrev": 'federation-university'
      },
      {
        "name": "Monash University",
        "path": "/uni/vic/monash",
        "abbrev": 'monash'
      },
      {
        "name": "Victoria University",
        "path": "/uni/vic/victoria-university",
        "abbrev": 'victoria-university'
      },
      {
        "name": "Swinburne University",
        "path": "/uni/vic/swinburne",
        "abbrev": 'swinburne'
      }
    ]
  },

  "/uni/sa": {
    "name": "South Australia | Graduation Gown sets for University Graduates in SA",
    "abbrev": "South Australia",
    "titleTag": "Uni Graduation Gowns Available in Adelaide, South Australia | Gown Suppliers",
    "metaTag": "Buy full graduation gown sets in South Australia at very reasonable price. We offer  multiple color collections of graduation gowns in Adelaide South Australia. Buy Now!",
    "path": "/uni/sa",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg",
    "alt": "South Australian University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Adelaide",
        "path": "/uni/sa/adelaide-uni",
        "abbrev": 'adelaide-uni'
      },
      {
        "name": "University of South Australia",
        "path": "/uni/sa/unisa",
        "abbrev": 'unisa'
      },
      {
        "name": "Australian Catholic University",
        "path": "/uni/acu",
        "abbrev": 'acu'
      },
      {
        "name": "Flinders University",
        "path": "/uni/sa/flinders",
        "abbrev": 'flinders'
      }
    ]
  },

  "/uni/tas": {
    "name": "Tasmania | Graduation Gown sets for University Graduates in TAS",
    "abbrev": "Tasmania",
    "titleTag": "Graduation & Academic Gowns For Sale in Tasmania",
    "path": "/uni/tas",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1549165146/Placement%20Photos/graduation-celebration.png",
    "alt": "Tasmanian University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Tasmania",
        "path": "/uni/tas/utas",
        "abbrev": 'utas'
      }
    ]
  },

  "/uni/act": {
    "name": "Australian Capital Territory | Graduation Gown sets for University Graduates in ACT",
    "abbrev": "Australian Capital Territory",
    "path": "/uni/act",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1549165145/Placement%20Photos/graduation-group-back-portrait.png",
    "alt": "ACT University graduates celebrating their graduation",
    "unis": [
      {
        "name": "Australian National University ",
        "path": "/uni/act/anu",
        "abbrev": 'anu'
      },
      {
        "name": "University of Canberra",
        "path": "/uni/act/uc",
        "abbrev": 'uc'
      },
      {
        "name": "Australian Catholic University",
        "path": "/uni/acu",
        "abbrev": 'acu'
      }
    ]
  },

  "/uni/wa": {
    "name": "Western Australia | Graduation Gown sets for University Graduates in WA",
    "abbrev": "Western Australia",
    "path": "/uni/wa",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg",
    "alt": "Western Australian University graduates celebrating their graduation",
    "unis": [
      {
        "name": "University of Western Australia",
        "path": "/uni/wa/uwa",
        "abbrev": 'uwa'
      },
      {
        "name": "Edith Cowan University",
        "path": "/uni/wa/ecu",
        "abbrev": 'ecu'
      },
      {
        "name": "Curtin University",
        "path": "/uni/wa/curtin",
        "abbrev": 'curtin'
      },
      {
        "name": "University of Notre Dame",
        "path": "/uni/notre-dame",
        "abbrev": 'notre-dame'
      }
    ]
  },


  "/uni/nt": {
    "name": "Northern Territory | Graduation Gown sets for University Graduates in NT",
    "abbrev": "Northern Territory",
    "path": "/uni/nt",
    "photo": "https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg",
    "alt": "Northern Territory University graduates celebrating their graduation",
    "unis": [
      {
        "name": "Charles Darwin University",
        "path": "/uni/nt/cdu",
        "abbrev": 'cdu'
      }
    ]
  },



};