import React, { useState } from 'react';

const FramesDescription = (props) => {
  const [open, setOpen] = useState(false);
  const leftAlign = {
    textAlign: 'left',
  }
  const summary = {
    paddingTop: 0,
    textAlign: 'center',
    lineHeight: '20px',
    maxWidth: '100%',
    marginBottom: '30px',

  }
  const emphasized = {
    fontWeight: 900,
    paddingBottom: 5,
  }
  const centerAlign = {
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '20px',
    marginBottom: '10px',
    maxWidth: '100%',
  }

  return (
    <div className="product-description">
      <div className="description-head" onClick={() => setOpen(!open)} >
        <img
          src={open
            ? "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/minus_box_grey_24x24.png"
            : "https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/add_box_grey_18x18.png"}
        />
        <h4>Product Details</h4>
      </div>
      <div
        className="inner-description"
        style={open
          ? {
            display: 'block'
          } : {
            display: 'none'
          }}>
        <div className="content" style={leftAlign}>
          <h2 style={centerAlign}>{props.uniShortName}{' '} Degree Frame</h2>
          <p style={summary}>Our Hand-Crafted frames are made to fit the current size of {props.uniShortName} testamur perfectly.</p>
          <h3 style={centerAlign}>Our {props.uniShortName}{' '} Degree Certificate Frame includes:</h3>
          <p><span style={emphasized}>Your choice of frame styles:</span><br /> Choose from modern black or pine wood</p>
          <p><span style={emphasized}>Your choice of five matte colours:</span><br /> Choose from manor black, navy blue, forest green, burgundy, or light cream</p>
          <p><span style={emphasized}>Custom-cut matte:</span><br /> The matte is made specifically to fit testamurs from {props.uniShortName}</p>
          <p><span style={emphasized}>Glass front:</span><br />We use real, solid glass of 1.8mm thickness</p>
          <p><span style={emphasized}>Ultra-safe packaging:</span><br />To prevent any breakages in the post, we have conducted drop-tests in conjunction with our logistics specialists. We have developed a packaging system that is highly resistant to breakage in the post</p>
          <p><span style={emphasized}>
            Easy setup:</span><br /> Once you receive your frame, the only thing left to do is insert your {props.uniShortName} degree certificate. Just follow our simple instructions attached
          </p>
        </div>
      </div>
    </div >
  )

};

export default FramesDescription;