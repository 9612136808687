import React from 'react';
import ShippingTable from './shipping-calculator';
import Helmet from 'react-helmet';

const ShippingPage = () => {

  return (
    <div className="shipping-page">
      <Helmet
        title="Shipping Estimator | Gowning Street"
        meta={[
          {"name": "description", "content": "Gowning Street is committed to providing fast dispatch and delivery times through Australia Post. Please see our shipping guide for delivery time estimates."},
        ]}
      />
      <h1>Shipping Estimates</h1>
      <ShippingTable />
    </div>
  )

};

export default ShippingPage;