import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import CustomerDetails from './form';
import ShippingDetails from './shipping';
import Confirmation from './confirmation';
import LockSvg from '../../assets/icons/lock.svg'

class Checkout extends Component {
  constructor(props){
    super(props);

    this.state = {
      view : 'customer',
      customer : true,
      shipping : false,
      order : false,
      payment : false
    }

    this.navigate = this.navigate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  navigate(event) {
    this.setState({
      view : event.target.id
    });
  }

  handleSubmit(view) {
    this.setState({
      view : view
    })
  }

  render() {
    const checkoutStep = this.state.view === 'customer'
      ? 1
      : this.state.view === 'shipping'
        ? 2
        : 3;
    return (
      <div className="checkout">
        <Helmet
          title="Secure Checkout | Gowning Street"
          meta={[
            {"name": "description", "content": "Gowning Street's secure checkout platform."},
          ]}
        >
        </Helmet>
        <div className="checkout-header">
          <img src={LockSvg} />
          <h3>SECURE CHECKOUT (Step {checkoutStep} of 3)</h3>
        </div>
        {this.state.view === "customer" ? <CustomerDetails handleSubmit={this.handleSubmit}/> : null}
        {this.state.view === "shipping" ? <ShippingDetails handleSubmit={this.handleSubmit}/> : null}
        {this.state.view === "confirmation" ? <Confirmation handleSubmit={this.handleSubmit}/> : null}
      </div>
    )
  }
};

const mapStateToProps = (state) => {
  return {
    customer: state.customer
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field : field,
        value : value
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);