import React from 'react';
import { Link } from "react-router-dom";
import Helmet from 'react-helmet';
import UniStates from '../sources/states';

const Buy = () => {
  const toggleState = (index) => {

    var button = document.getElementById(`button-${index}`);
    var unis = document.getElementById(index);

    if (unis.style.display === "none") {
      unis.style.display = "";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/minus_box_white_24x24.png";
    }
    else {
      unis.style.display = "none";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Utility/add_box_white_24x24.png";
    }
  }


  var states = Object.getOwnPropertyNames(UniStates);

  var hidden = {
    display: "none"
  }
  var toggle = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Utility/add_box_white_24x24.png";

  var List = states.map(function (state, index) {
    index++;
    var Unis = UniStates[state].unis.map(function (uni) {
      return (
        <div key={uni.name} className="unititle">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
          <Link to={uni.path}>
            <p>{uni.name}</p>
          </Link>
        </div>
      )
    })

    return (
      <div key={state}>
        <button className="uni-expander">
          <img className="toggle-icon" src={toggle} id={`button-${index}`} onClick={() => toggleState(index)} />
          <Link to={UniStates[state].path}>
            <h2 className="statetitle">{UniStates[state].abbrev}</h2>
          </Link>
        </button>
        <div className="uni-list" id={index} style={hidden}>
          {Unis}
        </div>
      </div>
    )
  });

  return (
    <div className="shop-gowns">
      <Helmet
        title="Buy Academic & Graduation Gowns for Australian Universities | Gowning Street"
        meta={[
          { "name": "description", "content": "At Gowning Street, we supply quality graduation gowns to Universities all over Australia, delivered directly to your door!" },
        ]}
      >
      </Helmet>
      <h1>Academic Dress for Australian Universities</h1>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-jumping.jpg" alt="University Graduates celebrating in their graduation gowns" className="topimage" />
      <div style={{ zIndex: '10', position: 'relative' }}>
        {List}
      </div>
      <div className="toproducts">
        <p>We have Academic dress available for <Link to="/uni/qld/qut">QUT</Link>, <Link to="/uni/qld/uq">UQ</Link>  and <Link to="/uni/tas/utas">UTAS</Link> to name a few. If you order before 12pm, you will get same day dispatch and most deliveries only take 3-4 days standard or 1-2 days express. If you need to purchase in bulk, we have excellent deals available where you can save up to $10 on each gown and save on shipping. If you are looking at a quality product at an affordable price, you have come to the right place! Gowning Street serves 1000’s of happy customers year after year, making your graduation that extra bit special. </p>

        <h3>Academic Dress Hire v Academic Dress Purchase</h3>
        <p>You might find that purchasing your academic dress from Gowning Street is sometimes more affordable than hiring. We serve a large number of Universities and Students every year and hence we are able to provide a better price point than most and with no compromise on quality, just jump online to see our reviews. We serve Universities across all states, so whether it is ANU academic dress or USYD academic dress we have it all here.</p>

        <h3>Graduation Hats, Graduation Hoods and More</h3>
        <p>If you just need a <Link to="/graduation-gown/graduation-hoods">graduation hood</Link> or <Link to="/graduation-gown/graduation-hat">graduation hat</Link> you have come to the right place. We sell these items individually, just jump over to our <Link to="/graduation-gown">main products page</Link> and have a look.
        </p>
      </div>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165146/Placement%20Photos/graduation-son-mum-hug-portrait.png" alt="University Graduate wearing graduation gown with family" className="mainimage" />
    </div>
  )

};

export default Buy;