import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import Loader from './custom-items/loader';

class PaypalConfirmation extends Component {
  constructor(props){
    super(props);

    this.state = {
      status : "Confirming your order ...",
      message : null,
      loading : false
    }
  }

  componentDidMount() {
    var href = window.location.pathname;
    var token = href.substring(11,href.length);

    var that = this;

    var query =  window.location.search.substring(1).split("&");

    if(query.length === 0 || query[0] === ""){
      this.setState({
        status : "Page not found",
      })
    }

    else {
      this.setState({
        loading : true
      })

      var queries = {};

      query.forEach(function(element){
        var pair = element.split("=");

        queries[pair[0]] = pair[1];
      })

      if(queries.paymentId || !queries.PayerID){
        var xhttp = new XMLHttpRequest();

        xhttp.addEventListener('load', function(){
          var response = JSON.parse(this.response);

          if(response.success === true){
            that.props.history.push("/thankyou?ordernum=" + response.orderNum);
          }

          else {
            that.setState({
              status : "Sorry, your order could not be confirmed",
              message : "Please try an alternative payment method, or contact us at enquiries@gowningstreet.com for assistance",
              loading : false
            })
          }
        })

        xhttp.open("POST", "/api/paypal/payment");
        xhttp.setRequestHeader("Content-Type", "application/json");
        xhttp.send(JSON.stringify({
          paymentId : queries.paymentId,
          PayerID : queries.PayerID,
          token : token
        }));
      }

      else {
        this.setState({
          status : "Page not found"
        })
      }

    }
  }

  render() {
    return (
      <div className="confirming">
        <Helmet
          title={this.state.status + " | Gowning Street"}
          meta={[
            {"name": "description", "content": this.state.status},
          ]}
        />
        <h1>{this.state.status}</h1>
        {this.state.loading ? <Loader /> : null}
        <p>{this.state.message}</p>
      </div>
    )
  }
};


const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    dropCart: (field, value) => {
      dispatch({
        type: 'EMPTY_CART',
      })
    }
  }
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(PaypalConfirmation));