import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';

import { Products } from '../../sources/products';
import { checkInventory } from '../cart/checkInventory';
import Gown from './gown';
import Cap from './cap';
import Hood from './hood';
import Qty from './qty';
import Popup from './popup';
import CustomButton from '../custom-items/button-loader';

import { connect } from 'react-redux';

const Items = (props) => {
  const path = window.location.pathname;
  const product = Products[path]
  const level = product.level;
  const phdStyle = product.phdStyle;
  const hatType = product.hatType;
  const name = product.name;
  const hood = product.hood;
  const hoodStyle = product["hood-style"];
  const productType = product.productType;
  const price = product.price;
  const image = product.photos[0];
  const gift = product["hood-code"];
  let hoodCode = product["hood-code"];
  let capCode = "HAT00003";
  let gownCode = "GOW00003";


  const [gown, setGown] = useState(gownCode);
  const [cap, setCap] = useState(capCode);
  const [qty, setQty] = useState(1);
  const [popup, setPopup] = useState(false);
  const [error, setError] = useState(null);
  const [isLoaded, setLoaded] = useState(null);

  useEffect(() => {
    setError(null)
    if (level === "Bachelor") {
      setGown('GOW00003')
    }
    else if (level === "Master"){
      console.log('its master')
      setGown('GOW00013')
    }
    else if (level === "PhD"){
      switch(phdStyle){
        case "cloth":
          setGown('GOW00021')
        break;
        case "red satin":
          setGown('GOW00041')
        break;

        default:
          setGown('GOW00031')
      }
      switch(hatType){
        case "red":
          setCap('HAT00024')
          break;

        case "yellow":
          setCap('HAT00033')
          break;

        default:
          setCap('HAT00003')
      }
    }
  }, [path])

  let errors;
  if(error){
    errors = error.map(function(error){
      if (error.maxQty === 0) {
        return (
          <p key={`out_of_stock_${error.code}`}>
            Sorry, we are currently out of stock of {error.description}. Please check again later.
          </p>
        )
      }
      else {
        return (
          <p key={error.code}>
            <p>Sorry, we only have {error.maxQty} of {error.description}.</p>
            <p>Please choose a lower amount.</p>
          </p>
        )
      }
    });
  }

  const handleClick = () => {
    const toShow = Products[window.location.pathname].show;
    const items = {
      gown,
      cap,
      hood: hoodCode,
      gift
    };

    ReactGA.event('add_to_cart', {
      category: 'add_to_cart',
      action: 'item added to cart',
      label: 'item added to cart',
      items: toShow.map(itemName => ({
        item_id: items[itemName],
        item_category: productType,
        quantity: qty,
        price,
      }))
    });

    const toCheck = toShow.map((itemName) => {
      return ({
        code: items[itemName],
        qty,
      })
    })

    checkInventory(toCheck, function(fails){
      const addToCartObject = {
        name,
        hoodStyle,
        qty,
        price,
        productType,
        image,
      }

      toShow.map(itemName => {
        return addToCartObject[itemName] = items[itemName]
      })

      if(fails.length === 0){
        props.onClick(
          {
            ...addToCartObject,
            isIsn: product?.isIsn ?? false
          }
        );
        setPopup(true);
        setLoaded(true);
      }
      else {
        setError(fails)
        setLoaded(true);
      }

    });
  }

  const renderSelections = () => {
    const { hood, hoodStyle, level, phdStyle, hatType } = product;
    let selections = product.show.map(function(element){
      switch(element){
        case "gown":
          return <Gown
            key={element}
            level={level}
            phdStyle={phdStyle}
            setGown={setGown}
            defaultGownCode={gown}
          />
        case "hood":
          return <Hood key={element} hood={hood} hoodStyle={hoodStyle} />
        case "cap":
          return <Cap
            key={element}
            setCap={setCap}
            level={level}
            hatType={hatType}
            defaultHatCode={cap}
          />
      }
    });
    return selections;
  }
  return (
    <div className="product-selections">
      { renderSelections(product, setGown, setCap) }
      <div className="quantity">
        <h3>Qty</h3>
        <Qty initialQty={1} setQty={setQty}/>
      </div>
      { error &&
        <div className="errors">
          {errors}
        </div>
      }
      <CustomButton
        handleSubmit={handleClick}
        content="Add to Cart"
        dataFetched={isLoaded}
      />
      {popup === true ?
        <Popup
          price={price}
          qty={qty}
          hidePopup={() => setPopup(false)}
          name={name}
        /> : null}
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    items: state.shoppingCart
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onClick: (addToCartObject) => {
      const addToCart = {
        type: "ADD_TO_CART",
        gown: null,
        cap: null,
        hood: null,
        gift: null,
        hoodStyle: null,
        productType: '',
        ...addToCartObject
      }
      dispatch(addToCart)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Items);
