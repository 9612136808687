import React from 'react';

function NavigationButton({
  label,
  handleClick,
  disabled,
}) {
return (
  <button onClick={handleClick} disabled={disabled}>
    {label}
  </button>
  )
}

export default NavigationButton;