import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../../constants';
import { BACHELOR_GOWN_PRICE, BACHELOR_SET_PRICE } from '../../../constants';

const FAQ = () => {

  return (
    <div className="FAQ">
      <Helmet
        title="FAQ | Gowning Street"
        meta={[
          {"name": "description", "content": "A list of our frequently asked questions"},
        ]}
      />
      <div className="FAQ-contents">
        <h1>Frequently Asked Questions | Gowning Street FAQ</h1>
        <h3>How can you sell your regalia for so little compared to most Universities? Are they actually good quality?</h3>
          <p>We do not pay store rent or employ a large contingent of staff and we run the process from end to end, ensuring top quality manufacturing right through to fast and efficient delivery. Our gowns are all hand-stitched from durable, high quality materials.</p>
        <h3>How long will delivery take?</h3>
          <p>Delivery times vary by location. Orders placed before 10am on a weekday leave our warehouse on the same day, while orders placed after that time leave on the next business day.</p>
          <p>You can find additional information on our <Link to="/shipping">Shipping Estimates Page</Link></p>
        <h3>Do you offer gown rental?</h3>
          <p>No, we do not offer gown rental at this stage. Our prices are often cheaper than rental services provided by your University</p>
        <h3>What is your exchange policy?</h3>
          <p style={{marginBottom: '20px'}}>Please use the <a href="https://www.gowningstreet.com.au/exchange" style={{fontWeight: '900', textDecoration: 'underline'}}>online form</a> to submit an exchange request for the ordered items.</p>
          <p>For your exchange to be accepted, all items must be in their original packaging, with the correct barcode attached and a printout of the exchange form.</p>
        <h3>What payment methods do you accept?</h3>
          <p>We accept payments via Paypal or via Credit/Debit card through Stripe</p>
        <h3>Do your gowns, mortarboards and hood colours match those rented out by my university?</h3>
          <p>Yes, our gowns and mortarboards are traditional Cambridge style regalia used by the majority universities in Australia. We are committed to ensuring that every garment we sell complies with university statutes. If in doubt, please refer to your University statute on the University website.</p>
        <h3>How should I treat my items once I receive them?</h3>
          <p>We recommend you hang your gown to allow any creases to fall out naturally. Gowns can also be ironed at low heat to remove any remaining creases. Similarly the mortarboards should be unpacked once received to remove any creases.</p>
        <h3>Can I receive a discount on bulk orders?</h3>
          <p>You can receive a bulk discount when you <Link to="/save-with-friends">buy with friends</Link>. For bulk orders of 25 or more gown sets, please contact us with the <Link to="/contact">Enquiry Form</Link> or reach us by phone on <a href={`tel:${GOWNING_STREET_PHONE_NUMBER}`} style={{'paddingRight': 0}}>{GOWNING_STREET_PHONE_NUMBER}</a>, or email us at <a href="mailto:enquiries@gowningstreet.com">enquiries@gowningstreet.com</a></p>
        <h3>What is appropriate to wear under a graduation gown?</h3>
          <p>For men, we recommend wearing business casual dress. Specifically, dark blue, black or grey business trousers. This should be matched by a white or light-coloured collared, button-up business shirt. Shoes should be black or brown with black socks. A tie is optional, subject to your University's requirements. Jeans, sports shoes, sandals and t-shirts should not be worn.</p>
          <p>For women, we recommend wearing a business shirt with collar and buttons. This is the most convenient option to ensure a professional appearance, as well as being able to fix the graduation hood in place. This can be matched with black or dark blue trousers. Dark coloured flats or pumps are preferrable to high heels for safety reasons. Jeans, t-shirts, sports shoes etc. should not be worn.</p>
        <h3>What do each of the graduation gown and hood colours represent</h3>
        <p>Most Australian Universities use black gowns for Bachelor and Masters graduations. PhD gowns will typically have a red linining in the centre of the gown and for some Universities, on the sleeves as well.</p>
        <p>Each University will have its own specifications when it comes to hood colours and design styles. This will differ by faculty and graduate level. At Gowning Street, we take great care to ensure each hood closely matches University specifications.</p>
        <h3>Can I wash my graduation gown?</h3>
        <p>Your graduation gown will be cleaned and ready to wear when you receive it. If you wish to clean your gown, we suggest hand washing in cold to warm water with gentle washing liquid. You can then hang to dry</p>
        <h3>How do I get the wrinkles out of a graduation gown?</h3>
        <p>You may iron your gown, but do not apply iron directly to the graduation gown fabric as this risks damaging the material. We recommend placing a damp, thin towel or old piece of clothing between the iron and the gown prior to ironing</p>
        <h3>How much is a graduation gown?</h3>
        <p>A full graduation gown set, including gown, cap and hood, is ${BACHELOR_SET_PRICE} per set. A graduation gown by itself is ${BACHELOR_GOWN_PRICE} per gown. You can also save up to $10 per gown when purchasing in bulk.</p>
        <h3>What is the cost of hiring a graduation gown?</h3>
        <p>Universities typically charge close to $150 to hire a graduation gown. Students will usually need to line up for hours to collect their gown and have limited time flexibility to return it. At Gowning Street, we offer full graduation gown sets for sale for ${BACHELOR_SET_PRICE}, which is often below the cost of hiring!</p>
      </div>
    </div>
  )

};

export default FAQ;