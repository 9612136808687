import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  BACHELOR_GOWN_PRICE,
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_CAP_PRICE,
  CAP_PRICE,
  GARMENT_BAG,
  HOOD_PRICE,
  MASTER_GOWN_CAP_PRICE,
  MASTER_GOWN_PRICE,
  PHD_BONNET_PRICE,
  PHD_GOWN_PRICE,
  PUPPY_LARGE_PRICE,
  PUPPY_SMALL_PRICE,
  TEDDY_SMALL_PRICE
} from '../../constants'

const Buy = () => {

  return (
    <div className="shop-products">
      <Helmet
        title="Buy a Graduation Gown or Other Regalia | Graduation Gowns, Hoods, Caps and Gifts"
        meta={[
          {"name": "description", "content": "Buy the best quality graduation products, including Bachelor and Master Style gowns, academic hoods and caps. Select any colour & size and better value than renting!"},
        ]}
      >
      </Helmet>
      <h1>Buy a Quality Graduation Gown or other Graduation Regalia Product</h1>
        <div className="subheading">
          <h2>Perfect for University, high school, college events, church and choir events</h2>
        </div>
        <div className="catalogue">
          <div className="img-container">
            <div className="img-box">
            <Link to="/uni">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165141/Regalia/full-uni-graduation-set.jpg" alt="Full university graduation gown set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Full University Reglia Sets</strong>
                  <p>${BACHELOR_SET_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/cap-and-gown-bachelor">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bachelor-cap-gown-set.jpg" alt="Bachelor style gown and trencher set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gown & Cap Sets - <br />Bachelor Style</strong>
                  <p>${BACHELOR_GOWN_CAP_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/cap-and-gown-master">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/master-cap-gown-set.jpg" alt="Master style gown and trencher set"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gown & Cap Sets - <br />Master Style</strong>
                  <p>${MASTER_GOWN_CAP_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/gown-bachelor">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165130/Regalia/bachelor-gown.jpg" alt="Bachelor style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gowns - <br />Bachelor Style</strong>
                  <p>${BACHELOR_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/master-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/mast-gown-front.jpg" alt="Master style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Gowns - <br />Master Style</strong>
                  <p>${MASTER_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/phd-gown-red-cloth">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-gown-rc-front.jpg" alt="PhD gown with red cloth lining"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">PhD gowns (Red cloth lining)</strong>
                  <p>${PHD_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/phd-gown-red-satin">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1635022419/Regalia/phd-gown-rs-front.jpg" alt="PhD gown with red satin lining"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">PhD gowns (Red satin lining)</strong>
                  <p>${PHD_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-hoods">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165121/Regalia/sitting-side-gown-set-red.jpg" alt="Academic hood"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Academic Hoods</strong>
                  <p>${HOOD_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-hat">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165127/Regalia/graduation-trencher.png" alt="graduation hat"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Hats</strong>
                  <p>${CAP_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/phd-bonnet-red">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1635022417/Regalia/phd-bonnet-red-side.jpg" alt="PhD hat with red tassel"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">PhD Hat (Red Tassel)</strong>
                  <p>${PHD_BONNET_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/phd-bonnet-yellow">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1635022418/Regalia/phd-bonnet-yellow-side.jpg" alt="PhD hat with yellow tassel"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">PhD Hat (Yellow Tassel)</strong>
                  <p>${PHD_BONNET_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-puppy-small">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165132/Regalia/graduation-puppy-furry-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Small</strong>
                  <p>${PUPPY_SMALL_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-puppy-large">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165131/Regalia/graduation-puppy-smooth-1.jpg" alt="graduation puppy"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Puppy - Large</strong>
                  <p>${PUPPY_LARGE_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/graduation-teddy-bear">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg" alt="graduation bear"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Teddy Bear</strong>
                  <p>${TEDDY_SMALL_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/graduation-gown/garment-bag">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1635022732/Regalia/graduation-gown-garment-bag.png" alt="garment bag"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Graduation Garment Bag</strong>
                  <p>${GARMENT_BAG}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
          </div>
        </div>
        <div className="products-desc">
          <h2>Wholesale graduation gowns for school students</h2>
          <p>Graduating from primary and secondary school is always a big occasion in the life of a child. Going that extra mile to give your students a grand graduation ceremony is definitely worth it! It will boost their self-confidence, help them take their academic achievements more seriously and give them lasting memories of their precious school days. If you are planning a year 6 or year 12 graduation ceremony, we are here to help! We take the stress out of graduation day by providing wholesale graduation gowns for school students of all ages and ceremonial gowns for school teachers too!
          </p>
          <h2>Bulk purchase graduation gowns for your entire class</h2>
          <p>We are capable of meeting the needs of schools of all sizes! Whether you need 50 or 500, we can supply handcrafted and finely tailored gowns for all your school student graduates. We provide:</p>
          <ul>
            <li>Multiple styles of gowns for primary age students</li>
            <li>Customised graduation gowns for year 12 graduation</li>
            <li>Customised graduation gowns for teachers and school staff</li>
            <li>Fabric and colour choices in all wholesale graduation gown design</li>
          </ul>
          <p>You can bulk purchase graduation gowns that are completely customised to your institute – pick the fabric, style and colours that represent your school and create a unique, world-class gown that represents the premium brand of your school!</p>
          <h2>Wholesale graduation gowns for school students at unbeatable prices</h2>
          <p>You can have that grand school graduation ceremony without blowing the budget! We understand the requirements of school communities and guarantee:</p>
          <ul>
            <li>High quality pieces at affordable prices</li>
            <li>Range of options so there is always something for everyone</li>
            <li>On time delivery to your school, so you get them well before graduation day and avoid last minute stress and hassle</li>
            <li>Cost recoverability from families without excluding anyone</li>
          </ul>
          <p>Our years of experience in gown design and wide network of tailors and cloth suppliers enables us to provide the best wholesale graduation gown deals to schools in Australia! You can confidently bulk purchase graduation gowns from us knowing that we are a 100% Australian owned and operated
          </p>
        </div>
    </div>
  )

};

export default Buy;