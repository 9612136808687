import React, { Component } from 'react';
import Helmet from 'react-helmet';
import ExchangeFindOrder from './exchangeFindOrder';
import OrdersToExchange from './ordersToExchange';
import ReturnParcel from './returnParcel';
import ExchangeShipping from './exchangeShipping';
import ExchangePayment from './exchangePayment';
import ExchangeConfirmation from './exchangeConfirmation';
import ShippingCalc from '../../../functions/shippingcalc';
import { checkInventory } from '../cart/checkInventory';

const initialState = {
  currentStep: 1,
  find_ordernum: '',
  find_firstname : '',
  find_lastname : '',
  find_postcode : '',
  error : null,
  order : null,
  items: [],
  toCheck: [],
  itemsExchanged: 0,
  exchangeCost: 10,
  invError: [],
  trackingNumber: '',
  inputEmptyError: false,
  firstname : '',
  lastname : '',
  phone : '',
  email : '',
  address_line1 : '',
  address_line2 : '',
  city : '',
  country : 'Australia',
  state : '',
  postcode : '',
  referral : '',
  comments : '',
  shipping: 'standard',
  shippingPrice: 0,
  subtotal: 0,
  totalCost: 0,
}

class Exchange extends Component {
  constructor (props){
    super(props)
    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.navigateBack = this.navigateBack.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    this.checkAvailability = this.checkAvailability.bind(this);
    this.confirmParcelReturned = this.confirmParcelReturned.bind(this);
    this.confirmShipping = this.confirmShipping.bind(this);
    this.confirmPayment = this.confirmPayment.bind(this);
  }

  handleChange(event, field) {
    var nextState = {};
    nextState[field] = event.target.value;
    nextState.inputEmptyError = false;
    this.setState(nextState);
  }

  navigateBack(toReset) {
    if (toReset) {
      this.setState({
        [toReset]: [],
        currentStep: this.state.currentStep - 1
      })
    }
    this.setState({ currentStep: this.state.currentStep - 1 })
  }

  handleSearch() {
    var that = this;
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.addEventListener('load', function(){
      var response = JSON.parse(this.response);

      if(response.success === false){
        that.setState({
          error : response.err
        })
      }
      else {
        that.setState({
          order : response.data,
          error : null,
          currentStep: 2,

          firstname : response.data.first_name,
          lastname : response.data.last_name,
          phone : response.data.phone,
          email : response.data.email,
          address_line1 : response.data.address1,
          address_line2 : response.data.address2,
          city : response.data.city,
          country : 'Australia',
          state : response.data.state,
          postcode : response.data.postcode,
        })
      }

    });
    xmlhttp.open('POST', '/api/checkorder');
    xmlhttp.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
    xmlhttp.send(JSON.stringify({
      ordernum : this.state.find_ordernum,
      // firstname : this.state.find_firstname,
      // lastname : this.state.find_lastname,
      postcode : this.state.find_postcode
    }));
  }

  handleSelection(e, code, identifier) {
    if (e.target.value === '') {
      this.setState({ inputEmptyError: true })
      return;
    }
    const items = this.state.items;
    let existingIndex = 0;
    const existing = items.find(({ from, id }, i) => {
      existingIndex = i;
      return from === code && id === identifier;
    })

    if (existing) {
      items[existingIndex] = {
        id: identifier,
        from: code,
        to: e.target.value
      };
    } else {
      items.push({
        id: identifier,
        from: code,
        to: e.target.value
      })
    }
    const itemsFiltered = items.filter(items => items.to !== '')
    this.setState({
      items: itemsFiltered,
      itemsExchanged: itemsFiltered.length,
      inputEmptyError: false,
    });
  }

  checkAvailability() {
    const nonExchanges = [];
    const context = this;
    if (this.state.items.length === 0 ) {
      this.setState({ inputEmptyError: true })
      return;
    }
    const toCheck = this.state.items.map(({ to }, index) => {
        if (to === 'no_exchange') {
          return to;
        }
        if (to.startsWith('GOW')) {
          return { gown: to, qty: 1, key: `${index}-${to}` }
        }
        if (to.startsWith('HAT')) {
          return { cap: to, qty: 1, key: `${index}-${to}` }
        }
    })

    if (toCheck.every(item => item === 'no_exchange')) {
      this.setState({ inputEmptyError: true })
      return;
    }

    checkInventory(toCheck, function(fails){
      if(fails.length > 0){
        context.setState({ invError : fails });
      } else {
        context.setState({
          currentStep: 3
        })
      }
    }, toCheck);
  }

  confirmParcelReturned(parcelTrackingNumber) {
    if (!parcelTrackingNumber) {
      this.setState({
        inputEmptyError: true,
      })
    } else {
      this.setState({
        currentStep: 4,
        inputEmptyError: false,
      })
    }
  }

  confirmShipping() {
    const shippingPrice = ShippingCalc([], { shipping: this.state.shipping }).price;
    const subtotal = this.state.itemsExchanged * this.state.exchangeCost;
    const totalCost = this.state.itemsExchanged * this.state.exchangeCost + shippingPrice;
    this.setState({ currentStep: 5, subtotal, totalCost, shippingPrice })
  }

  confirmPayment() {
    this.setState({ currentStep: 6 })
  }

  render() {
    var orderDetails = this.state.order;
    return (
      <div className='exchange'>
        <Helmet
          title='Order Exchange Form'
          meta={[
            {'name': 'description', 'content': 'Size exchange request form'},
          ]}
        />
        { this.state.currentStep === 1 &&
          <ExchangeFindOrder
            handleChange={this.handleChange}
            handleSearch={this.handleSearch}
            navigateBack={this.navigateBack}
            find_ordernum={this.state.find_ordernum}
            find_firstname={this.state.find_firstname}
            find_lastname={this.state.find_lastname}
            find_postcode={this.state.find_postcode}
            error={this.state.error}
          />
        }
        {
          this.state.currentStep === 2 &&
          <OrdersToExchange
            orderDetails={orderDetails}
            handleSelection={this.handleSelection}
            navigateBack={this.navigateBack}
            checkAvailability={this.checkAvailability}
            itemsExchanged={this.state.itemsExchanged}
            exchangeCost={this.state.exchangeCost}
            shipping={this.state.shipping}
            invError={this.state.invError}
            inputEmptyError={this.state.inputEmptyError}
          />
        }
        {
          this.state.currentStep === 3 &&
          <ReturnParcel
            handleChange={this.handleChange}
            trackingNumber={this.state.trackingNumber}
            items={this.state.items}
            find_ordernum={this.state.find_ordernum}
            confirmParcelReturned={this.confirmParcelReturned}
            navigateBack={this.navigateBack}
            inputEmptyError={this.state.inputEmptyError}
          />
        }
        {
          this.state.currentStep === 4 &&
          <ExchangeShipping
            shipping={this.state.shipping}
            confirmShipping={this.confirmShipping}
            handleChange={this.handleChange}
            navigateBack={this.navigateBack}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            phone={this.state.phone}
            email={this.state.email}
            address_line1={this.state.address_line1}
            address_line2={this.state.address_line2}
            city={this.state.city}
            state={this.state.state}
            postcode={this.state.postcode}
            shipping={this.state.shipping}
          />
        }
        {
          this.state.currentStep === 5 &&
          <ExchangePayment
            navigateBack={this.navigateBack}
            confirmPayment={this.confirmPayment}
            find_ordernum={this.state.find_ordernum}
            items={this.state.items}
            subtotal={this.state.subtotal}
            totalCost={this.state.totalCost}
            shippingPrice={this.state.shippingPrice}
            itemsExchanged={this.state.itemsExchanged}
            exchangeCost={this.state.exchangeCost}
            firstname={this.state.firstname}
            lastname={this.state.lastname}
            phone={this.state.phone}
            email={this.state.email}
            address_line1={this.state.address_line1}
            address_line2={this.state.address_line2}
            city={this.state.city}
            state={this.state.state}
            country={this.state.country}
            postcode={this.state.postcode}
            shipping={this.state.shipping}
            trackingNumber={this.state.trackingNumber}
          />
        }
        {
          this.state.currentStep === 6 &&
          <ExchangeConfirmation
            find_ordernum={this.state.find_ordernum}
          />
        }
      </div>
    )
  }

};

export default Exchange;