require('../scss/styles.scss');
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { Provider } from 'react-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Favicon from 'react-favicon'
import ReactGA from 'react-ga4';

import Reducers from './reducers/index';
import Footer from './components/footer';
import States from './components/shopping-routes/states'
import Product from './components/shopping-routes/product'
import Header from './components/header';
import About from './components/about';
import Buy from './components/buy';
import HoodOnlySearch from './components/hoodonly';
import BuyUni from './components/buy-uni';
import BuyLegalwear from './components/buy-legalwear';
import BuyFrames from './components/buy-frames';
import UniFrames from './components/shopping-routes/frames';
import University from './components/shopping-routes/universities'
import BuyGifts from './components/buy-gifts';
import Home from './components/homepage/home';
import Cart from './components/cart/cart';
import Checkout from './components/cart/checkout';
import PayPal from './components/paypalConfirmation';
import HoodOnly from './components/hoodonly';
import Thankyou from './components/thankyou';
import Friendsdeal from './components/friendsdeal';
import Contact from './components/info/contact';
import Regulations from './components/info/regulations';
import FAQ from './components/info/faq';
import Pricebeat from './components/info/pricebeat';
import Privacy from './components/info/privacy';
import Terms from './components/info/terms';
import ShippingInfo from './components/info/shipping';
import Exchange from './components/exchange/exchangeForm';
import Admin from './components/admin/admin';
import NotFound from './components/404';

import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}
const persistedReducer = persistReducer(persistConfig, Reducers)
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
});
const persistor = persistStore(store)

ReactGA.initialize('G-SDT93Z57DE');

function logPageView() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "pageview" });

  window.scrollTo(0, 0);
  return null;
}

const mode = process.env.NODE_ENV;
let Stripe_Pk = loadStripe("pk_test_mp5cdjXYDPwVUkaZFeKCqMRb");

if (mode === "production") {
  Stripe_Pk = loadStripe("pk_live_a64KuUIGXyhxhQnfIX5YlopM");
}

const Layout = props => (
  <div>
    <Header />
    <Route path="/" component={logPageView}/>
    {props.children}
    <Footer />
  </div>
);

const App = () => (
  <Switch>
    <Route exact path="/"><Home /></Route>
    // Uni routes
    <Route exact path="/uni"><BuyUni /></Route>
    <Route exact path={[
      "/uni/bachelor-cap-and-gown",
      "/uni/master-cap-and-gown",
      "/uni/bachelor-gown"
    ]}>
      <Product />
    </Route>
     <Route exact path={[
      "/uni/acu",
      "/uni/scu",
      "/uni/csu",
      "/uni/notre-dame",
    ]}>
      <University />
    </Route>
    <Route path={[
      "/uni/acu/:degree",
      "/uni/scu/:degree",
      "/uni/csu/:degree",
    ]}>
      <Product />
    </Route>
    <Route exact path="/uni/:state"><States /></Route>
    <Route exact path="/uni/:state/:uniname"><University /></Route>
    <Route exact path="/uni/:state/:uniname/:degree"><Product /></Route>
    <Route exact path="/uni/:state/:uniname/graduation-gown/:degree"><Product /></Route>
    // Product routes
    <Route exact path="/graduation-gown"><Buy /></Route>
    <Route exact path="/graduation-gown/graduation-hoods"><HoodOnlySearch /></Route>
    <Route path="/graduation-gown/:product"><Product /></Route>
    <Route exact path="/graduation-gown/graduation-hoods"><HoodOnly /></Route>
    // Frame routes
    <Route exact path="/certificate-frame"><BuyFrames /></Route>
    <Route path="/certificate-frame/:uni" component={UniFrames} />
    // Gift routes
    <Route path="/graduation-gifts"><BuyGifts /></Route>
    // Cart and checkout routes
    <Route exact path="/cart"><Cart /></Route>
    <Route path="/checkout"><Checkout /></Route>
    <Route path="/paypalconf*"><PayPal /></Route>
    <Route path="/shipping"><ShippingInfo /></Route>
    <Route exact path="/exchange"><Exchange /></Route>
    // Legal routes
    <Route exact path="/legal-wear"><BuyLegalwear /></Route>
    <Route path="/legal-wear/:product"><Product /></Route>
    // Company Intro and info routes
    <Route path="/about"><About /></Route>
    <Route path="/contact"><Contact /></Route>
    <Route path="/faq"><FAQ /></Route>
    <Route path="/save-with-friends"><Friendsdeal /></Route>
    <Route path="/regalia-regulations/:uni"><Regulations /></Route>
    <Route path="/pricebeat"><Pricebeat /></Route>
    <Route path="/terms"><Terms /></Route>
    <Route path="/privacy"><Privacy /></Route>
    <Route path="/thankyou"><Thankyou /></Route>
    // Admin
    <Route path="/admin"><Admin /></Route>


    <Route component={NotFound} />
  </Switch>
)

ReactDOM.render(
  <Provider store={store}>
    <Elements stripe={Stripe_Pk}>
      <MuiThemeProvider>
        <Router>
          <Layout>
            <Favicon url='https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Utility/favicon.jpg' />
            <App />
          </Layout>
        </Router>
      </MuiThemeProvider>
    </Elements>
  </Provider>,
  document.getElementById('content')
);

