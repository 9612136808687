import React from 'react';
import { Link } from 'react-router-dom';

const DrawerContent = ({close}) => {
  const handleToggle = (event, toggleTarget) => {
    var targetEl = document.getElementById(toggleTarget);
    var toggleEl = document.getElementById(event.target.id);

    if(targetEl.style.display === "none"){
      targetEl.style.display = "";
      toggleEl.innerHTML = "-";
    }
    else {
      targetEl.style.display = "none";
      toggleEl.innerHTML = "+";
    }

  }

  var hidden = {
    display: "none"
  }

  return (
    <div className="menuitems">
      <h2>Menu</h2>
      <div className="menuitem"><a onClick={() => close("/")}>Home</a></div>
      <div className="menuitem" id="menu-shop">
        <a onClick={() => close("/graduation-gown")}>Shop</a>
        <p id="shop-toggle" className="toggle" onClick={(e) => handleToggle(e, 'menu-shop-items')}>+</p>
      </div>
      <div id="menu-shop-items" style={hidden}>
        <a onClick={() => close("/uni")} >Full University Sets</a>
        <a onClick={() => close("/graduation-gown/cap-and-gown-bachelor")}>Bachelor Style Gown & Cap Sets</a>
        <a onClick={() => close("/graduation-gown/cap-and-gown-master")} >Master Style Gown & Cap Sets</a>
        <a onClick={() => close("/graduation-gown/gown-bachelor")}>Bachelor Style Gowns</a>
        <a onClick={() => close("/graduation-gown/master-gown")}>Master Style Gowns</a>
        <a onClick={() => close("/graduation-gown/graduation-hat")}>Graduation Hats</a>
        <a onClick={() => close("/graduation-gown/graduation-hoods")}>Academic Hoods</a>
        <a onClick={() => close("/certificate-frame")}>Certificate Frames</a>
        <a onClick={() => close("/graduation-gifts")}>Graduation Gifts</a>
        <a onClick={() => close("/legal-wear")}>Legal Wear</a>
      </div>
      <div className="menuitem"><a onClick={() => close("/pricebeat")}>Price Guarantee</a></div>
      <div className="menuitem"><a onClick={() => close("/save-with-friends")}>Save with Friends</a></div>
      <div className="menuitem"><a onClick={() => close("/cart")}>View Cart</a></div>
      <div className="menuitem"><a onClick={() => close("/contact")}>Contact Us</a></div>
      <div className="menuitem" id="menu-about">
        <p>Info</p>
        <p id="about-toggle" className="toggle" onClick={(e) => handleToggle(e, 'menu-about-items')}>+</p>
      </div>
      <div id="menu-about-items" style={hidden}>
        <a onClick={() => close("/about")}>About Us</a>
        <a onClick={() => close("/faq")}>FAQ</a>
        <a onClick={() => close("/shipping")}>Shipping Calculator</a>
        <a onClick={() => close("/terms")}>Terms & Conditions</a>
        <a onClick={() => close("/privacy")}>Privacy Policy</a>
      </div>
    </div>
  )
};

export default DrawerContent;