import React from 'react';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../../constants';

const HomeContent = () => {
  return (
    <div className="homecontent">
      <div className="home1">
        <hr />
        <h1>PREMIUM GRADUATION GOWN SETS FOR AUSTRALIAN UNIVERSITIES AT AFFORDABLE PRICES</h1>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="Graduate wearing graduation gown with family" />
        <Link to="/uni">
          <button>Shop Now</button>
        </Link>
        <h3>A brand you can trust</h3>
        <img className="infographic" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165145/Home%20Page/a-brand-you-can-trust.png" alt="Gowning Street is a brand you can trust" />
        <p>Gowning Street, the name is synonymous with the values of trust, heritage &amp; excellence. A name that has over the years represented both the Student and the Universities trust into our unwavering faith in the quality of our graduation regalia. And now these values have been extended to the range of our hand crafted graduation hoods, stoles and gowns.</p>
        <h3>Our customers</h3>
        <img className="infographic" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165144/Home%20Page/our-customers.jpg" alt="Gowning Street has many happy customers" />
        <p>The brand reflects the persona of a confident, curious and intellectual graduate. A graduate who is well educated, well-travelled and above all is sensitive and caring. Virtues like these make our patrons the complete person in the true sense of the word.</p>
        <h3>Our Workmanship</h3>
        <img className="infographic" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165145/Home%20Page/worksmanship.svg" alt="Gowning Street prides itself on superior worksmanship" />
        <p>The Australian University approved hoods, stoles and gowns are made out of our finest fabrics with superior construction and sophisticated embellishments that give a rich feel and super fit. Hand craftsmanship and attention to detail makes the product a pleasure to wear at your graduation ceremony.</p>
        <h3>A complete graduation experience</h3>
        <img className="infographic" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165145/Home%20Page/a-complete-graduation-experience.png" alt="Gowning Street provides a complete graduation experience" />
        <p>Finishing up at uni leaves us full of all different emotions; excitement, relief, and uncertainty are just three of them. But at the end of the day, academic graduation is a time of celebration and happiness, no matter if it’s a bachelor’s, master’s, doctorate or anything else. The fact is, you’ve put in the hard work and the graduation ceremony is a time to recognise that hard work.</p>
        <Link to="/uni">
          <button>Shop Now</button>
        </Link>
      </div>
      <div className="home2">
        <hr />
        <h2>TO RENT OR TO BUY YOUR ACADEMIC GOWN – THAT IS THE QUESTION</h2>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-lawn.jpg" alt="Graduates celebrating" />
        <p>There are two options when you’re in need of suitable attire to wear on that special day; you can either rent or purchase.</p>
        <p> While many see renting as a more affordable option, you’d be surprised to find out that at Gowning Street we can provide you with premium quality academic gowns for purchase at a cheaper price (and much less hassle) than a rental. Our range caters for universities across Australia, from Victoria to New South Wales, Tasmania, South Australia and all the way up to Queensland.</p>
        <h3>Just a few reasons why buying your graduation gown is better:</h3>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Placement%20Photos/graduate-graduation-gown-set-red-hood.jpg" alt="Graduates celebrating on University stairs" />
        <div className="reasonlist">
          <p>You can keep it forever as a memento and a reminder of your hard work and dedication to finishing your course</p>
          <p>If you have any siblings or friends that are doing the same course you can loan it to them to use</p>
          <p>If you and your friends all get on board together, you can take advantage of our bundle prices and pick your academic graduation set for as cheap as $79, lower than rental prices at many places</p>
          <p>You don’t need to worry about getting a stain on it; you can have it cleaned without the late fee in your own time</p>
          <p>There are no lines you need to stand in to pick it up – we can deliver it straight to your door</p>
        </div>
        <Link to="/uni">
          <button>Shop Now</button>
        </Link>
      </div>
      <div className="home3">
        <hr />
        <h2>DELIVERED DIRECTLY TO YOU VIRTUALLY ANYWHERE IN AUSTRALIA</h2>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-sunset.jpg" alt="Graduate enjoying the sunset" />
        <p>We take immense pride in being able to provide most Australian academics with our premium-quality 230GSM polyester weave gowns. If you’ve been looking for a reputable establishment to purchase your graduation attire, you’re bound to be impressed with our service and products.</p>
        <p>If you have any questions regarding any of the gowns we have available, or you’d like more information about shipping, please get in touch with us today either by calling <a href={`tel:${GOWNING_STREET_PHONE_NUMBER}`} style={{'paddingRight': 0}}>{GOWNING_STREET_PHONE_NUMBER}</a> or <Link to="/contact" style={{'paddingRight': 0}}>contact us online</Link></p>
        <p>For overseas customers, we offer international shipping around the world including <a href="https://www.graduationgowns.co.nz">New Zealand</a>, and <a href="https://www.gowningstreet.co.uk">the UK</a>.</p>
      </div>
      <Link to="/uni">
        <button>Shop Now</button>
      </Link>
    </div>
  )
};

export default HomeContent;