module.exports = {
  frames: {
    "A4" : [
      {label: 'Modern Frame', barcode: 'FRA00001'},
      {label: 'Pine Wood Frame (+$5)', barcode: 'FRA00002'}
    ],
    'A3' : [
      {label: 'Modern Frame', barcode: 'FRA00011'},
      {label: 'Pine Wood Frame (+$5)', barcode: 'FRA00012'}
    ],
    'Style3' : [
      {label: 'Modern Frame', barcode: 'FRA00003'},
      {label: 'Pine Wood Frame (+$5)', barcode: 'FRA00013'}
    ],
  },

  mattes: {
    F1D1: [
      {label: 'Manor Black', barcode: 'MAT00001'},
      {label: 'Navy Blue', barcode: 'MAT00002'},
      {label: 'Burgundy', barcode: 'MAT00003'},
      {label: 'Forest Green', barcode: 'MAT00004'},
      {label: 'Light Cream', barcode: 'MAT00005'},
    ],
    F1D2: [
      {label: 'Manor Black', barcode: 'MAT00021'},
      {label: 'Navy Blue', barcode: 'MAT00022'},
      {label: 'Burgundy', barcode: 'MAT00023'},
      {label: 'Forest Green', barcode: 'MAT00024'},
      {label: 'Light Cream', barcode: 'MAT00025'},
    ],
    F2D1: [
      {label: 'Manor Black', barcode: 'MAT00011'},
      {label: 'Navy Blue', barcode: 'MAT00012'},
      {label: 'Burgundy', barcode: 'MAT00013'},
      {label: 'Forest Green', barcode: 'MAT00014'},
      {label: 'Light Cream', barcode: 'MAT00015'},
    ],
    F3D1: [
      {label: 'Manor Black', barcode: 'MAT00311'},
      {label: 'Navy Blue', barcode: 'MAT00312'},
      {label: 'Burgundy', barcode: 'MAT00313'},
      {label: 'Forest Green', barcode: 'MAT00314'},
      {label: 'Light Cream', barcode: 'MAT00315'},
    ],
    F3D2: [
      {label: 'Manor Black', barcode: 'MAT00321'},
      {label: 'Navy Blue', barcode: 'MAT00322'},
      {label: 'Burgundy', barcode: 'MAT00323'},
      {label: 'Forest Green', barcode: 'MAT00324'},
      {label: 'Light Cream', barcode: 'MAT00325'},
    ],
    F3D3: [
      {label: 'Manor Black', barcode: 'MAT00331'},
      {label: 'Navy Blue', barcode: 'MAT00332'},
      {label: 'Burgundy', barcode: 'MAT00333'},
      {label: 'Forest Green', barcode: 'MAT00334'},
      {label: 'Light Cream', barcode: 'MAT00335'},
    ],
  },

















  frameStyles: [
    {label: 'Modern Frame', barcode: 'FRA00001'},
    {label: 'Pine Wood Frame (+$5)', barcode: 'FRA00002'},
  ],
  frameStylesOther: [
    {label: 'Modern Frame', barcode: 'FRA00011'},
    {label: 'Pine Wood Frame (+$5)', barcode: 'FRA00012'},
  ],
  frameStylesCustom1: [
    {label: 'Modern Frame', barcode: 'FRA00003'},
    {label: 'Pine Wood Frame (+$5)', barcode: 'FRA10003'},
  ],
  frameColours: [
    {label: 'Manor Black', barcode: 'MAT00001'},
    {label: 'Navy Blue', barcode: 'MAT00002'},
    {label: 'Burgundy', barcode: 'MAT00003'},
    {label: 'Forest Green', barcode: 'MAT00004'},
    {label: 'Light Cream', barcode: 'MAT00005'},
  ],
  frameColoursF1D2: [
    {label: 'Manor Black', barcode: 'MAT00021'},
    {label: 'Navy Blue', barcode: 'MAT00022'},
    {label: 'Burgundy', barcode: 'MAT00023'},
    {label: 'Forest Green', barcode: 'MAT00024'},
    {label: 'Light Cream', barcode: 'MAT00025'},
  ],
  frameColoursF3D1: [
    {label: 'Manor Black', barcode: 'MAT00311'},
    {label: 'Navy Blue', barcode: 'MAT00312'},
    {label: 'Burgundy', barcode: 'MAT00313'},
    {label: 'Forest Green', barcode: 'MAT00314'},
    {label: 'Light Cream', barcode: 'MAT00315'},
  ],
  frameColoursF3D2: [
    {label: 'Manor Black', barcode: 'MAT00321'},
    {label: 'Navy Blue', barcode: 'MAT00322'},
    {label: 'Burgundy', barcode: 'MAT00323'},
    {label: 'Forest Green', barcode: 'MAT00324'},
    {label: 'Light Cream', barcode: 'MAT00325'},
  ],
  frameColoursF3D3: [
    {label: 'Manor Black', barcode: 'MAT00331'},
    {label: 'Navy Blue', barcode: 'MAT00332'},
    {label: 'Burgundy', barcode: 'MAT00333'},
    {label: 'Forest Green', barcode: 'MAT00334'},
    {label: 'Light Cream', barcode: 'MAT00335'},
  ]
}