const prices = {
    BACHELOR_SET_PRICE: 105,
    BACHELOR_GOWN_CAP_PRICE: 85,
    BACHELOR_GOWN_PRICE: 65,
    MASTER_SET_PRICE: 105,
    MASTER_GOWN_CAP_PRICE: 85,
    MASTER_GOWN_PRICE: 70,
    PHD_SET_PRICE: 149,
    PHD_GOWN_PRICE: 95,
    HOOD_PRICE: 45,
    CAP_PRICE: 45,
    PHD_BONNET_PRICE: 59,
    ISN_SET_PRICE: 60,
    // Gifts
    TEDDY_SMALL_PRICE: 19,
    PUPPY_SMALL_PRICE: 19,
    PUPPY_LARGE_PRICE: 25,
    // Frames
    MODERN_FRAME: 49,
    TRADITIONAL_FRAME: 54,
    //Shipping
    STANDARD_SHIPPING_PRICE: 14,
    STANDARD_SIGNATURE_SHIPPING_PRICE: 16,
    EXPRESS_SHIPPING_PRICE: 19,
    EXPRESS_SIGNATURE_SHIPPING_PRICE: 21,
    INTERNATIONAL_SHIPPING_PRICE: 60,
    ISN_SHIPPING_PRICE: 0,
    // Legalwear
    BARRISTER_GOWN_PRICE: 199,
    ADMISSIONS_GOWN_PRICE: 199,
    BARRISTER_WIG_PRICE: 699,
    BENCHER_WIG_PRICE: 799,
    // Shipping

    // Misc.
    GARMENT_BAG: 14
}

module.exports = prices;
