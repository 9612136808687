import React from 'react';
import Helmet from 'react-helmet';

const Friends = () => {

    var amounts = [2,3,4,5,6,7,8,9,10];

    var deals = amounts.map(function(element){
      return (
        <div className="deal" key={element}>
          <p className="deal-head">Buy {element}</p>
          <p className="deal-amount">GET ${element} OFF</p>
          <p className="deal-foot">each gown, no extra shipping cost</p>
        </div>
      )
    });

    return (
      <div className="friends">
        <Helmet
          title="Save with Friends | Gowning Street"
          meta={[
            {"name": "description", "content": "Buy with friends and save up to $10 per graduation set you buy!"},
          ]}
        />
        <h1>Save with Friends | Bulk and wholesale discounts</h1>
        <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduates-throwing-cap.jpg" alt="Graduates throwing their graduation hats" />
        <p className="maindes">Looking to save even more on your graduation gown set? Gowning Street will help you save even more if buying with friends.</p>
        <p className="maindes">Enjoy up to $10 off each set when buying in bulk AND pay no additional shipping cost on extra items!</p>
        <p className="maindes">The deals below are automatically applied once you checkout:</p>
        <div className="alldeals">
          {deals}
        </div>
      </div>
    )

};

export default Friends;