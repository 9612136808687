import React from 'react';
import { withFormsy } from 'formsy-react';

const CustomDropdown = (props) => {
  const changeValue = (event) => {
    props.setValue(event.currentTarget.value);
    props.handleChange(event.currentTarget.value);
  }

  const className = props.showRequired ? 'required' : props.showError ? 'error' : null;
  const countries = ["","Australia", "New Zealand","Afghanistan","Albania","Algeria","American Samoa","Andorra","Angola","Anguila","Argentina","Armenia","Austria","Azerbaijan", "Bahamas","Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Botswana","Brazil","Brunei","Bulgaria","Cambodia","Cameroon","Canada","Cayman Islands","Chile","China","Colombia","Congo","Cook Islands","Costa Rica","Cote D'Ivoire", "Croatia","Cuba","Cyprus","Czech Republic","Denmark","Dominican Republic","East Timor","Ecuador","Egypt","El Salvador","Estonia", "Ethiopia","Fiji","Finland","France", "Gabon","Gambia","Georgia","Germany","Ghana","Gibralta","Greece","Greenland","Guam","Guatemala","Honduras","Hong Kong","Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Israel","Italy","Jamaica","Japan","Jordan","Kazakhstan","Kenya","Kuwait","Laos","Latvia","Lebanon","Liechtenstein","Lithuania","Luxembourg","Macao","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mexico","Monaco","Mongolia","Morocco","Myanmar","Nepal","Netherlands","New Caledonia","Nicaragua","Niger","Nigeria","Norway","Oman","Pakistan","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Rwanda", "Poland","Portugal","Puerto Rico","Qatar","Romania","Russian Federation","Saudi Arabia","Senegal","Singapore","Somalia","South Africa","Spain","Sri Lanka","Sweden","Switzerland","Taiwan","Thailand","Trinidad and Tobago","Turkey","Uganda","Ukraine","United Arab Emirates","United Kingdom","United States","Uruguay","Vanuatu", "Viet Nam","Virgin Islands (British)","Yemen","Zambia"];

  return (
    <div className={className}>
      { props.name === "country" ?
        <div className="block-form">
          <p>Country:</p>
          <select
            value={props.value}
            onChange={changeValue}
          >
          { countries.map((countryName) =>
            <option key={countryName} value={countryName}>{countryName}</option>)
          }
          </select>
        </div>
        :
        <div className="block-form">
          <p>How did you hear about us?</p>
          <select
            value={props.value}
            onChange={changeValue}
          >
            <option value=""></option>
            <option value="Google Search">Google Search</option>
            <option value="Google Shopping">Google Shopping</option>
            <option value="Word of Mouth">Word of Mouth</option>
            <option value="Facebook">Facebook</option>
          </select>
        </div>
      }
      {props.isFormSubmitted && !props.isValid &&
        <span style={{display: 'block', color: '#cc0000', fontSize: '12px', padding: '1px 5px 10px', textAlign: 'left'}}>
          {props.errorMessage}
        </span>
      }
    </div>
  );

};

export default withFormsy(CustomDropdown);


