const {
  MODERN_FRAME,
} = require('../../constants')

module.exports = {
  // ACU
  "/certificate-frame/acu" : {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700508/Frames/ACU/ACU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700709/Frames/ACU/ACU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700558/Frames/ACU/ACU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700679/Frames/ACU/ACU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700609/Frames/ACU/ACU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283962/Frames/ACU/ACU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272212/Frames/ACU/ACU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282880/Frames/ACU/ACU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282388/Frames/ACU/ACU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281305/Frames/ACU/ACU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your ACU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your ACU testamur perfectly",
    "altTags" : [
      "ACU certificate frame - Pine Wood Manor Black",
      "ACU certificate frame - Pine Wood Marine Blue",
      "ACU certificate frame - Pine Wood Wine",
      "ACU certificate frame - Pine Wood Eucalypt",
      "ACU certificate frame - Pine Wood Light Cream",
      "ACU certificate frame - Modern Black",
      "ACU certificate frame - Modern Marine Blue",
      "ACU certificate frame - Modern Wine",
      "ACU certificate frame - Modern Eucalypt",
      "ACU certificate frame - Modern Light Cream",
    ],
    "name" : "ACU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "ACU Certificate Frame | From $49"
  },

    // STATE: "NSW",

  "/certificate-frame/csu": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703144/Frames/CSU/CSU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703222/Frames/CSU/CSU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703206/Frames/CSU/CSU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703187/Frames/CSU/CSU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703166/Frames/CSU/CSU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284069/Frames/CSU/CSU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272340/Frames/CSU/CSU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282966/Frames/CSU/CSU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282441/Frames/CSU/CSU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281370/Frames/CSU/CSU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your CSU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your CSU testamur perfectly",
    "altTags" : [
      "CSU certificate frame - Pine Wood Manor Black",
      "CSU certificate frame - Pine Wood Marine Blue",
      "CSU certificate frame - Pine Wood Wine",
      "CSU certificate frame - Pine Wood Eucalypt",
      "CSU certificate frame - Pine Wood Light Cream",
      "CSU certificate frame - Modern Black",
      "CSU certificate frame - Modern Marine Blue",
      "CSU certificate frame - Modern Wine",
      "CSU certificate frame - Modern Eucalypt",
      "CSU certificate frame - Modern Light Cream",
    ],
    "name" : "CSU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "CSU Certificate Frame | From $49"
  },
  "/certificate-frame/mq": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284174/Frames/MQ/MQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272857/Frames/MQ/MQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283041/Frames/MQ/MQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282511/Frames/MQ/MQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281452/Frames/MQ/MQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Macquarie Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your MQ testamur perfectly",
    "altTags" : [
      "MQ certificate frame - Pine Wood Manor Black",
      "MQ certificate frame - Pine Wood Marine Blue",
      "MQ certificate frame - Pine Wood Wine",
      "MQ certificate frame - Pine Wood Eucalypt",
      "MQ certificate frame - Pine Wood Light Cream",
      "MQ certificate frame - Modern Black",
      "MQ certificate frame - Modern Marine Blue",
      "MQ certificate frame - Modern Wine",
      "MQ certificate frame - Modern Eucalypt",
      "MQ certificate frame - Modern Light Cream",
    ],
    "name" : "MQ Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Macquarie Uni Certificate Frame | From $49"
  },
  "/certificate-frame/scu": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710781/Frames/SCU/SCU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710844/Frames/SCU/SCU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710829/Frames/SCU/SCU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710815/Frames/SCU/SCU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710800/Frames/SCU/SCU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284217/Frames/SCU/SCU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272905/Frames/SCU/SCU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283095/Frames/SCU/SCU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282583/Frames/SCU/SCU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281509/Frames/SCU/SCU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your SCU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your SCU testamur perfectly",
    "altTags" : [
      "SCU certificate frame - Pine Wood Manor Black",
      "SCU certificate frame - Pine Wood Marine Blue",
      "SCU certificate frame - Pine Wood Wine",
      "SCU certificate frame - Pine Wood Eucalypt",
      "SCU certificate frame - Pine Wood Light Cream",
      "SCU certificate frame - Modern Black",
      "SCU certificate frame - Modern Marine Blue",
      "SCU certificate frame - Modern Wine",
      "SCU certificate frame - Modern Eucalypt",
      "SCU certificate frame - Modern Light Cream",
    ],
    "name" : "SCU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Southern Cross Uni Certificate Frame | From $49"
  },
  "/certificate-frame/une": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713191/Frames/UNE/UNE_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713283/Frames/UNE/UNE_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713262/Frames/UNE/UNE_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713245/Frames/UNE/UNE_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713226/Frames/UNE/UNE_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284461/Frames/UNE/UNE_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273181/Frames/UNE/UNE_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283193/Frames/UNE/UNE_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282695/Frames/UNE/UNE_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281621/Frames/UNE/UNE_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UNE certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UNE testamur perfectly",
    "altTags" : [
      "UNE certificate frame - Pine Wood Manor Black",
      "UNE certificate frame - Pine Wood Marine Blue",
      "UNE certificate frame - Pine Wood Wine",
      "UNE certificate frame - Pine Wood Eucalypt",
      "UNE certificate frame - Pine Wood Light Cream",
      "UNE certificate frame - Modern Black",
      "UNE certificate frame - Modern Marine Blue",
      "UNE certificate frame - Modern Wine",
      "UNE certificate frame - Modern Eucalypt",
      "UNE certificate frame - Modern Light Cream",
    ],
    "name" : "UNE Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UNE Certificate Frame | From $49"
  },
  "/certificate-frame/unsw": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566717819/Frames/UNSW/UNSW_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566717932/Frames/UNSW/UNSW_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566717908/Frames/UNSW/UNSW_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566717874/Frames/UNSW/UNSW_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566717843/Frames/UNSW/UNSW_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284472/Frames/UNSW/UNSW_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273195/Frames/UNSW/UNSW_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283212/Frames/UNSW/UNSW_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282709/Frames/UNSW/UNSW_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281635/Frames/UNSW/UNSW_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UNSW certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UNSW testamur perfectly",
    "altTags" : [
      "UNSW certificate frame - Pine Wood Manor Black",
      "UNSW certificate frame - Pine Wood Marine Blue",
      "UNSW certificate frame - Pine Wood Wine",
      "UNSW certificate frame - Pine Wood Eucalypt",
      "UNSW certificate frame - Pine Wood Light Cream",
      "UNSW certificate frame - Modern Black",
      "UNSW certificate frame - Modern Marine Blue",
      "UNSW certificate frame - Modern Wine",
      "UNSW certificate frame - Modern Eucalypt",
      "UNSW certificate frame - Modern Light Cream",
    ],
    "name" : "UNSW Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D2",
    "productType" : "bachelor-frame",
    "titleTag" : "UNSW Certificate Frame | From $49"
  },
  "/certificate-frame/uow": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623118/Frames/Wollongong/University_of_Wollongong_Manor_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Navy_Blue.jpg" },
      { style: 'FRA00013', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Green.jpg" },
      { style: 'FRA00013', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623117/Frames/Wollongong/University_of_Wollongong_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623118/Frames/Wollongong/University_of_Wollongong_Manor_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Navy_Blue.jpg" },
      { style: 'FRA00003', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623119/Frames/Wollongong/University_of_Wollongong_Green.jpg" },
      { style: 'FRA00003', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663623117/Frames/Wollongong/University_of_Wollongong_Cream.jpg" },
    ],
    "metaTag" : "Buy your University of Wollongong (UOW) certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UOW testamur perfectly",
    "altTags" : [
      "UOW certificate frame - Pine Wood Manor Black",
      "UOW certificate frame - Pine Wood Marine Blue",
      "UOW certificate frame - Pine Wood Wine",
      "UOW certificate frame - Pine Wood Eucalypt",
      "UOW certificate frame - Pine Wood Light Cream",
      "UOW certificate frame - Modern Black",
      "UOW certificate frame - Modern Marine Blue",
      "UOW certificate frame - Modern Wine",
      "UOW certificate frame - Modern Eucalypt",
      "UOW certificate frame - Modern Light Cream",
    ],
    "name" : "UOW Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D2",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Wollongong (UOW) Certificate Frame | From $49"
  },
  "/certificate-frame/uon": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566718224/Frames/UON/UON_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566718503/Frames/UON/UON_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566728277/Frames/UON/UON_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566718137/Frames/UON/UON_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566718207/Frames/UON/UON_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284243/Frames/UON/UON_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272935/Frames/UON/UON_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283119/Frames/UON/UON_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282614/Frames/UON/UON_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281532/Frames/UON/UON_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UON certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UON testamur perfectly",
    "altTags" : [
      "UON certificate frame - Pine Wood Manor Black",
      "UON certificate frame - Pine Wood Marine Blue",
      "UON certificate frame - Pine Wood Wine",
      "UON certificate frame - Pine Wood Eucalypt",
      "UON certificate frame - Pine Wood Light Cream",
      "UON certificate frame - Modern Black",
      "UON certificate frame - Modern Marine Blue",
      "UON certificate frame - Modern Wine",
      "UON certificate frame - Modern Eucalypt",
      "UON certificate frame - Modern Light Cream",
    ],
    "name" : "UON Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Newcastle Certificate Frame | From $49"
  },
  "/certificate-frame/notre-dame": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566709302/Frames/NotreDame/NotreDame_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710051/Frames/NotreDame/NotreDame_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710023/Frames/NotreDame/NotreDame_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710008/Frames/NotreDame/NotreDame_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566709994/Frames/NotreDame/NotreDame_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284315/Frames/NotreDame/NotreDame_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272948/Frames/NotreDame/NotreDame_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283135/Frames/NotreDame/NotreDame_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282628/Frames/NotreDame/NotreDame_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281545/Frames/NotreDame/NotreDame_Modern_Cream.jpg" }
    ],
    "metaTag" : "Buy your Notre Dame certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Notre Dame testamur perfectly",
    "altTags" : [
      "Notre Dame certificate frame - Pine Wood Manor Black",
      "Notre Dame certificate frame - Pine Wood Marine Blue",
      "Notre Dame certificate frame - Pine Wood Wine",
      "Notre Dame certificate frame - Pine Wood Eucalypt",
      "Notre Dame certificate frame - Pine Wood Light Cream",
      "Notre Dame certificate frame - Modern Black",
      "Notre Dame certificate frame - Modern Marine Blue",
      "Notre Dame certificate frame - Modern Wine",
      "Notre Dame certificate frame - Modern Eucalypt",
      "Notre Dame certificate frame - Modern Light Cream",
    ],
    "name" : "Notre-Dame Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Notre Dame Uni Certificate Frame | From $49"
  },
  "/certificate-frame/usyd": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00331', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566722001/Frames/USyd/USyd_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00332', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566723670/Frames/USyd/USyd_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00333', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566723654/Frames/USyd/USyd_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00334', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566723569/Frames/USyd/USyd_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00335', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566723483/Frames/USyd/USyd_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00331', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284504/Frames/USyd/USyd_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00332', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273251/Frames/USyd/USyd_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00333', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283250/Frames/USyd/USyd_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00334', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282748/Frames/USyd/USyd_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00335', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281670/Frames/USyd/USyd_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your USyd certificate frame for only $49 | Fast delivery, excellent quality, made to fit your USyd testamur perfectly",
    "altTags" : [
      "USyd certificate frame - Pine Wood Manor Black",
      "USyd certificate frame - Pine Wood Marine Blue",
      "USyd certificate frame - Pine Wood Wine",
      "USyd certificate frame - Pine Wood Eucalypt",
      "USyd certificate frame - Pine Wood Light Cream",
      "USyd certificate frame - Modern Black",
      "USyd certificate frame - Modern Marine Blue",
      "USyd certificate frame - Modern Wine",
      "USyd certificate frame - Modern Eucalypt",
      "USyd certificate frame - Modern Light Cream",
    ],
    "name" : "USyd Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D3",
    "productType" : "bachelor-frame",
    "titleTag" : "USyd Certificate Frame | From $49"
  },
  "/certificate-frame/uts": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724693/Frames/UTS/UTS_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724912/Frames/UTS/UTS_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724838/Frames/UTS/UTS_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724807/Frames/UTS/UTS_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724735/Frames/UTS/UTS_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284544/Frames/UTS/UTS_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273276/Frames/UTS/UTS_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283283/Frames/UTS/UTS_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282775/Frames/UTS/UTS_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281697/Frames/UTS/UTS_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UTS certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UTS testamur perfectly",
    "altTags" : [
      "UTS certificate frame - Pine Wood Manor Black",
      "UTS certificate frame - Pine Wood Marine Blue",
      "UTS certificate frame - Pine Wood Wine",
      "UTS certificate frame - Pine Wood Eucalypt",
      "UTS certificate frame - Pine Wood Light Cream",
      "UTS certificate frame - Modern Black",
      "UTS certificate frame - Modern Marine Blue",
      "UTS certificate frame - Modern Wine",
      "UTS certificate frame - Modern Eucalypt",
      "UTS certificate frame - Modern Light Cream",
    ],
    "name" : "UTS Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UTS Certificate Frame | From $49"
  },
  "/certificate-frame/uws": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727110/Frames/UWS/UWS_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727277/Frames/UWS/UWS_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727252/Frames/UWS/UWS_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727239/Frames/UWS/UWS_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727217/Frames/UWS/UWS_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284613/Frames/UWS/UWS_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273341/Frames/UWS/UWS_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283365/Frames/UWS/UWS_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282845/Frames/UWS/UWS_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281765/Frames/UWS/UWS_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UWS certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UWS testamur perfectly",
    "altTags" : [
      "UWS certificate frame - Pine Wood Manor Black",
      "UWS certificate frame - Pine Wood Marine Blue",
      "UWS certificate frame - Pine Wood Wine",
      "UWS certificate frame - Pine Wood Eucalypt",
      "UWS certificate frame - Pine Wood Light Cream",
      "UWS certificate frame - Modern Black",
      "UWS certificate frame - Modern Marine Blue",
      "UWS certificate frame - Modern Wine",
      "UWS certificate frame - Modern Eucalypt",
      "UWS certificate frame - Modern Light Cream",
    ],
    "name" : "UWS Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UWS Certificate Frame | From $49"
  },

    // STATE: "VIC",

  "/certificate-frame/federation-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704593/Frames/Federation/Federation_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704737/Frames/Federation/Federation_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704710/Frames/Federation/Federation_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704667/Frames/Federation/Federation_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704618/Frames/Federation/Federation_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284117/Frames/Federation/Federation_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272796/Frames/Federation/Federation_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282999/Frames/Federation/Federation_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282471/Frames/Federation/Federation_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281399/Frames/Federation/Federation_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Federation Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Federation Uni testamur perfectly",
    "altTags" : [
      "Federation University certificate frame - Pine Wood Manor Black",
      "Federation University certificate frame - Pine Wood Marine Blue",
      "Federation University certificate frame - Pine Wood Wine",
      "Federation University certificate frame - Pine Wood Eucalypt",
      "Federation University certificate frame - Pine Wood Light Cream",
      "Federation University certificate frame - Modern Black",
      "Federation University certificate frame - Modern Marine Blue",
      "Federation University certificate frame - Modern Wine",
      "Federation University certificate frame - Modern Eucalypt",
      "Federation University certificate frame - Modern Light Cream",
    ],
    "name" : "Federation University Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Federation Uni Certificate Frame | From $49"
  },
  "/certificate-frame/monash": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707967/Frames/Monash/Monash_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566708034/Frames/Monash/Monash_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566708019/Frames/Monash/Monash_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566708001/Frames/Monash/Monash_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707986/Frames/Monash/Monash_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284185/Frames/Monash/Monash_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272868/Frames/Monash/Monash_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283058/Frames/Monash/Monash_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282523/Frames/Monash/Monash_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281468/Frames/Monash/Monash_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Monash certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Monash testamur perfectly",
    "altTags" : [
      "Monash University certificate frame - Pine Wood Manor Black",
      "Monash University certificate frame - Pine Wood Marine Blue",
      "Monash University certificate frame - Pine Wood Wine",
      "Monash University certificate frame - Pine Wood Eucalypt",
      "Monash University certificate frame - Pine Wood Light Cream",
      "Monash University certificate frame - Modern Black",
      "Monash University certificate frame - Modern Marine Blue",
      "Monash University certificate frame - Modern Wine",
      "Monash University certificate frame - Modern Eucalypt",
      "Monash University certificate frame - Modern Light Cream",
    ],
    "name" : "Monash Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Monash Certificate Frame | From $49"
  },
  "/certificate-frame/rmit": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284185/Frames/Monash/Monash_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272868/Frames/Monash/Monash_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283058/Frames/Monash/Monash_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282523/Frames/Monash/Monash_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281468/Frames/Monash/Monash_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your RMIT certificate frame for only $49 | Fast delivery, excellent quality, made to fit your RMIT testamur perfectly",
    "altTags" : [
      "RMIT certificate frame - Pine Wood Manor Black",
      "RMIT certificate frame - Pine Wood Marine Blue",
      "RMIT certificate frame - Pine Wood Wine",
      "RMIT certificate frame - Pine Wood Eucalypt",
      "RMIT certificate frame - Pine Wood Light Cream",
      "RMIT certificate frame - Modern Black",
      "RMIT certificate frame - Modern Marine Blue",
      "RMIT certificate frame - Modern Wine",
      "RMIT certificate frame - Modern Eucalypt",
      "RMIT certificate frame - Modern Light Cream",
    ],
    "name" : "RMIT Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "RMIT Certificate Frame | From $49"
  },
  "/certificate-frame/swinburne": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284185/Frames/Monash/Monash_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272868/Frames/Monash/Monash_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283058/Frames/Monash/Monash_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282523/Frames/Monash/Monash_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281468/Frames/Monash/Monash_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Swinburne certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Swinburne testamur perfectly",
    "altTags" : [
      "Swinburne certificate frame - Pine Wood Manor Black",
      "Swinburne certificate frame - Pine Wood Marine Blue",
      "Swinburne certificate frame - Pine Wood Wine",
      "Swinburne certificate frame - Pine Wood Eucalypt",
      "Swinburne certificate frame - Pine Wood Light Cream",
      "Swinburne certificate frame - Modern Black",
      "Swinburne certificate frame - Modern Marine Blue",
      "Swinburne certificate frame - Modern Wine",
      "Swinburne certificate frame - Modern Eucalypt",
      "Swinburne certificate frame - Modern Light Cream",
    ],
    "name" : "Swinburne Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Swinburne Certificate Frame | From $49"
  },
  "/certificate-frame/unimelb": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713417/Frames/UniMelb/UniMelb_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713480/Frames/UniMelb/UniMelb_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713457/Frames/UniMelb/UniMelb_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713445/Frames/UniMelb/UniMelb_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713432/Frames/UniMelb/UniMelb_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284451/Frames/UniMelb/UniMelb_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273169/Frames/UniMelb/UniMelb_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283178/Frames/UniMelb/UniMelb_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282681/Frames/UniMelb/UniMelb_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281605/Frames/UniMelb/UniMelb_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UniMelb certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UniMelb testamur perfectly",
    "altTags" : [
      "UniMelb certificate frame - Pine Wood Manor Black",
      "UniMelb certificate frame - Pine Wood Marine Blue",
      "UniMelb certificate frame - Pine Wood Wine",
      "UniMelb certificate frame - Pine Wood Eucalypt",
      "UniMelb certificate frame - Pine Wood Light Cream",
      "UniMelb certificate frame - Modern Black",
      "UniMelb certificate frame - Modern Marine Blue",
      "UniMelb certificate frame - Modern Wine",
      "UniMelb certificate frame - Modern Eucalypt",
      "UniMelb certificate frame - Modern Light Cream",
    ],
    "name" : "UniMelb Certificate Frame (From 2016)",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UniMelb Certificate Frame | From $49"
  },
  "/certificate-frame/victoria-university": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727314/Frames/VICU/VICU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727428/Frames/VICU/VICU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727413/Frames/VICU/VICU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727390/Frames/VICU/VICU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727353/Frames/VICU/VICU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284601/Frames/VICU/VICU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273354/Frames/VICU/VICU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283351/Frames/VICU/VICU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282832/Frames/VICU/VICU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281751/Frames/VICU/VICU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Victoria Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Victoria Uni testamur perfectly",
    "altTags" : [
      "Victoria University certificate frame - Pine Wood Manor Black",
      "Victoria University certificate frame - Pine Wood Marine Blue",
      "Victoria University certificate frame - Pine Wood Wine",
      "Victoria University certificate frame - Pine Wood Eucalypt",
      "Victoria University certificate frame - Pine Wood Light Cream",
      "Victoria University certificate frame - Modern Black",
      "Victoria University certificate frame - Modern Marine Blue",
      "Victoria University certificate frame - Modern Wine",
      "Victoria University certificate frame - Modern Eucalypt",
      "Victoria University certificate frame - Modern Light Cream",
    ],
    "name" : "Victoria University Certificate Frame (From 2016)",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Victoria Uni Certificate Frame | From $49"
  },

    // STATE: "QLD",

 "/certificate-frame/uq" : {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721209/Frames/UQ/UQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721328/Frames/UQ/UQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721281/Frames/UQ/UQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721250/Frames/UQ/UQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721231/Frames/UQ/UQ_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284483/Frames/UQ/UQ_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273219/Frames/UQ/UQ_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283224/Frames/UQ/UQ_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282721/Frames/UQ/UQ_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281646/Frames/UQ/UQ_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UQ certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UQ testamur perfectly",
    "altTags" : [
      "UQ certificate frame - Pine Wood Manor Black",
      "UQ certificate frame - Pine Wood Marine Blue",
      "UQ certificate frame - Pine Wood Wine",
      "UQ certificate frame - Pine Wood Eucalypt",
      "UQ certificate frame - Pine Wood Light Cream",
      "UQ certificate frame - Modern Black",
      "UQ certificate frame - Modern Marine Blue",
      "UQ certificate frame - Modern Wine",
      "UQ certificate frame - Modern Eucalypt",
      "UQ certificate frame - Modern Light Cream",
    ],
    "name" : "UQ Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UQ Certificate Frame | From $49"
  },
  "/certificate-frame/qut": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710457/Frames/QUT/QUT_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710603/Frames/QUT/QUT_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710573/Frames/QUT/QUT_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710560/Frames/QUT/QUT_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710476/Frames/QUT/QUT_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00321', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284207/Frames/QUT/QUT_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00322', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272891/Frames/QUT/QUT_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00323', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283084/Frames/QUT/QUT_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00324', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282568/Frames/QUT/QUT_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00325', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281499/Frames/QUT/QUT_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your QUT certificate frame for only $49 | Fast delivery, excellent quality, made to fit your QUT testamur perfectly",
    "altTags" : [
      "QUT certificate frame - Pine Wood Manor Black",
      "QUT certificate frame - Pine Wood Marine Blue",
      "QUT certificate frame - Pine Wood Wine",
      "QUT certificate frame - Pine Wood Eucalypt",
      "QUT certificate frame - Pine Wood Light Cream",
      "QUT certificate frame - Modern Black",
      "QUT certificate frame - Modern Marine Blue",
      "QUT certificate frame - Modern Wine",
      "QUT certificate frame - Modern Eucalypt",
      "QUT certificate frame - Modern Light Cream",
    ],
    "name" : "QUT Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D2",
    "productType" : "bachelor-frame",
    "titleTag" : "QUT Certificate Frame | From $49"
  },

  "/certificate-frame/jcu": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394089/Frames/JCU/JCU_Modern_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394094/Frames/JCU/JCU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394091/Frames/JCU/JCU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394094/Frames/JCU/JCU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394092/Frames/JCU/JCU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394089/Frames/JCU/JCU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your JCU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your JCU testamur perfectly",
    "altTags" : [
      "JCU certificate frame - Pine Wood Manor Black",
      "JCU certificate frame - Pine Wood Marine Blue",
      "JCU certificate frame - Pine Wood Wine",
      "JCU certificate frame - Pine Wood Eucalypt",
      "JCU certificate frame - Pine Wood Light Cream",
      "JCU certificate frame - Modern Black",
      "JCU certificate frame - Modern Marine Blue",
      "JCU certificate frame - Modern Wine",
      "JCU certificate frame - Modern Eucalypt",
      "JCU certificate frame - Modern Light Cream",
    ],
    "name" : "JCU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "JCU Certificate Frame | From $49"
  },
  "/certificate-frame/bond": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702463/Frames/Bond/Bond_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702444/Frames/Bond/Bond_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702263/Frames/Bond/Bond_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702059/Frames/Bond/Bond_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702027/Frames/Bond/Bond_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284010/Frames/Bond/Bond_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272272/Frames/Bond/Bond_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282912/Frames/Bond/Bond_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282409/Frames/Bond/Bond_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281326/Frames/Bond/Bond_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Bond Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Bond Uni testamur perfectly",
    "altTags" : [
      "Bond University certificate frame - Pine Wood Manor Black",
      "Bond University certificate frame - Pine Wood Marine Blue",
      "Bond University certificate frame - Pine Wood Wine",
      "Bond University certificate frame - Pine Wood Eucalypt",
      "Bond University certificate frame - Pine Wood Light Cream",
      "Bond University certificate frame - Modern Black",
      "Bond University certificate frame - Modern Marine Blue",
      "Bond University certificate frame - Modern Wine",
      "Bond University certificate frame - Modern Eucalypt",
      "Bond University certificate frame - Modern Light Cream",
    ],
    "name" : "Bond Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Bond Uni Certificate Frame | From $49"
  },
  "/certificate-frame/cqu": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702929/Frames/CQU/CQU_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703066/Frames/CQU/CQU_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566703013/Frames/CQU/CQU_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702990/Frames/CQU/CQU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702968/Frames/CQU/CQU_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284030/Frames/CQU/CQU_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272308/Frames/CQU/CQU_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282945/Frames/CQU/CQU_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282419/Frames/CQU/CQU_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281348/Frames/CQU/CQU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your CQU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your CQU testamur perfectly",
    "altTags" : [
      "CQU certificate frame - Pine Wood Manor Black",
      "CQU certificate frame - Pine Wood Marine Blue",
      "CQU certificate frame - Pine Wood Wine",
      "CQU certificate frame - Pine Wood Eucalypt",
      "CQU certificate frame - Pine Wood Light Cream",
      "CQU certificate frame - Modern Black",
      "CQU certificate frame - Modern Marine Blue",
      "CQU certificate frame - Modern Wine",
      "CQU certificate frame - Modern Eucalypt",
      "CQU certificate frame - Modern Light Cream"
      ],
    "name" : "CQU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "CQU Certificate Frame | From $49"
  },
  "/certificate-frame/griffith": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707633/Frames/Griffith/Griffith_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707731/Frames/Griffith/Griffith_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707715/Frames/Griffith/Griffith_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707670/Frames/Griffith/Griffith_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566707652/Frames/Griffith/Griffith_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284146/Frames/Griffith/Griffith_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272825/Frames/Griffith/Griffith_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283021/Frames/Griffith/Griffith_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282491/Frames/Griffith/Griffith_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281425/Frames/Griffith/Griffith_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Griffith certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Griffith testamur perfectly",
    "altTags" : [
      "Griffith University certificate frame - Pine Wood Manor Black",
      "Griffith University certificate frame - Pine Wood Marine Blue",
      "Griffith University certificate frame - Pine Wood Wine",
      "Griffith University certificate frame - Pine Wood Eucalypt",
      "Griffith University certificate frame - Pine Wood Light Cream",
      "Griffith University certificate frame - Modern Black",
      "Griffith University certificate frame - Modern Marine Blue",
      "Griffith University certificate frame - Modern Wine",
      "Griffith University certificate frame - Modern Eucalypt",
      "Griffith University certificate frame - Modern Light Cream"
      ],
    "name" : "Griffith Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Griffith Uni Certificate Frame | From $49"
  },

   "/certificate-frame/usq": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284778/Frames/MQ/MQ_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561280867/Frames/MQ/MQ_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283603/Frames/MQ/MQ_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561278129/Frames/MQ/MQ_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282044/Frames/MQ/MQ_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284207/Frames/QUT/QUT_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272891/Frames/QUT/QUT_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283084/Frames/QUT/QUT_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282568/Frames/QUT/QUT_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281499/Frames/QUT/QUT_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your USQ certificate frame for only $49 | Fast delivery, excellent quality, made to fit your USQ testamur perfectly",
    "altTags" : [
      "USQ certificate frame - Pine Wood Manor Black",
      "USQ certificate frame - Pine Wood Marine Blue",
      "USQ certificate frame - Pine Wood Wine",
      "USQ certificate frame - Pine Wood Eucalypt",
      "USQ certificate frame - Pine Wood Light Cream",
      "USQ certificate frame - Modern Black",
      "USQ certificate frame - Modern Marine Blue",
      "USQ certificate frame - Modern Wine",
      "USQ certificate frame - Modern Eucalypt",
      "USQ certificate frame - Modern Light Cream",

    "USQ certificate frame - Traditional Navy", "USQ certificate frame - Traditional Eucalypt", "USQ certificate frame - Modern",],
    "name" : "USQ Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "USQ Certificate Frame | From $49"
  },
  "/certificate-frame/usc": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721580/Frames/USC/USC_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721842/Frames/USC/USC_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721689/Frames/USC/USC_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721644/Frames/USC/USC_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566721616/Frames/USC/USC_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284561/Frames/USC/USC_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273291/Frames/USC/USC_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283304/Frames/USC/USC_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282785/Frames/USC/USC_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281710/Frames/USC/USC_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your USC certificate frame for only $49 | Fast delivery, excellent quality, made to fit your USC testamur perfectly",
    "altTags" : [
      "USC certificate frame - Pine Wood Manor Black",
      "USC certificate frame - Pine Wood Marine Blue",
      "USC certificate frame - Pine Wood Wine",
      "USC certificate frame - Pine Wood Eucalypt",
      "USC certificate frame - Pine Wood Light Cream",
      "USC certificate frame - Modern Black",
      "USC certificate frame - Modern Marine Blue",
      "USC certificate frame - Modern Wine",
      "USC certificate frame - Modern Eucalypt",
      "USC certificate frame - Modern Light Cream"
      ],
    "name" : "USC Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "USC Certificate Frame | From $49"
  },

    // STATE: "SA",

  "/certificate-frame/adelaide-uni": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700773/Frames/Adelaide/Adelaide_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700982/Frames/Adelaide/Adelaide_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700903/Frames/Adelaide/Adelaide_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700950/Frames/Adelaide/Adelaide_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566700925/Frames/Adelaide/Adelaide_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284440/Frames/Adelaide/Adelaide_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273103/Frames/Adelaide/Adelaide_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283166/Frames/Adelaide/Adelaide_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282651/Frames/Adelaide/Adelaide_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281593/Frames/Adelaide/Adelaide_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Adelaide Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Adelaide Uni testamur perfectly",
    "altTags" : [
      "Adelaide University certificate frame - Pine Wood Manor Black",
      "Adelaide University certificate frame - Pine Wood Marine Blue",
      "Adelaide University certificate frame - Pine Wood Wine",
      "Adelaide University certificate frame - Pine Wood Eucalypt",
      "Adelaide University certificate frame - Pine Wood Light Cream",
      "Adelaide University certificate frame - Modern Black",
      "Adelaide University certificate frame - Modern Marine Blue",
      "Adelaide University certificate frame - Modern Wine",
      "Adelaide University certificate frame - Modern Eucalypt",
      "Adelaide University certificate frame - Modern Light Cream"
      ],
    "name" : "Adelaide University Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Adelaide Uni Certificate Frame | From $49"
  },
  "/certificate-frame/unisa": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713533/Frames/UniSA/UniSA_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713618/Frames/UniSA/UniSA_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713601/Frames/UniSA/UniSA_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713581/Frames/UniSA/UniSA_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713552/Frames/UniSA/UniSA_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284492/Frames/UniSA/UniSA_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273237/Frames/UniSA/UniSA_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283237/Frames/UniSA/UniSA_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282737/Frames/UniSA/UniSA_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281659/Frames/UniSA/UniSA_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UniSA certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UniSA testamur perfectly",
    "altTags" : [
      "UniSA certificate frame - Pine Wood Manor Black",
      "UniSA certificate frame - Pine Wood Marine Blue",
      "UniSA certificate frame - Pine Wood Wine",
      "UniSA certificate frame - Pine Wood Eucalypt",
      "UniSA certificate frame - Pine Wood Light Cream",
      "UniSA certificate frame - Modern Black",
      "UniSA certificate frame - Modern Marine Blue",
      "UniSA certificate frame - Modern Wine",
      "UniSA certificate frame - Modern Eucalypt",
      "UniSA certificate frame - Modern Light Cream"
      ],
    "name" : "UniSA Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UniSA Certificate Frame | From $49"
  },
  "/certificate-frame/flinders": {
    "photos" : [
      { style: 'FRA00012', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566705114/Frames/Flinders/Flinders_Traditional_Black.jpg" },
      { style: 'FRA00012', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566705250/Frames/Flinders/Flinders_Traditional_Navy.jpg" },
      { style: 'FRA00012', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566705195/Frames/Flinders/Flinders_Traditional_Maroon.jpg" },
      { style: 'FRA00012', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566705171/Frames/Flinders/Flinders_Traditional_Eucalypt.jpg" },
      { style: 'FRA00012', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566705138/Frames/Flinders/Flinders_Traditional_Cream.jpg" },
      { style: 'FRA00011', colour: 'MAT00011', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284130/Frames/Flinders/Flinders_Modern_Black.jpg" },
      { style: 'FRA00011', colour: 'MAT00012', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272808/Frames/Flinders/Flinders_Modern_Navy.jpg" },
      { style: 'FRA00011', colour: 'MAT00013', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283012/Frames/Flinders/Flinders_Modern_Maroon.jpg" },
      { style: 'FRA00011', colour: 'MAT00014', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282480/Frames/Flinders/Flinders_Modern_Eucalypt.jpg" },
      { style: 'FRA00011', colour: 'MAT00015', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281412/Frames/Flinders/Flinders_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Flinders Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Flinders Uni testamur perfectly",
    "altTags" : [
      "Flinders University certificate frame - Pine Wood Manor Black",
      "Flinders University certificate frame - Pine Wood Marine Blue",
      "Flinders University certificate frame - Pine Wood Wine",
      "Flinders University certificate frame - Pine Wood Eucalypt",
      "Flinders University certificate frame - Pine Wood Light Cream",
      "Flinders University certificate frame - Modern Black",
      "Flinders University certificate frame - Modern Marine Blue",
      "Flinders University certificate frame - Modern Wine",
      "Flinders University certificate frame - Modern Eucalypt",
      "Flinders University certificate frame - Modern Light Cream"
      ],
    "name" : "Flinders University Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A3",
    "matteStyle" : "F2D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Flinders Uni Certificate Frame | From $49"
  },

    // STATE: "WA",

  "/certificate-frame/uwa": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566726741/Frames/UWA/UWA_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727034/Frames/UWA/UWA_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566727002/Frames/UWA/UWA_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566726818/Frames/UWA/UWA_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566726775/Frames/UWA/UWA_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566818631/Frames/UWA/UWA_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273054/Frames/UWA/UWA_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283155/Frames/UWA/UWA_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282640/Frames/UWA/UWA_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281579/Frames/UWA/UWA_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UWA certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UWA testamur perfectly",
    "altTags" : [
      "UWA certificate frame - Pine Wood Manor Black",
      "UWA certificate frame - Pine Wood Marine Blue",
      "UWA certificate frame - Pine Wood Wine",
      "UWA certificate frame - Pine Wood Eucalypt",
      "UWA certificate frame - Pine Wood Light Cream",
      "UWA certificate frame - Modern Black",
      "UWA certificate frame - Modern Marine Blue",
      "UWA certificate frame - Modern Wine",
      "UWA certificate frame - Modern Eucalypt",
      "UWA certificate frame - Modern Light Cream"
      ],
    "name" : "UWA Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UWA Certificate Frame | From $49"
  },
  "/certificate-frame/ecu": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704307/Frames/ECU/ECU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704431/Frames/ECU/ECU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704371/Frames/ECU/ECU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704353/Frames/ECU/ECU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566704323/Frames/ECU/ECU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284105/Frames/ECU/ECU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272781/Frames/ECU/ECU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282989/Frames/ECU/ECU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282460/Frames/ECU/ECU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281389/Frames/ECU/ECU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your ECU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your ECU testamur perfectly",
    "altTags" : [
      "ECU certificate frame - Pine Wood Manor Black",
      "ECU certificate frame - Pine Wood Marine Blue",
      "ECU certificate frame - Pine Wood Wine",
      "ECU certificate frame - Pine Wood Eucalypt",
      "ECU certificate frame - Pine Wood Light Cream",
      "ECU certificate frame - Modern Black",
      "ECU certificate frame - Modern Marine Blue",
      "ECU certificate frame - Modern Wine",
      "ECU certificate frame - Modern Eucalypt",
      "ECU certificate frame - Modern Light Cream"
      ],
    "name" : "ECU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "ECU Certificate Frame | From $49"
  },


  "/certificate-frame/curtin": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00021', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622887/Frames/Curtin/Curtin_University_Manor_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00022', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622890/Frames/Curtin/Curtin_University_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00023', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622890/Frames/Curtin/Curtin_University_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00024', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622889/Frames/Curtin/Curtin_University_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00025', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622889/Frames/Curtin/Curtin_University_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00021', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622887/Frames/Curtin/Curtin_University_Manor_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00022', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622890/Frames/Curtin/Curtin_University_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00023', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622890/Frames/Curtin/Curtin_University_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00024', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622889/Frames/Curtin/Curtin_University_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00025', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622889/Frames/Curtin/Curtin_University_Cream.jpg" },
    ],
    "metaTag" : "Buy your Curtin certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Curtin testamur perfectly",
    "altTags" : [
      "Curtin University certificate frame - Pine Wood Manor Black",
      "Curtin University certificate frame - Pine Wood Marine Blue",
      "Curtin University certificate frame - Pine Wood Wine",
      "Curtin University certificate frame - Pine Wood Eucalypt",
      "Curtin University certificate frame - Pine Wood Light Cream",
      "Curtin University certificate frame - Modern Black",
      "Curtin University certificate frame - Modern Marine Blue",
      "Curtin University certificate frame - Modern Wine",
      "Curtin University certificate frame - Modern Eucalypt",
      "Curtin University certificate frame - Modern Light Cream"
      ],
    "name" : "Curtin University Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D2",
    "productType" : "bachelor-frame",
    "titleTag" : "Curtin Certificate Frame | From $49"
  },
  "/certificate-frame/utas": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724330/Frames/UTAS/UTAS_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724495/Frames/UTAS/UTAS_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724457/Frames/UTAS/UTAS_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724433/Frames/UTAS/UTAS_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566724355/Frames/UTAS/UTAS_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284516/Frames/UTAS/UTAS_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561273262/Frames/UTAS/UTAS_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283271/Frames/UTAS/UTAS_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282760/Frames/UTAS/UTAS_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281682/Frames/UTAS/UTAS_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your UTas certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UTas testamur perfectly",
    "altTags" : [
      "UTas certificate frame - Pine Wood Manor Black",
      "UTas certificate frame - Pine Wood Marine Blue",
      "UTas certificate frame - Pine Wood Wine",
      "UTas certificate frame - Pine Wood Eucalypt",
      "UTas certificate frame - Pine Wood Light Cream",
      "UTas certificate frame - Modern Black",
      "UTas certificate frame - Modern Marine Blue",
      "UTas certificate frame - Modern Wine",
      "UTas certificate frame - Modern Eucalypt",
      "UTas certificate frame - Modern Light Cream"
      ],
    "name" : "UTas Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "UTas Certificate Frame | From $49"
  },

    // STATE: "ACT",

  "/certificate-frame/anu": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701843/Frames/ANU/ANU_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701919/Frames/ANU/ANU_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701903/Frames/ANU/ANU_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701880/Frames/ANU/ANU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701859/Frames/ANU/ANU_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283976/Frames/ANU/ANU_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272256/Frames/ANU/ANU_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282901/Frames/ANU/ANU_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282398/Frames/ANU/ANU_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281315/Frames/ANU/ANU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your ANU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your ANU testamur perfectly",
    "altTags" : [
      "ANU certificate frame - Pine Wood Manor Black",
      "ANU certificate frame - Pine Wood Marine Blue",
      "ANU certificate frame - Pine Wood Wine",
      "ANU certificate frame - Pine Wood Eucalypt",
      "ANU certificate frame - Pine Wood Light Cream",
      "ANU certificate frame - Modern Black",
      "ANU certificate frame - Modern Marine Blue",
      "ANU certificate frame - Modern Wine",
      "ANU certificate frame - Modern Eucalypt",
      "ANU certificate frame - Modern Light Cream"
      ],
    "name" : "ANU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D1",
    "productType" : "bachelor-frame",
    "titleTag" : "ANU Certificate Frame | From $49"
  },
  "/certificate-frame/uc": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00021', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566710938/Frames/UC/UC_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00022', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566713152/Frames/UC/UC_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00023', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566711341/Frames/UC/UC_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00024', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566711323/Frames/UC/UC_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00025', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566711164/Frames/UC/UC_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00021', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284227/Frames/UC/UC_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00022', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272921/Frames/UC/UC_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00023', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561283109/Frames/UC/UC_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00024', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282599/Frames/UC/UC_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00025', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281519/Frames/UC/UC_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Canberra Uni certificate frame for only $49 | Fast delivery, excellent quality, made to fit your UC testamur perfectly",
    "altTags" : [
      "UC certificate frame - Pine Wood Manor Black",
      "UC certificate frame - Pine Wood Marine Blue",
      "UC certificate frame - Pine Wood Wine",
      "UC certificate frame - Pine Wood Eucalypt",
      "UC certificate frame - Pine Wood Light Cream",
      "UC certificate frame - Modern Black",
      "UC certificate frame - Modern Marine Blue",
      "UC certificate frame - Modern Wine",
      "UC certificate frame - Modern Eucalypt",
      "UC certificate frame - Modern Light Cream",
      ],
    "name" : "University of Canberra Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D2",
    "productType" : "bachelor-frame",
    "titleTag" : "University of Canberra Certificate Frame | From $49"
  },

    // STATE: "NT",

  "/certificate-frame/cdu": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702661/Frames/CDU/CDU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702754/Frames/CDU/CDU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702736/Frames/CDU/CDU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702709/Frames/CDU/CDU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566702686/Frames/CDU/CDU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561284052/Frames/CDU/CDU_Modern_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561272322/Frames/CDU/CDU_Modern_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282955/Frames/CDU/CDU_Modern_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561282428/Frames/CDU/CDU_Modern_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1561281360/Frames/CDU/CDU_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your CDU certificate frame for only $49 | Fast delivery, excellent quality, made to fit your CDU testamur perfectly",
    "altTags" : [
      "CDU certificate frame - Pine Wood Manor Black",
      "CDU certificate frame - Pine Wood Marine Blue",
      "CDU certificate frame - Pine Wood Wine",
      "CDU certificate frame - Pine Wood Eucalypt",
      "CDU certificate frame - Pine Wood Light Cream",
      "CDU certificate frame - Modern Black",
      "CDU certificate frame - Modern Marine Blue",
      "CDU certificate frame - Modern Wine",
      "CDU certificate frame - Modern Eucalypt",
      "CDU certificate frame - Modern Light Cream"
      ],
    "name" : "CDU Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Charles Darwin Uni Certificate Frame | From $49"
  },


// Legal

  "/certificate-frame/college-of-law": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622573/Frames/College_of_Law/The_College_of_Law_Manor_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622574/Frames/College_of_Law/The_College_of_Law_Navy_Blue.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622575/Frames/College_of_Law/The_College_of_Law_Burgundy.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622573/Frames/College_of_Law/The_College_of_Law_Forest_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622574/Frames/College_of_Law/The_College_of_Law_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622573/Frames/College_of_Law/The_College_of_Law_Manor_Black.jpg"},
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622574/Frames/College_of_Law/The_College_of_Law_Navy_Blue.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622575/Frames/College_of_Law/The_College_of_Law_Burgundy.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622573/Frames/College_of_Law/The_College_of_Law_Forest_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622574/Frames/College_of_Law/The_College_of_Law_Cream.jpg" },
    ],
    "metaTag" : "Buy your College of Law certificate frame for only $49 | Fast delivery, excellent quality, made to fit your College of Law testamur perfectly",
    "altTags" : [
      "College of Law certificate frame - Pine Wood Manor Black",
      "College of Law certificate frame - Pine Wood Marine Blue",
      "College of Law certificate frame - Pine Wood Wine",
      "College of Law certificate frame - Pine Wood Eucalypt",
      "College of Law certificate frame - Pine Wood Light Cream",
      "College of Law certificate frame - Modern Black",
      "College of Law certificate frame - Modern Marine Blue",
      "College of Law certificate frame - Modern Wine",
      "College of Law certificate frame - Modern Eucalypt",
      "College of Law certificate frame - Modern Light Cream",
    ],
    "name" : "College of Law (GDLP) Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "College of Law (GDLP) Certificate Frame | From $49"
  },

  "/certificate-frame/supreme-court-vic": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622994/Frames/Supreme_Court/Supreme_Court_Admission_Manor_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Navy_Blue.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Green.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622994/Frames/Supreme_Court/Supreme_Court_Admission_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622994/Frames/Supreme_Court/Supreme_Court_Admission_Manor_Black.jpg"},
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Navy_Blue.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622993/Frames/Supreme_Court/Supreme_Court_Admission_Green.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1663622994/Frames/Supreme_Court/Supreme_Court_Admission_Cream.jpg" },
    ],
    "metaTag" : "Buy your Supreme Court of Victoria certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Supreme Court of Victoria testamur perfectly",
    "altTags" : [
      "Supreme Court of Victoria certificate frame - Pine Wood Manor Black",
      "Supreme Court of Victoria certificate frame - Pine Wood Marine Blue",
      "Supreme Court of Victoria certificate frame - Pine Wood Wine",
      "Supreme Court of Victoria certificate frame - Pine Wood Eucalypt",
      "Supreme Court of Victoria certificate frame - Pine Wood Light Cream",
      "Supreme Court of Victoria certificate frame - Modern Black",
      "Supreme Court of Victoria certificate frame - Modern Marine Blue",
      "Supreme Court of Victoria certificate frame - Modern Wine",
      "Supreme Court of Victoria certificate frame - Modern Eucalypt",
      "Supreme Court of Victoria certificate frame - Modern Light Cream",
    ],
    "name" : "Supreme Court of Victoria Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Supreme Court of Victoria Certificate Frame | From $49"
  },

  "/certificate-frame/supreme-court-nsw": {
    "photos" : [
      { style: 'FRA00013', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701843/Frames/ANU/ANU_Traditional_Black.jpg" },
      { style: 'FRA00013', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701919/Frames/ANU/ANU_Traditional_Navy.jpg" },
      { style: 'FRA00013', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701903/Frames/ANU/ANU_Traditional_Maroon.jpg" },
      { style: 'FRA00013', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701880/Frames/ANU/ANU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00013', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701859/Frames/ANU/ANU_Traditional_Cream.jpg" },
      { style: 'FRA00003', colour: 'MAT00311', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394303/Frames/NSW_Supreme_Court/NSW_Sup_Court_Modern_Black.jpg" },
      { style: 'FRA00003', colour: 'MAT00312', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394305/Frames/NSW_Supreme_Court/NSW_Sup_Court_Modern_Navy.jpg" },
      { style: 'FRA00003', colour: 'MAT00313', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394305/Frames/NSW_Supreme_Court/NSW_Sup_Court_Modern_Maroon.jpg" },
      { style: 'FRA00003', colour: 'MAT00314', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394305/Frames/NSW_Supreme_Court/NSW_Sup_Court_Modern_Eucalypt.jpg" },
      { style: 'FRA00003', colour: 'MAT00315', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685394303/Frames/NSW_Supreme_Court/NSW_Sup_Court_Modern_Cream.jpg" },
    ],
    "metaTag" : "Buy your Supreme Court of New South Wales certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Supreme Court of NSW testamur perfectly",
    "altTags" : [
      "Supreme Court of NSW certificate frame - Pine Wood Manor Black",
      "Supreme Court of NSW certificate frame - Pine Wood Marine Blue",
      "Supreme Court of NSW certificate frame - Pine Wood Burgundy",
      "Supreme Court of NSW certificate frame - Pine Wood Eucalypt",
      "Supreme Court of NSW certificate frame - Pine Wood Light Cream",
      "Supreme Court of NSW certificate frame - Modern Black",
      "Supreme Court of NSW certificate frame - Modern Marine Blue",
      "Supreme Court of NSW certificate frame - Modern Burgundy",
      "Supreme Court of NSW certificate frame - Modern Eucalypt",
      "Supreme Court of NSW certificate frame - Modern Light Cream"
      ],
    "name" : "Supreme Court of NSW Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "Style3",
    "matteStyle" : "F3D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Supreme Court of NSW Certificate Frame | From $49"
  },

  "/certificate-frame/supreme-court-qld": {
    "photos" : [
      { style: 'FRA00002', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701843/Frames/ANU/ANU_Traditional_Black.jpg" },
      { style: 'FRA00002', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701919/Frames/ANU/ANU_Traditional_Navy.jpg" },
      { style: 'FRA00002', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701903/Frames/ANU/ANU_Traditional_Maroon.jpg" },
      { style: 'FRA00002', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701880/Frames/ANU/ANU_Traditional_Eucalypt.jpg" },
      { style: 'FRA00002', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1566701859/Frames/ANU/ANU_Traditional_Cream.jpg" },
      { style: 'FRA00001', colour: 'MAT00001', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685395757/Frames/QLD_Supreme_Court/Supreme_Court_QLD_Black.jpg" },
      { style: 'FRA00001', colour: 'MAT00002', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685395758/Frames/QLD_Supreme_Court/Supreme_Court_QLD_Navy.jpg" },
      { style: 'FRA00001', colour: 'MAT00003', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685395757/Frames/QLD_Supreme_Court/Supreme_Court_QLD_Maroon.jpg" },
      { style: 'FRA00001', colour: 'MAT00004', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685395756/Frames/QLD_Supreme_Court/Supreme_Court_QLD_Eucalypt.jpg" },
      { style: 'FRA00001', colour: 'MAT00005', url: "https://res.cloudinary.com/gowningstreet/image/upload/v1685395758/Frames/QLD_Supreme_Court/Supreme_Court_QLD_Cream.jpg"},
    ],
    "metaTag" : "Buy your Supreme Court of Queensland certificate frame for only $49 | Fast delivery, excellent quality, made to fit your Supreme Court of QLD testamur perfectly",
    "altTags" : [
      "Supreme Court of QLD certificate frame - Pine Wood Manor Black",
      "Supreme Court of QLD certificate frame - Pine Wood Marine Blue",
      "Supreme Court of QLD certificate frame - Pine Wood Burgundy",
      "Supreme Court of QLD certificate frame - Pine Wood Eucalypt",
      "Supreme Court of QLD certificate frame - Pine Wood Light Cream",
      "Supreme Court of QLD certificate frame - Modern Black",
      "Supreme Court of QLD certificate frame - Modern Marine Blue",
      "Supreme Court of QLD certificate frame - Modern Burgundy",
      "Supreme Court of QLD certificate frame - Modern Eucalypt",
      "Supreme Court of QLD certificate frame - Modern Light Cream"
      ],
    "name" : "Supreme Court of QLD Certificate Frame",
    "price" : MODERN_FRAME,
    "level" : "Bachelor",
    "frameSize" : "A4",
    "matteStyle" : "F1D1",
    "productType" : "bachelor-frame",
    "titleTag" : "Supreme Court of QLD Certificate Frame | From $49"
  },

}