import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import {
  BARRISTER_GOWN_PRICE,
  ADMISSIONS_GOWN_PRICE,
  BARRISTER_WIG_PRICE,
  BENCHER_WIG_PRICE,
  MODERN_FRAME
} from '../../constants'

const BuyLegalwear = ({name}) => {
  return (
    <div className="shop-products">
      <Helmet
        title="Purchase Quality Legal Wear at Affordable Prices| Shop online at Gowning Street"
        meta={[
          {"name": "description", "content": "We Make Quality Legal Wear at great prices! With Fast Shipping Australia-Wide. Our Legal Wear is crafted to perfection"},
        ]}
      >
      </Helmet>
      <h1>Quality Legal Wear at Affordable Prices - Australia Wide Delivery</h1>
        <div className="subheading">
          <h2>Legal wear handcrafted to perfection</h2>
        </div>
        <div className="catalogue">
          <div className="img-container">
          <div className="img-box">
            <Link to="/certificate-frame/college-of-law">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1663622575/Frames/College_of_Law/The_College_of_Law_Burgundy.jpg" alt="College of Law Certificate Frame"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">College of Law Certificate Frame (GDLP)
                  </strong>
                  <p>${MODERN_FRAME}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/certificate-frame/supreme-court-vic">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1663622994/Frames/Supreme_Court/Supreme_Court_Admission_Cream.jpg" alt="Supreme Court Certificate Frame"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Supreme Court of Victoria Certificate Frame
                  </strong>
                  <p>${MODERN_FRAME}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
          </div>
          <div className="img-box">
            <Link to="/legal-wear/legal-admissions-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg" alt="Legal Admissions Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Legal Admissions Gown
                  </strong>
                  <p>${ADMISSIONS_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/legal-wear/barristers-gown">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg" alt="Barrister's Gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Barrister's Gown
                  </strong>
                  <p>${BARRISTER_GOWN_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/legal-wear/barristers-wigs">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img
                    className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png"
                    alt="Barrister's Wig"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Barrister's Wig</strong>
                  <p>${BARRISTER_WIG_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
            <div className="img-box">
            <Link to="/legal-wear/benchers-wigs">
              <div className="img-unit">
                <div className="fixed-height-img">
                  <img className="graduation_regalia" src="https://res.cloudinary.com/gowningstreet/image/upload/v1592706143/Legal%20Wear/Benchers_Wig.png" alt="Bachelor style gown"/>
                </div>
                <div className="fixed-height-text">
                  <strong className="item-name">Bencher's Wig</strong>
                  <p>${BENCHER_WIG_PRICE}</p>
                </div>
                <button>Shop now</button>
              </div>
            </Link>
            </div>
          </div>
        </div>
        <div className="products-desc">
          <h2>We Stock Quality Legal Wear</h2>
          <p>Gowning Street is an experienced manufacturer of legal wear. We pride ourselves in the crafting of comfortable and quality legal wear. We deliver Australia-wide and offer same day dispatch for orders received before noon. We understand the importance of looking at your best at your proceedings.
          </p>
          <h2>Legal Regalia Shipped To Your Door</h2>
          <p>Gowning Street provides Legal Regalia at great prices. We offer fast delivery Australia-wide. Our legal robes come in many different sizes and are based on height. Ordering online is easy and we can dispatch the same day for orders completed by midday. You may be surprised by our affordable pricing, we are able to achieve this as we are online only we are able to pass on these savings to you.</p>
          <h2>We Also Offer Custom Manufactured Legal Wear</h2>
          <p>If you need legal robes manufactured for a specific jurisdiction or purpose, please get in touch with us and we can run through our custom manufacturing options. Our team is very experienced and we work very efficiently to deliver your project on time with utmost quality.</p>
        </div>
    </div>
  )

};

export default BuyLegalwear;