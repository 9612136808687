import React, { Component } from 'react';

class ChangeInv extends Component {

  constructor(props) {
    super(props);

    this.state = {
      code : "",
      qty : "",
      message : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(field, event) {
    var nextState = {};
    nextState[field] = event.target.value;

    if(field === "code"){
      nextState[field] = event.target.value.toUpperCase();
    }

    this.setState(nextState);
  }

  handleSubmit() {
    var qty = Number(this.state.qty);

    if(this.state.qty === "" || qty < 0 || isNaN(qty) || qty !== Math.floor(qty)){
      this.setState({
        message : "Please enter a positive integer quantity"
      })
    }

    else {
      var that = this;

      var productReq = new XMLHttpRequest();
      productReq.addEventListener('load', function(){
        var response = JSON.parse(this.response);

        if(response.success === true){
          that.setState({
            message : "Product successfully updated! Ref#: "  + Math.round(Math.random() * 1000)
          })

          that.props.reload();
        }
        else {
          that.setState({
            message : response.err
          })
        }

      })
      productReq.open("PUT", "/api/adminapi/products");
      productReq.setRequestHeader('Content-Type', 'application/json')
      productReq.send(JSON.stringify({
        code : that.state.code,
        qty : that.state.qty
      }));
    }
  }

  render() {
    return (
      <div className="edit-inventory">
        <h1>Edit Inventory</h1>
        <p>Item code</p>
        <input
          value={this.state.code}
          onChange={this.handleChange.bind(this, "code")}
        />
        <p>New Quantity</p>
        <input
          value={this.state.qty}
          onChange={this.handleChange.bind(this, "qty")}
        />
        <button onClick={this.handleSubmit}>Submit</button>
        <p>{this.state.message}</p>
      </div>
    )
  }

};

export default ChangeInv;