import React, { Component } from 'react';
import { Link, withRouter, Switch, Route } from 'react-router-dom';

class Menu extends Component {
  constructor(props) {
    super(props);
  }

  handleClick (e, link, onNavigate, closeMenu, menuType) {
    e.preventDefault();
    onNavigate(link);
    if (closeMenu) {
      closeMenu(menuType)
    }
  }

  render () {
    const { list, onNavigate = () => {}, menuType = '', closeMenu = () => {}  } = this.props;

    return (
      <div
        id={menuType}
        onMouseLeave={(event) => {
          event.preventDefault();
          closeMenu(event, null)
        }}
      >
        <ul className={menuType +'DropDown'}>
          { list.map(({ link = '/', label, submenuList }) => {
            return (
              <div key={label}>
                {
                  submenuList
                  ? (
                    <li className="subMenuItem">
                      <a className="menuLabel">{ label }</a>
                      <ul className="subNav">
                        {submenuList.map(({ subLink = '/', subLabel }) =>
                          <li key={subLabel} onClick={(e) => this.handleClick(e, subLink, onNavigate, closeMenu, menuType)}>
                            <a>
                              <div className="subnavItem">{ subLabel }</div>
                            </a>
                          </li>
                         )}
                      </ul>
                    </li>
                  )
                  : (
                    <li className="menuItem"  onClick={(e) => this.handleClick(e, link, onNavigate) }>
                      <a className="menuLabel">{ label }</a>
                    </li>
                  )
                }
              </div>
            )}
          )}
        </ul>
      </div>
    )
  }
};

export default Menu;