import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Products } from '../../sources/products';
import ShopItems from '../selections/shop-items';
import Description from '../selections/description';
import Helmet from 'react-helmet';
import NotFound from '../404';
import ProductDescriptions from '../../sources/product-descriptions';

const Product = () => {
  const { pathname } = useLocation();
  const [image, setImage] = useState(null);
  const [mainAlt, setMainAlt] = useState(null);
  const [isProducts, setIsProducts] = useState(false);
  const productInfo = Products?.[pathname];

  const productPhotos = productInfo?.photos;
  const productAltTags = productInfo?.altTags;

  useEffect(() => {
    const altTag = productAltTags?.[0] ?? "Graduation Gowns Display Image";
    setMainAlt(altTag)
    setImage(productPhotos?.[0])
  }, [pathname]);

  const setPrimary = (event) => {
    setMainAlt(event.target.alt);
    setImage(event.target.id);
  };

  const renderImage = productPhotos
    ? productPhotos.map(function (element, index) {
      if (!productAltTags) {
        return (
          <img
            src={element}
            alt="Graduation Gowns Display Image"
            className="image-mini"
            key={element}
            id={element}
            onClick={setPrimary}
          />
        )
      }
      else {
        return (
          <img
            src={element}
            alt={productAltTags[index]}
            className="image-mini"
            key={element}
            id={element}
            onClick={setPrimary}
          />
        )
      }
    })
    : []


  if (pathname.indexOf("products") !== -1) {
      setIsProducts(true);
  }

  let titleTag = productInfo?.titleTag
    ?? productInfo?.name + " | " + "University Graduation Gown Set";

    if (isProducts) {
      titleTag = "Gowning Street Graduation Regalia";
    }

  const metaTag = productInfo?.metaTag
    ?? "Gowning Street provides quality, hand crafted graduation gown sets for Australian University students";

  if (!productInfo) {
    return <NotFound />
  }

    return (
      <div className="product-set">
        <Helmet
          title={titleTag}
          meta={[
            { "name": "description", "content": metaTag },
          ]}
        />
        <div className="image-set">
          <img
            src={image}
            id="image-main"
            alt={mainAlt}
          />
          <div className="image-preview">
            {renderImage}
          </div>
        </div>

        <h1>{productInfo?.name}</h1>
        <h2 className="product-price">${productInfo?.price}</h2>
        <ShopItems path={pathname} />
        <Description path={pathname} />
        <ProductDescriptions productInfo={productInfo}/>
      </div>
    )
};

export default Product;