import React from 'react';
import { useLocation, Link } from "react-router-dom";
import Helmet from 'react-helmet';

import UniInfo from '../../sources/universities';
import UniDesc from '../../sources/uni-descriptions';

const University = () => {

  let path = useLocation().pathname;
  var name = UniInfo[path].name;
  var degrees = UniInfo[path].degrees;

  var bachelors = [];
  var masters = [];
  var advanceddip = [];
  var PhD = [];
  var hasAD = false;
  var hasPhD = false;

  var regLink = "/regalia-regulations/" + UniInfo[path].abbreviation;

  degrees.forEach(function (element) {
    if (element.level === "Bachelor") {
      bachelors.push(element);
    }
    else if (element.level === "Master") {
      masters.push(element);
    }
    else if (element.level === "Advanced Diploma") {
      hasAD = true;
      advanceddip.push(element);
    }
    else if (element.level === "PhD") {
      hasPhD = true;
      PhD.push(element);
    }
  })

  var populate = function (element) {

    var color1 = {
      backgroundColor: "hsl(0,0%,25%)"
    }

    var color2 = {
      backgroundColor: "hsl(0,0%,25%)"
    }

    if (element.colors) {
      color1.backgroundColor = element.colors[0];
      color2.backgroundColor = element.colors[1] || element.colors[0];
    }

    return (
      <Link to={element.path} className="listitem" key={element.path}>
        <div className="unicolor">
          <div className="color1" style={color1}></div>
          <div className="color2" style={color2}></div>
        </div>
        <p>{element.name}</p>
      </Link>
    )
  }

  var Masters = masters.map(populate);
  var Bachelors = bachelors.map(populate);
  var Advanceddip = advanceddip.map(populate);
  var PhDMenu = PhD.map(populate);

  var AD = null;

  if (hasAD) {
    AD = (
      <div className="degreegroup">
        <h2>Advanced Diploma</h2>
        <div className="degreeslist">
          {Advanceddip}
        </div>
      </div>
    )
  }

  var PhDMenuToggle = null;
  if (hasPhD) {
    PhDMenuToggle = (
      <div className="degreegroup">
        <h2>PhD</h2>
        <div className="degreeslist">
          {PhDMenu}
        </div>
      </div>
    )
  }


  var titleTag;
  var metaTag;

  if (UniInfo[path].titleTag) {
    titleTag = UniInfo[path].titleTag;
  }
  else {
    titleTag = "Buy Graduation Gowns | " + name;
  }

  if (UniInfo[path].metaTag) {
    metaTag = UniInfo[path].metaTag;
  }
  else {
    metaTag = "Just finished your course and need a graduation gown? Buy one at Gowning Street at a lower price than rental! | " + name;
  }

  //Graduation Dates Table
  var graddates = null;

  if (UniInfo[path].graduations) {
    graddates = UniInfo[path].graduations.map(function (element) {

      var subjects = element.Subjects.map(function (subject) {
        return (
          <li key={subject} className="gsubjects">{subject}</li>
        )
      })

      return (
        <tr key={element.Date}>
          <td className="gdates">{element.Date}</td>
          <td>
            <ul>{subjects}</ul>
          </td>
        </tr>
      )
    })
  }

  return (
    <div className="university">
      <Helmet
        title={titleTag}
        meta={[
          { "name": "description", "content": metaTag },
        ]}
      />
      <h1>{name}</h1>
      <img src={UniInfo[path].photo} alt={UniInfo[path].altTag} />
      <div className="bachmast">
        {AD}
        <div className="degreegroup">
          <h2>Bachelors</h2>
          <div className="degreeslist">
            {Bachelors}
          </div>
        </div>
        <div className="degreegroup">
          <h2>Masters</h2>
          <div className="degreeslist">
            {Masters}
          </div>
        </div>
        {PhDMenuToggle}
      </div>
      <UniDesc
        uniName={name}
      />

      <div className="regulation-link">
        <h2>Want to find out more about the academic dress policy at {UniInfo[path].abbreviation}?</h2>
        <button><a href={regLink} target="_blank">{UniInfo[path].abbreviation} academic dress regulations</a></button>
      </div>

    </div>

  )
}

export default University;