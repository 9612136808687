'use strict';

const { BACHELOR_GOWN_PRICE } = require("../constants");

const PriceCalc = function(order,shipping){

  let subtotal = 0;
  let items = 0;

  let discountFactor = 0;
  let eligibleItems = 0;

  order.forEach(function(element){
    subtotal += Number(element.qty) * Number(element.price);
    items += Number(element.qty);

    if(element.price >= BACHELOR_GOWN_PRICE){
      discountFactor += Number(element.qty);
      eligibleItems += Number(element.qty);
    }
  });

  if(discountFactor > 10){
    discountFactor = 10;
  }

  if(discountFactor === 1){
    discountFactor = 0;
  }

  let discount = discountFactor * eligibleItems;

  let total = subtotal + shipping.total - discount;

  var GST = Math.round( (total - (total / 1.10)) * 100) / 100;

  return {
    subtotal : subtotal,
    eligibleItems : eligibleItems,
    discount : discount,
    discountFactor : discountFactor,
    total : total,
    GST : GST
  };

};

module.exports = PriceCalc;