import React from 'react';

const Hood = ({ hood }) => {
  return (
    <div className="hood">
      <h3>Hood</h3>
      <p>{hood}</p>
    </div>
  )
}


export default Hood;