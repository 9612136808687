import React from 'react';
import ExchangeSelections from './exchangeSelections';
import NavigationButton from './navigationButton';

function OrdersToExchange({
  orderDetails,
  handleSelection,
  checkAvailability,
  navigateBack,
  invError,
  inputEmptyError,
}) {

  if (orderDetails !== null) {
    const extras = []
    const ordered = JSON.parse(orderDetails.items);

    const filtered = ordered.filter(function (element) {
      if (element.qty > 1) {
        for (let i = 0; i < (element.qty - 1); i++) {
          if (element.gown) {
            extras.push(element)
          }
          if (element.cap) {
            extras.push(element)
          }
        }
        return false;
      }
      return element.gown || element.cap
    })

    const toExchange = [...filtered, ...extras]

    //error message if out of stock
    const inventoryErrors = invError.map(function (error) {
      return (
        <p className="error">
          Not enough {error.description}: {error.maxQty} in stock
        </p>
      )
    })

    return <div className="customerOrder">
      <div className="shippingdetails">
        <h1>Step 2: Select Items to Exchange</h1>
        <p>Order Details For: {orderDetails.first_name} {orderDetails.last_name}</p>
      </div>

      <div className="exchangetable">
        <div className="rowhead">
          <div className="col1">Items ordered:</div>
          <div className="col2">Exchange options</div>
        </div>
        <ExchangeSelections
          toExchange={toExchange}
          handleSelection={handleSelection}
        />
        {
         inputEmptyError &&
          <span style={{ fontSize: '12px', padding: '15px 30px', color: 'red' }}>Please select items to exchange to proceed</span>
        }
      </div>
      <div style={{
        padding: '15px',
        display: 'block',
        margin: '0 auto',
        fontSize: '12px'
      }}>
        {inventoryErrors}
      </div>
      <div className="navigateButtonGroup">
        <NavigationButton handleClick={navigateBack} label="Back" />
        <NavigationButton handleClick={checkAvailability} label="Next" />
      </div>
    </div>
  }
}

export default OrdersToExchange;