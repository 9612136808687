import React from 'react';
import styled from 'styled-components'
import { Column } from './utils/Layout';
import { AlphaHeader } from './utils/Section';
import { mediaQueries } from './utils/mediaQueries';
import { Link } from 'react-router-dom';
import {
  BACHELOR_SET_PRICE,
  BACHELOR_GOWN_PRICE,
  MASTER_GOWN_PRICE,
  CAP_PRICE,
  HOOD_PRICE,
  TEDDY_SMALL_PRICE
} from '../../../constants';

const products = [
  {
    id: 1,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165138/Uni%20Regalia%20Photos/Waikato/waikato-bach-hons-side.jpg',
    header: 'University Graduation Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 2,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165143/Regalia/bach-gown-side.jpg',
    header: 'Bachelor Style Gown',
    description: 'Graduation Gown - Bachelor Style',
    link: '/graduation-gown/gown-bachelor',
    price: `$${BACHELOR_GOWN_PRICE}`
  },
  {
    id: 3,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549186837/Regalia/master-gown-front-main.jpg',
    header: 'Master Style Gown',
    description: 'Graduation Gown - Master Style',
    link: '/graduation-gown/master-gown',
    price: `$${MASTER_GOWN_PRICE}`
  },
  {
    id: 4,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1557666353/Regalia/graduation-cap-1.jpg',
    header: 'Graduation Hats',
    description: 'Graduation Hat / Cap / Trencher',
    link: '/graduation-gown/graduation-hat',
    price: `$${CAP_PRICE}`
  },
  {
    id: 5,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Regalia/hood.jpg',
    header: 'Graduation Hoods',
    description: 'Choose graduation hoods from a variety of colours',
    link: '/graduation-gown/graduation-hoods',
    price: `$${HOOD_PRICE}`
  },
  {
    id: 6,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1549165134/Regalia/graduation-teddy-bear-1.jpg',
    header: 'Graduation Gifts',
    description: 'Graduation Teddies & Puppies',
    link: '/graduation-gifts',
    price: `$${TEDDY_SMALL_PRICE}`
  },
  {
    id: 7,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635008480/Aus-Uni-Photos/UQ/uq-bach-all-front.jpg',
    header: 'UQ Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/qld/uq',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 8,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1592479477/Uni%20Regalia%20Photos/QUT/QUT-bach-business-front.jpg',
    header: 'QUT Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/qld/qut',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 9,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635008528/Aus-Uni-Photos/USyd/usyd-bach-comm-front.jpg',
    header: 'USyd Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/nsw/usyd',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 10,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635008292/Aus-Uni-Photos/Unimelb/unimelb-bach-arts-front.jpg',
    header: 'UniMelb Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/vic/unimelb',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 11,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635008040/Aus-Uni-Photos/Monash/monash-bach-arts-front.jpg',
    header: 'Monash Uni Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/vic/monash',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 12,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635008620/Aus-Uni-Photos/UTAS/utas-mast-educ-front.jpg',
    header: 'UTas Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/tas/utas',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 13,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635007494/Aus-Uni-Photos/ACU/acu-bach-health-front.png',
    header: 'ACU Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/acu',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 14,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635007667/Aus-Uni-Photos/ANU/anu-bach-busseco-front.jpg',
    header: 'ANU Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/act/anu',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 15,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635007721/Aus-Uni-Photos/AU/au-bach-med-front.jpg',
    header: 'Adelaide Uni Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/sa/adelaide-uni',
    price: `$${BACHELOR_SET_PRICE}`
  },
  {
    id: 16,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1635007968/Aus-Uni-Photos/JCU/jcu-mast-all-front.jpg',
    header: 'JCU Graduation Gown Sets',
    description: 'Full University Graduation Gown Sets',
    link: '/uni/qld/jcu',
    price: `$${BACHELOR_SET_PRICE}`
  }
];

const SectionConainerWhite = styled.div`
  padding: 70px 15px 80px 15px;
  background-color: #FFFFFF;
  ${mediaQueries.tablet`
    padding: 20px;
  `};
`
const StyledProductRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  width: 100%;
  margin: 0 auto;
  padding: 15px 0;
  ${mediaQueries.tablet`
    div:nth-child(5) {
      display: none;
    }
    div:nth-child(6) {
      display: none;
    }
    div:nth-child(7) {
      display: none;
    }
    div:nth-child(8) {
      display: none;
    }
    div:nth-child(9) {
      display: none;
    }
    div:nth-child(10) {
      display: none;
    }
    div:nth-child(11) {
      display: none;
    }
    div:nth-child(12) {
      display: none;
    }
    div:nth-child(13) {
      display: none;
    }
    div:nth-child(14) {
      display: none;
    }
    div:nth-child(15) {
      display: none;
    }
    div:nth-child(16) {
      display: none;
    }
    margin: 15px auto;
  `};
  ${mediaQueries.mobile`
    div:nth-child(5) {
      display: none;
    }
    div:nth-child(6) {
      display: none;
    }
    div:nth-child(7) {
      display: none;
    }
    div:nth-child(8) {
      display: none;
    }
    div:nth-child(9) {
      display: none;
    }
    div:nth-child(10) {
      display: none;
    }
    div:nth-child(11) {
      display: none;
    }
    div:nth-child(12) {
      display: none;
    }
    div:nth-child(13) {
      display: none;
    }
    div:nth-child(14) {
      display: none;
    }
    div:nth-child(15) {
      display: none;
    }
    div:nth-child(16) {
      display: none;
    }
    margin: 0 auto;
  `};
`

const Title = styled.div`
  display: inline-block;
  p {
    font-weight: 700;
  }
`

const StyledLink = styled(Link)`
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #041733;
`

const ProductContainer = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 13rem;
  min-height: 25rem;
  padding: 15px 5px;
  border-radius: 4px;
  &:hover {
    box-shadow: 2px 5px 10px #888888;
  }
  p {
    line-height: 20px;
    margin-top: 14px;
  }
  ${mediaQueries.tablet`
    padding: 20px 63px;
  `};
`

const ProductImg = styled.div`
  padding: 15px 20px 10px 20px;
  img {
    background-color: hsl(0, 0%, 90%);
    height: 250px;
    border-radius: 4px;
  }
`

const BtnContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 0 5px 0;
  button {
    font-size: 16px;
    background-color: white;
    color: black;
    width: 160px;
    height: 50px;
    padding: 0px;
    border: none;
    border-radius: 3px;
    box-shadow: 1px 1px 1px 1px #888888;
    text-align: center;
    margin-top: 5px;
  }
   ${mediaQueries.tablet`
      margin: 0 0 30px 0;
      button {
        font-size: 15px;
      }
  `};
   ${mediaQueries.mobile`
      button {
        font-size: 15px;
      }
  `};
`

const Steps = ({ steps }) => (
  <StyledProductRow>
    {
      products.map((product) => (
        <Column key={product.id}>
          <StyledLink to={product.link}>
            <ProductContainer>
              <ProductImg><img src={product.icon} /></ProductImg>
              <Title>
                <p>{ product.header }</p>
              </Title>
              <p>{ product.description }</p>
              <p>{ product.price }</p>
            </ProductContainer>
          </StyledLink>
        </Column>
        )
      )
    }
  </StyledProductRow>
)

const PopularProdcts = () => {
  return (
    <SectionConainerWhite>
      <AlphaHeader>Featured Graduation Regalia Products</AlphaHeader>
      <Steps products={products} />
      <BtnContainer to="/graduation-gown"><button>Shop Now</button></BtnContainer>
    </SectionConainerWhite>
  )
}

export default PopularProdcts;




