import React from 'react';
import { Link } from 'react-router-dom';
import Helmet from 'react-helmet';
import UniStates from '../sources/states';

const BuyFrames = () => {
  const toggleState = (index) => {
    var button = document.getElementById(`button-${index}`);
    var unis = document.getElementById(index);

    if(unis.style.display === "none"){
      unis.style.display = "";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/minus_box_white_24x24.png";
    }
    else {
      unis.style.display = "none";
      button.src = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Utility/add_box_white_24x24.png";
    }
  }

  const states = Object.getOwnPropertyNames(UniStates);

  const hidden = {
    display : "none"
  }
  const toggle = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165139/Utility/add_box_white_24x24.png";

  const list = states.map(function(state, index){
    const unis = UniStates[state].unis.map(function(uni){
      return (
        <div key={uni.name} className="unititle">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
          <Link to={`certificate-frame/${uni.abbrev}`}>
            <p>{uni.name}</p>
          </Link>
        </div>
      )
    })

    return (
      <div key={state}>
        <div className="uni-expander">
          <img className="toggle-icon" src={toggle} id={`button-${index}`} onClick={() => toggleState(index)} />
            <h2 className="statetitle">{UniStates[state].abbrev}</h2>
        </div>
        <div className="uni-list" id={index} style={hidden}>
          {unis}
        </div>
      </div>
    )
  });

  return (
    <div className="shop-gowns">
      <Helmet
        title="Buy Certificate Frames Online and Save | From $49"
        meta={[
          {"name": "description", "content": "Certificate Frames made for Australian University Testamurs. Frame your University Graduation Certificate"},
        ]}
      >
      </Helmet>
      <h1>Buy Certificate Frames online for Australian University Degrees</h1>
      <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1561279573/Frames/2_Frames_Image.png" alt="University degree certificate frames" className="topimage"/>
      {list}
      <div key="Legal">
        <div className="uni-expander">
          <img className="toggle-icon" src={toggle} id={`button-${"Legal"}`} onClick={() => toggleState("Legal")} />
            <h2 className="statetitle">Legal</h2>
        </div>
        <div className="uni-list" id={"Legal"} style={hidden}>
          <div key="College of Law" className="unititle">
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
            <Link to={`certificate-frame/college-of-law`}>
              <p>College of Law (GDLP)</p>
            </Link>
          </div>
          <div key="College of Law" className="unititle">
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
            <Link to={`certificate-frame/supreme-court-vic`}>
              <p>Supreme Court VIC</p>
            </Link>
          </div>
          <div key="College of Law" className="unititle">
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
            <Link to={`certificate-frame/supreme-court-nsw`}>
              <p>Supreme Court NSW</p>
            </Link>
          </div>
          <div key="College of Law" className="unititle">
            <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_18x18.png" />
            <Link to={`certificate-frame/supreme-court-qld`}>
              <p>Supreme Court QLD</p>
            </Link>
          </div>
        </div>
      </div>
      <div className="frame-description">
        <h2>Why buy your degree frame from Gowning Street?</h2>
        <p className="mini-heading">Design:</p>
        <p>Our degree certificate frames are designed to precisely fit your University testamur</p>
        <p className="mini-heading">Craftsmanship:</p>
        <p>Our traditional frames are lovingly handcrafted using pine wood</p>
        <p className="mini-heading">Value:</p>
        <p>We design and manufacture our own frames, meaning we cut out the middle-man, ensuring you get the best value for money</p>
        <h3>Why buy a degree certificate frame?</h3>
        <p className="mini-heading">Protect and preserve</p>
        <p>Did you know your University testamur can lose colour/shape over time? The best way to preserve it is by placing it inside a degree certificate frame which will ensure it keeps its shape and colour over time</p>
        <p className="mini-heading">Showcase your achievement</p>
        <p>The hard work you put into achieveing this significant life milestone deserves to be cherished</p>
        <p className="mini-heading">A degree frame is the perfect gift</p>
        <p>This is the perfect gift to give a graduating student. You will have peace of mind in purchasing from Gowning Street as our degree certificate frames are made to each specific University's certificate dimensions</p>
      </div>

    </div>
  )

};

export default BuyFrames;