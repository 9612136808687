const shopList = [
  {
    label: 'All Regalia',
    link: '/graduation-gown'
  },
  {
    label: 'Bachelor Gown Set',
    link: '/graduation-gown/cap-and-gown-bachelor'
  },
  {
    label: 'Bachelor Gown',
    link: '/graduation-gown/gown-bachelor'
  },
  {
    label: 'Master Gown Set',
    link: '/graduation-gown/cap-and-gown-master'
  },
  {
    label: 'Master Gown',
    link: '/graduation-gown/master-gown'
  },
  {
    label: 'PhD Gown',
    link: '/graduation-gown/phd-gown-red-cloth',
  },
  {
    label: 'Graduation Hat',
    link: '/graduation-gown/graduation-hat',
  },
  {
    label: 'Graduation Hood',
    link: '/graduation-gown/graduation-hoods',
  },
  {
    label: 'PhD Hat',
    link: '/graduation-gown/phd-bonnet-red',
  },
  {
    label: 'Graduation Gifts',
    link: '/graduation-gifts',
  },
  {
    label: 'Graduation Puppy',
    link: '/graduation-gown/graduation-puppy-large',
  },
  {
    label: 'Graduation Teddy',
    link: '/graduation-gown/graduation-teddy-bear',
  },
  {
    label: 'Legal Wear',
    link: '/legal-wear',
  },
];

const stateList = [
  {
    label: 'NSW',
    submenuList: [
      { subLabel: 'USyd', subLink: '/uni/nsw/usyd' },
      { subLabel: 'UNE', subLink: '/uni/nsw/une' },
      { subLabel: 'Macquarie', subLink: '/uni/nsw/mq' },
      { subLabel: 'CSU', subLink: '/uni/csu' },
      { subLabel: 'UoN', subLink: '/uni/nsw/uon' },
      { subLabel: 'UWS', subLink: '/uni/nsw/uws' },
      { subLabel: 'SCU', subLink: '/uni/scu' },
      { subLabel: 'ACU', subLink: '/uni/acu' },
      { subLabel: 'UNSW', subLink: '/uni/nsw/unsw' },
      { subLabel: 'Notre Dame', subLink: '/uni/notre-dame' },
      { subLabel: 'UTS', subLink: '/uni/nsw/uts' },
    ]
  },
  {
    label: 'VIC',
    submenuList: [
      { subLabel: 'UniMelb', subLink: '/uni/vic/unimelb' },
      { subLabel: 'RMIT', subLink: '/uni/vic/rmit' },
      { subLabel: 'Monash', subLink: '/uni/vic/monash' },
      { subLabel: 'ACU', subLink: '/uni/acu' },
      { subLabel: 'Federation Uni', subLink: '/uni/vic/federation-university' },
      { subLabel: 'Victoria Uni', subLink: '/uni/vic/victoria-university' },
      { subLabel: 'Swinburne', subLink: '/uni/vic/swinburne' },
    ]
  },
  {
    label: 'QLD',
    submenuList: [
      { subLabel: 'UQ', subLink: '/uni/qld/uq' },
      { subLabel: 'QUT', subLink: '/uni/qld/qut' },
      { subLabel: 'JCU', subLink: '/uni/qld/jcu' },
      { subLabel: 'ACU', subLink: '/uni/acu' },
      { subLabel: 'SCU', subLink: '/uni/scu' },
      { subLabel: 'Bond Uni', subLink: '/uni/qld/bond' },
      { subLabel: 'CQU', subLink: '/uni/qld/cqu' },
      { subLabel: 'Griffith Uni', subLink: '/uni/qld/griffith' },
      { subLabel: 'USQ', subLink: '/uni/qld/usq' },
      { subLabel: 'USC', subLink: '/uni/qld/usc' },
    ]
  },
  {
    label: 'SA',
    submenuList: [
      { subLabel: 'Adelaide Uni', subLink: '/uni/sa/adelaide-uni' },
      { subLabel: 'UniSA', subLink: '/uni/sa/unisa' },
      { subLabel: 'Flinders Uni', subLink: '/uni/sa/flinders' },
      { subLabel: 'ACU', subLink: '/uni/acu' }
    ]
  },
  {
    label: 'WA',
    submenuList: [
      { subLabel: 'UWA', subLink: '/uni/wa/uwa' },
      { subLabel: 'ECU', subLink: '/uni/wa/ecu' },
      { subLabel: 'Curtin', subLink: '/uni/wa/curtin' },
      { subLabel: 'Notre Dame', subLink: '/uni/notre-dame' }
    ]
  },
  {
    label: 'TAS',
    submenuList: [
      { subLabel: 'UTas', subLink: '/uni/tas/utas' }
    ]
  },
  {
    label: 'ACT',
    submenuList: [
      { subLabel: 'ANU', subLink: '/uni/act/anu' },
      { subLabel: 'Canberra Uni' , subLink: '/uni/act/uc' },
      { subLabel: 'ACU' , subLink: '/uni/acu' }
    ]
  },
  {
    label: 'NT',
     submenuList: [
      { subLabel: 'CDU', subLink: '/uni/nt/cdu' },
    ]
  }
];

const framesList = [
  {
    label: 'NSW',
    submenuList: [
      { subLabel: 'ACU', subLink: '/certificate-frame/acu' },
      { subLabel: 'CSU', subLink: '/certificate-frame/csu' },
      { subLabel: 'Macquarie', subLink: '/certificate-frame/mq' },
      { subLabel: 'SCU', subLink: '/certificate-frame/scu' },
      { subLabel: 'UNE', subLink: '/certificate-frame/une' },
      { subLabel: 'UNSW', subLink: '/certificate-frame/unsw' },
      { subLabel: 'UOW', subLink: '/certificate-frame/uow' },
      { subLabel: 'UoN', subLink: '/certificate-frame/uon' },
      { subLabel: 'Notre Dame', subLink: '/certificate-frame/notre-dame' },
      { subLabel: 'USyd', subLink: '/certificate-frame/usyd' },
      { subLabel: 'UTS', subLink: '/certificate-frame/uts' },
      { subLabel: 'UWS', subLink: '/certificate-frame/uws' },
    ]
  },
  {
    label: 'VIC',
    submenuList: [
      { subLabel: 'ACU', subLink: '/certificate-frame/acu' },
      { subLabel: 'Federation Uni', subLink: '/certificate-frame/federation-university' },
      { subLabel: 'Monash', subLink: '/certificate-frame/monash' },
      { subLabel: 'RMIT', subLink: '/certificate-frame/rmit' },
      { subLabel: 'Swinburne', subLink: '/certificate-frame/swinburne' },
      { subLabel: 'UniMelb', subLink: '/certificate-frame/unimelb' },
      { subLabel: 'Victoria Uni', subLink: '/certificate-frame/victoria-university' },
    ]
  },
  {
    label: 'QLD',
    submenuList: [
      { subLabel: 'UQ', subLink: '/certificate-frame/uq' },
      { subLabel: 'QUT', subLink: '/certificate-frame/qut' },
      { subLabel: 'JCU', subLink: '/certificate-frame/jcu' },
      { subLabel: 'SCU', subLink: '/certificate-frame/scu' },
      { subLabel: 'ACU', subLink: '/certificate-frame/acu' },
      { subLabel: 'Bond Uni', subLink: '/certificate-frame/bond' },
      { subLabel: 'CQU', subLink: '/certificate-frame/cqu' },
      { subLabel: 'Griffith Uni', subLink: '/certificate-frame/griffith' },
      { subLabel: 'USQ', subLink: '/certificate-frame/usq' },
      { subLabel: 'USC', subLink: '/certificate-frame/usc' },
    ]
  },
  {
    label: 'SA',
    submenuList: [
      { subLabel: 'Adelaide Uni', subLink: '/certificate-frame/adelaide-uni' },
      { subLabel: 'UniSA', subLink: '/certificate-frame/unisa' },
      { subLabel: 'Flinders Uni', subLink: '/certificate-frame/flinders' },
      { subLabel: 'ACU', subLink: '/certificate-frame/acu' }
    ]
  },
  {
    label: 'WA',
    submenuList: [
      { subLabel: 'UWA', subLink: '/certificate-frame/uwa' },
      { subLabel: 'ECU', subLink: '/certificate-frame/ecu' },
      { subLabel: 'Curtin', subLink: '/certificate-frame/curtin' },
      { subLabel: 'Notre Dame', subLink: '/certificate-frame/notre-dame' }
    ]
  },
  {
    label: 'TAS',
    submenuList: [
      { subLabel: 'UTas', subLink: '/certificate-frame/utas' }
    ]
  },
  {
    label: 'ACT',
    submenuList: [
      { subLabel: 'ANU', subLink: '/certificate-frame/anu' },
      { subLabel: 'Canberra Uni' , subLink: '/certificate-frame/uc' },
      { subLabel: 'ACU' , subLink: '/certificate-frame/acu' }
    ]
  },
  {
    label: 'NT',
     submenuList: [
      { subLabel: 'CDU', subLink: '/certificate-frame/cdu' },
    ]
  },
  {
    label: 'Legal',
     submenuList: [
      { subLabel: 'College of Law', subLink: '/certificate-frame/college-of-law' },
      { subLabel: 'Sup. Court VIC', subLink: '/certificate-frame/supreme-court-vic' },
      { subLabel: 'Sup. Court NSW', subLink: '/certificate-frame/supreme-court-nsw' },
      { subLabel: 'Sup. Court QLD', subLink: '/certificate-frame/supreme-court-qld' },
    ]
  }
];

module.exports = { shopList, stateList, framesList }