import React from 'react';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../constants';

const ProductDescriptions = ({productInfo}) => {

    var description = null;
    var contactNumber = GOWNING_STREET_PHONE_NUMBER;

    let productName = productInfo.name;

    switch(productName){
      case "Graduation Hat" :
        description = (
          <div className="su-description">
            <h2>Hand Crafted Graduation Hats</h2>
            <p className="para">Our Graduation Hat is of premium quality and durability. Grad hats are the symbol that epitomise graduation day. Our graduation caps are the perfect memorabilia that you will keep lifelong to reminisce about your special day. You may pass your graduation hat  onto family and friends for their graduations to come.</p>
            <p className="para">Our Mortarboard Hat is manufactured with polyester and wool felt fabric. They are perfect for University, High School and Tafe graduations. Stitched to perfection, our Mortarboard is designed with a rigid insert, and elasticised fit for comfort and fit most heads. If in doubt, always order a graduation hat with a size up.</p>
            <p className="para">Each Mortarboard comes with a black tassel with a raised button. Our website makes it easy to order graduation caps online.</p>
            <p className="para">Graduation Caps and Accessories, Graduation Mortarboard, Graduation Hat, PHD Bonnets, Graduation gifts, Teddy Bears and Sashes and Stoles are all available online to purchase from our store.</p>
            <p className="para">Our aim is to make your graduation day a lasting and memorable experience and to do this our sole focus is to provide you with the perfect regalia</p>
            <p className="para">For bulk orders please contact us directly and a member of our team will be in touch to discuss options.</p>
            <p className="para">Go to our <Link to="/graduation-gown/phd-bonnet-red">PHD bonnets</Link> and <Link to="/graduation-gown/graduation-hoods">Sashes</Link> page to see our database of products available</p>
            <p className="para">And make sure to check out our <Link to="/graduation-gifts">Graduation Gifts</Link></p>
            <p className="para">If you have a <Link to="/save-with-friends">Bulk Order</Link> or special request please get in touch with our team.</p>
            <p className="para">If you are not sure which grad hat you need, or to request a sample graduation hat, please <Link to="/contact">contact us</Link>. One of our friendly team members will be in touch to assist you on options.</p>
            <p className="para">For your graduation caps online, Gowning Street is best positioned to offer you an exceptional product with fast delivery.</p>
          </div>
        )
        break;

        case "PhD Hat (Red Tassel)" :
        description = (
          <div className="su-description">
            <h2>PhD Hats</h2>
            <p className="para">Our PHD Hat is of superior quality and conform. PHD caps are a recognition of your significant academic achievement . Our PHD hats are a treasure that you will want to cherish forever and will be important for the many ceremonies you will attend. You may pass your phd tudor bonnet for generations to come for their phd graduation ceremonies.</p>
            <p className="para">Our Tudor bonnet  is manufactured with black velvet and scarlet cord and tassels.. They are perfect for your doctorate ceremonies. Hand Stitched to perform our PHD bonnet is designed with an elastic fit for comfort and will fit most people. If between sizes, always order a PhD hat with a size up.</p>
            <p className="para">Each phd cap comes with a scarlet tassel and you may order directly off our website to order your doctorate hat online.</p>
            <p className="para">PHD Graduation Caps and Accessories, doctorate hat, tudor bonnet, PHD gowns, Graduation hoods, grad teddy can be easily ordered online to purchase from our store.</p>
            <p className="para">Our goal is to ensure your graduation day is a magnificent experience and to achieve this we ensure you are looking sharp in your doctoral regalia.</p>
            <p className="para">For wholesale purchases please email us directly and a member of our friendly team will be in touch.</p>
            <p className="para">Go to our <Link to="/graduation-gown/phd-bonnet-yellow">PHD Gold Tassel Bonnets</Link> and <Link to="/graduation-gifts">Graduation Gifts</Link> page to see our range of products available</p>
            <p className="para">And make sure to check out our full range of <Link to="/graduation-gown">Graduation Regalia</Link> available</p>
            <p className="para">If you are ordering multiple items please check our <Link to="/save-with-friends">Bulk Order</Link> specials.</p>
            <p className="para">If you are not sure which phd hat you require, or to order a sample phd bonnet, please <Link to="/contact">contact us</Link>. One of our helpful team members will be in touch to assist.</p>
          </div>
        )
        break;

      default:
        description = null;

    }

    return (
      <div className="uni-description">
        {description}
      </div>
    )

}

export default ProductDescriptions;