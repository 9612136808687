import React from 'react';
import styled from 'styled-components'
import { Column, Container } from './utils/Layout';
import { AlphaHeader, BetaHeader } from './utils/Section';
import { mediaQueries } from './utils/mediaQueries';

const steps = [
  {
    id: 1,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Uni_Icon.png',
    header: 'Choose',
    description: 'Choose your gown, cap and hood for University or school graduation ',
  },
  {
    id: 2,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Sizing_Icon.png',
    header: 'Customise',
    description: 'Select the gown and cap size that fits you best',
  },
  {
    id: 3,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Delivery_Icon.png',
    header: 'Deliver',
    description: 'Select the delivery option based on your upcoming graduation date',
  },
  {
    id: 4,
    icon: 'https://res.cloudinary.com/gowningstreet/image/upload/v1550899593/Utility/Graduates_Icon.png',
    header: 'Celebrate',
    description: 'Graduate stress free without the hassle of renting and celebrate in style!',
  },
];

const SectionConainerWhite = styled.div`
  padding: 60px 15px;
  background-color: hsl(0, 0%, 96%);
  margin: 0 auto;
  ${mediaQueries.tablet`
    padding: 20px 15px;
  `};
  ${mediaQueries.mobile`
    padding: 35px 15px;
  `};
`
const StyledStepRow = styled.div`
  display:flex;
  flex-flow: row wrap;
  width: 90%;
  margin: 0 auto;
`

const Title = styled.div`
  min-height: 2rem;
  padding: 2px;
  display: inline-block;
  vertical-align: middle;
  hr {
    background-color: #DC572B;
    height: 2px;
    border: 0;
    display: block;
  }
`

const StepsContainer = styled.div`
  border-radius: .125rem;
  outline: 0;
  margin: 0 auto;
  text-align: center;
  display: flex;
  alignContent: center;
  alignItems: center;
  flex-direction: column;
  height: 340px;
  width: 265px;
  ${mediaQueries.tablet`
    padding: 0 18px;
  `};
  ${mediaQueries.mobile`
    height: 290px;
  `};
`

const SetHeightParagraph = styled.div`
  display: inline-block;
  margin: 0 auto;
  max-width: 14rem;
  height: 12rem;
  padding: 0.25rem 1rem;
  p {
    line-height: 20px;
    margin-top: 5px;
    padding: 10px 5px;
  }

`

const StepNumRow = styled.div`
  margin: 0 auto;
  padding: 8px;
  margin-top: 10px;
`

const StepNum = styled.div`
  border-radius: 50%;
  background-color: #041733;
  height: 18px;
  width: 18px;
  padding: 5px;
  text-align: center;
  display: inline-block;
  span {
    color: #FFFFFF;
    font-size: 12px;
  }
`
const StepIcon = styled.div`
  padding: 15px;
  img {
    height: 40px;
  }

`

const Steps = ({ steps }) => (
  <StyledStepRow>
    {
      steps.map((step) => (
        <Column key={step.id}>
          <StepsContainer>
            <SetHeightParagraph>
              <StepNumRow>
                <StepNum>
                  <span>{step.id}</span>
                </StepNum>
              </StepNumRow>
              <StepIcon><img src={step.icon} /></StepIcon>
              <Title>
                <BetaHeader>{ step.header }</BetaHeader>
                <hr />
              </Title>
              <p>{ step.description }</p>
            </SetHeightParagraph>
          </StepsContainer>
        </Column>
        )
      )
    }
  </StyledStepRow>
)


const HowItWorks = ({ children }) => {
  return (
    <SectionConainerWhite>
      <AlphaHeader>How To Buy Your Graduation Gown Set</AlphaHeader>
      <Steps steps={steps} />
      { children }
    </SectionConainerWhite>
  )
}

export default HowItWorks;

