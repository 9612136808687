import React, { Component } from 'react';

class Inventory extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(id) {
    var that = this;

    var productReq = new XMLHttpRequest();
    productReq.addEventListener('load', function(){
      var response = JSON.parse(this.response);
    })
    productReq.open("POST", "/api/adminapi/products/delete");
    productReq.setRequestHeader('Content-Type', 'application/json')
    productReq.send(JSON.stringify({id : id}));

  }

  render() {
    var inventory = this.props.inventory;
    var that = this;

    var showTable = inventory.map(function(element){
      return (
        <tr key={element.id}>
          <td>{element.code}</td>
          <td>{element.description}</td>
          <td>{element.qty}</td>
          <td><button onClick={that.handleSubmit.bind(that,element.id)}>X</button></td>
        </tr>
      )
    })

    return (
      <div className="inventory">
        <h1>Inventory</h1>
        <table>
          <thead>
            <tr>
              <th>Item Code</th>
              <th>Description</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {showTable}
          </tbody>
        </table>
      </div>
    )
  }

};

export default Inventory;