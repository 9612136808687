import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../constants';


const StateDescriptions = ({ stateName }) => {
    let description = null;

    switch(stateName){
      case "New South Wales | Graduation Gown sets for University Graduates in NSW" :
        description = (
          <div className="su-description">
            <h2>FOR A PREMIUM SYDNEY GRADUATION GOWN IN NSW</h2>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899101/Placement%20Photos/graduate-with-parents.jpg" alt="New South Wales graduate in academic gown hugging mother" />
            <p className="para">It’s difficult to describe the mix of feelings we have when officially finishing university. One of the more prevalent emotions, though, is of course happiness. Not just because there are no more exams, but for so many different reasons. You’ve been working really hard for years to achieve a goal, staying up all hours of the night to either study or put the finishing touches up on an important assignment, and you’ve successfully reached that goal. Dedication and determination got you over the line, and you should be proud of yourself.</p>
            <p className="para">One event that you’ll no doubt be attending is your graduation ceremony, at which you’ll need to be wearing the appropriate attire. If you’re trying wondering whether you’re going to be buying a gown or renting one but can’t decide, consider just some of the many benefits of buying your own from Gowning Street specifically:</p>
            <div className="list">
              <p>You’re able to hold onto it to remind you of the hard work and fun times you had during your time at uni</p>
              <p>Give it to a friend or relative to use when they graduate</p>
              <p>Our <Link to="/save-with-friends">bulk purchases</Link> (i.e. any more than one) come with discounted prices, ranging up to $10 off each set – get some friends on board and save!</p>
              <p>Speedy delivery directly to you throughout Sydney and New South Wales</p>
            </div>
            <h3>PROVIDING A WIDE RANGE OF INSTITUTIONS’ GRADUATION GOWN COLOURS</h3>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="NSW graduate smiling in graduation gown set" />
            <p className="para">We have made sure to bring our customers with an extensive collection of products from many different institutions, including the <Link to="/uni/nsw/usyd">University of Sydney</Link>, Southern Cross University and more. Our black Cambridge style generic bachelor cap and gown option is suitable for a wide range of degrees from many institutions, so wherever you studied, we’re sure to have something that’s perfect for you!</p>
            <h3>DELIVERING TO GRADUATES ALL OVER AUSTRALIA</h3>
            <p className="para">Whether your institution is <Link to="/uni/qld/qut">QUT</Link> or <Link to="/uni/qld/uq">UQ</Link> in New South Wales or elsewhere in Australia, there’s a high chance you’ll find a graduation gown for it here. Take advantage of our fantastic prices and forget about renting!</p>
            <p className="para">For any questions or concerns, please phone {GOWNING_STREET_PHONE_NUMBER} or send us an <Link to="/contact">online message.</Link></p>
          </div>
        )
      break;

      case "South Australia | Graduation Gown sets for University Graduates in SA" :
        description = (
          <div className="su-description">
            <h2>ADELAIDE’S QUALITY GRADUATION GOWN SUPPLIERS</h2>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165145/Placement%20Photos/happy-graduation-with-mother-portrait.png" alt="South Australian graduate in academic gown with mother" />
            <p className="para">Once you’ve received your final results and see that you’ve passed, it’s a wonderfully happy time. You are now armed with the wherewithal to put your best foot forward in the industry that you’ve always wanted to be a part of. Before all of that, though, is the graduation ceremony, where all of those sleepless nights studying and finishing assignments are recognised, and your hard work is rewarded with your well-earned Testamur. Getting up and accepting it requires a set of appropriate attire for the occasion – a graduation gown.</p>
            <h3>SHOULD YOU RENT OR BUY YOUR GOWN?</h3>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165148/Placement%20Photos/group-graduation-back-photo.jpg" alt="SA graduates lining up for their graduation" />
            <p className="para">When it comes to sourcing the right graduation gown, you need to decide whether you’d rather rent it, or if buying it would be a better choice. With so many benefits of purchasing from Gowning Street, buying is always the superior choice. Advantages include:</p>
            <div className="list">
              <p>Not having to deal with lengthy lines to pick up/drop off your rental</p>
              <p>You can keep it with you for the rest of your life to remind you of your university days</p>
              <p>By taking advantage of our <Link to="/save-with-friends">discount packages</Link>you can pay up to $10 less than the regular prices listed here</p>
              <p>It can be used multiple times, so friends and family can use it when needed</p>
              <p>The options that we have available to buy include both the University of Adelaide as well as the University of South Australia, with colours to suit many different faculties. We also have a generic cap and gown set that is suitable for a wide variety of institutions and courses. Be sure to have a good look through them all, and talk with your uni mates to ensure you’re getting the very best prices. Our stock also includes gowns for <Link to="/uni/nsw">New South Wales</Link> and <Link to="/uni/qld">Queensland</Link> unis plus more.</p>
            </div>
            <h3>GET IN TOUCH WITH US TODAY TO LEARN MORE</h3>
            <p className="para">We ship not just to Adelaide but also right around Australia. Whether you have a question regarding one or more of the gowns that we have in stock or you’re after some further information concerning shipping or something else, feel free to contact us on {GOWNING_STREET_PHONE_NUMBER}. If you’d prefer, you can also send us an <Link to="/contact">online enquiry</Link> which we’ll respond to ASAP.</p>
          </div>
        )
      break;

      case "Tasmania | Graduation Gown sets for University Graduates in TAS" :
        description = (
          <div className="su-description">
            <h2>TASMANIA’S FIRST CHOICE FOR ACADEMIC GOWNS</h2>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165147/Placement%20Photos/graduation-girl-with-mother-portrait.png" alt="Tasmanian graduate in academic gown hugging mother" />
            <p className="para">Completing tertiary studies is a huge achievement, and now it’s time to celebrate! Graduation is a great time to share the occasion with partners, family, friends and fellow graduates, and gives you a chance to reflect on one chapter of your life as you move into the next. Whether it’s a certificate, bachelor’s doctorate or anything in between, you deserve to mark the event in style.</p>
            <h3>WHY YOU SHOULD BUY YOUR GRADUATION GOWN</h3>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-celebrating-stairs.jpg" alt="TAS friends graduating together" />
            <p className="para">The gown is probably one of the first things you’ll think of when it comes to graduation—it’s a hallmark of the celebration. Most universities give you an option to hire a gown on the day, which seems like a convenient choice, right? On the contrary, you can buy premium quality academic gowns in Tasmania from Gowning Street and end up spending less than the overpriced rental from your university. Not convinced? Here are some more benefits of choosing us:</p>
            <div className="list">
              <p>Keep the gown as a sentimental item and a reminder of the work you put into your academic success</p>
              <p>Pass it on to friends and siblings to save them from needing to hire their own</p>
              <p>Take advantage of our <Link to="/save-with-friends">bundle prices</Link>by buying academic gowns in a group for as little as $69 per person</p>
              <p>Spilled some food or drink on the fabric? You can have it cleaned without stressing over any fees for damage or late return</p>
              <p>Skip the long queues at the hire booth on the day by having it delivered straight to your door in advance</p>
            </div>
            <h3>ENJOY PROMPT DELIVERY OF A GRADUATION GOWN ANYWHERE IN TASMANIA</h3>
            <p className="para">Find your course in the categories below to get the colour and design as set out by your university. Once you find what you need you can purchase it through our website and we’ll deliver your graduation gown to any address in Tasmania. If you need any help, don’t hesitate to call us on {GOWNING_STREET_PHONE_NUMBER} or make an <Link to="/contact">enquiry online.</Link></p>
          </div>
        )
      break;

      case "Queensland  | Graduation Gown sets for University Graduates in QLD" :
        description = (
          <div className="su-description">
            <h2>QUEENSLAND’S BEST ACADEMIC GRADUATION GOWNS</h2>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165122/Placement%20Photos/graduation-with-family.png" alt="Queensland graduate in academic gown hugging mother" />
            <p className="para">Finishing your university course is an exciting time. You’ve spent years working hard and staying on top of all of the assignments and have studied all hours for exams, and it’s paid off. When you’re looking for somewhere to pick up an academic gown for the graduation ceremony, you have the option of either renting or buying. So which is the better choice?</p>
            <p className="para">It may come as quite a surprise, but buying a set can actually work out cheaper than getting a rental. Not only that – you also won’t have to worry about rushing to return it so you don’t get stung with late fees. Also, how great would it be to hang onto it forever as a piece of personal memorabilia, a tangible reminder of all the great times you spent with your university friends all those years ago?</p>
            <h3>CATERING TO SEVERAL DIFFERENT ACADEMIC INSTITUTIONS IN QUEENSLAND</h3>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165137/Placement%20Photos/uni-archways-leaning-wide.jpg" alt="QLD graduate looking to the right in graduation gown set" />
            <p className="para">Further to the generic black bachelor cap and gown, you’ll find designs to suit institutions including UQ, QUT and USQ. So wherever it is you’re graduating from, we’re sure to be able to fit you with something to match. Our graduation gowns are all exceptionally manufactured, and you are bound to absolutely love what we deliver to you.</p>
            <p className="para">We have a <Link to="/save-with-friends">bundle savings</Link> deal available to save you and your mates money, too, which can end up saving you up to $10 per gown if you have 10 people on board! Take advantage of this sensational deal today.</p>
            <h3>FIND OUT MORE ABOUT WHAT WE CAN PROVIDE YOU</h3>
            <p className="para">Our available stock doesn’t just stop at Melbourne institutions. We supply for universities from <Link to="/uni/sa">South Australia</Link> to <Link to="/uni/nsw">New South Wales</Link> and beyond. Our delivery is available to the majority of Australia, and are proud to be able to provide quick shipping. If you’re after any further information regarding the gowns that we have available, please call us directly on {GOWNING_STREET_PHONE_NUMBER} to speak with one of our friendly staff.</p>
            <p className="para">Alternatively, you can use our <Link to="/contact">online enquiry form</Link> which we’ll respond to as soon as possible.</p>
          </div>
        )
      break;

      case "Australian Capital Territory | Graduation Gown sets for University Graduates in ACT" :
        description = (
          <div className="su-description">
            <h2>CANBERRA’S QUALITY GRADUATION GOWN SUPPLIERS</h2>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-glass-building.jpg" alt="Australian Capital Territory graduates graduating with friends" />
            <p className="para">Once you’ve received your final results and see that you’ve passed, it’s a wonderfully happy time. You are now armed with the wherewithal to put your best foot forward in the industry that you’ve always wanted to be a part of. Before all of that, though, is the graduation ceremony, where all of those sleepless nights studying and finishing assignments are recognised, and your hard work is rewarded with your well-earned Testamur. Getting up and accepting it requires a set of appropriate attire for the occasion – a graduation gown.</p>
            <h3>SHOULD YOU RENT OR BUY YOUR GOWN?</h3>
            <img className="mainstateimg" src="https://res.cloudinary.com/gowningstreet/image/upload/v1550899100/Placement%20Photos/graduates-leaving-ceremony.jpg" alt="ACT graduates celebrating" />
            <p className="para">When it comes to sourcing the right graduation gown, you need to decide whether you’d rather rent it, or if buying it would be a better choice. With so many benefits of purchasing from Gowning Street, buying is always the superior choice. Advantages include:</p>
            <div className="list">
              <p>Not having to deal with lengthy lines to pick up/drop off your rental</p>
              <p>You can keep it with you for the rest of your life to remind you of your university days</p>
              <p>By taking advantage of our <Link to="/save-with-friends">discount packages</Link> you can pay up to $10 less than the regular prices listed here</p>
              <p>It can be used multiple times, so friends and family can use it when needed</p>
            </div>
            <p className="para">The options that we have available to buy include both the University of Canberra as well as ANU, with colours to suit many different faculties. We also have a generic cap and gown set that is suitable for a wide variety of institutions and courses. Be sure to have a good look through them all, and talk with your uni mates to ensure you’re getting the very best prices. Our stock also includes gowns for <Link to="/uni/nsw">New South Wales</Link> and <Link to="/uni/qld">Queensland</Link> unis plus more.</p>
            <h3>GET IN TOUCH WITH US TODAY TO LEARN MORE</h3>
            <p className="para">We ship not just to Canberra but also right around Australia. Whether you have a question regarding one or more of the gowns that we have in stock or you’re after some further information concerning shipping or something else, feel free to contact us on {GOWNING_STREET_PHONE_NUMBER}. If you’d prefer, you can also send us an <Link to="/contact">online enquiry</Link> which we’ll respond to ASAP.</p>
          </div>
        )
      break;

      case "Victoria | Graduation Gown sets for  University Graduates in VIC" :
        description = (
          <div className="su-description">
            <h3>AN ALTERNATIVE TO GRADUATION GOWN HIRE IN MELBOURNE</h3>
            <p className="para">
              Finishing university is clearly a very exciting time. After all those years of hard work, stress and all-night
              study sessions, you finally achieved your goal. Your dedication and passion for your chosen field played
              an important role in this. You are happy and of course very proud of yourself. The graduation ceremony
              therefore is a very important event, celebrating this achievement and sharing the moment with your close
              friends and your family. This special event requires special attire- the classic graduation gown.
            </p>
            <p className="para">
              Many students find themselves in a bit of a dilemma whether they should buy or rent their graduation
              gowns. While most graduates are hiring it, there are clear advantages of buying your own. Believe it or
              not, it can be cheaper than hiring and there are so many other advantages to buying, like being able to
              keep it as a memory for the rest of your life or not having to queue at the hire pick up/drop off lines.
            </p>
            <h3>PROVIDING A WIDE RANGE OF GRADUATION GOWN CHOICE IN MELBOURNE</h3>
            <p className="para">
              Gowning street provides graduation gowns to such Universities in Victoria, including Melbourne
              University, Monash University, RMIT, Swinburne University and Victoria University, therefore you will find
              a suitable graduation gown no doubt. We have an extensive rage of products catering to all the
              universities mentioned above.
            </p>
            <p className="para">
              There are also plenty of discount packages, so it is worth teaming up with your friends as you can save
              up to $10 on each gown this way.
            </p>
            <h3>GET IN TOUCH WITH US FOR MORE DETAILS</h3>
            <p className="para">
              Whether your institution is in Victoria or just elsewhere in Australia, rest assured because it is almost
              certain that you will find a graduation gown and a graduation hood here.
            </p>
            <p className="para">
              If you have any questions, please get in touch with us on <a href={`tel:${GOWNING_STREET_PHONE_NUMBER}`} style={{'paddingRight': 0}}>{GOWNING_STREET_PHONE_NUMBER}</a> or <Link to="/contact" style={{'paddingRight': 0}}>contact us online</Link> and we will
              reply to your enquiry ASAP.
            </p>
          </div>
        )
      break;

      case "Western Australia | Graduation Gown sets for University Graduates in WA" :
        description = (
          <div className="su-description">
            <h3>THE BEST ALTERNATIVE FOR GRADUATION GOWN HIRE IN PERTH</h3>
            <p className="para">
              So, the time has come, your hard work, all those all-night study sessions, stressing and lack of sleep is a
              thing of the past. You fought the demon -your thesis- and now you are graduating. Well done, you should
              be proud of yourself- at least we really hope you are. Of course, this fabulous achievement must be
              celebrated the right way, which is a graduation ceremony. And this ceremony requires special attire, the
              classic grad gown.
            </p>
            <p className="para">
              This can cause a bit of a dilemma and confusion as many students don’t know whether if its better to buy
              or hire their cap and gown. With Gowning Street, you will find the best deals for buying them and it will
              save you the stress of pick up and drop off queues and you can keep it as a prized memory for the rest
              of your life.
            </p>
            <h3>PROVIDING THE BEST GRADUATION GOWN IN PERTH</h3>
            <p className="para">
              Gowning Street provides graduation regalia to such Universities in Western Australia including the
              University of Western Australia and Edith Cowan University which guarantees that everyone will find a
              suitable attire. The extensive range of grad gowns is the proof of that.
            </p>
            <p className="para">
              Not only that, but we offer a range of discount packages which will make you want to team up with you
              friends and classmates as you can save up to $10 on each gown. And your friends will thank you for the
              amazing deal too.
            </p>
            <h3>CONTACT US FOR MORE DETAILS</h3>
            <p className="para">
              No matter where your institution is -and we mean the whole country, not just Western Australia- because
              we deliver Australia-wide and you will surely find what you are looking for with us.
            </p>
            <p className="para">
              If you have any questions or enquiries, please get in touch with us on <a href={`tel:${GOWNING_STREET_PHONE_NUMBER}`} style={{'paddingRight': 0}}>{GOWNING_STREET_PHONE_NUMBER}</a> or <Link to="/contact" style={{'paddingRight': 0}}>online</Link> and we will
              reply to your enquiry ASAP.
            </p>
          </div>
        )
      break;


      default:
        description = null;
    }

  return (
    <div className="state-description">
      {description}
    </div>
  )

};

export default StateDescriptions;
