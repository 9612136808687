import React, { useState, useEffect } from 'react';
import { Products } from '../../sources/products';

const Gown = ({
  level = '',
  phdStyle = '',
  setGown,
  defaultGownCode
}) => {

  const [selections, setSelections] = useState(["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"]);
  const [selectedValue, setSelectedValue] = useState(defaultGownCode);

  useEffect(() => {
    const path = window.location.pathname;
    const category = level || Products[path].level;
    if (category === "Bachelor") {
      setSelections(["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"]);
    }
    else if (category === "Master") {
      setSelections(["GOW00011", "GOW00012", "GOW00013", "GOW00014", "GOW00015"]);
    }
    else if (category === "PhD") {
      switch (phdStyle) {
        case "cloth":
          setSelections(["GOW00021", "GOW00022", "GOW00023", "GOW00024", "GOW00025"]);
          break;

        case "red satin":
          setSelections(["GOW00041", "GOW00042", "GOW00043", "GOW00044", "GOW00045"]);
          break;

        default:
          setSelections(["GOW00031", "GOW00032", "GOW00033", "GOW00034", "GOW00035"]);
      }
    }
    else {
      setSelections(["GOW00001", "GOW00002", "GOW00003", "GOW00004", "GOW00005"]);
    };

    if (category === "Bachelor" && selectedValue[6] == 1) {
      setSelectedValue('GOW00003')
    } else if (category === "Master" && selectedValue[6] == 0) {
      setSelectedValue('GOW00013')
    }

  }, [level, phdStyle,]);

  const handleChange = (event) => {
    setSelectedValue(event.target.value)
    setGown(event.target.value);
  };
  return (
    <div className="gown-size">
      <h3>Choose your Gown size</h3>
      <p className="sizedesc">Based on your height</p>
      <select
        className="select-size"
        value={selectedValue}
        onChange={event => handleChange(event)}
      >
        <option value={selections[0]}>Extra Small (Less than 158cm)</option>
        <option value={selections[1]}>Small (159 - 164cms)</option>
        <option value={selections[2]}>Medium (165 - 170cms)</option>
        <option value={selections[3]}>Large (171 - 177cms)</option>
        <option value={selections[4]}>Extra Large (Greater than 178cms)</option>
      </select>
    </div>
  )
};

export default Gown;