import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Helmet from 'react-helmet';
import StateDescriptions from '../../sources/state-descriptions'
import UniStates from '../../sources/states';

const States = () => {
  let { pathname } = useLocation();
  const name = UniStates[pathname].name;
  const unis = UniStates[pathname].unis
  let titleTag = name + " Graduation & Academic Gowns";
  if (UniStates[pathname].titleTag) {
    titleTag = UniStates[pathname].titleTag;
  }

  const metaTag = "Buy full graduation gown sets in " + name + " for as little as $79 per set when you buy with friends, delivered directly to your door."
  if (UniStates[pathname].metaTag) {
    metaTag = UniStates[pathname].metaTag;
  }

  const Universities = unis.map(function (element) {
    return (
      <div className="uni" key={element.name}>
        <div className="uni-icon">
          <img src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165142/Utility/uni_icon_grey_24x24.png" />
        </div>
        <div className="uni-name">
          <Link to={element.path}>{element.name}</Link>
        </div>
      </div>
    )
  })

  return (
    <div className="state">
      <Helmet
        title={titleTag}
        meta={[
          { "name": "description", "content": metaTag },
        ]}
      />
      <h1>{name}</h1>
      <img className="mainstateimg" src={UniStates[pathname].photo} alt={UniStates[pathname].alt} />
      <div className="unislist">
        {Universities}
      </div>
      <StateDescriptions
        stateName={name}
      />
    </div>
  )

}

export default States;