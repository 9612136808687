import React from 'react';
import Helmet from 'react-helmet';

const uniRegData = require("../../sources/uni-regulations");
import { useParams } from "react-router-dom";


const Regulations = ({})  => {
  const { uni } = useParams();
console.log(useParams())
    var title = "Academic Dress Regulations at " + uni;

    // if (Object.keys(UniRegData).indexOf(Uni) === -1) {
    //   browserHistory.push("/404")
    // };

  return (
    <div className="regulations">
      <Helmet
        title={title}
        meta={[
          {"name": "Academic regalia regulations at " + {uni}, "content": "Description of academic dress regulations at " + {uni}},
        ]}
      />
      <h1>Academic Dress Regulations at {uni}</h1>
      <p>Graduation regalia at {uni} are the modern version of a 12th century tradition among medieval monks. These elaborate robes symbolise education, scholarship and accomplishment – hallmarks of all {uni} students. Academic regalia at {uni} is worn at graduation and other ceremonial occasions to represent the long academic tradition and achievements of the university</p>

      <h2>Graduation gowns at {uni}</h2>
      <p>At {uni} university: Bachelors wear the Oxford or Cambridge style of black gown. Masters wear the Oxford/Cambridge Masters style of black gown. The Masters style gown distinction from the Bachelors style is that it has a longer sleeve. Doctorates and PHD have their own distinctive gown with coloured facing</p>

      <h2>Graduation hats at {uni}</h2>
      <p>{uni} bases its graduation hat design on the ‘biretta’ worn by Roman Catholic clergy in medieval times. The cap consists of a horizontal square board fixed upon a skull cap with a tassel attached to the center. This ‘motorboard’ design is worn by Bachelors and Masters graduates. {uni} Doctors wear a design variation called the Tudor bonnet. This is a a traditional soft-crowned, round-brimmed cap, with a tassel hanging from a cord encircling the hat</p>

        <h2>Academic Regalia Manufacturing at Gowning Street</h2>
        <p>Premium design at affordable prices<br></br><br></br>At Gowning Street, our passion in gown making is reflected in the precision of our design and in the quality of our fabrics. At the same time, we work hard to ensure your comfort and convenience so that you can forever cherish this most important day of your life<br></br><br></br>Our fast delivery, group discounts and customised fitting let you enjoy a stress free graduation – take photos at your convenience without worrying about damaging your gown or returning it within the two-hour rental window. Buying from Gowning Street is more affordable than renting and you get to treasure your graduation regalia for life</p>

      <p></p>

    </div>
  )

};

export default Regulations;