import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import styled from 'styled-components';
import { connect } from 'react-redux';
import axios from 'axios';

import Drawer from 'material-ui/Drawer';
import DrawerContent from './homepage/drawercontent';
import Menu from './menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const { shopList, stateList, framesList } = require('../sources/navLinks')


const MenuContainer = styled.div`
  display: ${({ visibleMenu }) => visibleMenu ? 'block' : 'none' };
  position: absolute;
  height: 30vh;
  width: 100%;
`

const Header = ({
  order,
  customer,
  dropCart,
  dropCustomer,
  children,
}) => {
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState(null);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [itemsInCart, setItemsInCart] = useState(order.length);

  useEffect(() => {
    const checkPrice = async () => {
      const result = await axios.post('/api/checkPrice', {
        items: order,
        customer: customer
      })
      return result;
    };

    if (order.length > 0) {
      try {
        checkPrice().then((checkPriceResult) => {
          if (!checkPriceResult.data.success) {
            dropCart();
            dropCustomer();
            history.push('/');
          }
        })
      } catch (error) {
        history.push('/');
      }
    }
  }, []);

  useEffect(() => {
    let itemsCount = 0;
    order.forEach(function (element) {
      itemsCount += element.qty;
    });
    setItemsInCart(itemsCount)
  }, [order]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleClose = (route) => {
    setOpen(false);
    history.push(route);
  };

  const navigate = (route) => {
    history.push(route);
  }

  const toggleMenu = (e, key) => {
    setMenu(key)
    setVisibleMenu(!visibleMenu);
  }

  const getListByMenuType = ({ menuType }) => {
    const menuList = {
      uni: stateList,
      shop: shopList,
      frames: framesList,
    };
    return menuList[menuType]
  }
  const nav = "https://res.cloudinary.com/gowningstreet/image/upload/v1549165140/Utility/menu_grey_24x24.png";

  const useComponentVisible =(initialIsVisible) => {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
        if (event.key === 'Escape') {
            setIsComponentVisible(false);
        }
    };

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleHideDropdown, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keydown', handleHideDropdown, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  }

const { ref, isComponentVisible } = useComponentVisible(true);

  return (
    <div>
      <div className="header">
        <div className="banner">
          {/*            <div id="flags">
              <a><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/Australia_Flag.png"/></a>
              <a target="blank" href="https://www.graduationgowns.co.nz"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/New_Zealand_Flag.png"/></a>
              <a href="https://www.gowningstreet.co.uk"><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1588678348/Utility/United_Kingdom_Flag.png"/></a>
            </div>*/}
          <p style={{ width: '100%' }}>Australia's most affordable, quality, local graduation gown provider</p>
        </div>
        <div className="headertop">
          <img
            onClick={handleToggle}
            src={nav}
            id="nav-icon"
          />
          <img
            className="homelogo"
            onClick={() => navigate("/")}
            src="https://res.cloudinary.com/gowningstreet/image/upload/v1550922414/Utility/Gowningstreet_logo.jpg"
          />
          <Link id="cart-and-items" to="/cart">
            <img
              src="https://res.cloudinary.com/gowningstreet/image/upload/v1549165120/Utility/shopping_cart_grey_24x24.png"
              id="cart-icon"
            />
            <p
              style={{ color: 'grey' }}
              id="cart-items"
            >
              {itemsInCart}
            </p>
          </Link>
        </div>
        <div className="navbar">
          <a className="navItem" onClick={(e) => toggleMenu(e, 'uni')}>
            Uni Sets
            {menu === 'uni'
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron" />
            }
          </a>
          <a className="navItem" onClick={(e) => toggleMenu(e, 'frames')}>
            Frames
            {menu === 'frames'
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron" />
            }
          </a>
          <a className="navItem" onClick={(e) => toggleMenu(e, 'shop')}>
            Shop
              {menu === 'shop'
              ? <FontAwesomeIcon icon={faChevronDown} className="chevron" />
              : <FontAwesomeIcon icon={faChevronUp} className="chevron" />
            }
          </a>

          <a className="navItem" onClick={() => navigate("/save-with-friends")}>Specials</a>
          <a className="navItem" onClick={() => navigate("/contact")}>Contact</a>
        </div>
        <Drawer
          docked={false}
          width={280}
          open={open}
          className="drawer"
          onRequestChange={() => setOpen(!open)}
        >
          <DrawerContent
            close={handleClose}
          />
        </Drawer>
        {children}
      </div>
      <MenuContainer
        visibleMenu={visibleMenu}
        ref={ref}
      >
        <div className="menuBlocks" style={{ background: 'yellow !important'}}>
          {
            visibleMenu &&
            <div style={{ display: 'flex' }}>
              <Menu
                className="menu"
                onNavigate={navigate}
                list={getListByMenuType({ menuType: menu })}
                menuType={menu}
                closeMenu={(event) => {
                  toggleMenu(event, null)
                }}
              />
            </div>
          }
        </div>
      </MenuContainer>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    customer: state.customer,
    order: state.shoppingCart
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateState: (field, value) => {
      dispatch({
        type: 'UPDATE_CUSTOMER',
        field: field,
        value: value
      })
    },
    dropCart: () => {
      dispatch({
        type: 'EMPTY_CART'
      })
    },
    dropCustomer: () => {
      dispatch({
        type: 'DROP_CUSTOMER'
      })
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

