import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { GOWNING_STREET_PHONE_NUMBER } from '../../constants';
class Contact extends Component {

  constructor(props) {
    super(props);

    this.state = {
      name : "",
      email : "",
      number : "",
      query : "",
      hasSubmitted : false,
      error : null
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

  }

  handleChange(field, event) {
    var nextState = {};
    nextState[field] = event.target.value;

    this.setState(nextState);
  }

  handleSubmit() {
    var that = this;

    if (this.state.name === "" ||
      this.state.email === "" ||
      this.state.number === "" ||
      this.state.query === "") {
      this.setState({
        error: "Please fill in all fields"
      })
    }

    else {
      var subReq = new XMLHttpRequest();
      subReq.addEventListener("load", function () {

        if (this.status === 200) {
          that.setState({
            hasSubmitted: true
          })
        }
        else {
          that.setState({
            error: "Sorry, your request could not be submitted. Please send us an email instead"
          })
        }

      });
      subReq.open("POST", "/api/contactform");
      subReq.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      subReq.send(JSON.stringify({
        name: this.state.name,
        email: this.state.email,
        number: this.state.number,
        query: this.state.query,
      }))

    }
  }

  render() {
    var formDisplay = null;

    if (!this.state.hasSubmitted) {
      formDisplay = (
        <div className="contact-form">
          <input
            type="text"
            placeholder="Name*"
            onChange={this.handleChange.bind(this, "name")}
          />
          <input
            type="text"
            placeholder="Email*"
            onChange={this.handleChange.bind(this, "email")}
          />
          <input
            type="text"
            placeholder="Best Contact Number*"
            onChange={this.handleChange.bind(this, "number")}
          />
          <textarea
            placeholder="Your query*"
            onChange={this.handleChange.bind(this, "query")}
          />
          <button onClick={this.handleSubmit}>Submit</button>
          <p className="error">{this.state.error}</p>
        </div>
      )
    }
    else {
      formDisplay = (
        <div className="thanksforsubmitting">
          <p>Thank you for submitting your enquiry.</p>
          <p>Our support team will aim to respond to your query within 24 hours.</p>
          {/* <p>
            Please note that our head office will be closed from 21 Dec to Jan 9 during the Christmas / Holiday period. Orders will continue to ship, but support and email responses will not be available.
          </p> */}
          <p>In the mean time, the answer to your questions may be in our <Link to="/faq">FAQ</Link></p>
        </div>
      )
    }

    return (
      <div className="contact-us">
        <Helmet
          title="Contact Us | Gowning Street"
          meta={[
            { "name": "description", "content": "Got any questions about our products or services? You can contact us by phone or email." },
          ]}
        />
        <div className="contact-content">
          <h1>Contact Us</h1>
          <p className="description">Have questions? Please look at our <Link to="/faq">FAQ</Link> page in the first instance.
          </p>
          <p className="description">
            Please note that we do not provide rental services. Our regalia is for sale only and often at below rental cost.
          </p>
          <p className="description">
            If you would like a shipping estimate, it is available on our <Link to="/shipping">shipping estimates</Link> page.
          </p>
          <p className="description">
            If you can't find what you're looking for our support team is available to assist you. Call us 9am - 5pm, Monday to Friday, or email us any time and we will aim to respond to your query within 24 hours
          </p>
          <h2>Email</h2>
          <p className="email">enquiries@gowningstreet.com</p>
          <h2>Phone</h2>
          <p>{GOWNING_STREET_PHONE_NUMBER}</p>
          <h2>Contact Form</h2>
          {formDisplay}
          <h3>Connect with us on <a target="#" href="https://www.facebook.com/gowningstreet/">Facebook</a><img src="https://res.cloudinary.com/gowningstreet/image/upload/v1550903393/Utility/Facebook_icon.png"/></h3>
        </div>
      </div>
    )
  }
};

export default Contact;