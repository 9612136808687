import React from 'react';
import Helmet from 'react-helmet';

const Privacy = () => {

  return (
    <div className="privacy-policy">
      <Helmet
        title="Privacy Policy | Gowning Street"
        meta={[
          {"name": "description", "content": "Gowning Street is serious about protecting your privacy - please read our privacy policy for more information"},
        ]}
      />
      <div className="privacy-contents">
        <h1>Privacy Policy</h1>
        <h2>Introduction</h2>
          <p>Gowning Street regards customer privacy as an important part of our relationship with our customers. The following privacy policy applies to all Gowning Street users, and conforms to Internet privacy standards.</p>
        <h2>Collection of Information</h2>
          <p>In order to use the Gowning Street website, we may require information from you in order to provide the best service possible.</p>
          <p>All correspondence may also be collected and stored, particularly in regard to sales, support and accounts, including email.</p>
          <p>Any information collected by Gowning Street is collected via correspondence from you or your company. This may be via the telephone, email, mail, fax or directly through our website.</p>
        <h2>Use of Collected Information</h2>
          <p>Any details collected from Gowning Street customers is required in order to provide you with our products and/or services, and a high level of customer service.</p>
          <p>Correspondence is recorded in order to provide service references, and to assist in our staff development.</p>
        <h2>Storage of Collected Information</h2>
          <p>The security of your personal information is important to us. When you enter sensitive information (such as credit card numbers) on our website, we encrypt that information using secure socket layer technology (SSL). When Credit Card details are collected, we simply pass them on in order to be processed as required. We never permanently store complete Credit Card details.</p>
          <p>We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it.</p>
          <p>If you have any questions about security on our Website, you can email us at <span className="email">enquiries@gowningstreet.com</span>.</p>
        <h2>Access to Collected Information</h2>
          <p>If you would like us to delete or modify any of your stored information, you may contact us via email</p>
        <h2>Orders</h2>
          <p>If you purchase a product or service from us, we may request certain personally identifiable information from you. You may be required to provide contact information (such as name, email, contact number and postal address). Financial information (such as credit card number, expiration date) is passed on to a 3rd party provider, such as Paypal or Stripe.</p>
          <p>We use this information for billing purposes and to fill your orders. If we have trouble processing an order, we will use this information to contact you.</p>
        <h2>Communications</h2>
          <p>Gowning Street uses personally identifiable information for essential communications, such as Emails, accounts information, and critical service details. We may also use this information for other purposes, including some promotional Emails. If at any time a customer wishes not to receive such correspondence, they can request to be removed from any mailing lists by emailing us at <span className="email">enquiries@gowningstreet.com</span>.</p>
          <p>You will be notified when your personal information is collected by any third party that is not our agent/service provider, so you can make an informed choice as to whether or not to share your information with that party.</p>
        <h2>Third Parties</h2>
          <p>Gowning Street may at its discretion use other third parties to provide essential services on our site or for our business processes. We may share your details as necessary for the third party to provide that service.</p>
          <p>These third parties are prohibited from using your personally identifiable information for any other purpose.</p>
          <p>Gowning Street does not share any information with third parties for any unknown or unrelated uses.</p>
        <h2>Legal</h2>
          <p>We reserve the right to disclose your personally identifiable information as required by law and when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, court order, or legal process served on our Website.</p>
        <h2>Links</h2>
          <p>Links on the Gowning Street site to external entities are not covered within this policy. The terms and conditions set out in this privacy statement only cover the domain name of  www.gowningsteet.com.au</p>
        <h2>Changes to Privacy Policies</h2>
          <p>If we decide to change our privacy policy, we will post those changes to this privacy statement, the homepage, and other places we deem appropriate so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it. We reserve the right to modify this privacy statement at any time, so please review it frequently. If we make material changes to this policy, we will notify you here, by Email, or by means of a notice on our homepage.</p>
        <h2>Gowning Street Security Policy</h2>
          <p>Gowning street uses the Stripe payment gateway for its online credit card transactions. Stripe processes online credit card transactions for thousands of Australian merchants, providing a safe and secure means of collecting payments via the internet</p>
          <p>All online credit card transactions performed on this site using the Stripe gateway are secured payments</p>
          <p>Payments are fully automated with an immediate response.</p>
          <p>Your complete credit card number cannot be viewed by Gowning Street or any outside party.</p>
          <p>All transactions are performed under 128 Bit SSL Certificate.</p>
          <p>All transaction data is encrypted for storage within Stripe’s bank-grade data centre, further protecting your credit card data.</p>
          <p>Stripe is an authorised third party processor for all the major Australian banks.</p>
          <p>Stripe at no time touches your funds; all monies are directly transferred from your credit card to the merchant account held by Gowning Street.</p>
      </div>
    </div>
  )

};

export default Privacy;