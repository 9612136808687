const {
  STANDARD_SHIPPING_PRICE,
  STANDARD_SIGNATURE_SHIPPING_PRICE,
  EXPRESS_SHIPPING_PRICE,
  EXPRESS_SIGNATURE_SHIPPING_PRICE,
  INTERNATIONAL_SHIPPING_PRICE,
  ISN_SHIPPING_PRICE,
} = require('../constants')

const ShippingCalc = function(order,customer){

  const shippingOptions = {
    standard : {
      price : STANDARD_SHIPPING_PRICE,
      code : 155
    },
    "standard-signature" : {
      price : STANDARD_SIGNATURE_SHIPPING_PRICE,
      code : 55
    },
    express: {
      price : EXPRESS_SHIPPING_PRICE,
      code : 154
    },
    "express-signature": {
      price : EXPRESS_SIGNATURE_SHIPPING_PRICE,
      code : 54
    },
    "international": {
      price : INTERNATIONAL_SHIPPING_PRICE,
      code : 118
    },
    isn: {
      price: ISN_SHIPPING_PRICE,
      code: 155
    }
  }

  var shippingChoice = shippingOptions[customer.shipping];

  let qty = 0;
  order.forEach(function(item){
    if (item.qty) {
      qty += item.qty;
    } else {
      qty += 1;
    }
  });

  return {
    price : shippingChoice.price,
    qty : qty,
    total : shippingChoice.price,
    code : shippingChoice.code
  };

};

module.exports = ShippingCalc;